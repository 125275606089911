import config from "console_config";

export const ANALYTICS_ENABLED = config.ID_GOOGLE_TAG_MANAGER;
export const PROJECT_ID_FIELD = "id";
export const ORGANIZATION_ID_FIELD = "name";
export const ENVIRONMENT_ID_FIELD = "id";
export const TEAM_ID_FIELD = "name";
export const URL_SCREENSHOT_API = config.URL_SCREENSHOT_API;
export const PLATFORMER_ROLES = [
  "team member",
  "platform admin read-only",
  "platform admin"
];
export const ADMIN_ROLES = [...PLATFORMER_ROLES, "administrator"];
export const PLATFORM_ADMIN_ROLE = "platform admin";
export const SAFE_ROLES = [
  ...ADMIN_ROLES,
  "invoice account",
  "technical support"
];
export const SUPPORT_ROLES = [
  "administrator",
  "sales team",
  "technical support"
];
export const BILLING_PATH_MATCH =
  /^\/[A-z0-9-]+\/[A-z0-9-]+\/plan$|^\/-\/users\/[A-z0-9-]+\/billing/;
export const SETTINGS_PATH_MATCH =
  /^\/[A-z0-9-]+\/[A-z0-9-]+\/plan$|^\/-\/users\/[A-z0-9-]+\/settings/;
export const SUPPORT_PATH_MATCH =
  /^\/[A-z0-9-]+\/[A-z0-9-]+\/plan$|^\/-\/users\/[A-z0-9-]+\/tickets/;

export const PLAN_TYPES = {
  "trial/development": "Trial",
  development: "Development",
  essential: "Essential",
  small: "Small",
  standard: "Standard",
  "high-mem/standard": "Standard High Mem",
  "standard-high-memory": "Standard High Mem",
  medium: "Medium",
  "high-mem/medium": "Medium High Mem",
  "medium-high-memory": "Medium High Mem",
  large: "Large",
  "large-high-memory": "Large High Mem",
  "high-mem/large": "Large High Mem",
  xlarge: "Xlarge",
  "high-mem/xlarge": "X-Large High Mem",
  "xlarge-high-memory": "X-Large High Mem",
  "2xlarge": "2xlarge",
  "high-mem/2xlarge": "2X-Large High Mem",
  "2xlarge-high-memory": "2X-Large High Mem"
};

export const BACKUP_LABEL = {
  BACKUPS_ESSENTIAL: "BASIC",
  BACKUPS_BASIC: "BASIC",
  BACKUPS_ADVANCED: "PLATFORM_FEATURE_BACKUPS_ADVANCED",
  BACKUPS_PREMIUM: "PLATFORM_FEATURE_BACKUPS_PREMIUM"
};

// TODO remove all "ESSENTIAL" after BackEnd has replaced "ESSENTIAL" plan with "BASIC" plan
// The BackEnd is changing the name for the "Essential" plan to "Basic". To ensure no breaks, both cases are being considered.
const basicType = {
  label: "Basic",
  manual: 2,
  automated: 2
};
// There are two different "advanced" backups returned at different times, "ADVANCED", is a "default" type, and "PLATFORM_FEATURE_BACKUPS_ADVANCED", is a "sellable" type
const advancedType = {
  label: "Advanced",
  manual: 4,
  automated: 21
};

export const BACKUP_TYPES = {
  BASIC: basicType,
  ESSENTIAL: basicType,
  ADVANCED: advancedType,
  PLATFORM_FEATURE_BACKUPS_ADVANCED: advancedType,
  PLATFORM_FEATURE_BACKUPS_PREMIUM: {
    label: "Premium",
    manual: 4,
    automated: 44
  }
};

export const BACKUP_DESCRIPTIONS = {
  Basic:
    "Automated backups are stored for 2 days. You can store up to 2 manual backups at any time.",
  Advanced:
    "21 automated and 4 manual backups. {br}Each daily backup is stored for 1 week. {br}Each weekly backup is stored for 1 month. {br}Each monthly backup is stored for 1 year.",
  Premium:
    "44 automated backups and 4 manual backups. {br}Each 6-hourly backup is stored for 1 day. {br}Each daily backup is stored for 1 month. {br}Each monthly backup is stored for 1 year."
};

export const GIG = 1024;
export const STORAGE_SUFFIX = "GB";

// List of available environment types, we don't get them from the server
// so we need to hardcode them
export const ENVIRONMENT_TYPES = [
  "production",
  "staging",
  "development"
] as const;

// Values used to parse the date into a natural language format
export const MOMENTJS_OPTIONS = {
  relativeTime: {
    future: "in %s",
    past: "%s",
    s: "%d sec",
    ss: "%d secs",
    m: "%d min",
    mm: "%d mins",
    h: "%d hr",
    hh: "%d hrs",
    d: "%d day",
    dd: "%d days",
    M: "%d month",
    MM: "%d months",
    y: "%d year",
    yy: "%d years"
  }
};
export const MOMENTJS_TIME_FORMAT = "HH:mm MMM D, YYYY";

export const PERMISSIONS = [
  { value: "billing", label: "Manage billing" },
  { value: "plans", label: "Manage plans" },
  { value: "members", label: "Manage users" },
  { value: "projects:create", label: "Create projects" }
];

export const ACTIVITY_CONTEXT = {
  Environment: "environment",
  Integration: "integration",
  Project: "project"
} as const;

export const SUBSCRIPTION_PLAN = [
  // The order of the plan is important as in this case it is sorted in
  // ascending order of the plan size
  "ovh-team-4",
  "ovh-soho-1",
  "ovh-soho-2",
  "ovh-soho-4",
  "ovh-large-team-1",
  "ovh-large-team-2",
  "ovh-large-team-4",
  "ovh-large-team-8",
  "ovh-large-team-16",
  "ovh-soho-8",
  "ovh-team-1",
  "ovh-team-2",
  "ovh-team-8",
  "development",
  "trial/development",
  "small",
  "essential",
  "trial/standard",
  "standard",
  "high-mem/standard",
  "standard-high-memory",
  "trial/medium",
  "medium",
  "high-mem/medium",
  "medium-high-memory",
  "trial/large",
  "large",
  "high-mem/large",
  "large-high-memory",
  "xlarge",
  "high-mem/xlarge",
  "xlarge-high-memory",
  "trial/2xlarge",
  "2xlarge",
  "high-mem/2xlarge",
  "2xlarge-high-memory",
  "grid/xlarge",
  "4xlarge",
  "grid/2xlarge",
  "grid/4xlarge",
  "grid/8xlarge"
];

export const ACTIVITY_TYPES = Object.freeze({
  CDN_FASTLY_ORIGIN: {
    RESTRICTION: {
      DISABLE: "cdn.fastly.origin_restriction_disable",
      SETUP: "cdn.fastly.origin_restriction_setup"
    }
  },
  ENVIRONMENT: {
    ACCESS: {
      ADD: "environment.access.add",
      REMOVE: "environment.access.remove"
    },
    ACTIVATE: "environment.activate",
    BACKUP: "environment.backup",
    BACKUP_DELETE: "environment.backup.delete",
    BRANCH: "environment.branch",
    CERTIFICATE_RENEWAL: "environment.certificate.renewal",
    CRON: "environment.cron",
    DEACTIVATE: "environment.deactivate",
    DELETE: "environment.delete",
    DOMAIN: {
      CREATE: "environment.domain.create",
      DELETE: "environment.domain.delete",
      UPDATE: "environment.domain.update"
    },
    INITIALIZE: "environment.initialize",
    MERGE: "environment.merge",
    MERGE_PR: "environment.merge-pr",
    OPERATION: "environment.operation",
    PAUSE: "environment.pause",
    PUSH: "environment.push",
    REDEPLOY: "environment.redeploy",
    RESOURCES: { UPDATE: "environment.resources.update" },
    RESTORE: "environment.restore",
    RESUME: "environment.resume",
    ROUTE: {
      CREATE: "environment.route.create",
      DELETE: "environment.route.delete",
      UPDATE: "environment.route.update"
    },
    SOURCE_OPERATION: "environment.source-operation",
    SUBSCRIPTION: { UPDATE: "environment.subscription.update" },
    SYNCHRONIZE: "environment.synchronize",
    UPDATE: {
      HTTP_ACCESS: "environment.update.http_access",
      RESTRICT_ROBOTS: "environment.update.restrict_robots",
      SMTP: "environment.update.smtp"
    },
    VARIABLE: {
      CREATE: "environment.variable.create",
      DELETE: "environment.variable.delete",
      UPDATE: "environment.variable.update"
    }
  },
  INTEGRATION: {
    BITBUCKET: {
      FETCH: "integration.bitbucket.fetch",
      REGISTER_HOOKS: "integration.bitbucket.register_hooks",
      SERVER: {
        FETCH: "integration.bitbucket_server.fetch",
        REGISTER_HOOKS: "integration.bitbucket_server.register_hooks"
      }
    },
    BLACKFIRE: { WEBHOOK: "integration.blackfire.webhook" },
    GITHUB: {
      FETCH: "integration.github.fetch",
      REGISTER_HOOKS: "integration.github.register_hooks"
    },
    GITLAB: {
      FETCH: "integration.gitlab.fetch",
      REGISTER_HOOKS: "integration.gitlab.register_hooks"
    },
    HEALTH: {
      EMAIL: "integration.health.email",
      PAGERDUTY: "integration.health.pagerduty",
      SLACK: "integration.health.slack",
      WEBHOOK: "integration.health.webhook"
    },
    SCRIPT: "integration.script",
    WEBHOOK: "integration.webhook"
  },
  MAINTENANCE: { UPGRADE: "maintenance.upgrade" },
  PROJECT: {
    CLEAR_BUILD_CACHE: "project.clear_build_cache",
    CREATE: "project.create",
    SUSPEND: "project.suspend",
    RESUME: "project.resume",
    DOMAIN: {
      CREATE: "project.domain.create",
      DELETE: "project.domain.delete",
      UPDATE: "project.domain.update"
    },
    MODIFY_TITLE: "project.modify.title",
    VARIABLE: {
      CREATE: "project.variable.create",
      DELETE: "project.variable.delete",
      UPDATE: "project.variable.update"
    }
  }
} as const);

export const activityTypeRun = [
  ACTIVITY_TYPES.ENVIRONMENT.OPERATION,
  ACTIVITY_TYPES.ENVIRONMENT.SOURCE_OPERATION
];

export const activityTypeBuilds = [
  ACTIVITY_TYPES.ENVIRONMENT.BRANCH,
  ACTIVITY_TYPES.ENVIRONMENT.INITIALIZE,
  ACTIVITY_TYPES.ENVIRONMENT.MERGE,
  ACTIVITY_TYPES.ENVIRONMENT.PUSH,
  ACTIVITY_TYPES.ENVIRONMENT.REDEPLOY,
  ACTIVITY_TYPES.ENVIRONMENT.RESTORE,
  ACTIVITY_TYPES.ENVIRONMENT.ROUTE.CREATE,
  ACTIVITY_TYPES.ENVIRONMENT.ROUTE.DELETE,
  ACTIVITY_TYPES.ENVIRONMENT.ROUTE.UPDATE,
  ACTIVITY_TYPES.ENVIRONMENT.VARIABLE.CREATE,
  ACTIVITY_TYPES.ENVIRONMENT.VARIABLE.DELETE,
  ACTIVITY_TYPES.ENVIRONMENT.VARIABLE.UPDATE,
  ACTIVITY_TYPES.ENVIRONMENT.SYNCHRONIZE,
  ACTIVITY_TYPES.INTEGRATION.BITBUCKET.FETCH,
  ACTIVITY_TYPES.INTEGRATION.BITBUCKET.REGISTER_HOOKS,
  ACTIVITY_TYPES.INTEGRATION.BITBUCKET.SERVER.FETCH,
  ACTIVITY_TYPES.INTEGRATION.BITBUCKET.SERVER.REGISTER_HOOKS,
  ACTIVITY_TYPES.INTEGRATION.GITHUB.FETCH,
  ACTIVITY_TYPES.INTEGRATION.GITLAB.FETCH,
  ACTIVITY_TYPES.PROJECT.CREATE,
  ACTIVITY_TYPES.PROJECT.VARIABLE.CREATE,
  ACTIVITY_TYPES.PROJECT.VARIABLE.DELETE,
  ACTIVITY_TYPES.PROJECT.VARIABLE.UPDATE
];

export const INFRASTRUCTURE_TYPE = Object.freeze({
  GRID: "grid",
  DEDICATED: "dedicated"
});

export const SUBSCRIPTION_DEFAULTS = {
  storage: 5120,
  environments: 3,
  plan: "development"
};

export const PLATFORM_FEATURE_BLACKFIRE = "PLATFORM_FEATURE_BLACKFIRE";

export const CONFIGURE_RESOURCES_PROFILE_LABEL = {
  HIGHER_MEMORY: "Higher memory profile",
  HIGH_MEMORY: "High memory profile",
  BALANCED: "Balanced profile",
  LOW_MEMORY: "Low memory profile",
  HIGH_CPU: "High cpu profile"
} as const;

export const DIALOG_RESOURCE_PROFILE = {
  HIGHER_MEMORY: "Higher memory",
  HIGH_MEMORY: "High memory",
  BALANCED: "Balanced memory",
  LOW_MEMORY: "Low memory",
  HIGH_CPU: "High cpu"
} as const;

export const FLEXIBLE_PLAN_VALUE =
  config.CUSTOM_FLEXIBLE_PLAN_VALUE ?? "flexible";

export const DEFAULT_ORGANIZATION_COUNTRY = "FR";

// In invoices discounts come as line items with the following names]
// they don't follow a standard (yet) so we need to hardcode them here
export const DISCOUNT_LINE_ITEMS = [
  "standard_user_management_discount",
  "agency_discount",
  "special_partner",
  "enterprise_project",
  "invoice_account",
  "vendor_account",
  "custom_discount",
  "organization_inactivity_discount",
  "prepaid_discount",
  "voucher",
  "trial",
  "discount"
];

export const QUERY_PREVIOUS_PAGE = "?previousPage=";
export const QUERY_PROJECT_CREATION = "&projectCreation=true";
export const QUERY_PROJECT_BILLING = "&projectBilling=true";

export const DEFAULT_DEMO_REGION_ID = "eu-5.platform.sh";
export const DEFAULT_DEMO_REGION_CONTINENT = "Europe";

export const METADATA_TYPES = Object.freeze({
  app: "app",
  service: "service",
  worker: "worker",
  router: "router"
});
