export enum Variant {
  primary = "primary",
  secondary = "secondary",
  outline = "outline",
  link = "link",
  linkSecondary = "linkSecondary",
  critical = "critical",
  dark = "dark"
}

/** Provides a contract on the structure of the children */
export enum Content {
  /** The children is expected to be a single SVG icon */
  icon = "icon",
  /** We expect at least two chidren the first one should be a SVG icon and the rest will be considered the text */
  mixed = "mixed",
  /** Any kind of text */
  text = "text"
}

export type Variants = keyof typeof Variant;
export type Contents = keyof typeof Content;

export type LayoutProps = {
  /** One of the valid variants. */
  variant?: Variants;
  /**  Whether the button is disabled or not. */
  disabled?: boolean;
  /** The layout will shift based on the content type. */
  content?: Contents;
  /** Text for link is not capitalized */
  capitalized?: boolean | "true" | "false";
  /** Sets the size of the icon */
  iconSize?: "small" | "normal" | "large";
};
