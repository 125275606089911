import { getFeatureFlagFromEnvironment } from "Reducers/featureFlags/environment";

import type { OrganizationMemberPermissions } from "Api/organizations/member/Member.types";

export const permissions = {
  admin: "admin",
  billing: "billing",
  plans: "plans",
  members: "members",
  "projects:create": "projects:create",
  "projects:list": "projects:list"
} as const;

export type Permission = OrganizationMemberPermissions;

const filterPermissions = (permission: string) => {
  if (
    !getFeatureFlagFromEnvironment("ENABLE_ORGANIZATION_PLANS_PERMISSION") &&
    permission === permissions.plans
  ) {
    return false;
  }

  return true;
};

export const permissionsKeys = Object.keys(permissions).filter(
  filterPermissions
) as Array<Permission>;

export const makePermissionsMap = <T>(initialValue: T) =>
  Object.fromEntries(
    permissionsKeys.map(permission => [permission, initialValue])
  ) as { [key in Permission]: T };
