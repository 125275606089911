import { createAsyncThunk } from "@reduxjs/toolkit";

import ObservabilityPipelineHttpClient from "Api/observability/pipeline/client";
import logger from "Libs/logger";

import type { AsyncThunkOptionType } from "Reducers/types";

export const loadObservabilitySandboxAccess = createAsyncThunk<
  boolean,
  { client: ObservabilityPipelineHttpClient },
  AsyncThunkOptionType
>(
  "app/observability_sandbox/access",
  async ({ client }, { rejectWithValue }) => {
    try {
      const obsSandboxAccessResponse = await client.getSandboxAccess();

      return obsSandboxAccessResponse.access.granted;
    } catch (err) {
      logger(err, {
        action: "app/observability_sandbox/access"
      });

      return rejectWithValue(err);
    }
  }
);
