import { createSlice } from "@reduxjs/toolkit";

import { setDeep } from "Libs/objectAccess";

import { getBillingAlert, updateBillingAlert } from "./thunks";
import { BillingAlertState } from "./types";

const initialState: BillingAlertState = {
  data: {},
  isLoadingBillingAlert: false
};

const billingAlertReducer = createSlice({
  name: "app/organization/billing_alert",
  initialState,
  reducers: {
    clearBillingAlertError: state => {
      delete state.error;
    }
  },
  extraReducers: builder => {
    builder
      // GET BILLING ALERT
      .addCase(getBillingAlert.pending, state => {
        state.isLoadingBillingAlert = true;
        delete state.error;
      })
      .addCase(getBillingAlert.fulfilled, (state, { meta, payload }) => {
        const { organizationId } = meta.arg;

        setDeep(state, ["data", organizationId], payload);
        state.isLoadingBillingAlert = false;
      })
      .addCase(getBillingAlert.rejected, (state, { meta, payload }) => {
        const { organizationId } = meta.arg;

        state.isLoadingBillingAlert = false;
        setDeep(state, ["error", organizationId], payload);
      })
      // UPDATE BILLING ALERT
      .addCase(updateBillingAlert.pending, state => {
        state.isLoadingBillingAlert = true;
        delete state.error;
      })
      .addCase(updateBillingAlert.fulfilled, (state, { meta, payload }) => {
        const { organizationId } = meta.arg;

        setDeep(state, ["data", organizationId], payload);
        state.isLoadingBillingAlert = false;
      })
      .addCase(updateBillingAlert.rejected, (state, { meta, payload }) => {
        const { organizationId } = meta.arg;

        state.isLoadingBillingAlert = false;
        setDeep(state, ["error", organizationId], payload);
      });
  }
});

export const { clearBillingAlertError } = billingAlertReducer.actions;
export default billingAlertReducer.reducer;
