import { createSlice } from "@reduxjs/toolkit";

import { setDeep } from "Libs/objectAccess";

import { getOrganizationOrderThunk } from "./thunks/getOrganizationOrder.thunk";
import { getOrganizationOrderAsOfNow } from "./thunks/getOrganizationOrderAsOfNow.thunk";
import { getOrganizationOrdersThunk } from "./thunks/getOrganizationOrders.thunk";
import { getOrganizationRecurringOrderThunk } from "./thunks/getOrganizationRecurringOrder.thunk";
import { DataOrders } from "./types";

import type { OrganizationOrder } from "platformsh-client";

export type OrderState = Readonly<{
  data: {
    [organizationId: string]: DataOrders | undefined;
  };
  loading: boolean;
  errors?: any;
  byId: {
    [orderId: string]:
      | {
          data: OrganizationOrder;
          loading?: boolean;
        }
      | undefined;
  };
  recurring?: {
    loading: boolean;
    data: OrganizationOrder;
    errors: any;
  };
  asOf: { [orderId: string]: OrganizationOrder | undefined };
}>;

const initialState: OrderState = {
  data: {},
  loading: false,
  byId: {},
  asOf: {}
};

const order = createSlice({
  name: "order",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getOrganizationOrdersThunk.pending, state => {
        state.loading = true;
      })
      .addCase(getOrganizationOrdersThunk.fulfilled, (state, action) => {
        const { organizationId } = action.meta.arg;

        setDeep(state, ["data", organizationId], action.payload);
        state.loading = false;
      })
      .addCase(getOrganizationOrdersThunk.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      })

      .addCase(getOrganizationOrderThunk.pending, (state, action) => {
        const { orderId } = action.meta.arg;

        setDeep(state, ["byId", orderId, "loading"], true);
      })
      .addCase(
        getOrganizationOrderThunk.fulfilled,
        (state, { meta, payload }) => {
          const { orderId } = meta.arg;

          if (payload) {
            setDeep(state, ["byId", orderId, "data"], payload);
          }
          setDeep(state, ["byId", orderId, "loading"], false);
        }
      )
      .addCase(
        getOrganizationOrderThunk.rejected,
        (state, { meta, payload }) => {
          const { orderId } = meta.arg;

          state.loading = false;
          state.errors = payload;
          setDeep(state, ["byId", orderId, "loading"], false);
        }
      )

      .addCase(getOrganizationRecurringOrderThunk.pending, state => {
        setDeep(state, ["recurring", "loading"], true);
      })
      .addCase(
        getOrganizationRecurringOrderThunk.fulfilled,
        (state, { payload }) => {
          if (payload) {
            setDeep(state, ["recurring", "data"], payload);
          }
          setDeep(state, ["recurring", "loading"], false);
        }
      )
      .addCase(getOrganizationRecurringOrderThunk.rejected, (state, action) => {
        setDeep(state, ["recurring", "loading"], false);
        setDeep(state, ["recurring", "errors"], action.payload);
      })

      .addCase(getOrganizationOrderAsOfNow.pending, () => {
        //
      })
      .addCase(getOrganizationOrderAsOfNow.fulfilled, (state, { payload }) => {
        if (payload) {
          setDeep(state, ["asOf", payload.id], payload);
        }
      })
      .addCase(getOrganizationOrderAsOfNow.rejected, () => {
        //
      });
  }
});

export default order.reducer;
