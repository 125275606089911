export default (
  x,
  y,
  width,
  height /*, color*/
) => `<?xml version="1.0" encoding="iso-8859-1"?>
<svg x="${x}px" y="${y}px" width="${width}px" height="${height}px"  version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
	 <path style="fill:#F2F2F2;" d="M512,256.004c0,97.046-54.004,181.478-133.604,224.886c-36.361,19.838-78.063,31.107-122.397,31.107
	 C114.615,511.996,0,397.382,0,256.004C0,158.951,54.004,74.519,133.604,31.11c36.361-19.838,78.063-31.107,122.397-31.107
	 C397.385,0.003,512,114.617,512,256.004z"/>
 <g>
	 <path style="fill:#2D3A3E;" d="M512,256.004c0,97.046-54.004,181.478-133.604,224.886c-42.777,9.141-89.168-2.902-122.397-36.13
		 c-52.122-52.122-52.122-136.635,0-188.756c52.122-52.128,52.122-136.635,0-188.764c-33.227-33.227-79.62-45.271-122.397-36.13
		 c36.361-19.838,78.063-31.107,122.397-31.107C397.385,0.003,512,114.617,512,256.004z"/>
	 <path style="fill:#2D3A3E;" d="M195.226,397.989l-0.001-0.002c-4.585-6.564-9.095-13.549-13.516-20.861
		 c-5.851-9.677-10.45-20.084-13.4-31c-19.072-70.572,31.853-135.322,65.304-208.258h-44.177c0,0-46.279,75.284-59.663,132.364
		 C101.461,200.7,83.413,137.868,83.413,137.868H42.479c0,0,55.979,188.352,121.315,275.934h43.314
		 C203.098,408.879,199.135,403.585,195.226,397.989L195.226,397.989z"/>
 </g>
 <path style="fill:#F2F2F2;" d="M313.025,111.049l0.001,0.002c4.585,6.564,9.095,13.549,13.516,20.861
	 c5.851,9.677,10.45,20.084,13.4,31c19.072,70.572-31.853,135.322-65.304,208.258h44.177c0,0,46.279-75.284,59.663-132.364
	 c28.313,69.532,46.362,132.364,46.362,132.364h40.934c0,0-55.979-188.352-121.315-275.934h-43.314
	 C305.154,100.159,309.116,105.452,313.025,111.049L313.025,111.049z"/>
 <g>
 </g>
 <g>
 </g>
 <g>
 </g>
 <g>
 </g>
 <g>
 </g>
 <g>
 </g>
 <g>
 </g>
 <g>
 </g>
 <g>
 </g>
 <g>
 </g>
 <g>
 </g>
 <g>
 </g>
 <g>
 </g>
 <g>
 </g>
 <g>
 </g>
 </svg>`;
