import React from "react";

import { YearSelect } from "./styles";

type YearPickerPropType = {
  currentYear?: number;
  onChange: (year: number) => void;
  yearOptions: number[];
  disabledYears?: Array<number>;
};

const YearPicker = (props: YearPickerPropType) => {
  const { onChange, currentYear, yearOptions, disabledYears } = props;

  const options = yearOptions?.map(year => ({
    value: year,
    label: year,
    isDisabled: disabledYears?.includes(year)
  }));
  return (
    <YearSelect
      onChange={(p: any) => onChange(p.value)}
      value={options?.find(m => m.value == currentYear)}
      options={options}
      menuPortalTarget={null}
      classNamePrefix="year-date-select"
    />
  );
};

export default YearPicker;
