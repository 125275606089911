import React from "react";

import { useIntl } from "react-intl";

import { APP_NAME } from "Constants/documentationUrls";
import Button, { ButtonWrapper, AddButton } from "ds/Button";
import Modal from "ds/Modal";
import { Title, BodyText } from "ds/Typography";
import { meSelector } from "Reducers/app/selectors";
import { useAppSelector } from "Store/hooks";

import { CreateProjectButtonProps } from "../types";

const TicketModal = ({
  isModalOpen,
  setIsModalOpen,
  isLink,
  linkStyle,
  linkVariant = "link",
  openModal,
  label
}: CreateProjectButtonProps) => {
  const intl = useIntl();
  const me = useAppSelector(meSelector);

  const params = new URLSearchParams({
    subject: intl.formatMessage({
      id: "requires_staff_verification.form.subject"
    }),
    description: intl.formatMessage({
      id: "requires_staff_verification.form.description"
    }),
    priority: "normal",
    category: "access",
    isGeneral: "true"
  });

  return (
    <>
      <Modal
        isOpen={isModalOpen!}
        onOpenChange={() => setIsModalOpen?.(!isModalOpen)}
        id="ticket"
      >
        <Title tag="h3">
          {intl.formatMessage({ id: "kyc_verification.ticket.title" })}
        </Title>
        <BodyText>
          {intl.formatMessage(
            { id: "kyc_verification.ticket.intro" },
            { app_name: APP_NAME }
          )}
        </BodyText>
        <ButtonWrapper justifyContent="end" spacing="modal">
          <Button
            to="/"
            analyticId="kyc_verification.ticket.back"
            variant="secondary"
            onClick={() => setIsModalOpen?.(false)}
          >
            {intl.formatMessage({ id: "kyc_verification.ticket.back" })}
          </Button>
          <Button
            to={`/-/users/${me?.username}/tickets/open?${params.toString()}`}
            analyticId="kyc_verification.ticket.contact"
          >
            {intl.formatMessage({ id: "kyc_verification.ticket.contact" })}
          </Button>
        </ButtonWrapper>
      </Modal>
      {isLink ? (
        <Button
          analyticId="create_project"
          id="create_project"
          variant={linkVariant}
          style={linkStyle}
          onClick={openModal}
        >
          {label}
        </Button>
      ) : (
        <AddButton
          id="create_project"
          analyticId="create_project"
          onClick={openModal}
        >
          {label}
        </AddButton>
      )}
    </>
  );
};

export default TicketModal;
