import React from "react";

import useUniqueId from "Libs/useUniqueId";

//this icon won't work on other backgrounds than the rainbow one, its not handled in dark mode

const BillingIcon = () => {
  const uid = useUniqueId();

  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`billing-icon-${uid}`}
    >
      {" "}
      <title id={`billing-icon-${uid}`}>Billing icon</title>
      <path
        d="M3.65337 6.58664C3.65337 5.80867 3.96241 5.06256 4.51252 4.51246C5.06263 3.96235 5.80873 3.6533 6.5867 3.6533H7.92003C8.69456 3.65286 9.43749 3.34611 9.9867 2.79997L10.92 1.86664C11.1926 1.5925 11.5167 1.37496 11.8737 1.22651C12.2306 1.07807 12.6134 1.00165 13 1.00165C13.3866 1.00165 13.7694 1.07807 14.1264 1.22651C14.4833 1.37496 14.8074 1.5925 15.08 1.86664L16.0134 2.79997C16.5626 3.34611 17.3055 3.65286 18.08 3.6533H19.4134C20.1913 3.6533 20.9374 3.96235 21.4875 4.51246C22.0377 5.06256 22.3467 5.80867 22.3467 6.58664V7.91997C22.3471 8.6945 22.6539 9.43742 23.2 9.98664L24.1334 10.92C24.4075 11.1926 24.625 11.5167 24.7735 11.8736C24.9219 12.2306 24.9984 12.6134 24.9984 13C24.9984 13.3866 24.9219 13.7694 24.7735 14.1263C24.625 14.4833 24.4075 14.8074 24.1334 15.08L23.2 16.0133C22.6539 16.5625 22.3471 17.3054 22.3467 18.08V19.4133C22.3467 20.1913 22.0377 20.9374 21.4875 21.4875C20.9374 22.0376 20.1913 22.3466 19.4134 22.3466H18.08C17.3055 22.3471 16.5626 22.6538 16.0134 23.2L15.08 24.1333C14.8074 24.4074 14.4833 24.625 14.1264 24.7734C13.7694 24.9219 13.3866 24.9983 13 24.9983C12.6134 24.9983 12.2306 24.9219 11.8737 24.7734C11.5167 24.625 11.1926 24.4074 10.92 24.1333L9.9867 23.2C9.43749 22.6538 8.69456 22.3471 7.92003 22.3466H6.5867C5.80873 22.3466 5.06263 22.0376 4.51252 21.4875C3.96241 20.9374 3.65337 20.1913 3.65337 19.4133V18.08C3.65292 17.3054 3.34617 16.5625 2.80003 16.0133L1.8667 15.08C1.59257 14.8074 1.37502 14.4833 1.22658 14.1263C1.07813 13.7694 1.00171 13.3866 1.00171 13C1.00171 12.6134 1.07813 12.2306 1.22658 11.8736C1.37502 11.5167 1.59257 11.1926 1.8667 10.92L2.80003 9.98664C3.34617 9.43742 3.65292 8.6945 3.65337 7.91997V6.58664Z"
        fill="#DDF933"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.00171 13.0016C5.00171 14.0522 5.20864 15.0925 5.61067 16.0631C6.01271 17.0337 6.60199 17.9156 7.34485 18.6585C8.08772 19.4014 8.96964 19.9906 9.94024 20.3927C10.9108 20.7947 11.9511 21.0016 13.0017 21.0016C14.0523 21.0016 15.0926 20.7947 16.0632 20.3927C17.0338 19.9906 17.9157 19.4014 18.6586 18.6585C19.4014 17.9156 19.9907 17.0337 20.3927 16.0631C20.7948 15.0925 21.0017 14.0522 21.0017 13.0016C21.0017 11.9511 20.7948 10.9108 20.3927 9.94018C19.9907 8.96958 19.4014 8.08766 18.6586 7.34479C17.9157 6.60193 17.0338 6.01265 16.0632 5.61061C15.0926 5.20857 14.0523 5.00165 13.0017 5.00165C11.9511 5.00165 10.9108 5.20857 9.94024 5.61061C8.96964 6.01265 8.08772 6.60193 7.34485 7.34479C6.60199 8.08766 6.01271 8.96958 5.61067 9.94018C5.20864 10.9108 5.00171 11.9511 5.00171 13.0016Z"
        fill="#D5F800"
        stroke="#0C0F10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.661 11.0017C14.4423 10.575 14.0316 10.3181 13.5943 10.335H12.4085C11.7543 10.335 11.2236 10.9306 11.2236 11.6683C11.2236 12.4035 11.7543 13.0008 12.4085 13.0008H13.5943C14.2485 13.0008 14.7792 13.5972 14.7792 14.3341C14.7792 15.0701 14.2485 15.6666 13.5943 15.6666H12.4085C11.9712 15.6835 11.5605 15.4266 11.3419 14.9999"
        stroke="#0C0F10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0017 8.55719V10.335"
        stroke="#0C0F10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0017 15.6683V17.4461"
        stroke="#0C0F10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.66846 7.66833L9.00179 9.00167"
        stroke="#0C0F10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0017 17.0016L18.335 18.335"
        stroke="#0C0F10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BillingIcon;
