import { createAsyncThunk } from "@reduxjs/toolkit";

import { updateBackupScheduleRequest } from "Api/projectSettings/updateBackupSchedule.request";
import logger from "Libs/logger";

import type {
  ProjectSetting,
  BackupDefaultConfig,
  EnvTypeDataRetention
} from "../";
import type { AsyncThunkOptionType } from "Reducers/types";

export const updateBackupSchedule = createAsyncThunk<
  ProjectSetting,
  {
    backupRetentionData: BackupDefaultConfig;
    projectId: string;
    environmentType: EnvTypeDataRetention;
  },
  AsyncThunkOptionType
>(
  "app/project_settings/updateBackupSchedule",
  async (
    { backupRetentionData, projectId, environmentType },
    { rejectWithValue }
  ) => {
    try {
      const updatedBackupRetentionData = await updateBackupScheduleRequest({
        backupRetentionData,
        projectId,
        environmentType
      });

      return updatedBackupRetentionData._embedded.entity;
    } catch (err) {
      logger(err, {
        action: "project_settings/updateBackupSchedule"
      });

      return rejectWithValue((err as { detail: string })?.detail);
    }
  }
);
