import { apiProxyRequest } from "Api/apiProxyRequest";

import type { BillingAlert } from "Reducers/organization/billingAlert";

export const updateBillingAlertRequest = (
  organizationId: string,
  { threshold, active }: { threshold: number; active: boolean }
) =>
  apiProxyRequest<BillingAlert>(
    `/organizations/${organizationId}/alerts/billing`,
    "PATCH",
    { config: { threshold }, active }
  );
