import styled, { css as styledCSS } from "styled-components";

import * as Dropdown from "ds/Dropdown";
import * as Tabs from "ds/Tabs";
import { semiBoldAlias } from "Libs/theme";

export const SubNavBarTabWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 2;
  width: 100%;
  height: 30px;
  justify-content: space-between;
  overflow: visible;
  box-sizing: border-box;
  .link-button {
    margin-top: -4px;
  }
`;

export const CustomTab = styled(Tabs.Trigger)<{ $isLightBackground?: boolean }>`
  ${props =>
    props.theme.name === "contrast" &&
    styledCSS`
      position: relative;
      &:focus:after{
        content: "";
        display: block;
        position: absolute;
        top: 6px;
        left: -10px;
        background-color: var(--link-color-primary-600-background-color,var(--color-primary-600));
        height: 6px;
        width: 6px;
        border-radius: 4px;
      }
    `};
  ${({ $isLightBackground }) =>
    !$isLightBackground &&
    `
  &[data-orientation="horizontal"] {
    margin-top: 5px;
    color: var(--theme-neutral-500);

    &[data-state="active"] {
      color: var(--mode-overrides-header-text-weak);
      border-color: var(--customTab-tab-border-color,var(--tab-snow-border-color,var(--snow)));
    }

    :hover {
      color: var(--customTab-tab-color,var(--tab-snow-color,var(--snow)));
    }
  }
`}
  white-space: nowrap;
`;

export const SubNavBarLayout = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  min-height: auto;
  max-height: initial;
  margin: 0 auto;
  padding: 16px 0;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
  touch-callout: none;
  user-select: none;
  overflow: visible;
  box-sizing: border-box;

  a.button {
    margin-bottom: -50%;
    text-transform: none;
    letter-spacing: normal;
  }
  a:not(.button) {
    padding: 0;
    line-height: 22px;
    height: 22px;
    font-size: 13px;
    &:hover,
    &:focus,
    &:active {
      color: var(--link-night-color, var(--night));
      outline: none;
    }
    &.active {
      position: relative;
      background: transparent;
      ${semiBoldAlias()};
      &:before {
        content: "";
        display: inline-block;
        width: 100%;
        position: absolute;
        bottom: -4px;
        left: 0;
        height: 2px;
        background-color: var(--link-night-background-color, var(--night));
      }
    }
  }

  @media (min-width: 768px) {
    overflow-x: auto;
    a:not(.button) {
      padding: 0 8px;

      &.active {
        &:before {
          left: 8px;
          bottom: -7px;
          width: calc(100% - 17px);
        }
      }
    }

    ${SubNavBarTabWrapper} {
      bottom: 1px;
      height: 30px;
      border-bottom: none;
    }
  }
`;

export const Wrapper = styled.div``;

export const TabItem = styled(Dropdown.LinkItem)`
  text-transform: capitalize;
`;

export const MobileSubNavBar = styled.div<{ $isLightBackground?: boolean }>`
  padding: 16px 0;
  width: max-content;
  .mobile-menu-trigger-button {
    justify-content: flex-start;
    padding: 14px 0;
    text-transform: uppercase;
    ${({ $isLightBackground }) => {
      if (!$isLightBackground) {
        return `
        color: var(--customTab-tab-color,var(--tab-snow-color,var(--snow)));
        `;
      }
    }}
  }
`;
