import styled from "styled-components";

export enum Variant {
  yellow = "yellow",
  red = "red",
  blue = "blue",
  purple = "purple",
  green = "green",
  gray = "gray",
  snow = "snow",
  slate = "slate",
  rose = "rose",
  orange = "orange",
  dark = "dark"
}

export interface TagProps {
  /** Naming is based on main colors from light mode */
  variant?: keyof typeof Variant;
}

const VARIANTS = {
  yellow: {
    color: "var(--mode-text-neutral-default)",
    background: "var(--mode-bg-warning-weak)"
  },
  red: {
    color: "var(--mode-text-neutral-default)",
    background: "var(--mode-bg-critical-weak)"
  },
  blue: {
    color: "var(--mode-text-neutral-default)",
    background: "var(--mode-bg-primary-weak)"
  },
  purple: {
    color: "var(--mode-text-neutral-default)",
    background: "var(--mode-bg-secondary-weak)"
  },
  green: {
    color: "var(--mode-text-neutral-default)",
    background: "var(--mode-bg-success-weak)"
  },
  gray: {
    color: "var(--mode-text-neutral-default)",
    background: "var(--mode-bg-neutral-weak)"
  },
  snow: {
    color: "var(--mode-text-neutral-weakest)",
    background: "var(--mode-bg-neutral-weakest)"
  },
  slate: {
    color: "var(--mode-text-neutral-default)",
    background: "var(--mode-bg-neutral-weaker)"
  },
  dark: {
    color: "var(--mode-text-neutral-inverse)",
    background: "var(--mode-bg-neutral-strong)"
  },
  rose: {
    color: "var(--mode-text-neutral-default)",
    background: "var(--mode-bg-accent-weak)"
  },
  orange: {
    color: "var(--mode-text-neutral-default)",
    background: "var(--mode-bg-failure-weak)"
  }
};

const Tag = styled.div<TagProps>`
  display: inline-flex;
  background: ${({ variant }) => VARIANTS[variant || "gray"].background};
  color: ${({ variant }) => VARIANTS[variant || "gray"].color};
  font-size: 12px;
  box-sizing: border-box;
  font-weight: 600;
  line-height: 20px;
  padding: 2px 8px;
  height: 24px;
  white-space: nowrap;
  border-radius: 4px;
`;

export default Tag;
