import { apiProxyRequest as request } from "Api/apiProxyRequest";

import type { Team } from "Api/teams/teams.types";

export type ListMemberTeamsOptions = {
  filter?: {
    organization_id?: string;
    updated_at?: string;
  };
  page?: {
    size?: number;
    before?: string;
    after?: string;
  };
  sort?:
    | "label"
    | "-label"
    | "created_at"
    | "-created_at"
    | "updated_at"
    | "-updated_at";
};

export type ListMemberTeamsResponse = {
  items: Array<Team>;
  _links: {
    self: { href: string };
    next?: { href: string };
    previous?: { href: string };
  };
};

export const listMemberTeams = (
  memberId: string,
  options?: ListMemberTeamsOptions
) =>
  request<ListMemberTeamsResponse>(`/users/${memberId}/teams`, "GET", options);
