import { createAsyncThunk } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import { AsyncThunkOptionType } from "Reducers/types";

import type { Domain, Project } from "platformsh-client";

export interface addDomainProps {
  isDefault?: boolean;
  name: string;
}

export const addDomainThunk = createAsyncThunk<
  Domain | undefined,
  { data: addDomainProps; project: Project },
  AsyncThunkOptionType<{ error: Error }>
>("app/project/domain/add", async ({ data, project }, { rejectWithValue }) => {
  try {
    const result = await project.addDomain(data.name);
    const domain = await result.getEntity();
    return domain;
  } catch (err: any) {
    logger(
      { errorMessage: err.message, projectId: project.id },
      { action: "projectAddDomain" }
    );
    return rejectWithValue({ error: err });
  }
});
