import { Action, SerializedError } from "@reduxjs/toolkit";

import { getIntl } from "Libs/intl";

import type { AppDispatch, RootState } from "Store/configureStore";

export type SetErrorMessageAction<P = any> = {
  error: {
    message?: string;
  };
  payload?: P;
} & Action;

export type AsyncThunkOptionType<E = any> = {
  dispatch: AppDispatch;
  rejectValue: E;
  state: RootState;
  extra: { getIntl: typeof getIntl };
};

export type CommonState<T> = {
  data?: T;
  loading?: boolean;
  error?: SerializedError;
};

export type GitErrorType = {
  code: number;
  detail?: Record<string, string>;
  message?: string;
  status?: string;
  title?: string;
};

export type CommonErrorType = {
  message?: string;
  code?: number;
  status?: string;
  original: any;
};

export type OrderBy<T extends string> = {
  field: T;
  direction: 1 | -1;
};

export enum Status {
  Loading,
  Idle,
  Fulfilled,
  Rejected
}
