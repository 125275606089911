import { createSelector } from "@reduxjs/toolkit";

import type { RootState } from "Store/configureStore";

export const selectMemberData = (state: RootState) =>
  state.memberData.memberData;

export const selectMemberDetails = createSelector(
  selectMemberData,
  state => state.member
);

export const selectUserDetails = createSelector(
  selectMemberData,
  state => state.user
);

export const selectIsMemberDataLoading = createSelector(
  selectMemberData,
  state => state.isLoading
);

export const selectMemberDataError = createSelector(
  selectMemberData,
  state => state.error
);
export const memberCanUpdateSelector = createSelector(
  selectMemberDetails,
  state => !!state?._links.update
);

export const editingMemberDataSelector = createSelector(
  selectMemberDetails,
  selectUserDetails,
  (member, user) =>
    member && user ? { member, user: user[member.id] } : undefined
);
