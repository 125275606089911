import styled from "styled-components";

export const Content = styled.div`
  display: grid;
  gap: 40px;
`;

export const Wrapper = styled.div`
  white-space: nowrap;
`;
