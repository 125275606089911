import { useCallback } from "react";

import { useAppDispatch, useAppSelector } from "Store/hooks";

import { selectSortDirection } from "../membersList.selectors";
import { loadMembers } from "../thunks/loadMembers.thunk";

export const useLoadMembers = (organizationId?: string) => {
  const dispatch = useAppDispatch();
  const sortDirection = useAppSelector(selectSortDirection);

  const load = useCallback(() => {
    if (organizationId) {
      return dispatch(loadMembers({ organizationId, sort: sortDirection }));
    }
  }, [dispatch, organizationId, sortDirection]);

  return load;
};
