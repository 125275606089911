// Special selectors that don't belong to any particular reducer
// and that we use to get data we need to send to analytics

// Gets the organization and project ids from the react router store
function selectOrganizationAndProjectId(store) {
  const pathname = store.getState().router?.location?.pathname;

  if (!pathname) {
    return null;
  }

  return getOrganizationAndProjectIdFromPathname(pathname);
}

// Given a pathname retrieves the organization and project id
function getOrganizationAndProjectIdFromPathname(pathname) {
  return pathname ? pathname.split("/").slice(1, 3) : null;
}

// Selects the wizard for the current project
function selectWizard(store) {
  const [user, projectId] = selectOrganizationAndProjectId(store);
  if (!user || !projectId) {
    return null;
  }
  const wizard = store.getState().projectWizard?.toJS().data[user][projectId];

  return wizard;
}

//
// Exports
//

// Gets the wizard step id from the wizard store, this is done like this
// so the data we send to analytics is always in sync with the wizard templates
// since we use the store as the single source of truth
export function selectWizardStepId(store, step) {
  const wizard = selectWizard(store);

  if (!wizard) {
    return null;
  }

  return wizard.data[step]?.id || "start/finish";
}

// Gets the step position a user is going to from the store
// we need to do some calculations here based on the direction,
// since this middleware runs BEFORE the action reaches the store
// so what we are getting here is the previous step a user was in
// before the action is applied.
export function getWizardStepFromStore(store, direction) {
  const wizard = selectWizard(store);

  if (!wizard) {
    return null;
  }

  const { currentStep: previousStep } = wizard;

  if (typeof previousStep === "undefined") {
    return null;
  }

  // we don't need to add +1 here cause the steps are an array
  // (so index starts from 0) but the step ids from the templates
  // we get from the server start at 1
  let currentStep = previousStep;

  if (direction === "back") {
    // for the same reason as above we need to substract 2 instead of 1
    currentStep -= 2;
  }

  return selectWizardStepId(store, currentStep);
}
