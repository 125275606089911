import styled from "styled-components";

import * as Typography from "ds/Typography";

const Title = styled(Typography.BodyText).attrs(() => ({
  weight: "semiBold",
  size: "small"
}))``;

export default Title;
