import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "Store/configureStore";

export const billingAlertState = (state: RootState) => state.billingAlert;

export const isBillingAlertLoadingSelector = createSelector(
  billingAlertState,
  billingState => billingState.isLoadingBillingAlert
);

export const billingAlertSelector = createSelector(
  billingAlertState,
  (_: RootState, params: { organizationId: string }) => params,
  (billingState, { organizationId }) => billingState.data?.[organizationId]
);

export const billingAlertThresholdSelector = createSelector(
  billingAlertSelector,
  orgBillingAlert => {
    if (orgBillingAlert?.active) {
      return orgBillingAlert?.config?.threshold;
    }
  }
);

export const billingAlertErrorSelector = createSelector(
  billingAlertState,
  (_: RootState, params: { organizationId: string }) => params,
  (billingState, { organizationId }) => billingState.error?.[organizationId]
);
