import * as Tabs from "@radix-ui/react-tabs";
import styled from "styled-components";

interface ListProps {
  /** Only applied to the horizontal tabs. Adds a border at the bottom of the
   * tab list */
  hasBorder?: boolean;
}

const List = styled(Tabs.List)<ListProps>`
  &[data-orientation="vertical"] {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &[data-orientation="horizontal"] {
    display: flex;
    flex-direction: row;
    gap: 24px;

    ${({ hasBorder }) =>
      hasBorder &&
      `
        border-bottom: 1px solid var(--mode-border-neutral-weakest);
        padding-left: 16px;
        padding-right: 16px;
        display: inline-flex;
    `}
  }
`;

export default List;
