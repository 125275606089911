import styled from "styled-components";

import { BodyText } from "ds/Typography";

export const Title = styled(BodyText).attrs<{ weight?: string }>(props => ({
  size: "small",
  weight: props.weight || "semiBold"
}))`
  color: var(--mode-text-neutral-default-weak);
`;

export default Title;
