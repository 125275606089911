import styled from "styled-components";

interface LayoutProps {
  isOpen?: boolean;
  width?: string;
}

export const Layout = styled.nav<LayoutProps>`
  position: absolute;
  background-color: var(--menu-snow-background-color, var(--snow));
  display: ${props => (props.isOpen ? "inline-block" : "none")};
  left: 24px;
  top: 100%;
  margin-top: 4px;
  font-size: 12px;
  z-index: 10;
  box-shadow: var(--menu-box-shadow-box-shadow, var(--box-shadow))
    var(--menu-ebony-shadow-box-shadow-color, var(--ebony-shadow));
  border-radius: 2px;
  min-width: 300px;
  width: calc(100vw - 48px);
  ul,
  ol {
    margin: 0;
    padding: 0;
    li {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }
  a {
    color: var(--link-night-color, var(--night));
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    padding: 0 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    span {
      color: var(--link-night-color, var(--night));
      font-size: 14px;
      white-space: nowrap;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &:hover {
      background-color: var(
        --link-color-primary-100-background-color,
        var(--color-primary-100)
      );
      border-radius: 4px;
    }
    &.active,
    &:active {
      background: transparent;
      border: none;
      box-shadow: none;
      margin: 0;
      span {
        color: var(--link-color-primary-600-color, var(--color-primary-600));
      }
    }
  }

  @media (min-width: 768px) {
    position: absolute;
    width: ${props => props.width || "auto"};
    left: 0;
  }
`;
