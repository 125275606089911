import genericColor from "./generic-color";
import monochrome from "./generic-monochrome";

const generic = (x, y, width, height, color) => {
  return color === "monochrome"
    ? monochrome(x, y, width, height)
    : genericColor(x, y, width, height);
};

export default generic;
