import React from "react";

import Icon from "ds/Icon";
import { WrappingIconProps } from "ds/Icon/Icon";

const Copy = (props: WrappingIconProps) => {
  return (
    <Icon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 5C15 5 15.5 4.5 15.5 4C15.5 3.5 15 3 14.5 3H5.5C4.4 3 3.5 3.9 3.5 5V14C3.5 14.5 4 15 4.5 15C5 15 5.5 14.5 5.5 14V5H14.5ZM9.5 21H18.5C19.6 21 20.5 20.1 20.5 19V9C20.5 7.9 19.6 7 18.5 7H9.5C8.4 7 7.5 7.9 7.5 9V19C7.5 20.1 8.4 21 9.5 21ZM9.5 19V9H18.5V19H9.5Z"
      />
    </Icon>
  );
};

export default Copy;
