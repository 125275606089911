import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getMembers,
  GetMembersQueryParameters,
  resolveMembersLinks
} from "Api/organizations/member/getMembers.request";

export interface LoadMemberParams {
  organizationId: string;
  sort?: GetMembersQueryParameters["sort"];
}

export const loadMembers = createAsyncThunk(
  `organization/members/loadMembers`,
  async ({ organizationId, sort: sort }: LoadMemberParams) => {
    const members = await getMembers(organizationId, { sort });
    const api = resolveMembersLinks(members);
    const users = await api.users?.();
    return { members, users };
  }
);
