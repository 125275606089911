import styled from "styled-components";

const TriggerIcon = styled.div`
  width: 24px;
  height: 24px;
  color: var(--link-slate-color, var(--slate));

  svg,
  [fill] {
    fill: currentColor;
  }
`;

export default TriggerIcon;
