import React, { useEffect, useRef, InputHTMLAttributes } from "react";

import Label from "ds/Label";

import Error from "./Error.styles";
import InputPrimitive from "./Input.styles";
import Root from "./Root.styles";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: React.ReactNode;
  error?: React.ReactNode;
  isValid?: boolean;
  width?: string;
  autofocus?: boolean;
  analyticId?: string;
}

const Input = ({
  className,
  error,
  label,
  required,
  id,
  disabled,
  isValid = true,
  width,
  autofocus = false,
  analyticId,
  ...props
}: InputProps) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!autofocus || !ref.current) return;
    if (ref.current.focus) {
      ref.current.focus();
    }
  }, [ref, autofocus]);

  return (
    <Root className={className}>
      {label && (
        <Label
          required={required}
          htmlFor={id}
          disabled={disabled}
          isValid={isValid}
        >
          {label}
        </Label>
      )}
      <InputPrimitive
        ref={ref}
        id={id}
        data-heap-identifier={analyticId}
        required={required}
        disabled={disabled}
        isValid={isValid}
        width={width}
        {...props}
      />
      {error && <Error>{error}</Error>}
    </Root>
  );
};

export default Input;
