import { createAsyncThunk } from "@reduxjs/toolkit";

import { getAddonsRequest } from "Api/organizations/addons/getAddons.request";
import logger from "Libs/logger";
import { organizationByDescriptionIdSelector } from "Reducers/organization";

import type { OrganizationAddon } from "../types";
import type { AsyncThunkOptionType } from "Reducers/types";

export const getAddons = createAsyncThunk<
  OrganizationAddon,
  { organizationId: string },
  AsyncThunkOptionType
>(
  "app/organization/get_addons",
  async ({ organizationId }, { rejectWithValue, getState }) => {
    try {
      const organization = organizationByDescriptionIdSelector(getState(), {
        organizationDescriptionId: organizationId
      });

      if (!organization) {
        return rejectWithValue("Organization does not exit");
      }

      const addons = await getAddonsRequest(organization.id);

      return addons;
    } catch (err: unknown) {
      const error =
        (err as { title: string })?.title ??
        (err as { description: string })?.description ??
        "An error has occurred";

      logger(err, {
        action: "get_addons",
        organizationId
      });

      return rejectWithValue(error);
    }
  }
);
