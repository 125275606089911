import { createSlice } from "@reduxjs/toolkit";

import { loadObservabilitySandboxAccess } from "./thunks";
import { ObservabilitySandboxState } from "./types";

const initialState: ObservabilitySandboxState = {
  access: false
};

const observabilitySandboxReducer = createSlice({
  name: "observabilitySandbox",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadObservabilitySandboxAccess.pending, state => {
      state.access = false;
    });
    builder.addCase(
      loadObservabilitySandboxAccess.fulfilled,
      (state, action) => {
        state.access = action.payload;
      }
    );
    builder.addCase(loadObservabilitySandboxAccess.rejected, state => {
      state.access = false;
    });
  }
});

export default observabilitySandboxReducer.reducer;
