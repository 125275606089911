import styled from "styled-components";

export const Overlay = styled.div`
  background-color: var(--mode-overlay-default);
  inset: 0;
  position: fixed;
  z-index: 998;
  opacity: 0.8;
`;
export default Overlay;
