import React from "react";

import * as S from "./Highlightable.styles";

interface Props {
  textToHighlight: string;
  searchText?: string;
}

export const Highlightable = ({ textToHighlight, searchText = "" }: Props) => {
  if (searchText === "") {
    return <>{textToHighlight}</>;
  }

  const regex = new RegExp(searchText, "i");
  const parts = textToHighlight.split(regex);
  const matches = textToHighlight.match(regex);

  if (!matches) {
    return null;
  }

  return (
    <span>
      {" "}
      {parts.map((part: string, idx: number) => {
        // we ignore the first part since this is the part that
        // goes before the first (if any) match
        if (idx === 0) {
          return <React.Fragment key={part + idx}>{part}</React.Fragment>;
        }

        return (
          <React.Fragment key={part + idx}>
            <S.Mark>{matches[idx - 1]}</S.Mark>
            <>{part}</>
          </React.Fragment>
        );
      })}
    </span>
  );
};

export default Highlightable;
