import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getMember,
  resolveMemberLinks
} from "Api/organizations/member/getMember.request";

export interface LoadMemberDataArgs {
  id: string;
  organizationId: string;
}

export default createAsyncThunk(
  `organization/member/loadMemberData`,
  async ({ id, organizationId }: LoadMemberDataArgs) => {
    const member = await getMember(organizationId, id);
    const api = resolveMemberLinks(member);
    const user = await api.user();

    return { member, user };
  }
);
