export default (x, y, width, height /*, color*/) =>
  `<?xml version="1.0" encoding="iso-8859-1"?>
<svg x="${x}px" y="${y}px" width="${width}px" height="${height}px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.17653 13.6313C7.12028 13.5188 7.02653 13.3453 6.96559 13.2422C6.83434 13.0125 6.68903 12.7547 6.56247 12.5391C6.51091 12.45 6.42653 12.3 6.37497 12.2109C6.32341 12.1219 6.23903 11.9719 6.18747 11.8828C6.13591 11.7938 6.05153 11.6438 5.99997 11.5547C5.94841 11.4656 5.86403 11.3156 5.81247 11.2266C5.76091 11.1375 5.67653 10.9875 5.62497 10.8984C5.57341 10.8094 5.48903 10.6594 5.43747 10.5703C5.38591 10.4813 5.30153 10.3313 5.24997 10.2422C5.19841 10.1531 5.11403 10.0031 5.06247 9.91406C5.01091 9.825 4.92184 9.67031 4.87028 9.57656C4.59841 9.10781 4.54684 9.01406 4.46247 8.85938C4.41559 8.77031 4.33122 8.61563 4.27497 8.52188C4.21872 8.42344 4.12966 8.26875 4.07809 8.17969C4.02653 8.09063 3.94216 7.94063 3.89059 7.85156C3.83903 7.7625 3.75466 7.6125 3.70309 7.52344C3.65153 7.43438 3.56716 7.28438 3.51559 7.19531C3.46403 7.10625 3.37966 6.95625 3.32809 6.86719C3.27653 6.77813 3.18747 6.62344 3.13591 6.52969C3.07966 6.43125 2.99528 6.29531 2.94372 6.225C2.89216 6.15 2.86403 6.09375 2.88278 6.09375C2.91091 6.09375 7.42028 9.29063 7.55622 9.4125C7.63122 9.47344 7.62653 9.50156 7.48122 9.79219C7.29841 10.1531 7.10153 10.7672 7.03122 11.2031C6.92341 11.8875 7.00309 12.8625 7.22341 13.5094C7.33591 13.8516 7.31716 13.9078 7.17653 13.6313Z" fill="#1F488D"/>
<path d="M5.25937 7.77189L2.90625 6.08908L3.15 5.73283C4.23281 4.17658 5.88281 2.83126 7.65 2.0672C8.63906 1.64064 9.58125 1.37345 10.5937 1.23751C11.2547 1.15314 12.6937 1.15314 13.3922 1.2422C16.8375 1.69689 19.7906 3.70314 21.5156 6.76876L21.6703 7.04064L16.4016 7.02658C10.6359 7.01251 10.9453 6.99845 10.2422 7.28439C9.68437 7.5047 9.58125 7.56095 9.21094 7.8047C8.76094 8.1047 8.30156 8.54064 7.97812 8.98126C7.84687 9.15939 7.71094 9.33751 7.67812 9.3797C7.62187 9.45001 7.35469 9.27189 5.25937 7.77189Z" fill="#275AB0"/>
<path d="M11.4234 22.6031C11.4328 22.5328 11.5547 21.2437 11.7 19.7344C11.8453 18.225 11.9625 16.9875 11.9672 16.9828C11.9718 16.9781 12.1265 16.9641 12.3047 16.95C12.8625 16.9078 13.4531 16.7766 13.8047 16.6172C14.6437 16.2375 15.0328 15.9797 15.5109 15.4922C15.7031 15.3 15.8672 15.1406 15.8719 15.1406C15.8812 15.1406 15.8437 15.2203 15.7875 15.3187C15.7312 15.4125 15.6562 15.5437 15.6234 15.6094C15.5859 15.675 15.5156 15.7922 15.4687 15.8672C15.4172 15.9469 15.3562 16.05 15.3281 16.1016C15.3047 16.1531 15.2297 16.2797 15.164 16.3828C15.0984 16.4859 15.0281 16.6125 15 16.6641C14.9765 16.7156 14.9015 16.8422 14.8359 16.9453C14.7703 17.0484 14.7 17.175 14.6719 17.2266C14.6484 17.2781 14.5734 17.4047 14.5125 17.5078C14.4469 17.6109 14.339 17.8031 14.2687 17.9297C14.2031 18.0609 14.1234 18.1922 14.0953 18.2297C14.039 18.2953 13.9547 18.4312 13.8797 18.5859C13.8515 18.6375 13.7765 18.7641 13.7109 18.8672C13.6453 18.9703 13.575 19.0969 13.5469 19.1484C13.5234 19.2 13.4484 19.3266 13.3828 19.4297C13.3172 19.5328 13.2469 19.6594 13.2187 19.7109C13.1953 19.7625 13.1203 19.8891 13.0547 19.9922C12.989 20.0953 12.9234 20.2125 12.9047 20.25C12.8906 20.2875 12.8062 20.4281 12.7265 20.5547C12.6468 20.6859 12.5672 20.8219 12.5484 20.8594C12.5297 20.8969 12.464 21.0141 12.3984 21.1172C12.3328 21.2203 12.2578 21.3469 12.2343 21.3984C12.2062 21.45 12.1359 21.5766 12.0703 21.6797C12.0047 21.7828 11.9297 21.9094 11.9062 21.9609C11.8781 22.0125 11.8078 22.1391 11.7422 22.2422C11.6765 22.3453 11.6062 22.4719 11.5828 22.5234C11.5593 22.5797 11.5125 22.6453 11.475 22.6781C11.4187 22.725 11.4093 22.7109 11.4234 22.6031Z" fill="#367DEF"/>
<path d="M11.3906 15.825C11.0766 15.7735 10.7578 15.6797 10.4766 15.5578C10.425 15.5344 10.3312 15.4969 10.2656 15.4688C9.96562 15.3422 9.5625 15.0375 9.15937 14.6344C8.84062 14.3157 8.75156 14.1891 8.50312 13.6875C8.43281 13.5469 8.35781 13.3922 8.33437 13.3453C8.24062 13.1532 8.10938 12.3797 8.10938 12.0047C8.10938 11.3719 8.22656 10.875 8.5125 10.2985C8.69062 9.94222 8.71875 9.90003 8.88281 9.67972C9.28125 9.1594 10.0687 8.54065 10.4672 8.43284C10.575 8.40472 10.6875 8.36722 10.7109 8.34847C10.7578 8.31565 11.1609 8.21722 11.4609 8.16565C11.6578 8.13284 12.3187 8.13284 12.5156 8.17034C12.9422 8.24065 13.1766 8.2969 13.2516 8.33909C13.2984 8.36253 13.4344 8.41878 13.5562 8.46565C13.6781 8.50784 13.8047 8.57347 13.8328 8.60628C13.8609 8.64378 13.9031 8.6719 13.9266 8.6719C14.0672 8.6719 15.1406 9.65159 15.1406 9.78284C15.1406 9.7969 15.1922 9.87659 15.2531 9.95628C15.5297 10.3219 15.7687 11.0672 15.825 11.7235C15.8578 12.1313 15.7406 13.0922 15.6515 13.1907C15.6281 13.2188 15.6094 13.2703 15.6094 13.3078C15.6094 13.5563 15.0844 14.3578 14.6297 14.8032C14.0719 15.3516 13.2891 15.7219 12.4453 15.8391C12.0234 15.9 11.8641 15.8953 11.3906 15.825Z" fill="#367DEF"/>
<path d="M10.5469 22.7579C6.54843 22.2001 3.20156 19.5142 1.80937 15.736C1.6125 15.211 1.40625 14.4235 1.28906 13.7813C1.18593 13.2048 1.13906 11.4985 1.21406 10.8282C1.38281 9.2579 1.94062 7.61727 2.73281 6.35633C2.85937 6.15477 2.88281 6.14071 2.93437 6.21102C2.96718 6.25321 3.32343 6.87196 3.73125 7.58446C4.13437 8.29696 5.01093 9.83446 5.67187 10.9923C6.33281 12.1548 6.95156 13.2423 7.04531 13.4157C7.14375 13.5892 7.25156 13.7626 7.29375 13.8001C7.33125 13.8423 7.41562 13.9876 7.47656 14.1282C7.88437 15.0517 8.75625 15.9376 9.72656 16.4157C10.4109 16.7532 10.8562 16.8798 11.5922 16.9454C11.8078 16.9688 11.9531 17.0017 11.9531 17.0345C11.9531 17.0626 11.8359 18.2907 11.6953 19.7579C11.55 21.2298 11.4375 22.4954 11.4422 22.5704C11.4422 22.6501 11.4469 22.7392 11.4422 22.772C11.4375 22.8423 11.1422 22.8376 10.5469 22.7579Z" fill="#548DEE"/>
<path d="M9.22969 14.7328L9.07031 14.5547L9.24844 14.7141C9.40781 14.8688 9.44531 14.9062 9.40781 14.9062C9.39844 14.9062 9.31875 14.8266 9.22969 14.7328Z" fill="#548DEE"/>
<path d="M14.6906 9.27188L14.5547 9.11719L14.7094 9.25313C14.85 9.38438 14.8828 9.42188 14.8453 9.42188C14.8359 9.42188 14.7703 9.35156 14.6906 9.27188Z" fill="#548DEE"/>
<path d="M16.0922 9.14539C15.4359 8.17976 14.3109 7.38758 13.2281 7.12508C13.0922 7.09695 12.9844 7.04539 12.9844 7.01727C12.9844 6.98445 13.2141 6.97977 13.725 6.99852C14.1281 7.01727 16.0828 7.03133 18.0703 7.03133C20.0531 7.03133 21.6562 7.05008 21.6328 7.06883C21.6047 7.09227 20.4375 7.63133 19.0312 8.27351C17.625 8.91101 16.4437 9.45008 16.4062 9.46883C16.3547 9.49695 16.275 9.41258 16.0922 9.14539Z" fill="#A8BFE7"/>
<path d="M11.4375 22.8C11.4375 22.7719 11.6109 22.4719 12.2812 21.3281C12.4594 21.0328 12.8906 20.2922 13.2422 19.6875C13.5984 19.0828 14.1422 18.1547 14.4516 17.625C15.0281 16.6406 15.1219 16.4813 15.5766 15.6984C15.7219 15.45 15.8437 15.2156 15.8531 15.1828C15.8625 15.1453 15.9 15.0938 15.9375 15.0609C15.975 15.0281 15.9937 15 15.975 15C15.9562 15 16.0312 14.8594 16.1437 14.6859C17.0906 13.2141 17.2312 11.4516 16.5234 9.90001C16.425 9.68907 16.3547 9.50626 16.3641 9.49688C16.4016 9.45938 21.6656 7.06876 21.675 7.08751C21.7734 7.2047 22.2281 8.33907 22.3781 8.83595C22.7297 9.99376 22.8469 10.8984 22.814 12.2438C22.7859 13.5844 22.6406 14.4328 22.2422 15.5625C20.7328 19.8984 16.6453 22.8281 12.1078 22.8281C11.7375 22.8281 11.4375 22.8141 11.4375 22.8Z" fill="#B1CDFD"/>
<path d="M11.3203 16.8984C10.6078 16.8047 10.1063 16.6359 9.50159 16.2938C8.3391 15.6328 7.4391 14.4469 7.12035 13.1625C6.99847 12.6703 6.9516 11.7281 7.0266 11.25C7.30785 9.43594 8.54066 7.9125 10.2422 7.27969C10.8703 7.05 11.1094 7.0125 12 7.00781C12.9047 7.00781 12.9891 7.02188 13.6875 7.275C15.2016 7.81875 16.4063 9.15 16.8094 10.725C17.2453 12.4031 16.7485 14.2219 15.5203 15.4547C14.3906 16.5938 12.9 17.1047 11.3203 16.8984ZM12.961 15.75C14.3578 15.3609 15.3891 14.3156 15.75 12.9188C15.8813 12.4078 15.8813 11.5969 15.75 11.0813C15.0656 8.39531 11.9063 7.2375 9.70316 8.86406C8.35316 9.85781 7.80941 11.4328 8.25003 13.0688C8.59222 14.3203 9.59066 15.3328 10.875 15.7172C11.4 15.8766 11.4657 15.8859 12.0703 15.8672C12.4266 15.8625 12.7032 15.825 12.961 15.75Z" fill="white"/>
</svg>
`;
