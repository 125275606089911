import config from "console_config";
import client from "Libs/platform";

export const getIdentityTokenRequest = async ({
  accessToken,
  publicKeyBase64,
  retry = false
}: {
  accessToken: string;
  publicKeyBase64: string;
  retry?: boolean;
}) => {
  const response = await fetch(`${config.URL_AUTH}/jwt`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify({
      user_public_key: publicKeyBase64
    })
  });

  if (!response?.ok && !retry) {
    await client.reAuthenticate();

    return getIdentityTokenRequest({
      accessToken,
      publicKeyBase64,
      retry: true
    });
  }

  const jsonResponse = await response.json();

  return jsonResponse?.token as string;
};
