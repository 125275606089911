import { apiProxyRequest as request } from "Api/apiProxyRequest";

export const deleteOrganizationInvitation = (
  organizationId: string,
  invitationId: string
) =>
  request(
    `/organizations/${organizationId}/invitations/${invitationId}`,
    "DELETE"
  );
