import { apiProxyRequest as request } from "Api/apiProxyRequest";
import { OrganizationProjectsList } from "Api/organizations/projects/OrganizationProjects.types";

type FilterKinds =
  | "ne"
  | "eq"
  | "in"
  | "nin"
  | "between"
  | "contains"
  | "starts"
  | "ends";

type SortTypes = "-updated_at" | "updated_at" | "created_at" | "-created_at";

export type GetOrganizationProjectsParams = {
  filter?: {
    title?: {
      [Kind in FilterKinds]?: string;
    };
    subscription_id?: {
      [Kind in FilterKinds]?: string;
    };
    type?: {
      [Kind in FilterKinds]?: string;
    };
    plan?: {
      [Kind in FilterKinds]?: string;
    };
    status?: {
      [Kind in FilterKinds]?: string;
    };
  };
  page?: {
    before?: string;
    after?: string;
    size?: number;
  };
  sort?: SortTypes;
};

export const getOrganizationProjects = (
  organizationId: string,
  params?: GetOrganizationProjectsParams
) =>
  request<OrganizationProjectsList>(
    `/organizations/${organizationId}/projects`,
    "GET",
    params
  );
