import localForage from "localforage";

import config from "console_config";

import type { CardTheme } from "Containers/announcements/common/styles";
import type { AppDispatch } from "Store/configureStore";

type RawAnnouncement = {
  title: string;
  date_published: string;
  content: string;
  artwork: string;
  tileTheme: CardTheme;
  id: number;
  publish: boolean;
  steps: {
    content: string;
    artwork?: string;
    tileTheme?: CardTheme;
  }[];
};

type AnnouncementsResponse = {
  version: string;
  title: string;
  description: string;
  production_url: string;
  testing_url: string;
  feed_url: string;
  items: RawAnnouncement[];
};

export type Announcement =
  ReturnType<typeof flagReadAnnouncements> extends (infer V)[] ? V : never;

type AnnouncementsState = {
  announcements: Announcement[];
  error?: string;
};

const initialState: AnnouncementsState = {
  announcements: []
};

const INIT = "app/announcements/setAlertState";
const DISPLAY_ANNOUNCEMENT = "app/announcements/display";
const HIDE_ANNOUNCEMENT = "app/announcements/hide";
const MARK_AS_READ = "app/announcements/read";
const FAILURE = "app/announcements/failure";

const FORAGE_KEY = "readAnnouncements";

const endpoint = config.URL_ANNOUNCEMENTS;
const defaultError = "Unable to load announcements";

const transformArtworkURLs = (response: AnnouncementsResponse) => {
  if (!response.items) {
    return;
  }

  const urlRoot = response.production_url;

  return response.items.map(announcement => ({
    ...announcement,
    artwork: announcement.artwork && `${urlRoot}${announcement.artwork}`,
    ...(announcement.artwork && { tileTheme: announcement.tileTheme }),
    steps: announcement.steps.map(step => ({
      ...step,
      artwork: step.artwork && `${urlRoot}${step.artwork}`,
      ...(step.artwork && {
        tileTheme: step.tileTheme || announcement.tileTheme
      })
    }))
  }));
};

const hasURLFlag = () => window.location.search.includes("show-drafts=");

const filterDrafts = (
  announcements: ReturnType<typeof transformArtworkURLs>
) => {
  const showDrafts = hasURLFlag();
  return announcements?.filter(
    announcement => announcement.publish || showDrafts
  );
};

const fetchAnnouncements = () =>
  fetch(`${endpoint}?h=${window.location.hostname}`, {
    headers: {
      Accept: "application/json"
    }
  })
    .then(response => response.json() as Promise<AnnouncementsResponse>)
    .then(transformArtworkURLs)
    .then(filterDrafts)
    .then(announcements => announcements?.slice(0, 15) || []);

const getReadAnnouncements = async () =>
  (await localForage.getItem<number[]>(FORAGE_KEY)) || [];

const flagReadAnnouncements = (
  announcements: Awaited<ReturnType<typeof fetchAnnouncements>>,
  read: Awaited<ReturnType<typeof getReadAnnouncements>>
) =>
  announcements.map(announcement => ({
    ...announcement,
    read: announcement.steps.length === 0 || read.includes(announcement.id),
    active: false
  }));

const updateAnnouncement = (
  state: AnnouncementsState,
  id: number,
  props: Partial<Announcement>
): AnnouncementsState => ({
  ...state,
  announcements: state.announcements.map(announcement => {
    if (announcement.id === id) {
      return {
        ...announcement,
        ...props
      };
    }
    return announcement;
  })
});

export const init = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const announcements = flagReadAnnouncements(
        ...(await Promise.all([fetchAnnouncements(), getReadAnnouncements()]))
      );

      dispatch({
        type: INIT,
        payload: { announcements }
      });
    } catch (error) {
      if (error instanceof Error) {
        dispatch({ type: FAILURE, payload: error.message });
      } else {
        dispatch({ type: FAILURE, payload: String(error) });
      }
    }
  };
};

export const markAsRead = (id: number) => async (dispatch: AppDispatch) => {
  const items = [...(await getReadAnnouncements()), id];
  await localForage.setItem(FORAGE_KEY, items);
  dispatch({
    type: MARK_AS_READ,
    payload: id
  });
};

export const display = (id: number) =>
  ({
    type: DISPLAY_ANNOUNCEMENT,
    payload: id
  }) as const;

export const hide = (id: number) =>
  ({
    type: HIDE_ANNOUNCEMENT,
    payload: id
  }) as const;

export const failure = (message = defaultError) =>
  ({
    type: FAILURE,
    payload: message
  }) as const;

type Action =
  | {
      type: typeof INIT;
      payload: AnnouncementsState;
    }
  | { type: typeof MARK_AS_READ; payload: number }
  | ReturnType<typeof display>
  | ReturnType<typeof hide>
  | ReturnType<typeof failure>;

export default function announcementsReducer(
  state = initialState,
  action: Action = {} as Action
): AnnouncementsState {
  switch (action.type) {
    case INIT:
      return action.payload;
    case DISPLAY_ANNOUNCEMENT:
      return updateAnnouncement(state, action.payload, { active: true });
    case HIDE_ANNOUNCEMENT:
      return updateAnnouncement(state, action.payload, { active: false });
    case MARK_AS_READ:
      return updateAnnouncement(state, action.payload, { read: true });
    case FAILURE:
      return { ...state, error: action.payload };
    default:
      return state;
  }
}
