import { apiProxyRequest } from "Api/apiProxyRequest";

import type { OrganizationAddon } from "Reducers/organization/addons";

export const updateAddonsRequest = (
  organizationId: string,
  selection: string
) =>
  apiProxyRequest<OrganizationAddon>(
    `/organizations/${organizationId}/addons`,
    "PATCH",
    {
      user_management: selection === "default" ? null : selection
    }
  );
