import styled from "styled-components";

import { Link as PrimitiveLink } from "ds/Button";

import { __disabled_styles } from "./Dropdown.styles";

export const Link = styled(PrimitiveLink)`
  border: 1px solid transparent;
  outline: none;
  box-shadow: none;
  font-size: 15px;
  line-height: 24px;
  height: 32px;
  width: 100%;

  :after {
    content: none;
  }

  :hover {
    outline: none;
    border-color: transparent;
    box-shadow: none;
  }
  ${({ disabled }) => disabled && __disabled_styles};
`;
