import { createAsyncThunk } from "@reduxjs/toolkit";

import client from "Libs/platform";
import { AsyncThunkOptionType } from "Reducers/types";

import type { OrganizationOrder } from "platformsh-client";

export const getOrganizationOrderAsOfNow = createAsyncThunk<
  OrganizationOrder | undefined,
  { organizationId: string; orderId: string },
  AsyncThunkOptionType<{ error: unknown }>
>(
  "organization/asOfOrder",
  async ({ organizationId, orderId }, { rejectWithValue }) => {
    try {
      const order = await client.getOrganizationOrder(
        organizationId,
        orderId,
        undefined,
        (new Date().getTime() / 1000).toFixed(0)
      );

      return order;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);
