import isEqual from "lodash/isEqual";
import isObject from "lodash/isObject";
import transform from "lodash/transform";

export const difference = <
  T1 extends Record<string | number | symbol, any>,
  T2 extends T1
>(
  origObj: T1,
  newObj: T2
) => {
  const changes = <
    U extends Record<string | number | symbol, any>,
    V extends U
  >(
    newObj: V,
    origObj: U
  ) => {
    type DeepPartial<T> = T extends object
      ? {
          [P in keyof T]?: DeepPartial<T[P]>;
        }
      : T;

    let arrayIndexCounter = 0;

    return transform<V, DeepPartial<V>>(newObj, (result, value, key) => {
      if (!isEqual(value, origObj[key])) {
        const resultKey = Array.isArray(origObj) ? arrayIndexCounter++ : key;

        result[resultKey] =
          isObject(value) && isObject(origObj[key])
            ? changes(value, origObj[key])
            : value;
      }
    });
  };

  return changes(newObj, origObj);
};

export const isDifferent = <
  T1 extends Record<string | number | symbol, any>,
  T2 extends T1
>(
  origObj: T1,
  newObj: T2
) => {
  // "difference" function will return "{}" if one of the two is an empty object even though they're different
  if (Object.keys(origObj).length === 0) {
    return Object.keys(newObj).length > 0;
  }
  if (Object.keys(newObj).length === 0) {
    return Object.keys(origObj).length > 0;
  }

  const diff = difference(origObj, newObj);
  return Object.keys(diff).length > 0;
};
