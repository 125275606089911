import { createSlice } from "@reduxjs/toolkit";

import { loadLoggedInMember } from "./thunks/loadLoggedInMember";

import type { OrganizationMember } from "Api/organizations/member/Member.types";
import type { RefUser } from "Api/ref/User.types";

export interface LoggedInMemberState {
  data?: { member: OrganizationMember; user: RefUser };
  isLoading: boolean;
  error: unknown;
}

const initialState: LoggedInMemberState = {
  data: undefined,
  isLoading: false,
  error: undefined
};

const slice = createSlice({
  name: "loggedInMember",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(loadLoggedInMember.pending, state => ({
        ...state,
        isLoading: true
      }))
      .addCase(loadLoggedInMember.fulfilled, (state, action) => ({
        ...state,
        data: action.payload,
        isLoading: false,
        error: undefined
      }))
      .addCase(loadLoggedInMember.rejected, (state, action) => ({
        ...state,
        isLoading: false,
        error: action.error,
        // A clean up of the current data is required since an error can happen
        // if the logged in user does not have manage users access. In this case
        // the api will throw a 403 forbidden and is totally fine. Failing to
        // clean up the old information on the profile will result on the user
        // keeping the information of the previously visited organization.
        data: undefined
      }));
  }
});

export const loggedInMemberReducer = slice.reducer;
