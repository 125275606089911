import { createAsyncThunk } from "@reduxjs/toolkit";

import { getSetupCatalogRequest } from "Api/getSetupCatalog.request";
import logger from "Libs/logger";

export type GetSetupCatalogThunkArguments =
  | {
      catalogUrl?: string | null;
      organizationId?: string | null;
    }
  | undefined;

export const getSetupCatalogThunk = createAsyncThunk(
  "setup/catalog",
  (
    { catalogUrl, organizationId }: GetSetupCatalogThunkArguments = {},
    { rejectWithValue }
  ) => {
    try {
      return getSetupCatalogRequest({ catalogUrl, organizationId });
    } catch (error) {
      logger(error, { action: "setup_load_catalog" });

      return rejectWithValue(error);
    }
  }
);
