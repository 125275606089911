import { createSelector } from "@reduxjs/toolkit";

import type { EnvTypeDataRetention } from ".";
import type { RootState } from "Store/configureStore";

export const projectSettingsStateSelector = (state: RootState) =>
  state.projectSettings;

export const projectSettingsLoadingSelector = createSelector(
  projectSettingsStateSelector,
  projectSettings => projectSettings.loading
);

export const projectSettingsErrorSelector = createSelector(
  projectSettingsStateSelector,
  projectSettings => {
    const error = projectSettings.error;
    if (typeof error?.detail === "string") return error.detail;
    if (typeof error?.detail === "object") {
      return Object.values(error.detail)[0];
    }
    if (error?.message) return error.message;
  }
);

export const projectSettingsSelector = createSelector(
  projectSettingsStateSelector,
  (_: RootState, params: { projectId: string }) => params,
  (projectSettings, { projectId }) => projectSettings.data?.[projectId]
);

export const projectSettingsSizingApiSelector = createSelector(
  projectSettingsSelector,
  projectSetting => projectSetting?.sizing_api_enabled
);

export const buildResourcesProjectSelector = createSelector(
  projectSettingsSelector,
  projectSetting => projectSetting?.build_resources
);

export const dataRetentionSelector = createSelector(
  projectSettingsSelector,
  projectSetting => projectSetting?.data_retention
);

export const envDataRetentionSelector = createSelector(
  dataRetentionSelector,
  (_: RootState, params: { environmentType: EnvTypeDataRetention }) => params,
  (dataRetention, { environmentType }) => dataRetention?.[environmentType]
);

const generateFrequencyAndBackupsCount = (scheduleData, manualCount = 0) => {
  const frequencyOptions = {
    h: { show: false, label: "Hourly" },
    d: { show: false, label: "Daily" },
    w: { show: false, label: "Weekly" },
    M: { show: false, label: "Monthly" },
    y: { show: false, label: "Yearly" }
  };
  let backupsCount = 0;

  scheduleData.forEach(schedule => {
    backupsCount += schedule.count;
    const freqChar = schedule.interval.replace(/\d*/, "");
    if (frequencyOptions?.[freqChar]) {
      frequencyOptions[freqChar].show = true;
    }
  });

  const cleanFrequency = Object.values(frequencyOptions)
    .filter(option => option.show)
    .map(option => option.label)
    .join(", ");

  return [cleanFrequency, backupsCount + manualCount] as [string, number];
};

export const backupEnvTypesInfoSelector = createSelector(
  dataRetentionSelector,
  data => {
    const prod = { type: "production", frequency: "", backups: 0 };
    const staging = { type: "staging", frequency: "", backups: 0 };
    const dev = { type: "development", frequency: "", backups: 0 };

    return [prod, staging, dev].map(backup => {
      if (data?.[backup.type]) {
        const [frequency, backupsCount] = generateFrequencyAndBackupsCount(
          data?.[backup.type]?.default_config?.schedule,
          data?.[backup.type]?.default_config?.manual_count
        );

        backup.frequency = frequency;
        backup.backups = backupsCount;
      }
      return backup;
    });
  }
);
