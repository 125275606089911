import styled from "styled-components";

import * as Dropdown from "ds/Dropdown";
import { semiBoldAlias } from "Libs/theme";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 8px;

  input:focus {
    border: none;
    box-shadow: none;
  }

  nav {
    top: unset;
    left: 40px;
    width: unset;
    min-width: 100px !important;
    margin-top: 30px;
    & > div {
      max-width: 280px;
      min-width: 216px !important;
    }
    span {
      font-size: 15px;
    }
  }

  .header-arrow,
  .chevron-link {
    outline: none;
  }
`;

export const CurrentOrganization = styled.span`
  font-size: 16px;
  ${semiBoldAlias()};
  overflow: hidden;
  text-overflow: ellipsis;

  color: var(
    --currentOrganization-navbar-color,
    var(--navbar-snow-color, var(--snow))
  );
`;

export const OrganizationNameWrapper = styled.div`
  max-width: 300px;
  width: 100%;
  .chevron-link {
    padding-right: 0;
  }
`;

export const DropdownTrigger = styled(Dropdown.Trigger)`
  font-size: 16px;
  ${semiBoldAlias()};
  text-overflow: ellipsis;

  padding: 0;

  color: var(
    --currentOrganization-navbar-color,
    var(--navbar-snow-color, var(--snow))
  );
  :focus,
  :hover {
    color: var(
      --currentOrganization-navbar-color,
      var(--navbar-snow-color, var(--snow))
    );
  }

  &[data-state="open"] {
    color: var(
      --currentOrganization-navbar-color,
      var(--navbar-snow-color, var(--snow))
    );
  }
`;
