import { createSelector } from "@reduxjs/toolkit";

import type { RootState } from "Store/configureStore";

export const observabilityPipelineSelector = (state: RootState) =>
  state.observabilityPipeline;

export const observabilityPipelineHttpClients = createSelector(
  observabilityPipelineSelector,
  ({ clients }) => clients
);

export const observabilityPipelineHttpClient = createSelector(
  observabilityPipelineSelector,
  (
    _: RootState,
    params: {
      projectId: string;
      machineName: string;
    }
  ) => params,
  (clients, { machineName, projectId }) =>
    clients[`${projectId}/${machineName}`] ?? null
);
