import { useIntl } from "react-intl";

export const useMonthNames = () => {
  const intl = useIntl();
  return [
    intl.formatMessage({ id: "january" }),
    intl.formatMessage({ id: "february" }),
    intl.formatMessage({ id: "march" }),
    intl.formatMessage({ id: "april" }),
    intl.formatMessage({ id: "may" }),
    intl.formatMessage({ id: "june" }),
    intl.formatMessage({ id: "july" }),
    intl.formatMessage({ id: "august" }),
    intl.formatMessage({ id: "september" }),
    intl.formatMessage({ id: "october" }),
    intl.formatMessage({ id: "november" }),
    intl.formatMessage({ id: "december" })
  ];
};
