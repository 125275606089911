import React, { memo, useEffect, useState } from "react";

import * as InputPrimitive from "ds/Input";
import { arrayIsDeepEqual } from "Libs/utils";
import { isValidEmail } from "Libs/validators";

export interface InputEmailProps {
  value: Array<string>;
  onChange: (value: Array<string>) => void;
  placeholder?: string;
  className?: string;
}

const stringToMails = (string: string) =>
  string
    .split(",")
    .map(email => email.trim())
    .filter(email => !!email && isValidEmail(email));

const InputEmail = ({ value, onChange, ...props }: InputEmailProps) => {
  const [inputState, setInputState] = useState("");

  useEffect(() => {
    if (!arrayIsDeepEqual(value, stringToMails(inputState)))
      onChange(stringToMails(inputState));
  }, [inputState, onChange, value]);

  // Handles the syncrhonization between the public value and the internal
  // input state. Verifies if some of the elements have been removed from the
  // list, if so, it will remove it from the text value trying to get rid of
  // any comma and extra space around that email.
  useEffect(() => {
    setInputState(state => {
      let result = state;
      const validEmails = stringToMails(state);
      validEmails.forEach(validEmail => {
        if (!value.includes(validEmail)) {
          result = result.replace(new RegExp(` *${validEmail} *,? *`), "");
        }
      });
      return result;
    });
  }, [value]);

  return (
    <InputPrimitive.Input
      data-heap-redact-text
      onChange={event => setInputState(event.currentTarget.value)}
      value={inputState}
      isValid
      {...props}
    />
  );
};

export default memo(InputEmail);
