import { apiProxyRequest as request } from "Api/apiProxyRequest";

import type { TeamProjectAccess } from "Api/teams/teams.types";

export type ListProjectTeamAccessOptions = {
  page?: {
    size?: number;
    before?: string;
    after?: string;
  };
  sort?: "-granted_at" | "granted_at" | "-updated_at" | "updated_at";
};

export type ListProjectTeamAccessResponse = {
  items: Array<TeamProjectAccess>;
  _links: {
    "ref:teams:0"?: { href: string };
    self: { href: string };
    previous?: { href: string };
    next?: { href: string };
  };
};

export const listProjectTeamAccess = (
  projectId: string,
  options?: ListProjectTeamAccessOptions
) =>
  request<ListProjectTeamAccessResponse>(
    `/projects/${projectId}/team-access`,
    "GET",
    options
  );
