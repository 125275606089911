import React, { SVGProps } from "react";

const Checked = ({ fill, ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 0C0.447715 0 0 0.447715 0 1V9C0 9.55228 0.447715 10 1 10H9C9.55228 10 10 9.55229 10 9V1C10 0.447715 9.55229 0 9 0H1Z"
        fill={fill}
      />
    </svg>
  );
};

export default Checked;
