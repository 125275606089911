
  // IMPORTANT: This file was generated by tools/build.js
  // If you edit it, your changes will be overwritten once dev server restarts
  export default {
  "CONFIG_ID": "upsun:staging",
  "CUSTOM_APP_NAME": "Upsun",
  "CUSTOM_ASSETS_URL": false,
  "CUSTOM_AUTH_LEFT_PANEL": [
    "/register"
  ],
  "CUSTOM_BLACKFIRE_CARD_LANGUAGE": [
    "php",
    "python"
  ],
  "CUSTOM_BRANDING_MESSAGE": false,
  "CUSTOM_CLI_COMMAND": "upsunstg",
  "CUSTOM_CONFIG_DIR_NAME": ".upsun",
  "CUSTOM_CONSOLE_ID": "upsun",
  "CUSTOM_CONSOLE_TITLE": false,
  "CUSTOM_CSP": false,
  "CUSTOM_DEFAULT_BRANCH": "main",
  "CUSTOM_FEDERATED_AUTH_PROVIDERS": [
    {
      "connectUrl": "https://auth.upsun.plat.farm/auth/authorize/github-upsun?intent=connect",
      "imagePath": "images/github.svg",
      "imagePathDark": "images/github-white.svg",
      "label": "GitHub",
      "loginUrl": "https://auth.upsun.plat.farm/auth/authorize/github-upsun?intent=login",
      "name": "github",
      "signupUrl": "https://auth.upsun.plat.farm/auth/authorize/github-upsun?intent=signup"
    },
    {
      "connectUrl": "https://auth.upsun.plat.farm/auth/authorize/google?intent=connect",
      "imagePath": "images/google.svg",
      "label": "Google",
      "loginUrl": "https://auth.upsun.plat.farm/auth/authorize/google?intent=login",
      "name": "google",
      "signupUrl": "https://auth.upsun.plat.farm/auth/authorize/google?intent=signup"
    },
    {
      "connectUrl": "https://auth.upsun.plat.farm/auth/authorize/bitbucket-upsun?intent=connect",
      "imagePath": "images/bitbucket.svg",
      "label": "Bitbucket",
      "loginUrl": "https://auth.upsun.plat.farm/auth/authorize/bitbucket-upsun?intent=login",
      "name": "bitbucket",
      "signupUrl": "https://auth.upsun.plat.farm/auth/authorize/bitbucket-upsun?intent=signup"
    },
    {
      "connectUrl": "https://auth.upsun.plat.farm/auth/authorize/gitlab?intent=connect",
      "imagePath": "images/gitlab.svg",
      "label": "GitLab",
      "loginUrl": "https://auth.upsun.plat.farm/auth/authorize/gitlab?intent=login",
      "name": "gitlab",
      "signupUrl": "https://auth.upsun.plat.farm/auth/authorize/gitlab?intent=signup"
    }
  ],
  "CUSTOM_FLEXIBLE_PLAN_VALUE": "upsun/flexible",
  "CUSTOM_FOOTER_TEXT": false,
  "CUSTOM_LOGO_HEIGHT": 32,
  "CUSTOM_LOGO_WIDTH": 32,
  "CUSTOM_ORGANIZATION_VENDORS": {
    "platformsh": "https://staging-5em2ouy-i7dymnob47qsi.us-2.platformsh.site",
    "upsun": ""
  },
  "CUSTOM_PROFILING_LANGUAGE": [
    "php",
    "python",
    "golang"
  ],
  "CUSTOM_SIMPLE_LEFT_PANEL": [
    "/"
  ],
  "CUSTOM_THEME": false,
  "CUSTOM_TRIAL_STAGES": false,
  "CUSTOM_URL_SUPPORT": false,
  "CUSTOM_WINDOW_TITLE": "Upsun",
  "CUSTOM_URL_BILLING": false,
  "CUSTOM_URL_CLI_DOWNLOAD": false,
  "CUSTOM_URL_DOCS": "https://docs.upsun.com",
  "CUSTOM_URL_DOCS_STATUS_CHECK": false,
  "CUSTOM_URL_PROJECT_CREATION": false,
  "CUSTOM_URL_REGISTER": "https://upsun.com/register/",
  "CUSTOM_URL_SUBSCRIPTION_DELETE_REDIRECT": false,
  "CUSTOM_URL_SUBSCRIPTION_UPGRADE": false,
  "CUSTOM_URL_VENDOR_CONSOLE": false,
  "URL_ACCOUNTS": "https://accounts.staging.plat.farm",
  "URL_ANNOUNCEMENTS": "https://platform.sh/product/announcements/upsun.json",
  "URL_API_BLACKFIRE": "https://api.blackfire.io",
  "URL_API_PROXY": "https://api.upsun.plat.farm/api",
  "URL_AUTH": "https://auth.upsun.plat.farm",
  "URL_DEMO_PROJECT": "https://github.com/platformsh/demo-cmd.git",
  "URL_GITHUB": "https://api.github.com",
  "URL_GITLAB": "https://gitlab.com",
  "URL_LOGOUT": "https://auth.upsun.plat.farm/auth/logout",
  "URL_REDIRECT_AFTER_LOGIN": false,
  "URL_REDIRECT_AUTH_USER": "https://console.upsun.plat.farm",
  "URL_SCREENSHOT_API": "https://screenshots.upsun.plat.farm/screenshot",
  "URL_SIA": "https://staging-5em2ouy-3dhe6p6clb6wm.eu-3.platformsh.site",
  "URL_ZENDESK": "https://support.platform.sh/hc/en-us/requests",
  "ID_API_CLIENT": "ui@upsun",
  "ID_COOKIELAW": false,
  "ID_DRIFT_CHATBOT": false,
  "ID_HEAP": "1950127935",
  "ID_PLATFORM_TREE": false,
  "ID_RECAPTCHA": "6Lfq4s8UAAAAABtXLuH3OSAZXqcg6dYkRaTaUY2m",
  "ID_STATUS_PAGE": "l2x5n4czt1ph",
  "ID_ZENDESK": false,
  "DISABLE_ALL_PROJECTS": true,
  "ENABLE_ACCOUNT_PAGES": true,
  "ENABLE_ACCOUNT_SECURITY_ROUTE": true,
  "ENABLE_ADD_DOMAIN_SUPPORT": false,
  "ENABLE_ADD_PROJECT_USER_PRICING_AGREEMENT": true,
  "ENABLE_ANNOUNCEMENTS": true,
  "ENABLE_AUTOMATED_BACKUPS": false,
  "ENABLE_BILLING": true,
  "ENABLE_BLACKFIRE_CARD": true,
  "ENABLE_CHANGE_USERNAME_EMAIL": true,
  "ENABLE_CONNECTED_ACCOUNTS_ROUTE": true,
  "ENABLE_CONTINUOUS_PROFILING_SELLABLE": true,
  "ENABLE_CREATE_ORGANIZATION": true,
  "ENABLE_CREATE_PROJECT": true,
  "ENABLE_CUSTOM_BANNER_MESSAGE": true,
  "ENABLE_CUSTOM_LEFT_PANEL": false,
  "ENABLE_DEPLOYMENT_OVERLAY": true,
  "ENABLE_DISCLAIMER_GENERAL_SUPPORT": false,
  "ENABLE_EDIT_PLAN": true,
  "ENABLE_ENVIRONMENT_DOMAINS": true,
  "ENABLE_ENVIRONMENT_EMAIL_SETTING": true,
  "ENABLE_ENVIRONMENT_SOCKET": true,
  "ENABLE_EXTERNAL_URL": false,
  "ENABLE_FIRST_PROJECT_INCENTIVE": true,
  "ENABLE_FREE_TRIAL_DIALOG": true,
  "ENABLE_GREEN_DISCOUNT": true,
  "ENABLE_HIPAA_COMPLIANCE": false,
  "ENABLE_HTTP_METRICS": true,
  "ENABLE_LOCAL_DEPLOYMENT_TARGET": false,
  "ENABLE_MARKETING_CHECKBOX": true,
  "ENABLE_MIGRATION_FLOW": true,
  "ENABLE_NOTIFICATION_SETTINGS": true,
  "ENABLE_OBSERVABILITY_SANDBOX": true,
  "ENABLE_OBSERVABILITY_SUITE_INTEGRATIONS": true,
  "ENABLE_ORGANIZATION": true,
  "ENABLE_ORGANIZATION_FIRST_ONBOARDING": true,
  "ENABLE_ORGANIZATION_PLANS_PERMISSION": false,
  "ENABLE_PROFILING": true,
  "ENABLE_PROJECT_ACCESS_SELECT": true,
  "ENABLE_PROJECT_PROVISIONING_WIZARD": false,
  "ENABLE_PROJECT_SETUP_TIPS": true,
  "ENABLE_PROJECT_SETUP_WIZARD": false,
  "ENABLE_REACTIVATE_TRIAL": true,
  "ENABLE_REGISTRATION": true,
  "ENABLE_RESET_PROFILE_PASSWORD": true,
  "ENABLE_RESOURCE_METRICS_THRESHOLD": false,
  "ENABLE_SENTRY": false,
  "ENABLE_SERVICE_TREE": true,
  "ENABLE_SINGLE_STEP_SINGUP_FLOW": true,
  "ENABLE_SOURCE_OPERATION": true,
  "ENABLE_SOURCE_REPOSITORY_TOOLTIP": false,
  "ENABLE_SSO": true,
  "ENABLE_STAFF_TEST_MODE": true,
  "ENABLE_STRIPE": true,
  "ENABLE_SUBSCRIBE_FLOW": true,
  "ENABLE_SUPPORT_SELLABLE": true,
  "ENABLE_TERMS_AND_ACCEPTANCE_CHECKBOX": true,
  "ENABLE_TOTAL_RESOURCE_ALLOCATION_METRICS": true,
  "ENABLE_UNBRANDED_ILLUSTRATIONS": true,
  "ENABLE_USAGE_BASED_BILLING_PAGES": true,
  "ENABLE_USER_MANAGEMENT": true,
  "ENABLE_VOUCHERS": true,
  "ENABLE_ZENDESK": false,
  "VERSION_NUMBER": "3.2.54"
}