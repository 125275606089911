import styled from "styled-components";

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ButtonWrapper = styled.div`
  margin-top: 48px;

  @media screen and (min-width: 900px) {
    margin-top: 64px;
  }
`;
