import React from "react";

import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import Banner from "ds/Banner";
import { selectCurrentUserUsername } from "Reducers/app/selectors";
import { selectFeatureOrganizationsEnabled } from "Reducers/featureFlags/featureFlags.selectors";
import { useAppSelector } from "Store/hooks";

type PaymentMissingProps = {
  organizationId: string;
};

const PaymentMissing = ({ organizationId }: PaymentMissingProps) => {
  const username = useAppSelector(selectCurrentUserUsername);
  const organizationEnabled = useAppSelector(selectFeatureOrganizationsEnabled);

  let billingUrl = `/-/users/${username}/billing`;
  if (organizationEnabled && organizationId) {
    billingUrl = `/${organizationId}/-/billing`;
  }

  return (
    <Banner
      priority={"critical"}
      text={
        <FormattedMessage
          id="banner.payment_missing"
          defaultMessage="Payment method missing. Add your <a>billing details</a> to keep your projects running."
          values={{
            a: txt => <Link to={billingUrl}>{txt}</Link>
          }}
        />
      }
    />
  );
};
export default PaymentMissing;
