import * as PopoverPrimitive from "@radix-ui/react-popover";
import styled, { css as styledCSS } from "styled-components";

export interface TriggerProps {
  $hoverState: boolean;
}

export const Trigger = styled(PopoverPrimitive.Trigger)<TriggerProps>`
  ${({ $hoverState }) =>
    !$hoverState &&
    styledCSS`
      outline: none;
      border-color: transparent !important;
      box-shadow: 0 0 0 transparent, inset 0 0 0 transparent !important;    
  `};
`;

export default Trigger;
