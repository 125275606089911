import styled, { css as styledCSS } from "styled-components";

interface InputProps {
  isValid?: boolean;
  width?: string;
}

const Input = styled.input<InputProps>`
  background-color: var(--mode-bg-neutral-weaker);
  color: var(--mode-text-neutral-default-weak);
  font-family: "Open Sans";
  outline: none;
  border: 1px solid var(--mode-bg-neutral-weaker);
  height: 38px;
  padding: 8px 16px;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 24px;
  border-radius: var(
    --input-layout-input-border-radius,
    var(--input-2px-border-radius, var(--2px))
  );
  width: ${props => props.width ?? "fit-content"};

  &::placeholder {
    color: var(--mode-text-neutral-weakest);
    font-size: 15px;
    line-height: 24px;
  }

  &:focus {
    border: 1px solid var(--mode-border-primary-strong);
    background: transparent;
    box-shadow: 0px 0px 2px #9cb9f5;
  }

  &[disabled] {
    cursor: not-allowed;
    color: var(--mode-text-neutral-weakest-disabled);
    background-color: var(--mode-bg-neutral-weaker);
  }

  ${({ isValid }) =>
    !isValid &&
    styledCSS`
    border: 1px solid var(--mode-border-critical-default);

    &:focus {
      border: 1px solid var(--mode-border-critical-default);
      box-shadow: none;
    }
  `}
`;

export default Input;
