import {
  createAsyncThunk,
  createSlice,
  createSelector
} from "@reduxjs/toolkit";

import consoleConfig from "console_config";
import { THIRD_PARTIES } from "Constants/integrationThirdParties";
import logger from "Libs/logger";
import { setDeep } from "Libs/objectAccess";
import { AsyncThunkOptionType, GitErrorType } from "Reducers/types";
import { RootState } from "Store/configureStore";

import {
  APIError,
  AddIntegrationParams,
  IntegrationErrors,
  UpdateIntegrationParams,
  DeleteIntegrationParams,
  GitRepoParams,
  SortingOrder,
  SortingValues,
  SelectorParams,
  Config,
  RemoteGitProject,
  RemoteGitData
} from "./types";

import type { Integration } from "platformsh-client";

const CONFIG: Record<string, Config> = {
  github: {
    tokenUrl: "/user",
    repoUrl: "/user/repos?per_page=100&page=1"
  },
  gitlab: {
    tokenUrl: "/api/v4/user",
    repoUrl: "/api/v4/projects?membership=1&per_page=100&page=1"
  }
};

const isAPIError = (error: any): error is APIError =>
  typeof error.message === "string" && error.detail;

export const getIntegrations = createAsyncThunk(
  "app/integrations",
  async ({ projectId }: { projectId: string }) => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;
    const integrations = await client.getIntegrations(projectId);
    return integrations;
  }
);

export const getIntegration = createAsyncThunk(
  "app/integration",
  async (
    {
      projectId,
      integrationId
    }: {
      projectId: string;
      integrationId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;
      const integration = await client.getIntegration(projectId, integrationId);
      return integration;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const addIntegration = createAsyncThunk<
  Integration,
  AddIntegrationParams,
  AsyncThunkOptionType<IntegrationErrors>
>(
  "app/integration/add",
  async ({ project, type, data }, { rejectWithValue }) => {
    try {
      const result = await project.addIntegration(type, data);
      const integration: Integration = await result.getEntity();
      return integration;
    } catch (error: unknown) {
      let errorMessage =
        "An error occurred while attempting to add integration.";
      if (isAPIError(error)) {
        logger(error, {
          action: "addIntegration",
          meta: {
            projectId: project.id
          }
        });

        if (typeof error.detail === "object") {
          const errValues = Object.values(error.detail).join(" ");
          // The error returned by git in the type field
          errorMessage = error.detail.type ?? (errValues || error.message);
        } else {
          errorMessage = error.detail;
        }
      }
      return rejectWithValue({ errorMessage, error: error as GitErrorType });
    }
  }
);

export const updateIntegration = createAsyncThunk<
  Integration,
  UpdateIntegrationParams,
  AsyncThunkOptionType<IntegrationErrors>
>(
  "app/integration/update",
  async ({ projectId, integration, data }, { rejectWithValue }) => {
    const fields = Object.assign(
      {},
      { projectId },
      { integrationId: integration.id },
      data
    );
    try {
      const result = await integration.update(fields);
      const newIntegration: Integration = await result?.getEntity();
      return newIntegration;
    } catch (error: unknown) {
      let errorMessage =
        "An error occurred while attempting to update integration.";
      if (isAPIError(error)) {
        logger(
          {
            errMessage: error.message,
            integrationId: integration.id,
            projectId
          },
          {
            action: "updateIntegration"
          }
        );
        if (typeof error.detail === "object") {
          // The error returned by git in the type field
          errorMessage =
            error.detail.type || error.detail?.error || error.message;
        } else {
          errorMessage = error.detail;
        }
      }
      return rejectWithValue({ errorMessage, error: error as GitErrorType });
    }
  }
);

export const deleteIntegration = createAsyncThunk<
  Integration | undefined,
  DeleteIntegrationParams,
  AsyncThunkOptionType<IntegrationErrors>
>("app/integration/delete", async ({ projectId, integration }) => {
  if (!integration) return;
  await integration.delete().catch(err => {
    const errMessage = JSON.parse(err);
    logger(
      {
        errMessage,
        integrationId: integration.id,
        projectId
      },
      {
        action: "deleteIntegration"
      }
    );
    throw new Error(errMessage.error);
  });
  return integration;
});

/**
 * Transform string returns by the api like:
 * <https://gitlab.com/api/v4/users/....>; rel="first", <https://gitlab.com/api/v4/users/...>; rel="next", <https://gitlab.com/api/v4/users/...>; rel="last"
 *
 * to object
 * { first: "url", next: "url", last: "url" }
 */
const getLinks = (str: string | null) => {
  if (!str) return {};
  return str.split(", ").reduce(
    (acc, cu) => {
      const [link, val] = cu.split("; ");
      const key = val.substring(val.indexOf('"') + 1, val.lastIndexOf('"'));
      acc[key] = link.replace(/[<>]/g, "");
      return acc;
    },
    {} as Record<string, string>
  );
};

const fetchNextProjectData = async (
  url: string,
  token: string,
  projectsArray: RemoteGitProject[] = []
): Promise<RemoteGitProject[]> => {
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  const projectData = await response.json();
  projectsArray.push(...projectData);

  const links = getLinks(response.headers.get("Link"));
  if (links.next) {
    return fetchNextProjectData(links.next, token, projectsArray);
  }
  return projectsArray;
};

export const getGitRepositoriesIntegration = createAsyncThunk<
  RemoteGitData | undefined,
  GitRepoParams,
  AsyncThunkOptionType<IntegrationErrors>
>(
  "app/integration/git",
  async ({ baseUrl, token, type }, { rejectWithValue }) => {
    if (!["gitlab", "github"].includes(type)) return;

    const apiUrl =
      baseUrl?.replace(/\/$/, "") ||
      (type === "github" ? consoleConfig.URL_GITHUB : consoleConfig.URL_GITLAB);
    const config = CONFIG[type];

    try {
      const resUser = await fetch(`${apiUrl}${config.tokenUrl}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (resUser?.status === 401) throw new Error("Bad Credentials");

      const userData: {
        avatar_url?: string;
        login?: string;
        name?: string;
        username?: string;
      } = await resUser.json();

      const projectData = await fetchNextProjectData(
        `${apiUrl}${config.repoUrl}`,
        token
      );

      return {
        repositories: projectData.map(repository => {
          const repo =
            type === "github"
              ? repository.full_name
              : repository.path_with_namespace;
          return { label: repo, value: repo };
        }),
        user: {
          name: userData.name || userData.login || userData.username,
          avatarUrl: userData.avatar_url
        }
      };
    } catch (error: unknown) {
      let errorMessage =
        "An error occurred while attempting to get Git repositories.";
      if (isAPIError(error)) {
        logger(
          { errorMessage: error.message, token: token },
          { action: "getGitRepositoriesIntegration" }
        );
        errorMessage = error.message;
      }
      return rejectWithValue({ errorMessage, error: error as GitErrorType });
    }
  }
);

export type IntegrationState = {
  data: {
    [projectId: string]: { [integrationId: string]: Integration } | undefined;
  };
  loading: boolean;
  errorMessage?: string;
  error?: GitErrorType;
  git?: {
    status?: "pending" | "fulfilled" | "rejected";
    data?: {
      [token: string]: RemoteGitData | undefined;
    };
  };
  lastEdited?: string;
  status?: "idle" | "added" | "rejected" | "pending" | "updated" | "deleted";
};

const initialState: IntegrationState = {
  data: {},
  loading: false
};

const integrations = createSlice({
  name: "integrations",
  initialState,
  reducers: {
    initForm(state, action) {
      delete state.errorMessage;
      delete state.error;
      state.status = "idle";
      state.lastEdited = action?.payload?.id;
    },
    initGit(state) {
      delete state.git?.status;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getIntegrations.pending, state => {
        state.loading = true;
      })
      .addCase(getIntegrations.fulfilled, (state, action) => {
        const { projectId } = action.meta.arg;
        state.data = action.payload.reduce(
          (projectIntegrations, integration) => {
            setDeep(
              projectIntegrations,
              [projectId, integration.id],
              integration
            );
            return projectIntegrations;
          },
          {} as {
            [projectId: string]:
              | { [integrationId: string]: Integration }
              | undefined;
          }
        );
        state.loading = false;
        state.status = "idle";
        delete state.lastEdited;
      })

      .addCase(getIntegrations.rejected, (state, action) => {
        state.errorMessage = action.error.message;
        state.error = action.error as unknown as GitErrorType;
        state.loading = false;
      })
      .addCase(getIntegration.pending, state => {
        state.loading = true;
        delete state.errorMessage;
        delete state.error;
      })
      .addCase(getIntegration.fulfilled, (state, action) => {
        const { projectId, integrationId } = action.meta.arg;
        setDeep(state, ["data", projectId, integrationId], action.payload);
        state.loading = false;
      })
      .addCase(getIntegration.rejected, (state, action) => {
        state.errorMessage = action.error.message;
        state.error = action.error as unknown as GitErrorType;
        state.loading = false;
      })

      .addCase(getGitRepositoriesIntegration.pending, state => {
        state.loading = true;
        delete state.errorMessage;
        delete state.error;
        setDeep(state, ["git", "status"], "pending");
      })
      .addCase(getGitRepositoriesIntegration.fulfilled, (state, action) => {
        const { token } = action.meta.arg;
        setDeep(state, ["git", "data", token], action.payload);
        state.loading = false;
        setDeep(state, ["git", "status"], "fulfilled");
      })

      .addCase(getGitRepositoriesIntegration.rejected, (state, action) => {
        state.errorMessage = action.payload?.errorMessage;
        setDeep(state, ["git", "status"], "rejected");
      })
      .addCase(addIntegration.pending, state => {
        state.status = "pending";
        delete state.errorMessage;
        delete state.error;
      })
      .addCase(addIntegration.fulfilled, (state, action) => {
        const { project } = action.meta.arg;
        setDeep(state, ["data", project.id, action.payload.id], action.payload);
        state.status = "added";
        state.lastEdited = action.payload?.id;
      })
      .addCase(addIntegration.rejected, (state, action) => {
        state.errorMessage = action.payload?.errorMessage;
        state.error = action.payload?.error;
        state.status = "rejected";
      })
      .addCase(updateIntegration.pending, state => {
        state.status = "pending";
        delete state.errorMessage;
        delete state.error;
      })
      .addCase(updateIntegration.fulfilled, (state, action) => {
        const { projectId } = action.meta.arg;
        setDeep(state, ["data", projectId, action.payload.id], action.payload);
        state.status = "updated";
      })
      .addCase(updateIntegration.rejected, (state, action) => {
        state.error = action.payload?.error;
        state.errorMessage = action.payload?.errorMessage;
        state.status = "rejected";
      })
      .addCase(deleteIntegration.pending, state => {
        state.status = "pending";
        delete state.errorMessage;
        delete state.error;
      })

      .addCase(deleteIntegration.fulfilled, (state, action) => {
        const { projectId } = action.meta.arg;
        const project = state.data[projectId];
        if (action.payload && project) {
          delete project[action.payload.id];
        }
        delete state.errorMessage;
        delete state.error;
        state.status = "deleted";
      })
      .addCase(deleteIntegration.rejected, (state, action) => {
        state.errorMessage = action.payload?.errorMessage;
        state.error = action.payload?.error;
        state.status = "rejected";
      });
  }
});

export const { initForm, initGit } = integrations.actions;
export default integrations.reducer;

const selectSelf = (state: RootState) => {
  return state.integration;
};

const getCategory = (obj: Integration) => {
  const category = THIRD_PARTIES.find(elt => elt.type === obj.type)?.category;
  if (category) return category;
  const typeSplit = obj.type.split(".");
  return typeSplit[typeSplit.length - 1];
};

export const numberOfIntegrationsSelector = createSelector(
  selectSelf,
  (_: RootState, params: SelectorParams) => params,
  (integration, { projectId }) => {
    return Object.keys(integration.data[projectId] || {}).length;
  }
);

export const singleIntegrationSelector = createSelector(
  selectSelf,
  (_: RootState, params: SelectorParams) => params,
  (integration, { integrationId, projectId }) => {
    const singleIntegration = integration.data[projectId]?.[integrationId];

    if (!singleIntegration) return;

    // Check if integration comes from a third party and set category accordingly
    singleIntegration.category = getCategory(singleIntegration);

    return singleIntegration;
  }
);

const getIntegrationsSorter =
  (sortBy: SortingValues, sortOrder: SortingOrder) =>
  (a: Integration, b: Integration) => {
    let catA = a[sortBy] as string;
    let catB = b[sortBy] as string;

    // We need to deal with a special case when sorting by integration type
    // some types include the prefix health, but this is removed later in the
    // translation string, if we sort by the internal type the order won't make
    // sense to the user. (i.e. health.email would be after gitlab, when the name
    // we use is just email)
    if (sortBy === "type" && catA.startsWith("health.")) {
      catA = catA.split(".")[1];
    }

    if (sortBy === "type" && catB.startsWith("health.")) {
      catB = catB.split(".")[1];
    }

    if (sortOrder === "descending") {
      return catA > catB ? -1 : catA < catB ? 1 : 0;
    }

    return catA < catB ? -1 : catA > catB ? 1 : 0;
  };

export const integrationsSelector = createSelector(
  selectSelf,
  (_: RootState, params: SelectorParams) => params,
  (integration, { projectId, sortBy = "type", sortOrder = "descending" }) => {
    const sorter = getIntegrationsSorter(sortBy, sortOrder);

    return Object.values(integration.data[projectId] || {})
      .map(elt =>
        Object.assign({}, elt, {
          category: getCategory(elt)
        })
      )
      .sort(sorter);
  }
);
export const integrationTypesSelector = createSelector(
  integrationsSelector,
  integrations =>
    integrations
      .map(int => int.type)
      .filter(int => int !== "health.email")
      .map(int => int.charAt(0).toUpperCase() + int.slice(1))
      .join(", ")
);

export const integrationsLoadingSelector = createSelector(
  selectSelf,
  integration => {
    return integration.loading;
  }
);

export const integrationsStatusSelector = createSelector(
  selectSelf,
  integration => {
    return integration.status;
  }
);

export const integrationErrorSelector = (state: RootState) => ({
  error: state.integration.error,
  errorMessage: state.integration.errorMessage
});
