import styled from "styled-components";

export const CustomBackLink = styled.div`
  justify-content: flex-start;
  margin-left: 24px;
  margin-top: 20px;
`;

export const LogoCloseContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  padding-right: 24px;
  padding-left: 24px;
`;

export const Line = styled.div`
  height: 2px;
  background-color: var(
    --hamburgerLine-section-background-color,
    var(--section-snow-background-color, var(--snow))
  );
  width: 24px;

  &:first-child {
    transform: translate3d(0px, 0px, 0px) rotate(0deg);
  }

  &:last-child {
    transform: translate3d(0px, 8px, 0px) rotate(0deg);
  }
`;

export const LinesContainer = styled.div`
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
`;
