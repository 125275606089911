import { createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "Store/configureStore";

import {
  selectSelectedMemberIds,
  selectSelectableFilteredMembers
} from "../membersList.selectors";

export const toggleSelectAllMembers = createAsyncThunk(
  "toggleSelectAllMembers",
  (_, { getState }) => {
    const state = getState() as RootState;
    const selected = selectSelectedMemberIds(state);
    const selectableMembers = selectSelectableFilteredMembers(state);

    const allSelected = selectableMembers.every(member =>
      selected.includes(member.member.id)
    );

    if (allSelected) {
      const selectableMembersIds = selectableMembers.map(
        member => member.member.id
      );
      return selected.filter(id => !selectableMembersIds.includes(id));
    }

    return Array.from(
      new Set(selected.concat(selectableMembers.map(({ member }) => member.id)))
    );
  }
);
