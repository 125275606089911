import React, { memo } from "react";

import { CloseButton } from "ds/Button";
import { Box } from "ds/OverflowList";
import { Label } from "ds/Typography";

export interface EmailBoxProps {
  element: string;
  onClose: (element: string) => void;
}

const EmailBox = ({ element, onClose }: EmailBoxProps) => (
  <Box>
    <Label textStyle="extraSmall">{element}</Label>
    <CloseButton onClick={() => onClose(element)} />
  </Box>
);

export default memo(EmailBox);
