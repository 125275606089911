import { createAsyncThunk } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import { AsyncThunkOptionType } from "Reducers/types";

import type { ProjectLevelVariable, Result } from "platformsh-client";

export const updateVariable = createAsyncThunk<
  ProjectLevelVariable | undefined,
  {
    organizationId: string;
    projectId: string;
    variable: ProjectLevelVariable;
    data: unknown;
  },
  AsyncThunkOptionType<{ error: Error }>
>(
  "app/project/variable/update",
  async ({ projectId, variable, data }, { rejectWithValue }) => {
    const fields = Object.assign(
      {},
      { projectId },
      { variableId: variable.id },
      data
    );
    try {
      const result: Result = await variable.update(fields);
      const newVariable: ProjectLevelVariable = await result?.getEntity();
      return newVariable;
    } catch (err) {
      const error = err as Error;
      logger(
        { errMessage: error?.message, projectId, variableId: variable.id },
        {
          action: "projectUpdateVariable"
        }
      );
      return rejectWithValue({ error: error });
    }
  }
);
