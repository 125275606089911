import { createSelector } from "@reduxjs/toolkit";

import type { RootState } from "Store/configureStore";

export const observabilitySandboxSelector = (state: RootState) =>
  state.observabilitySandbox;

export const isObservabilitySandboxGranted = createSelector(
  observabilitySandboxSelector,
  ({ access }) => access
);
