export default (x, y, width, height /*, color*/) =>
  `<?xml version="1.0" encoding="iso-8859-1"?> <svg x="${x}px" y="${y}px" width="${width}px" height="${height}px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
	 viewBox="0 0 18 16" style="enable-background:new 0 0 18 16;" xml:space="preserve">
<path d="M17,14h-7v-1c0-0.6-0.4-1-1-1s-1,0.4-1,1v1H1c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h16c0.6,0,1-0.4,1-1C18,14.4,17.6,14,17,14z"
	/>
<g>
	<path d="M17,6H1C0.4,6,0,6.4,0,7v2c0,0.6,0.4,1,1,1h16c0.6,0,1-0.4,1-1V7C18,6.4,17.6,6,17,6z M3,9C2.4,9,2,8.6,2,8
		c0-0.6,0.4-1,1-1s1,0.4,1,1C4,8.6,3.6,9,3,9z"/>
	<path d="M17,0H1C0.4,0,0,0.4,0,1v2c0,0.6,0.4,1,1,1h16c0.6,0,1-0.4,1-1V1C18,0.4,17.6,0,17,0z M3,3C2.4,3,2,2.6,2,2
		c0-0.6,0.4-1,1-1s1,0.4,1,1C4,2.6,3.6,3,3,3z"/>
</g>
</svg>`;
