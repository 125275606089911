import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isActivitiesOverlayVisible: false
};

const settingsSlice = createSlice({
  name: "metricsSettings",
  initialState,
  reducers: {
    toggleActivitiesOverlay: (state, { payload }) => {
      // the 'enabled' property is now accepted in the payload.
      // If 'enabled' is explicitly provided, it should be a boolean;
      // otherwise, the toggle is based on the current visibility state.
      const isToggle = typeof payload?.enabled === "boolean";
      state.isActivitiesOverlayVisible = isToggle
        ? payload?.enabled
        : !state.isActivitiesOverlayVisible;
    }
  }
});

export const { toggleActivitiesOverlay } = settingsSlice.actions;

export default settingsSlice.reducer;
