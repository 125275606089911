import { apiProxyRequest as request } from "Api/apiProxyRequest";

import { ProjectUserAccess } from "./projectUserAccess.types";

export type ListProjectUserAccessResponse = {
  items: Array<ProjectUserAccess>;
  _links: {
    "ref:users:0": { href: string };
    self: { href: string };
    previous?: { href: string };
    next?: { href: string };
  };
};

export const listProjectUserAccess = (projectId: string) =>
  request<ListProjectUserAccessResponse>(`/projects/${projectId}/user-access`);
