import { createSelector } from "@reduxjs/toolkit";

import { includesAny } from "Libs/utils";
import { RootState } from "Store/configureStore";

export const selectLoggedInMember = (state: RootState) => state.loggedInMember;

export const isLoadingLoggedInMemberSelector = createSelector(
  selectLoggedInMember,
  state => state.isLoading
);

export const loggedInMemberDataSelector = createSelector(
  selectLoggedInMember,
  state => state.data
);

export const hasBillingPermissionSelector = createSelector(
  selectLoggedInMember,
  state =>
    (state.data?.member?.permissions &&
      includesAny(state.data?.member?.permissions, ["billing", "admin"])) ||
    state.data?.member?.owner
);

export const loggedImMemberErrorSelector = createSelector(
  selectLoggedInMember,
  state => state.error
);

export const hasProjectCreatePermissionSelector = createSelector(
  selectLoggedInMember,
  state =>
    (state.data?.member?.permissions &&
      includesAny(state.data?.member?.permissions, [
        "projects:create",
        "admin"
      ])) ||
    state.data?.member?.owner
);
