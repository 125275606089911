import * as Dropdown from "@radix-ui/react-dropdown-menu";
import styled from "styled-components";

import { item } from "ds/Dropdown/Dropdown.styles";
import { Input as RadioPrimitive } from "ds/Radio";

export const Radio = styled(RadioPrimitive)`
  margin-bottom: 0;
  flex: 1;
`;

export const Item = styled(Dropdown.RadioItem)`
  ${item};

  ${({ disabled }) => disabled && `pointer-events: none;`};
`;
