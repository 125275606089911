import styled, { css as styledCSS } from "styled-components";

import { __button_outline } from "./Button.styles";

export enum Variant {
  primary = "primary",
  secondary = "secondary",
  tertiary = "tertiary"
}

export interface Props {
  variant?: keyof typeof Variant;
  disabled?: boolean;
}

const primary = styledCSS<Props>`
  color: var(--mode-vector-neutral-default);

  :focus,
  :hover{
    color: var(--mode-vector-neutral-strong);
  }

  :focus {
    ${__button_outline};
  }

  :active {
    color: var(--mode-vector-neutral-strong);
  }

  ${({ disabled }) =>
    disabled &&
    styledCSS`
    color: var(--mode-vector-neutral-weakest);
  `}
`;

const secondary = styledCSS<Props>`
  color: var(--mode-vector-primary-default);

  :hover{
    color: var(--mode-vector-primary-strong);
  }

  :focus {
    ${__button_outline};
  }

  :active {
    color: var(--mode-vector-primary-strong);
  }

  ${({ disabled }) =>
    disabled &&
    styledCSS`
      color: var(--mode-bg-primary-weak);
    `}
`;
const tertiary = styledCSS<Props>`
  color: var(--mode-vector-neutral-stronger);

  :focus,
  :hover{
    color: var(--mode-vector-neutral-strongest);
  }

  :focus {
    ${__button_outline};
  }

  :active {
    color: var(--mode-vector-neutral-strongest);
  }

  ${({ disabled }) =>
    disabled &&
    styledCSS`
    color: var(--mode-vector-neutral-weakest);
  `}
`;

export const Root = styled.button<Props>`
  padding: 0;
  background-color: transparent;
  border: 1px solid transparent;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  mix-blend-mode: normal;
  border-radius: 2px;
  cursor: pointer;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;

  transition-duration: 0.25s;
  transition-property: color, background-color, border-color, box-shadow;

  svg,
  [fill] {
    outline: none;
    pointer-events: none;
    fill: currentcolor;
  }

  ${({ variant }) => {
    switch (variant) {
      case Variant.secondary:
        return secondary;
      case Variant.tertiary:
        return tertiary;
      case Variant.primary:
      default:
        return primary;
    }
  }}
`;

export default Root;
