import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "Store/configureStore";

import { loggedInMemberDataSelector } from "../loggedInMember/loggedInMember.selectors";

export const membersListSelector = (state: RootState) => state.membersList;

export const selectMembers = createSelector(
  membersListSelector,
  membersList => membersList.members
);

export const selectNextPageURL = createSelector(
  membersListSelector,
  membersList => membersList.next
);

// Considers that there are no more members until the current request finishes
// loading
export const selectHasMore = createSelector(
  membersListSelector,
  membersList =>
    !!membersList.next && !membersList.isLoading && !membersList.isLoadingMore
);

export const selectSelectedMemberIds = createSelector(
  membersListSelector,
  membersList => membersList.selected
);

export const selectSelectedMembers = createSelector(
  selectMembers,
  selectSelectedMemberIds,
  (members, memberIds) =>
    memberIds.map(id => members.find(({ member }) => member.id === id))
);

export const selectSortDirection = createSelector(
  membersListSelector,
  membersList => membersList.sort
);

export const memberListCountSelector = createSelector(
  membersListSelector,
  membersList => membersList.count
);

export const selectIsLoadingMembers = createSelector(
  membersListSelector,
  membersList => !!membersList.isLoading
);

export const selectIsLoadingMoreMembers = createSelector(
  membersListSelector,
  membersList => !!membersList.isLoadingMore
);

export const selectErrorLoadingMembers = createSelector(
  membersListSelector,
  members => members.error
);

export const selectPermissionsFilter = createSelector(
  membersListSelector,
  membersList => membersList.permissions
);

export const selectSearchFilter = createSelector(
  membersListSelector,
  membersList => membersList.searchFilter
);

export const selectFilteredMembers = createSelector(
  [selectMembers, selectPermissionsFilter, selectSearchFilter],
  (members, permissionsFilter, searchFilter) => {
    if (!members) return members;

    const shouldFilterByPermissions = Object.values(permissionsFilter).some(
      value => !value
    );
    const shouldFilterBySearch = !!searchFilter.length;

    let filteredMembers = members;

    if (filteredMembers && shouldFilterByPermissions) {
      const enabledPermissions = Object.entries(permissionsFilter)
        .filter(([, value]) => !!value)
        .map(([key]) => key);

      filteredMembers = filteredMembers.filter(
        ({ member }) =>
          member.permissions.some(permission =>
            enabledPermissions.includes(permission)
          ) ||
          (enabledPermissions.length && member.owner) ||
          (!member.permissions.length && !member.owner)
      );
    }

    if (filteredMembers && shouldFilterBySearch) {
      filteredMembers = filteredMembers.filter(
        ({ user }) =>
          user?.email.includes(searchFilter) ||
          user?.username.includes(searchFilter) ||
          `${user?.first_name} ${user?.last_name}`.includes(searchFilter.trim())
      );
    }

    return filteredMembers;
  }
);

export const selectSelectableFilteredMembers = createSelector(
  [selectFilteredMembers, loggedInMemberDataSelector],
  (filteredMembers, loggedInMember) =>
    filteredMembers.filter(
      member =>
        !member.member.owner && loggedInMember?.member.id !== member.member.id
    )
);

export const selectAllSelected = createSelector(
  [selectSelectedMembers, selectSelectableFilteredMembers],
  (selectedMembers, filteredMembers) => {
    if (!selectedMembers || !filteredMembers.length) return false;

    const selectedMemberIds = selectedMembers.map(member => member?.member.id);
    const filteredMemberIds = filteredMembers.map(member => member.member.id);

    return filteredMemberIds.every(memberId =>
      selectedMemberIds.includes(memberId)
    );
  }
);
