import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "Store/configureStore";

import { nextDeployment } from "./deployment";

import { environmentsAsArraySelector } from "./";

export const environmentState = (state: RootState) => state.environment;

type EnvironmentDecodedParams = {
  organizationId: string;
  projectId: string;
};

export const selectEnvironments = createSelector(
  environmentState,
  (_: RootState, params: EnvironmentDecodedParams) => params,
  (environments, { organizationId, projectId }) =>
    environments?.data?.[organizationId]?.[projectId]
);

export const selectUsedEnvironments = createSelector(
  selectEnvironments,
  environments =>
    Object.values(environments ?? {}).filter(environment =>
      ["active", "dirty", "paused"].includes(environment.status)
    )
);

export const selectDeletedEnvironment = createSelector(
  environmentState,
  (
    _: RootState,
    props: {
      projectId: string;
      environmentId: string;
    }
  ) => props,
  (environment, { projectId, environmentId }) => {
    return environment.deleted?.environment?.[projectId]?.[environmentId];
  }
);

export const environmentStatusByDeploymentSelector = createSelector(
  nextDeployment,
  nextDeployment => nextDeployment?.environment_info?.status === "inactive"
);

export const nonInactiveEnvironmentsSelector = createSelector(
  environmentsAsArraySelector,
  environments =>
    environments.filter(
      ({ status, is_main }) => status !== "inactive" && !is_main
    )
);

export const selectHasDedicatedDeployment = createSelector(
  environmentsAsArraySelector,
  environments =>
    environments?.some(
      ({ deployment_target }) => deployment_target === "dedicated"
    )
);

export const selectHasEnterpriseDeployment = createSelector(
  environmentsAsArraySelector,
  environments =>
    environments?.some(
      ({ deployment_target }) => deployment_target === "enterprise"
    )
);
