import React from "react";

import { useIntl } from "react-intl";

import Button, { ButtonWrapper } from "ds/Button";
import Modal from "ds/Modal";
import { Title, BodyText } from "ds/Typography";
import { organizationByDescriptionIdSelector } from "Reducers/organization";
import { useAppSelector } from "Store/hooks";

const CreateProjectModal = ({
  setIsCreateProjectModalOpen,
  isCreateProjectModalOpen,
  organizationId
}: {
  setIsCreateProjectModalOpen: (isCreateProjectModalOpen: boolean) => void;
  isCreateProjectModalOpen: boolean;
  organizationId: string;
}) => {
  const organization = useAppSelector(state =>
    organizationByDescriptionIdSelector(state, {
      organizationDescriptionId: organizationId
    })
  );

  const intl = useIntl();
  return (
    <Modal
      isOpen={isCreateProjectModalOpen}
      onOpenChange={() =>
        setIsCreateProjectModalOpen(!isCreateProjectModalOpen)
      }
      id="create_project"
    >
      <Title tag="h3">
        {intl.formatMessage({ id: "create_project_modal.title" })}
      </Title>
      <BodyText>
        {intl.formatMessage({ id: "create_project_modal.description" })}
      </BodyText>
      {organization && (
        <ButtonWrapper justifyContent="end" spacing="modal">
          <Button
            analyticId="cancel"
            to={`/${organization.name}`}
            variant="secondary"
            onClick={() => setIsCreateProjectModalOpen(false)}
          >
            {intl.formatMessage({ id: "cancel" })}
          </Button>
          <Button
            to={`/${organization.name}/create-project`}
            analyticId="create_project_modal.button"
          >
            {intl.formatMessage({ id: "create_project_modal.button" })}
          </Button>
        </ButtonWrapper>
      )}
    </Modal>
  );
};
export default CreateProjectModal;
