import React from "react";

import Icon from "ds/Icon";
import { WrappingIconProps } from "ds/Icon/Icon";

const Dot = (props: WrappingIconProps) => {
  return (
    <Icon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
      />
    </Icon>
  );
};

export default Dot;
