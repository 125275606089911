import { configureStore } from "@reduxjs/toolkit";

import { getIntl } from "Libs/intl";
import rootReducer from "Reducers";

import analyticsMiddleware from "./analytics";

const middlewares = [analyticsMiddleware];

export const configureAppStore = (preloadedState = {}) => {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
        thunk: { extraArgument: { getIntl } }
      }).concat(middlewares)
  });

  return store;
};

type store = ReturnType<typeof configureAppStore>;

export type GetState = store["getState"];
export type RootState = ReturnType<GetState>;
export type AppDispatch = store["dispatch"];

export default configureAppStore;
