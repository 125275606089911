import { createSelector } from "@reduxjs/toolkit";

import {
  getMemberProjectAccess,
  GetMemberProjectAccessParams
} from "Api/organizations/member/projectAccess/getMemberProjectAccess.request";
import { paginatedEndpointSliceFactory } from "Reducers/sliceFactory";
import { RootState } from "Store/configureStore";

type SliceMemberAccessArgs = {
  userId: string;
  options?: GetMemberProjectAccessParams;
};

const { slice, hooks, selectors } = paginatedEndpointSliceFactory(
  "memberProjects",
  ({ userId, options }: SliceMemberAccessArgs) =>
    getMemberProjectAccess(userId, options),
  meta => `${meta.userId}:${meta.options?.filter?.organization_id}`,
  async response => response
);

export const {
  useDataByKey: useGetMemberProjectAccess,
  useInvalidateKey: useInvalidateMemberProjectAccess
} = hooks;

export const selectMemberProjectAccess = createSelector(
  selectors.selectByKey(state => state?.data),
  (
    _: RootState,
    { projectId }: { projectId: string } & SliceMemberAccessArgs
  ) => projectId,
  (projects, projectId) => projects?.items.find(p => p.project_id === projectId)
);

export const memberProjectsReducer = slice.reducer;
