import React, { useEffect, useState } from "react";
/**
 * dynamically update the ref element dimensions as it it resizes
 */
const useResizeObserver = (ref: React.MutableRefObject<HTMLElement | null>) => {
  const [size, setSize] = useState({ width: 0 });

  useEffect(() => {
    if (!ref.current) return;

    const handleResize = (entries: ResizeObserverEntry[]) => {
      const newWidth = entries[0].contentRect.width;
      setSize({ width: newWidth });
    };

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  return size;
};

export default useResizeObserver;
