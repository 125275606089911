import React from "react";

import { createBrowserRouter } from "react-router-dom";

const routes = createBrowserRouter([
  {
    path: "/",
    Component: () => <div>Console</div>
  }
]);

export default routes;
