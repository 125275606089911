import client, { request } from "Libs/platform";

export type RequestMethods = "GET" | "POST" | "PATCH" | "DELETE";

/**
 * This method will always request to the currently configured api url.
 * If you have to resolve an auth-blocked resource outside the API proxy, you
 * can use directly the request method isntead.
 *
 * @param url - Absolute path to fetch. It must start with '/'
 */
export const apiProxyRequest = <T = void>(
  url: string,
  method: RequestMethods = "GET",
  data?: object | FormData,
  ...rest
) =>
  request(
    `${client.getConfig().api_url}${url}`,
    method,
    data,
    ...rest
  ) as Promise<T>;
