import { useEffect, useMemo } from "react";

import { OrganizationProject } from "Api/organizations/projects/OrganizationProjects.types";
import { meSelector } from "Reducers/app/selectors";
import { useGetFilteredOrganizationProjects } from "Reducers/editingMember/organizationProjects/hooks/useGetFilteredOrganizationProjects";
import { selectFeatureOrganizationsEnabled } from "Reducers/featureFlags/featureFlags.selectors";
import {
  CommonProject,
  meProjectToCommonProject
} from "Reducers/project/project/hooks/useExtendedProjectAccess";
import { useAppSelector } from "Store/hooks";

function organizationsProjectToCommonProject(
  project: OrganizationProject,
  organizationName: string
): CommonProject {
  return {
    id: project.id,
    organizationId: project.organization_id,
    organizationName,
    region: project.region,
    subscriptionId: project.subscription_id,
    title: project.title
  };
}

// The dropdown menu is limited, not easy to get the scroll down to work and
// therefore no load more on scroll down. Users can still search by title which
// is a good alternative to load more.
export const useMeOrOrganizationProjects = (
  organizationId: string,
  organizationName: string,
  title: string
) => {
  const [projects, load] = useGetFilteredOrganizationProjects(organizationId);
  const me = useAppSelector(meSelector);

  const isOrganizationsEnabled = useAppSelector(
    selectFeatureOrganizationsEnabled
  );

  useEffect(() => {
    if (isOrganizationsEnabled)
      load({ filter: { title: title ? { contains: title } : undefined } });
  }, [isOrganizationsEnabled, load, title]);

  const commonProjects = useMemo(() => {
    if (isOrganizationsEnabled)
      return projects.map(project =>
        organizationsProjectToCommonProject(project, organizationName)
      );

    return (
      me?.projects.map(project =>
        meProjectToCommonProject(project, me.username)
      ) || []
    );
  }, [projects, me, isOrganizationsEnabled, organizationName]);

  return commonProjects;
};
