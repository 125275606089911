import { createAsyncThunk } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import client from "Libs/platform";
import { hasHtml } from "Libs/utils";
import { selectUserProjectById } from "Reducers/app/selectors";
import { organizationsSelector } from "Reducers/organization";
import { ReloadProjectReturnPayload } from "Reducers/project/project";

import type { AsyncThunkOptionType } from "Reducers/types";

export const reloadProject = createAsyncThunk<
  ReloadProjectReturnPayload,
  { projectId: string },
  AsyncThunkOptionType
>(
  "app/project_reducer/project/reload",
  async ({ projectId }, { rejectWithValue, getState }) => {
    try {
      const projectMe = selectUserProjectById(getState(), { projectId });
      const project = await client.getProject(projectId);

      return {
        project,
        projectMe,
        organizations: organizationsSelector(getState())
      };
    } catch (err) {
      if (
        ![404, 403, 400].includes((err as { code: number })?.code) &&
        !hasHtml(err as string)
      ) {
        logger(err, {
          action: "project_reload",
          projectId
        });
      }

      return rejectWithValue(err);
    }
  }
);
