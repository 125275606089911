import React from "react";

import * as Dropdown from "@radix-ui/react-dropdown-menu";

import useUniqueId from "Libs/useUniqueId";
import { sendHeapEventDropdownItem } from "Store/analytics/heapFunctions";

import * as S from "./RadioItem.styles";

const RadioItem = React.forwardRef<
  React.ElementRef<typeof Dropdown.RadioItem>,
  React.ComponentProps<typeof Dropdown.RadioItem>
>(function RadioItem({ children, id, ...props }, forwardedRef) {
  const uniqueID = useUniqueId();

  const onSelect = () => {
    sendHeapEventDropdownItem({
      analyticId: id,
      type: "radio"
    });
  };
  return (
    <S.Item
      {...props}
      onSelect={event => {
        event.preventDefault();
        onSelect();
        if (props.onSelect) {
          props.onSelect(event);
        }
      }}
      ref={forwardedRef}
    >
      <S.Radio
        id={id || `radioItem-${uniqueID}`}
        label={children}
        value={props.value}
        disabled={props.disabled}
      />
    </S.Item>
  );
});

export default RadioItem;
