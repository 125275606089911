import type { JSX } from "react";
import { useEffect, useState } from "react";

import logger from "Libs/logger";

// Takes a media query string i.e. "(min-width: 768px)"
function useMediaQuery(queryString: string): boolean {
  const mediaQuery = window?.matchMedia(queryString);
  const [match, setMatch] = useState(() => mediaQuery?.matches);

  const handler = e => {
    return setMatch(e.matches);
  };

  useEffect(() => {
    try {
      if (mediaQuery?.addEventListener) {
        mediaQuery?.addEventListener("change", handler);
        return () => mediaQuery?.removeEventListener("change", handler);
      }

      mediaQuery?.addListener(handler);
      return () => mediaQuery?.removeListener(handler);
    } catch (err) {
      logger(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return match;
}

// Adapted as renderprops to use with class components
// https://reacttraining.com/blog/using-hooks-in-classes/
// <MediaQuery queryString="(min-width: 768px)">
//  {
//    match => <p>{match ? "Add public key" : "Add"}</p>;
//  }
//</MediaQuery>

type MediaQueryProps = {
  queryString: string;
  children: (match: boolean) => JSX.Element;
};
export function MediaQuery({ queryString, children }: MediaQueryProps) {
  const match = useMediaQuery(queryString);
  return children(match);
}

export default useMediaQuery;
