import React from "react";

import PropTypes from "prop-types";

const offScreenStyle = {
  border: 0,
  clip: "rect(0 0 0 0)",
  height: "1px",
  margin: "-1px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  padding: 0,
  width: "1px",
  position: "absolute"
};

const MessageBlock = ({ message, "aria-live": ariaLive }) => (
  <div style={offScreenStyle} role="log" aria-live={ariaLive}>
    {message ? message : ""}
  </div>
);

MessageBlock.propTypes = {
  message: PropTypes.string.isRequired,
  "aria-live": PropTypes.string.isRequired
};

export default MessageBlock;
