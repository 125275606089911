import React from "react";

import { Link } from "react-router-dom";
interface Props {
  url?: any;
  label?: string | React.ReactNode;
  children?: React.ReactNode;
  target?: string;
  rel?: string;
  onClick?: (e?: any) => void;
}
const isRelative = url => url && url[0] === "/";

const MenuLink = ({ url, label, children, ...props }: Props) => {
  const labelEl = children || (
    <>
      <span className="active" />
      {label}
    </>
  );

  if (url && isRelative(url)) {
    return (
      <Link className="menu-item" to={url} {...props}>
        {labelEl}
      </Link>
    );
  }

  return (
    <a className="menu-item" href={url} {...props}>
      {labelEl}
    </a>
  );
};

export default MenuLink;
