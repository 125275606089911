import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
  combineReducers
} from "@reduxjs/toolkit";

import { deleteOrganizationInvitation } from "Api/organizations/invitation/deleteOrganizationInvitation";
import { Invitation } from "Api/organizations/invitation/invitation.types";
import { OrganizationMemberPermissions } from "Api/organizations/member/Member.types";
import bulkOperation from "Libs/bulkOperation";
import client from "Libs/platform";
import { RootState } from "Store/configureStore";

import filterReducer, {
  selectAreAllPermissionsSelected,
  selectSearch,
  selectSelectedPermissions
} from "./invitations.filters";
import { loadOrganizationInvitations } from "./thunks/loadOrganizationInvitations.thunk";

export const NAME = "organizationInvitations";

export interface InviteParams {
  organizationId: string;
  emails: Array<string>;
  permissions: Array<string>;
  force: boolean;
}

export const invite = createAsyncThunk(
  `${NAME}/invite`,
  ({ organizationId, emails, permissions, force }: InviteParams) =>
    bulkOperation(
      emails.map(email =>
        client.createOrganizationInvitation(
          email,
          organizationId,
          permissions,
          force
        )
      ),
      (_, index) => emails[index],
      (reason, index) => ({ email: emails[index], reason })
    )
);

// Sketchy type predicate
export function isInviteRejectedReturn(
  action: any
): action is ReturnType<typeof invite.rejected> {
  return "error" in action;
}

export interface ResendParams {
  organizationId: string;
  invitations: Array<Invitation>;
}

export const resend = createAsyncThunk(
  `${NAME}/resend`,
  ({ organizationId, invitations }: ResendParams) =>
    bulkOperation(
      invitations.map(invitation =>
        client.createOrganizationInvitation(
          invitation.email,
          organizationId,
          invitation.permissions,
          true
        )
      ),
      (_, index) => invitations[index],
      (reason, index) => ({ invitation: invitations[index], reason })
    )
);

// Sketchy type predicate
export function isResendRejectedReturn(
  action: any
): action is ReturnType<typeof resend.rejected> {
  return "error" in action;
}

export interface RejectedRevokedInvitation {
  invitation: Invitation;
  reason: string;
}

export interface RevokeInvitationParams {
  organizationId: string;
  invitations: Array<Invitation>;
}

export const revokeInvitation = createAsyncThunk(
  `${NAME}/revokeInvitation`,
  ({ organizationId, invitations }: RevokeInvitationParams) =>
    bulkOperation(
      invitations.map(invitation =>
        deleteOrganizationInvitation(organizationId, invitation.id)
      ),
      (_, index) => invitations[index],
      (_, index) => invitations[index]
    )
);

// Sketchy type predicate
export function isRevokeRejectedReturn(
  action: any
): action is ReturnType<typeof revokeInvitation.rejected> {
  return "error" in action;
}

const initialState = {
  isLoadingInvite: false,
  isLoadingInvitationList: false,
  invitationList: [] as Array<Invitation>,
  isRevoking: false,
  selected: [] as Array<string>
};

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setSelected: (state, action: PayloadAction<string | Array<string>>) => {
      const selected = state.selected;
      // Override the current selected list with the new passed as argument
      if (action.payload instanceof Array) {
        state.selected = action.payload;
      }

      // Toggle all
      if (action.payload === "all") {
        const invitations = state.invitationList;

        // If all are selected already unselect all
        if (invitations.length === selected.length) {
          state.selected = [];
        }

        state.selected = invitations.map(invitation => invitation.id);
      }

      // single toggle
      if (typeof action.payload === "string") {
        state.selected = selected.includes(action.payload)
          ? selected.filter(value => value !== action.payload)
          : [...selected, action.payload];
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(invite.pending, state => {
        state.isLoadingInvite = true;
      })
      .addCase(invite.rejected, state => {
        state.isLoadingInvite = false;
      });

    builder
      .addCase(loadOrganizationInvitations.pending, state => {
        state.isLoadingInvitationList = true;
      })
      .addCase(loadOrganizationInvitations.fulfilled, (state, action) => {
        state.invitationList = action.payload;
        state.isLoadingInvitationList = false;
      })
      .addCase(loadOrganizationInvitations.rejected, state => {
        state.isLoadingInvitationList = false;
      });

    builder
      .addCase(revokeInvitation.pending, state => {
        state.isRevoking = true;
      })
      .addCase(revokeInvitation.fulfilled, state => {
        state.isRevoking = false;
      })
      .addCase(revokeInvitation.rejected, state => {
        state.isRevoking = false;
      });
  }
});

export const { setSelected } = slice.actions;

export default combineReducers({
  invitations: slice.reducer,
  filters: filterReducer
});

export const selectInvitation = (state: RootState) => state[NAME].invitations;

export const selectIsInviting = createSelector(
  [selectInvitation],
  invitation => !!invitation.isLoadingInvite
);

export const selectIsLoadingInvitationList = createSelector(
  [selectInvitation],
  invitation => invitation.isLoadingInvitationList
);

/** Returns all OrganizationInvitations as stored */
export const selectInvitations = createSelector(
  [selectInvitation],
  invitation => invitation.invitationList
);

/** Returns a map where the keys are invitations ids - useful for rendering
 * without needing to find on arrays */
export const selectSelectedInvitationsMap = createSelector(
  [selectInvitation],
  invitation => Object.fromEntries(invitation.selected.map(id => [id, true]))
);

/** True if all the invitations on the store are selected */
export const selectAreAllInvitationsSelected = createSelector(
  [selectSelectedInvitationsMap, selectInvitations],
  (selected, invitations) =>
    Object.keys(selected).length === invitations.length && !!invitations.length
);

/** Returns a list of the currently selected OrganizationInvitations */
export const selectSelectedInvitations = createSelector(
  [selectSelectedInvitationsMap, selectInvitations],
  (selected, invitations) =>
    invitations.filter(
      invitation => !!selected[invitation.id] && invitation.state !== "error"
    )
);

/** Returns a list of the invitations that match the current filters on the
 * store */
export const selectFilteredInvitations = createSelector(
  [
    selectInvitations,
    selectSelectedPermissions,
    selectAreAllPermissionsSelected,
    selectSearch
  ],
  (invitations, selectedPermissions, allPermissionsSelected, search) => {
    // Don't filter if all the permissions have been selected
    if (!allPermissionsSelected) {
      invitations = invitations.filter(invitation => {
        if (
          selectedPermissions.length === 0 &&
          invitation.permissions.length === 0
        ) {
          return true;
        }

        return invitation.permissions.some(permission =>
          selectedPermissions.includes(
            permission as OrganizationMemberPermissions
          )
        );
      });
    }

    if (search !== "") {
      invitations = invitations.filter(invitation =>
        invitation.email.includes(search.trim())
      );
    }

    return invitations;
  }
);
