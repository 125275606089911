import React from "react";

import { useIntl } from "react-intl";

import Button, { ButtonWrapper, AddButton } from "ds/Button";
import Modal from "ds/Modal";
import { BodyText, Title } from "ds/Typography";
import { meSelector } from "Reducers/app/selectors";
import { useAppSelector } from "Store/hooks";

import * as S from "../styles";
import { CreateProjectButtonProps } from "../types";

const RestrictedOrganizationModal = ({
  isModalOpen,
  setIsModalOpen,
  isOrgAdmin,
  isLink,
  linkStyle,
  linkVariant = "link",
  openModal,
  label
}: CreateProjectButtonProps) => {
  const intl = useIntl();
  const me = useAppSelector(meSelector);

  return (
    <>
      <Modal
        isOpen={isModalOpen!}
        onOpenChange={() => setIsModalOpen?.(!isModalOpen)}
        id="restricted-org"
      >
        <Title tag="h3">
          {intl.formatMessage({
            id: "create_project_button.restricted_org.title"
          })}
        </Title>
        {isOrgAdmin ? (
          <>
            <BodyText>
              {intl.formatMessage({
                id: "create_project_button.restricted_org.admin.description"
              })}
            </BodyText>
            <ButtonWrapper justifyContent="end" spacing="modal">
              <Button
                to={`/-/users/${me?.username}/tickets`}
                analyticId="kyc_verification.ticket.contact"
              >
                {intl.formatMessage({
                  id: "kyc_verification.ticket.contact"
                })}
              </Button>
            </ButtonWrapper>
          </>
        ) : (
          <S.Content>
            <BodyText>
              {intl.formatMessage({
                id: "create_project_button.restricted_org.member.description"
              })}
            </BodyText>
            <BodyText>
              {intl.formatMessage({
                id: "create_project_button.restricted_org.member.disclaimer"
              })}
            </BodyText>
          </S.Content>
        )}
      </Modal>
      {isLink ? (
        <Button
          analyticId="create_project"
          id="create_project"
          variant={linkVariant}
          style={linkStyle}
          onClick={openModal}
        >
          {label}
        </Button>
      ) : (
        <AddButton
          id="create_project"
          analyticId="create_project"
          onClick={openModal}
        >
          {label}
        </AddButton>
      )}
    </>
  );
};

export default RestrictedOrganizationModal;
