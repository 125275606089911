import React, { ComponentProps, ReactNode } from "react";

import { PopoverContentProps } from "@radix-ui/react-popover";

import { IconControl } from "ds/Button";
import InfoSm from "ds/Icons/InfoSm";
import { sendHeapEventDialog } from "Store/analytics/heapFunctions";

import Content from "./Content";
import Description from "./Description.styles";
import Root from "./Root";
import Title from "./Title.styles";
import Trigger from "./Trigger";

const defaultTrigger = (
  <IconControl>
    <InfoSm />
  </IconControl>
);

export interface DialogProps extends ComponentProps<typeof Root> {
  trigger?: ReactNode;
  title?: ReactNode;
  description?: ReactNode;
  link?: ReactNode;
  className?: string;
  align?: PopoverContentProps["align"];
  side?: PopoverContentProps["side"];
  analyticId?: string;
}

const Dialog = ({
  title,
  description,
  link,
  trigger = defaultTrigger,
  align = "start",
  side = "bottom",
  analyticId,
  ...props
}: DialogProps) => {
  const sendHeapEvent = ({ isOpen }) => {
    isOpen && analyticId && sendHeapEventDialog({ isOpen, analyticId });
  };
  return (
    <Root {...props}>
      <Trigger sendHeapEvent={sendHeapEvent} analyticId={analyticId}>
        {trigger}
      </Trigger>
      <Content align={align} side={side}>
        {title && <Title>{title}</Title>}
        {description && <Description>{description}</Description>}
        {link && link}
      </Content>
    </Root>
  );
};

export default Dialog;
