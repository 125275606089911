import { useMemo } from "react";

import { useParams } from "react-router-dom";

/**
 * Extracts the console properties from current component route
 */
const useDecodedParams = <
  Params extends { [K in keyof Params]?: string } = Record<string, string>
>() => {
  const params = useParams<Params>();

  const decodedParams = useMemo(() => {
    const entries = Object.entries<string | undefined>(params);
    const decodedParams = entries.reduce((params, [key, value]) => {
      return Object.assign(params, {
        [key]: value ? decodeURIComponent(value) : value
      });
    }, {} as Params);
    return decodedParams;
  }, [params]);

  return decodedParams;
};

export default useDecodedParams;
