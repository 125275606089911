import React from "react";

import { Navigate } from "react-router-dom";

import { selectCurrentUserUsername } from "Reducers/app/selectors";
import { useAppSelector } from "Store/hooks";

const BillingRedirect = () => {
  const username = useAppSelector(selectCurrentUserUsername);
  return <Navigate to={`/-/users/${username}/billing`} />;
};

export default BillingRedirect;
