import { useEffect } from "react";

import {
  selectFeatureBillingEnabled,
  selectFeatureOrganizationsEnabled
} from "Reducers/featureFlags/featureFlags.selectors";
import { organizationByDescriptionIdSelector } from "Reducers/organization";
import { useAppDispatch, useAppSelector } from "Store/hooks";

import {
  organizationPaymentSourceLoadingSelector,
  organizationPaymentSourceSelector
} from "../paymentSource.selectors";
import { getOrganizationPaymentSource } from "../thunks/getOrganizationPaymentSource.thunk";

export const useLoadPaymentSource = (
  organizationId: string,
  loadingPaymentSource: "idle" | boolean = "idle"
) => {
  const dispatch = useAppDispatch();
  const organizationEnabled = useAppSelector(selectFeatureOrganizationsEnabled);
  const billingEnabled = useAppSelector(selectFeatureBillingEnabled);

  const paymentSource = useAppSelector(state =>
    organizationPaymentSourceSelector(state, { organizationId })
  );
  const loading = useAppSelector(organizationPaymentSourceLoadingSelector);

  const organization = useAppSelector(state =>
    organizationByDescriptionIdSelector(state, {
      organizationDescriptionId: organizationId
    })
  );

  useEffect(() => {
    if (
      billingEnabled &&
      organizationEnabled &&
      loadingPaymentSource === "idle" &&
      organization?.name
    ) {
      dispatch(getOrganizationPaymentSource({ organizationId }));
    }
  }, [
    dispatch,
    loadingPaymentSource,
    organizationEnabled,
    organizationId,
    billingEnabled,
    organization?.name
  ]);

  return {
    paymentSource,
    loading
  };
};
