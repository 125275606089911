import React, { useMemo, useRef } from "react";

import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import * as Dropdown from "ds/Dropdown";
import * as Tabs from "ds/Tabs";
import Tooltip from "ds/Tooltip/Tooltip";
import useResizeObserver from "Hooks/useResizeObserver";

import * as S from "./styles";
import { NavigatationTabType } from "./types";

type SubNavBarPropType = {
  tabs: Array<NavigatationTabType>;
  currentPathName: string;
  links?: Record<string, string>; //API HAL links
  islightbackground?: boolean;
  className?: string;
};

const getTabName = (tabName, intl) =>
  tabName?.startsWith("plugin.")
    ? tabName?.replace("plugin.", "")
    : intl.formatMessage({ id: `tabs.${tabName?.toLowerCase()}` });

const SubNavBar = ({
  className,
  currentPathName,
  tabs,
  links,
  islightbackground = false
}: SubNavBarPropType) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const tabContainerRef = useRef<HTMLDivElement>(null);
  const tabContainerWidth = useResizeObserver(tabContainerRef).width;

  const currentTab = useMemo(
    () =>
      tabs.find(
        ({ route, testPath }) =>
          currentPathName == route || testPath?.(currentPathName)
      ),
    [currentPathName, tabs]
  );

  const checkIsTabDisabled = (tab: NavigatationTabType) => {
    // A tab permission if present decides if that tab should be rendered based on the permission
    // of the environments links matching the tab.permission value if permisson is a string
    // and the returned value of calling tab.permission with links if it is a function
    return (
      (typeof tab.permission === "function" &&
        !tab.permission(currentPathName, links || {})) ||
      (typeof tab.permission === "string" && !links?.[tab.permission])
    );
  };

  const enabledTabs = tabs.filter(tab => !checkIsTabDisabled(tab));

  const tabTextWidth = useMemo(() => {
    // calculate the number of text that will fit into
    // the tab elements container width before it overflows

    //tab characters without the padding after each tab
    const textLength = enabledTabs.reduce(
      (len, t) => getTabName(t.name, intl).length + len,
      0
    );
    //padding between tabs is 24px
    const paddingWidth = enabledTabs.length * 24 - 48;

    //each tab character width is approximately 10px
    const charWidth = 10;
    return textLength * charWidth + paddingWidth;
  }, [enabledTabs, intl]);

  const tabItemExceedWidth =
    tabContainerWidth && tabTextWidth > tabContainerWidth;

  return enabledTabs.length > 0 ? (
    <S.Wrapper className={className} ref={tabContainerRef}>
      {tabItemExceedWidth ? (
        <S.MobileSubNavBar
          $isLightBackground={islightbackground}
          className="mobile-subnvarbar"
        >
          <Dropdown.Root id="mobile-subnavbar">
            <Dropdown.Trigger
              variant="picklist"
              className="mobile-menu-trigger-button"
            >
              {getTabName(currentTab?.name, intl)}
              <Dropdown.Chevron />
            </Dropdown.Trigger>
            <Dropdown.Portal>
              <Dropdown.Content align="start">
                {enabledTabs.map(tab =>
                  checkIsTabDisabled(tab) ? null : (
                    <S.TabItem
                      to={tab.route}
                      key={tab.name}
                      onClick={tab.onClick}
                    >
                      {getTabName(tab.name, intl)}
                    </S.TabItem>
                  )
                )}
              </Dropdown.Content>
            </Dropdown.Portal>
          </Dropdown.Root>
        </S.MobileSubNavBar>
      ) : (
        <S.SubNavBarLayout>
          <S.SubNavBarTabWrapper>
            <Tabs.Root defaultValue={currentTab?.name}>
              <Tabs.List>
                {enabledTabs.map(tab => {
                  const customTab = (
                    <S.CustomTab
                      data-state={
                        tab.name === currentTab?.name ? "active" : "inactive"
                      }
                      data-heap-identifier={tab.name}
                      key={tab.name}
                      data-testid={tab.name}
                      onClick={
                        tab.onClick ? tab.onClick : () => navigate(tab.route)
                      }
                      value={tab.name}
                      $isLightBackground={islightbackground}
                    >
                      {getTabName(tab.name, intl)}
                    </S.CustomTab>
                  );

                  return tab.tooltip ? (
                    <Tooltip
                      key={tab.route}
                      tooltip={tab.tooltip}
                      align="start"
                      analyticId={`subnavbar ${tab.route}`}
                    >
                      {customTab}
                    </Tooltip>
                  ) : (
                    customTab
                  );
                })}
              </Tabs.List>
            </Tabs.Root>
          </S.SubNavBarTabWrapper>
        </S.SubNavBarLayout>
      )}
    </S.Wrapper>
  ) : null;
};

export default SubNavBar;
