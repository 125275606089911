import useDecodedParams from "Hooks/useDecodedParams";
import {
  selectFeatureOrganizationsEnabled,
  selectFeatureUsageBasedBillingEnabled
} from "Reducers/featureFlags/featureFlags.selectors";
import { organizationByDescriptionIdSelector } from "Reducers/organization";
import { useOrganizationProfile } from "Reducers/organization/hooks/useOrganizationProfile";
import { useAppSelector } from "Store/hooks";

export function useIsTeamsEnabled() {
  const { organizationId } = useDecodedParams<{ organizationId: string }>();

  const organization = useAppSelector(state =>
    organizationByDescriptionIdSelector(state, {
      organizationDescriptionId: organizationId
    })
  );
  const isUsageBasedBillingEnabled = useAppSelector(
    selectFeatureUsageBasedBillingEnabled
  );
  const { organizationProfile } = useOrganizationProfile(organizationId);

  const isOrgsEnabled = useAppSelector(selectFeatureOrganizationsEnabled);

  if (!isOrgsEnabled) return "disabled";

  const organizationIsTeamsCapable =
    organization?.capabilities?.includes("teams");

  // If teams is disabled and the organization is not enterprise, we need to promote a sellable
  if (
    !organizationIsTeamsCapable &&
    organizationProfile &&
    organizationProfile?.account_tier !== "enterprise" &&
    organization?.vendor !== "upsun"
  )
    return "disabled-promote";

  if (!organizationIsTeamsCapable && isUsageBasedBillingEnabled)
    return "disabled-promote-links";

  return organizationIsTeamsCapable ? "enabled" : "disabled";
}
