import { trackEventHeap } from "Store/analytics/heapTracker";

export const sendHeapEventDialog = ({
  isOpen,
  analyticId
}: {
  isOpen: boolean;
  analyticId?: string;
}) => {
  isOpen &&
    analyticId &&
    trackEventHeap("info dialog opened", { value: analyticId });
};

export const sendHeapEventTooltip = analyticId => {
  trackEventHeap("tooltip opened", {
    value: analyticId
  });
};

export const sendHeapEventRadio = analyticId => {
  trackEventHeap("radio clicked", {
    value: analyticId
  });
};

export const sendHeapEventCheckbox = ({ check_id, value, analyticId }) => {
  trackEventHeap("checkbox clicked", {
    check_id,
    value,
    analyticId
  });
};

export const sendHeapEventAccordion = analyticId => {
  trackEventHeap("accordion status changed", {
    value: analyticId?.toString()
  });
};

export const sendHeapEventSelect = (analyticId, selectedValue) => {
  trackEventHeap("select value changed", {
    value: analyticId,
    selectedValue
  });
};

export const sendHeapEventDropdown = analyticId => {
  trackEventHeap("dropdown open changed", {
    value: analyticId
  });
};

export const sendHeapEventDropdownItem = ({
  analyticId,
  type,
  checked
}: {
  type: string;
  analyticId?: string;
  checked?: boolean | "indeterminate";
}) => {
  trackEventHeap(`dropdown item type ${type} selected`, {
    value: analyticId,
    checked
  });
};

export const sendHeapEventTab = ({ analyticId }: { analyticId?: string }) => {
  analyticId && trackEventHeap("tab active", { value: analyticId });
};
