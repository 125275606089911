import { createSelector } from "@reduxjs/toolkit";

import { selectCurrentUserId } from "Reducers/app/selectors";

import { ProfileState } from ".";

import type { RootState } from "Store/configureStore";

export const profileStateSelector = (state: RootState): ProfileState =>
  state.profile;

export const profileDataSelector = createSelector(
  profileStateSelector,
  profile => profile.data
);

// Selects the user profile of the current logged in user.
export const selectUserProfileByMeUsername = createSelector(
  selectCurrentUserId,
  profileDataSelector,
  (userId, profileData) => profileData?.[userId]
);

// Selects the user profile using the username instead of the ID. Useful if you
// have to get the profile information of the user on the URL.
export const selectUserProfile = createSelector(
  profileDataSelector,
  (_: RootState, username: string) => username,
  (profileData, username) =>
    Object.values(profileData || {}).find(
      userData => userData?.username === username
    )
);
export const userProfileNameByIdSelector = createSelector(
  profileDataSelector,
  (_: RootState, id: string) => id,
  (profileData, id) => {
    if (!profileData) return undefined;
    return Object.values(profileData).find(userData => userData?.id === id)
      ?.username;
  }
);
export const profileInflightSelector = createSelector(
  profileStateSelector,
  profile => profile.inflight
);

export const getIsUpdatingProfileSelector = createSelector(
  profileInflightSelector,
  inflight => inflight.update
);

export const profileColorScheme = createSelector(
  selectUserProfile,
  userProfile => userProfile?.ui_colorscheme || ""
);
