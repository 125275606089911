import React, { useLayoutEffect, useMemo } from "react";

import DatePicker, { ReactDatePickerProps } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import RangeHeader from "./RangeHeader";
import { PickerWrapper } from "./styles";
import { DateRangeType } from "./types";

type Props = {
  yearRange?: number;
  prevYear?: boolean;
  nextYear?: boolean;
  maximumRange?: DateRangeType;
  range?: Partial<DateRangeType>;
  dualView?: boolean;
  onChange: (range: DateRangeType) => void;
  navigable?: boolean;
  asCalendar?: boolean;
} & Omit<ReactDatePickerProps, "onChange">;

const DAY_NAMES = {
  su: "Sun",
  mo: "Mon",
  tu: "Tue",
  we: "Wed",
  th: "Thu",
  fr: "Fri",
  sa: "Sat"
};
const RangePicker = (props: Props) => {
  const {
    range,
    dualView,
    onChange,
    selected,
    includeDateIntervals,
    yearRange = 5,
    navigable = false,
    maximumRange,
    children,
    asCalendar,
    ...otherProps
  } = props;

  const { end: endDate, start: startDate } = range || {
    start: new Date()
  };

  useLayoutEffect(() => {
    // Replaces React Date picker day name default abbreviation with name abbreviation
    // used on figma product component design system
    document.querySelectorAll(".react-datepicker__day-name").forEach(day => {
      if (day.classList.contains("react-datepicker__day-name-fixed")) return;
      day.textContent =
        DAY_NAMES[day.textContent?.toLowerCase() || ""] ||
        day.textContent?.toLowerCase();
      day.classList.add("react-datepicker__day-name-fixed");
    });
  }, []);

  const onDateChange = dates => {
    const [start, end] = dates;
    onChange({ start, end });
  };

  const yearOptions = useMemo(
    () =>
      new Array(yearRange)
        .fill(0)
        .map((_, i) => new Date().getUTCFullYear() - yearRange + i + 1),
    [yearRange]
  );

  return (
    <PickerWrapper>
      <DatePicker
        showTwoColumnMonthYearPicker
        includeDateIntervals={includeDateIntervals}
        selected={selected || startDate}
        onChange={onDateChange}
        startDate={asCalendar ? undefined : startDate}
        endDate={asCalendar ? undefined : endDate}
        selectsRange
        inline
        renderCustomHeader={p => {
          return (
            <RangeHeader
              navigable={navigable}
              {...p}
              split={dualView}
              startDate={startDate}
              endDate={endDate}
              yearOptions={yearOptions}
              maximumRange={maximumRange}
              asCalendar={asCalendar}
            />
          );
        }}
        {...otherProps}
      >
        {children}
      </DatePicker>
    </PickerWrapper>
  );
};

export default RangePicker;
