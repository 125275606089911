import { createSelector } from "@reduxjs/toolkit";

import { selectCurrentUserUUID } from "Reducers/app/selectors";
import { selectFeatureUserManagementEnabled } from "Reducers/featureFlags/featureFlags.selectors";
import { loggedInMemberDataSelector } from "Reducers/organization/loggedInMember/loggedInMember.selectors";

import type { Organization, Project } from "platformsh-client";
import type { RootState } from "Store/configureStore";

export const projectStateSelector = (state: RootState) => state.project;

export const projectListSelector = createSelector(
  projectStateSelector,
  projectState => projectState.list
);

export const selectProjectsFromAllOrgs = createSelector(
  projectListSelector,
  list =>
    Object.values(list).reduce((acc, projects) => {
      return { ...acc, ...projects };
    }, {})
);

export const projectsAllSelector = createSelector(
  projectStateSelector,
  projectState => projectState.all
);

export const ProjectLoadingErrorSelector = createSelector(
  projectStateSelector,
  projectState => projectState.projectLoadingError
);

export const projectSelector = createSelector(
  [
    projectListSelector,
    (_: RootState, params: { organizationId: string; projectId: string }) =>
      params
  ],
  (list, { organizationId, projectId }) => list?.[organizationId]?.[projectId]
);

export const gitProjectSelector = createSelector(
  [
    projectStateSelector,
    (_: RootState, params: { organizationId?: string; projectId?: string }) =>
      params
  ],
  (projectState, { organizationId, projectId }) =>
    organizationId && projectId
      ? projectState?.data?.[organizationId]?.[projectId]
      : undefined
);

export const selectHasManagementAccess = (
  state: RootState,
  props: { organizationId: string; projectId: string; userId: string }
) => {
  const project = gitProjectSelector(state, props);
  const userManagementEnabled = selectFeatureUserManagementEnabled(state);
  const currentUserId = selectCurrentUserUUID(state);
  const userNotEditingItself = currentUserId !== props.userId;
  const member = loggedInMemberDataSelector(state);

  return (
    (userManagementEnabled &&
      userNotEditingItself &&
      project?.hasPermission?.("#manage-access")) ||
    member?.member?.owner ||
    member?.member?.permissions.includes("members")
  );
};

export const getIsLoadingProjects = createSelector(
  projectStateSelector,
  projectState => projectState.loading
);

export const getIsLoadingListProjectsSelector = createSelector(
  projectStateSelector,
  projectState => projectState.loadingList
);

export const getIsLoadedListProjectsSelector = createSelector(
  projectStateSelector,
  projectState => projectState.loadedList
);

export const selectProjectStatus = createSelector(
  [projectSelector],
  project => project?.status
);

export const selectProjectHasIntegrations = createSelector(
  [gitProjectSelector],
  project => project?.hasLink?.("#integrations")
);

export const orgByProjectIdSelector = createSelector(
  [
    projectStateSelector,
    (_: RootState, params: { projectId: string }) => params
  ],
  (projectState, { projectId }) => projectState.orgByProjectId?.[projectId]
);

export const selectProjectsOrderedByTitle = createSelector(
  projectsAllSelector,
  projects => {
    // Shallow copying is required otherwise ".sort" will attempt to mutate the original, which is state and should not be mutated directly
    const projectsCopy = [...projects];
    return (
      projectsCopy.sort((projectA, projectB) => {
        const titleA = projectA?.title?.toLocaleLowerCase();
        const titleB = projectB?.title?.toLocaleLowerCase();

        if (titleA > titleB) return 1;
        if (titleA < titleB) return -1;
        return 0;
      }) || []
    );
  }
);

export const getOrganizationDescriptionIdFromProject = (
  project: Project | undefined,
  organizations: Record<string, Organization | undefined>
) => {
  if (!project) {
    return;
  }

  if (project?.owner_info?.type === "user") {
    return project.owner_info?.username;
  }

  return organizations[project?.organization_id || project?.owner]?.name;
};

export const isLoadingExtendedProjectAccessSelector = createSelector(
  projectStateSelector,
  projectState => projectState.loadingExtendedAccess
);

export const extendedProjectAccessSelector = createSelector(
  projectStateSelector,
  projectState =>
    projectState.extendedAccess.filter(p => p.resource_type === "project")
);

export const extendedProjectAccessByProjectIdSelector = createSelector(
  extendedProjectAccessSelector,
  (_: RootState, params: { projectId: string }) => params,
  (extendedAccess, { projectId }) =>
    extendedAccess.find(p => p.resource_id === projectId)
);

export const getProjectBySubscriptionIdSelector = createSelector(
  projectListSelector,
  (_: RootState, params: { organizationId: string; subscriptionId: string }) =>
    params,
  (projectList, { organizationId, subscriptionId }) =>
    Object.values(projectList[organizationId] ?? {}).find(
      project => project?.subscription_id === subscriptionId
    )
);

export const selectFormattedInfrastructureProjects = createSelector(
  projectsAllSelector,
  projects => {
    return Object.values(projects)
      .flat()
      .map(project => project?.enterprise_tag);
  }
);

export const canManageProjectVariablesSelector = createSelector(
  gitProjectSelector,
  project => project?.hasPermission("#edit")
);
