import type { DataType } from "../index";
import type { WritableDraft } from "immer/dist/internal";
import type { Certificate } from "platformsh-client";

export const organizeCertificatesIntoProjectObject = (
  certificates: WritableDraft<Certificate[]>,
  organizationDescriptionId: string,
  projectDescriptionId: string
) => {
  return certificates.reduce<DataType>(
    (organizationsProjectsEnvironments, projectCertificate) => {
      if (!organizationsProjectsEnvironments[organizationDescriptionId]) {
        organizationsProjectsEnvironments[organizationDescriptionId] = {};
      }
      if (
        !organizationsProjectsEnvironments[organizationDescriptionId][
          projectDescriptionId
        ]
      ) {
        organizationsProjectsEnvironments[organizationDescriptionId][
          projectDescriptionId
        ] = {};
      }
      organizationsProjectsEnvironments[organizationDescriptionId][
        projectDescriptionId
      ][projectCertificate?.id] = projectCertificate;
      return organizationsProjectsEnvironments;
    },
    {}
  );
};
