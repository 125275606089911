import React, { useState } from "react";

import { useIntl } from "react-intl";

import FloatBox from "Containers/NavBar/FloatBox";
import NavigationMenu from "Containers/NavBar/NavigationMenu";
import { ActionButton } from "ds/Button";
import Backup from "ds/Icons/Backup";
import { breakpoints } from "Libs/theme";

import BackupModal from "../Environment/BackupModal";

import type { Project, Environment } from "platformsh-client";

// this controls when to show the text in the action button
// for this to work the text must be wrapped in a <span> tag
const showCompleteButtonMQ = `(min-width: ${breakpoints[1]})`;

interface Props {
  project: Project;
  environment: Environment;
  push: () => void;
  organizationId: string;
  environmentId: string;
  currentPathName: string;
}

const BackupNavBar = ({
  project,
  environment,
  organizationId,
  environmentId,
  ...props
}: Props) => {
  const [openModal, setOpenModal] = useState(false);

  const intl = useIntl();

  return (
    <FloatBox>
      <NavigationMenu
        project={project}
        environment={environment}
        environmentId={environmentId}
        organizationId={organizationId}
        {...props}
      />

      <ActionButton
        analyticId="backup"
        disabled={environment?.status === "inactive"}
        onClick={() => setOpenModal(true)}
        aria-label={intl.formatMessage({ id: "backup" })}
        expandWhen={showCompleteButtonMQ}
      >
        <Backup />
        {intl.formatMessage({ id: "backup" })}
      </ActionButton>
      <BackupModal isOpen={openModal} closeModal={() => setOpenModal(false)} />
    </FloatBox>
  );
};

export default BackupNavBar;
