import { useCallback, useState } from "react";

import ReactDom from "react-dom";

import { apiProxyRequest } from "Api/apiProxyRequest";
import { getOrganizationProjects } from "Api/organizations/projects/getOrganizationProjects.request";
import { useNotification } from "Containers/Notifications";

import type { GetOrganizationProjectsParams } from "Api/organizations/projects/getOrganizationProjects.request";
import type {
  OrganizationProjectsList,
  OrganizationProject
} from "Api/organizations/projects/OrganizationProjects.types";

type Props = GetOrganizationProjectsParams;

export function useGetFilteredOrganizationProjects(organizationId: string) {
  const notify = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [projectList, setProjectList] = useState<OrganizationProjectsList>();
  const [projects, setProjects] = useState<Array<OrganizationProject>>([]);
  const [isPaginated, setIsPaginated] = useState<boolean>();
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [facets, setFacets] = useState<OrganizationProjectsList["facets"]>({
    plans: {}
  });

  const getProjectsByTitle = useCallback(
    async (filters?: Props) => {
      setIsLoading(true);
      try {
        const projectList = await getOrganizationProjects(
          organizationId,
          filters
        );
        // This should do the trick until we use react 18
        ReactDom.unstable_batchedUpdates(() => {
          setProjectList(projectList);
          setProjects(projectList.items);
          // Only the results of unfiltered requests can be trusted to
          // know whether the result is paginated or not.
          setIsPaginated(isPaginated =>
            filters === undefined ? !!projectList._links.next : isPaginated
          );
          setHasMore(!!projectList._links.next);
          setFacets(projectList.facets);
          setIsLoading(false);
        });
      } catch (error) {
        if (error instanceof Error) notify.error(error.message);
        setIsLoading(false);
      }
    },
    [organizationId, notify]
  );

  const getNextPage = useCallback(async () => {
    const nextPage = projectList?._links.next?.href;
    if (nextPage && !isLoading && !isLoadingMore) {
      setIsLoadingMore(true);
      try {
        const data = await apiProxyRequest<OrganizationProjectsList>(nextPage);
        // This should do the trick until we use react 18
        ReactDom.unstable_batchedUpdates(() => {
          setProjectList(data);
          setProjects(list => list.concat(data.items));
          setHasMore(!!data._links.next);
          setIsLoadingMore(false);
        });
      } catch (error) {
        setIsLoadingMore(false);
        if (error instanceof Error) notify.error(error.message);
      }
    }
  }, [projectList, isLoading, isLoadingMore, notify]);

  return [
    projects,
    getProjectsByTitle,
    isLoading,
    getNextPage,
    isPaginated,
    hasMore,
    isLoadingMore,
    facets
  ] as const;
}
