import React, { useState } from "react";

import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import config from "console_config";
import { ButtonTheme, ThemeUIText } from "Containers/NavBar/styles";
import TestingModal from "Containers/testing-widget";
import { MenuActiveTag } from "Containers/testing-widget/TestingModal.styles";
import * as Dropdown from "ds/Dropdown";
import UserIcon from "ds/Icons/User";
import useUrls from "Hooks/useUrls";
import { getProfileUrl } from "Libs/getUrl/getUrl";
import { hasSafeRole } from "Libs/utils";
import { selectCurrentUserRoles } from "Reducers/app/selectors";
import { getFeatureFlag } from "Reducers/featureFlags";
import { selectFeatureStaffTestModeEnabled } from "Reducers/featureFlags/featureFlags.selectors";
import { updateUserProfile } from "Reducers/profile";
import { isActiveSelector } from "Reducers/testMode";
import { useAppDispatch, useAppSelector } from "Store/hooks";

import * as U from "../styles";
import { ButtonThemeContainer } from "../styles";

import * as S from "./UserMenuDropdown.styles";

import type { Me, AccountsProfile, AuthUser } from "platformsh-client";

type UserMenuDropdownProps = {
  user: Me;
  profile?: AccountsProfile & AuthUser;
  accountUrl: string;
};

const UserMenuDropdown = ({
  user,
  accountUrl,
  profile
}: UserMenuDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openTestingModal, setOpenTestingModal] = useState(false);
  const dispatch = useAppDispatch();

  const formatMessage = useIntl().formatMessage;
  const username = profile?.username || user.username;
  const profileUrl = getProfileUrl({ accountUrl, username });

  const memberRoles = useAppSelector(selectCurrentUserRoles);
  const canTest = memberRoles && hasSafeRole(memberRoles);
  const isTestModeActive = useSelector(isActiveSelector);

  const staffTestModeEnabled = useAppSelector(
    selectFeatureStaffTestModeEnabled
  );

  const changeColorTheme = (mode: string) => {
    dispatch(
      updateUserProfile({
        id: profile?.id!,
        ui_colorscheme: mode
      })
    );
  };

  const organizationEnabled = getFeatureFlag("ENABLE_ORGANIZATION");

  const [
    userSshKeyUrl,
    userTokensUrl,
    userSecurityUrl,
    userConnectedAccountsUrl,
    logoutUrl
  ] = useUrls(
    [
      "users.profile.sshKeys",
      "users.profile.tokens",
      "users.profile.security",
      "users.profile.accounts",
      "root.logout"
    ],
    { username }
  );

  const displayName =
    profile?.display_name && profile?.display_name !== " "
      ? profile?.display_name
      : user.display_name;

  const picture = profile?.picture || user.picture;

  const email = profile?.email || user.email;

  const showDefaultImage = picture?.endsWith("user-default.png") || !picture;

  const isRelative = url => url?.[0] === "/";

  const shouldShowDropdownLinkItem =
    typeof config.CUSTOM_URL_VENDOR_CONSOLE === "string" &&
    config.CUSTOM_CONSOLE_TITLE;

  return (
    <>
      <Dropdown.Root
        open={isOpen}
        onOpenChange={() => setIsOpen(prevState => !prevState)}
        modal
      >
        <S.DropdownTrigger variant="mixed">
          {displayName} <S.DropdownChevron />{" "}
        </S.DropdownTrigger>
        <Dropdown.Portal>
          <Dropdown.Content align="end">
            {organizationEnabled && (
              <U.UserInfoContainer>
                <U.UserInfoImageContainer>
                  {showDefaultImage ? (
                    <UserIcon color="var(--mode-vector-neutral-weakest)" />
                  ) : (
                    <img
                      src={picture}
                      alt={displayName}
                      referrerPolicy="no-referrer"
                    />
                  )}
                </U.UserInfoImageContainer>

                <U.UserInfoTextContainer>
                  <U.NameText>{displayName}</U.NameText>
                  <U.EmailText>{email}</U.EmailText>
                </U.UserInfoTextContainer>
              </U.UserInfoContainer>
            )}
            {shouldShowDropdownLinkItem && (
              <Dropdown.LinkItem
                {...(isRelative(config.CUSTOM_URL_VENDOR_CONSOLE)
                  ? { to: config.CUSTOM_URL_VENDOR_CONSOLE }
                  : { href: config.CUSTOM_URL_VENDOR_CONSOLE })}
                analyticId="CUSTOM_URL_VENDOR_CONSOLE"
              >
                {config.CUSTOM_CONSOLE_TITLE}
              </Dropdown.LinkItem>
            )}

            <Dropdown.LinkItem
              to={profileUrl || ""}
              analyticId="menu.my_profile"
            >
              {formatMessage({ id: "menu.my_profile" })}
            </Dropdown.LinkItem>

            {getFeatureFlag("ENABLE_CONNECTED_ACCOUNTS_ROUTE") && (
              <Dropdown.LinkItem
                to={userConnectedAccountsUrl}
                analyticId="connected_accounts"
              >
                {formatMessage({ id: "connected_accounts" })}
              </Dropdown.LinkItem>
            )}

            <Dropdown.LinkItem to={userSecurityUrl} analyticId="tabs.security">
              {formatMessage({ id: "tabs.security" })}
            </Dropdown.LinkItem>

            <Dropdown.LinkItem to={userTokensUrl} analyticId="tabs.tokens">
              {formatMessage({ id: "tabs.tokens" })}
            </Dropdown.LinkItem>

            <Dropdown.LinkItem to={userSshKeyUrl} analyticId="tabs.ssh_keys">
              {formatMessage({ id: "tabs.ssh_keys" })}
            </Dropdown.LinkItem>

            <S.DropdownSeparator />

            <U.ThemeText>{formatMessage({ id: "theme" })}</U.ThemeText>
            <ButtonThemeContainer>
              <ButtonTheme
                selectedTheme={"light"}
                onClick={() => {
                  changeColorTheme("light");
                }}
              >
                <ThemeUIText selectedTheme={"light"}>
                  {formatMessage({ id: "light" })}
                </ThemeUIText>
              </ButtonTheme>
              <ButtonTheme
                selectedTheme={"dark"}
                onClick={() => {
                  changeColorTheme("dark");
                }}
              >
                <ThemeUIText selectedTheme={"dark"}>
                  {formatMessage({ id: "dark" })}
                </ThemeUIText>
              </ButtonTheme>
              <ButtonTheme
                selectedTheme={"contrast"}
                onClick={() => {
                  changeColorTheme("contrast");
                }}
              >
                <ThemeUIText selectedTheme={"contrast"}>
                  {formatMessage({ id: "WCAG" })}
                </ThemeUIText>
              </ButtonTheme>
            </ButtonThemeContainer>
            <S.DropdownSeparator />

            {canTest && staffTestModeEnabled && (
              <Dropdown.Item
                onClick={() => {
                  setOpenTestingModal(true);
                  setIsOpen(false);
                }}
              >
                {formatMessage({ id: "menu.staff_test_mode" })}
                {isTestModeActive && (
                  <MenuActiveTag variant="yellow">
                    {formatMessage({
                      id: "test_mode.active_label"
                    })}
                  </MenuActiveTag>
                )}
              </Dropdown.Item>
            )}
            <Dropdown.LinkItem to={logoutUrl}>
              {formatMessage({ id: "menu.logout" })}
            </Dropdown.LinkItem>
          </Dropdown.Content>
        </Dropdown.Portal>
      </Dropdown.Root>
      <TestingModal
        isOpen={openTestingModal}
        onClose={() => setOpenTestingModal(false)}
      />
    </>
  );
};

export default UserMenuDropdown;
