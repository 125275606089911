import { createAsyncThunk } from "@reduxjs/toolkit";

import { updateAddonsRequest } from "Api/organizations/addons/updateAddons.request";
import logger from "Libs/logger";
import { organizationByDescriptionIdSelector } from "Reducers/organization";

import type { OrganizationAddon } from "../types";
import type { AsyncThunkOptionType } from "Reducers/types";

export const updateAddons = createAsyncThunk<
  OrganizationAddon,
  { organizationId: string; selection: string },
  AsyncThunkOptionType
>(
  "app/organization/update_addons",
  async ({ organizationId, selection }, { rejectWithValue, getState }) => {
    try {
      const organization = organizationByDescriptionIdSelector(getState(), {
        organizationDescriptionId: organizationId
      });

      if (!organization) {
        return rejectWithValue("Organization does not exit");
      }

      const addons = await updateAddonsRequest(organization.id, selection);

      return addons;
    } catch (err: unknown) {
      const error =
        (err as { detail: string })?.detail ??
        (err as { title: string })?.title ??
        (err as { description: string })?.description ??
        "An error has occurred";

      logger(err, {
        action: "get_addons",
        organizationId
      });

      return rejectWithValue(error);
    }
  }
);
