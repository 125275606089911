import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import {
  loadUserProfile,
  updateUserProfile,
  deleteProfilePicture
} from "./thunks/";
import { ProfileState } from "./types";

const initialState: ProfileState = {
  loading: false,
  data: undefined,
  emailReset: "",
  inflight: { load: false, update: false }
};

const profileReducer = createSlice({
  name: "profile",
  initialState,
  reducers: {
    clearErrors(state) {
      state.errors = undefined;
    },
    pictureError(state, action: PayloadAction<ProfileState["errors"]>) {
      state.errors = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      // Load Profile
      .addCase(loadUserProfile.pending, state => {
        state.inflight.load = true;
      })
      .addCase(loadUserProfile.fulfilled, (state, { payload }) => {
        state.inflight.load = false;
        state.data = { ...state.data, [payload.id]: payload };
      })
      .addCase(loadUserProfile.rejected, (state, { payload }) => {
        state.inflight.load = false;
        state.errors = payload;
      })
      // Update Profile
      .addCase(updateUserProfile.pending, state => {
        state.inflight.update = true;
        state.loading = true;
        state.errors = undefined;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        if (action.meta.arg.email) state.emailReset = action.meta.arg.email;

        state.inflight.update = false;
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.inflight.update = false;
        state.errors = action.payload as ProfileState["errors"];
      })
      // Delete Profile Picture
      .addCase(deleteProfilePicture.rejected, (state, { payload }) => {
        const error =
          typeof payload !== "string" ? JSON.parse(payload) : payload;
        state.errors = error;
      });
  }
});

export default profileReducer.reducer;

export const { clearErrors, pictureError } = profileReducer.actions;
