import React from "react";

import { Close as ClosePrimitive } from "@radix-ui/react-toast";

import * as S from "./Close.styles";

import type { CloseButtonProps } from "ds/Button/CloseButton";

const Close = (props: CloseButtonProps) => (
  <ClosePrimitive asChild>
    <S.Layout {...props} />
  </ClosePrimitive>
);

export default Close;
