import client from "Libs/platform";

import { generateAdminAgentHeaders } from "./generateAdminAgentHeaders";
import { ServiceProcess } from "./types/ServiceProcess.type";

type AdminAgentRequestConfig = {
  adminAgentUrl: string;
  body?: unknown;
  method?: string;
  retry?: boolean;
};

export const adminAgentRequest = async ({
  adminAgentUrl,
  body,
  method,
  retry
}: AdminAgentRequestConfig): Promise<ServiceProcess[]> => {
  const headers = await generateAdminAgentHeaders({
    body
  });

  const response = await fetch(`${adminAgentUrl}`, {
    method,
    headers,
    body: body ? JSON.stringify(body) : undefined
  });

  if (!response?.ok && retry) {
    throw response.json();
  }

  if (!response?.ok) {
    await client.reAuthenticate();

    return adminAgentRequest({
      adminAgentUrl,
      body,
      method,
      retry: true
    });
  }

  return response.json();
};
