import { createSlice } from "@reduxjs/toolkit";

import { pause, PauseState } from "./";

const initialState: PauseState = {
  data: null,
  isLoading: false
};

export const pauseSlice = createSlice({
  name: "pause",
  initialState: initialState,
  reducers: {
    clearPause: state => {
      state.isLoading = false;
      state.error = undefined;
      state.data = null;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(pause.pending, state => {
        state.isLoading = true;
      })
      .addCase(pause.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        state.data = action.payload;
      })
      .addCase(pause.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  }
});

export const { clearPause } = pauseSlice.actions;

export default pauseSlice.reducer;
