import { createAsyncThunk } from "@reduxjs/toolkit";

import { updateBillingAlertRequest } from "Api/organizations/billingAlert/updateBillingAlert.request";
import logger from "Libs/logger";
import { organizationByDescriptionIdSelector } from "Reducers/organization";

import type { BillingAlert } from "../";
import type { AsyncThunkOptionType } from "Reducers/types";

export const updateBillingAlert = createAsyncThunk<
  BillingAlert,
  { organizationId: string; threshold: number; active: boolean },
  AsyncThunkOptionType
>(
  "app/organization/update_billing_alert",
  async (
    { organizationId, threshold, active },
    { rejectWithValue, getState }
  ) => {
    try {
      const organization = organizationByDescriptionIdSelector(getState(), {
        organizationDescriptionId: organizationId
      });

      if (!organization) {
        return rejectWithValue("Organization does not exit");
      }

      const billingAlert = await updateBillingAlertRequest(organization.id, {
        threshold,
        active
      });

      return billingAlert;
    } catch (err: unknown) {
      const error =
        (err as { detail: string })?.detail ??
        (err as { title: string })?.title ??
        (err as { description: string })?.description ??
        "An error has occurred";

      logger(err, {
        action: "get_addons",
        organizationId
      });

      return rejectWithValue(error);
    }
  }
);
