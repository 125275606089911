import localForage from "localforage";

import logger from "Libs/logger";
import { setupTheme, getColorMode } from "Libs/themes";
import { isJson } from "Libs/utils";
import { profileColorScheme } from "Reducers/profile";

import { selectCurrentUserUsername } from "./selectors";

import type { AppDispatch, GetState } from "Store/configureStore";

const LOAD_THEME_START = "app/themes/load_theme_start";
const LOAD_THEME_SUCCESS = "app/themes/load_theme_success";
const LOAD_THEME_FAILURE = "app/themes/load_theme_failure";

const LOAD_THEME_FROM_PROFILE_START =
  "app/themes/load_theme_from_profile_start";
const LOAD_THEME_FROM_PROFILE_SUCCESS =
  "app/themes/load_theme_from_profile_success";
const LOAD_THEME_FROM_PROFILE_FAILURE =
  "app/themes/load_theme_from_profile_failure";

export const loadTheme = () => {
  return async (dispatch: AppDispatch) => {
    dispatch({ type: LOAD_THEME_START });

    try {
      await setupTheme();

      const colorSchemeFromCache = await getColorMode();

      dispatch({
        type: LOAD_THEME_SUCCESS,
        payload: {
          name: colorSchemeFromCache
        }
      });
    } catch (err) {
      if (![404, 403].includes((err as { code: number }).code)) {
        const errorMessage = isJson(err)
          ? err
          : "An error occurred while attempting to load theme.";
        logger(errorMessage, {
          action: "loadTheme"
        });
      }
      dispatch({ type: LOAD_THEME_FAILURE, error: true, payload: err });
    }
  };
};

export const setThemeFromProfile =
  (profile?: { ui_colorscheme?: string }) =>
  async (dispatch: AppDispatch, getState: GetState) => {
    dispatch({ type: LOAD_THEME_FROM_PROFILE_START });
    const username = selectCurrentUserUsername(getState());

    const colorSchemeFromCache = await localForage.getItem("ui_colorscheme");
    const colorSchemeFromProfile =
      profile?.ui_colorscheme || profileColorScheme(getState(), username);

    try {
      if (colorSchemeFromCache !== colorSchemeFromProfile) {
        await localForage.setItem("ui_colorscheme", colorSchemeFromProfile);
      }

      dispatch(loadTheme());
      dispatch({
        type: LOAD_THEME_FROM_PROFILE_SUCCESS,
        meta: colorSchemeFromProfile
      });
    } catch (err) {
      dispatch({ type: LOAD_THEME_FROM_PROFILE_FAILURE, payload: err });
    }
  };

type ThemeState = {
  loading?: boolean;
  data?: { name: string };
  errors?: unknown;
};

type ThemeAction =
  | { type: typeof LOAD_THEME_START }
  | { type: typeof LOAD_THEME_SUCCESS; payload: { name: string } }
  | { type: typeof LOAD_THEME_FAILURE; payload: unknown };

export default function themeReducer(
  state: ThemeState = {},
  action: ThemeAction
): ThemeState {
  switch (action.type) {
    case LOAD_THEME_START:
      return {
        ...state,
        loading: true
      };
    case LOAD_THEME_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: false
      };
    case LOAD_THEME_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload
      };
    default:
      return state;
  }
}
