import { createSelector } from "@reduxjs/toolkit";

import { ADMIN_ROLES } from "Constants/constants";
import { hasSafeRole } from "Libs/utils";

import type { RootState } from "Store/configureStore";

const selectApp = (state: RootState) => state?.app;

export const meSelector = createSelector(selectApp, app => {
  return app?.me;
});

export const selectCurrentUserUsername = createSelector(
  meSelector,
  me => me?.username || ""
);

export const selectCurrentUserId = createSelector(
  meSelector,
  me => me?.id || ""
);

export const selectCurrentUserTeams = createSelector(
  meSelector,
  me => me?.teams
);

export const selectCurrentUserRoles = createSelector(
  meSelector,
  me => me?.roles
);

export const selectCurrentUserIsAdmin = createSelector(
  selectCurrentUserRoles,
  roles => roles?.some(role => ADMIN_ROLES.includes(role))
);

export const selectCurrentUserHasSafeRole = createSelector(
  selectCurrentUserRoles,
  roles => hasSafeRole(roles)
);

export const currentRouteSelector = createSelector(
  selectApp,
  app => app.routeHistory.currentRoute
);

export const selectCurrentUserProjects = createSelector(
  meSelector,
  me => me?.projects
);

export const selectUserProjectById = createSelector(
  selectCurrentUserProjects,
  (_: RootState, params: { projectId: string }) => params,
  (projects, p) => projects?.find(project => project.id === p?.projectId)
);

export const selectCurrentUserUUID = createSelector(meSelector, me => me?.uuid);

export const selectLastRoute = createSelector(
  selectApp,
  app => app.routeHistory.previousRoutes[0]
);

export const selectIsCurrentUserPlatformMember = createSelector(
  meSelector,
  me => me?.mail?.includes("@platform.sh")
);

export const meIdSelector = createSelector(meSelector, me => me?.id);
