import React from "react";

import { useIntl } from "react-intl";

import { AddButton } from "ds/Button";
import Tooltip from "ds/Tooltip";

const NotEnoughPermissionsButton = ({ label }) => {
  const intl = useIntl();

  return (
    <div data-testid="not-enough-permissions-button">
      <Tooltip
        tooltip={intl.formatMessage({
          id: "create_project_button.disabled.tooltip"
        })}
        align="end"
        analyticId="create_project_button.disabled.tooltip"
      >
        <AddButton disabled analyticId="create_project">
          {label}
        </AddButton>
      </Tooltip>
    </div>
  );
};

export default NotEnoughPermissionsButton;
