import { combineReducers } from "redux";

import notificationsReducer from "Containers/Notifications/Notifications.slice";
import environmentTypeReducer from "Reducers/access/environmentTypeAccess/environmentTypeAccess.reducer";
import invitationReducer from "Reducers/access/invitation/invitation.reducer";
import activityReducer from "Reducers/activity";
import announcementsReducer from "Reducers/announcements";
import apiTokenReducer from "Reducers/apiToken";
import appReducer from "Reducers/app";
import { auth401modalsReducer } from "Reducers/app/auth401modals.slice";
import searchReducer from "Reducers/app/search";
import themeReducer from "Reducers/app/theme";
import backupsStoredReducer from "Reducers/backupsStored";
import discountReducer from "Reducers/discount";
import memberDataReducer from "Reducers/editingMember/editingMember.reducer";
import environmentReducer from "Reducers/environment";
import backupReducer from "Reducers/environment/actions/backup";
import branchReducer from "Reducers/environment/actions/branch";
import mergeReducer from "Reducers/environment/actions/merge";
import pauseReducer from "Reducers/environment/actions/pause";
import redeployReducer from "Reducers/environment/actions/redeploy";
import resumeReducer from "Reducers/environment/actions/resume";
import synchronisationReducer from "Reducers/environment/actions/sync";
import deploymentReducer from "Reducers/environment/deployment";
import git from "Reducers/environment/git";
import runtimeOperationsReducer from "Reducers/environment/runtimeOperations";
import serviceReducer from "Reducers/environment/service";
import environmentVariableReducer from "Reducers/environment/settings/variable";
import sourceOperationsReducer from "Reducers/environment/sourceOperations";
import featureFlagsReducer from "Reducers/featureFlags";
import integrationReducer from "Reducers/integration";
import logReducer from "Reducers/log";
import metricsReducer from "Reducers/metrics/metrics.slice";
import organizationReducer from "Reducers/organization";
import addonsReducer from "Reducers/organization/addons";
import billingAlertReducer from "Reducers/organization/billingAlert";
import organizationDiscountsReducer from "Reducers/organization/discounts";
import organizationDunningReducer from "Reducers/organization/dunning";
import organizationInvitations from "Reducers/organization/invitations/invitations";
import organizationOrderReducer from "Reducers/organization/order/order.slice";
import organizationPaymentSourceReducer from "Reducers/organization/paymentSource/paymentSource.slice";
import organizationProfileReducer from "Reducers/organization/profile";
import connectedAccountsReducer from "Reducers/organization/settings/connectedAccounts";
import securityReducer from "Reducers/organization/settings/security";
import userSshKeyReducer from "Reducers/organization/settings/sshKey";
import organizationSubscriptionReducer from "Reducers/organization/subscription";
import paymentSourceReducer from "Reducers/paymentSource";
import profileReducer from "Reducers/profile";
import capabilitiesReducer from "Reducers/project/capabilities";
import projectReducer from "Reducers/project/project";
import regionReducer from "Reducers/project/region";
import projectCertificateReducer from "Reducers/project/settings/certificate";
import { domainsReducer } from "Reducers/project/settings/domains/domains.slice";
import { environmentDomainsReducer } from "Reducers/project/settings/environmentDomains/environmentDomains.slice";
import projectVariableReducer from "Reducers/project/settings/variable";
import projectWizardReducer from "Reducers/project/wizard";
import projectSettingsReducer from "Reducers/projectSettings";
import settingsMenuReducer from "Reducers/settingsMenu";
import { setupCatalogReducer } from "Reducers/setupCatalog/setupCatalog.slice";
import { setupOptionsReducer } from "Reducers/setupOptions/setupOptions.slice";
import subscriptionReducer from "Reducers/subscription";
import systemStatusReducer from "Reducers/systemStatus";
import testModeReducer from "Reducers/testMode";
import ticketsReducer from "Reducers/tickets/list";
import openTicketReducer from "Reducers/tickets/open";
import prioritiesReducer from "Reducers/tickets/priorities";
import viewReducer from "Reducers/tickets/view";
import userReducer from "Reducers/user/user";
import voucherReducer from "Reducers/voucher";
import projectAccessReducer from "src/reducers/access/projectAccess/projectAccess.reducer";

import { projectTeamAccessReducer } from "./access/teams/teams.slice";
import bannersReducer from "./banners";
import { memberProjectsReducer } from "./editingMember/memberProjects/memberProjects.slice";
import observabilityPipelineReducer from "./observability/pipeline";
import observabilitySandboxReducer from "./observability/sandbox";
import { enforceMFAReducer } from "./organization/enforceMFA/enforceMFA.slice";
import { loggedInMemberReducer } from "./organization/loggedInMember/loggedInMember.slice";
import { membersListReducer } from "./organization/members/membersList.slice";
import { memberTeamsReducer } from "./organization/members/memberTeams.slice";
import { teamReducer } from "./organization/team/team.slice";
import { teamListReducer } from "./organization/team/teamList.slice";
import { teamMembersReducer } from "./organization/team/teamMembers.slice";
import { teamProjectAccessesReducer } from "./organization/team/teamProjectAccesses.slice";
import { serviceProcessesReducer } from "./serviceProcesses/serviceProcesses.slice";
import { userAccessReducer } from "./userAccess/userAccess.reducer";

const rootReducer = combineReducers({
  activity: activityReducer,
  addons: addonsReducer,
  announcements: announcementsReducer,
  apiToken: apiTokenReducer,
  app: appReducer,
  backup: backupReducer,
  backupsStored: backupsStoredReducer,
  billingAlert: billingAlertReducer,
  branch: branchReducer,
  capabilities: capabilitiesReducer,
  connectedAccounts: connectedAccountsReducer,
  deployment: deploymentReducer,
  discount: discountReducer,
  environment: environmentReducer,
  environmentType: environmentTypeReducer,
  environmentVariable: environmentVariableReducer,
  featureFlags: featureFlagsReducer,
  integration: integrationReducer,
  invitation: invitationReducer,
  log: logReducer,
  merge: mergeReducer,
  metrics: metricsReducer,
  openTicket: openTicketReducer,
  organization: organizationReducer,
  organizationDiscounts: organizationDiscountsReducer,
  organizationDunning: organizationDunningReducer,
  organizationOrder: organizationOrderReducer,
  organizationPaymentSource: organizationPaymentSourceReducer,
  organizationProfile: organizationProfileReducer,
  setupCatalog: setupCatalogReducer,
  setupOptions: setupOptionsReducer,
  organizationSubscription: organizationSubscriptionReducer,
  pause: pauseReducer,
  paymentSource: paymentSourceReducer,
  priorities: prioritiesReducer,
  profile: profileReducer,
  project: projectReducer,
  projectAccess: projectAccessReducer,
  projectCertificate: projectCertificateReducer,
  projectEnvironmentType: environmentTypeReducer,
  projectDomain: domainsReducer,
  projectEnvironmentDomain: environmentDomainsReducer,
  projectSettings: projectSettingsReducer,
  projectVariable: projectVariableReducer,
  projectWizard: projectWizardReducer,
  redeploy: redeployReducer,
  resume: resumeReducer,
  region: regionReducer,
  search: searchReducer,
  security: securityReducer,
  service: serviceReducer,
  serviceProcesses: serviceProcessesReducer,
  settingsMenu: settingsMenuReducer,
  sourceOperations: sourceOperationsReducer,
  runtimeOperations: runtimeOperationsReducer,
  subscription: subscriptionReducer,
  synchronisation: synchronisationReducer,
  systemStatus: systemStatusReducer,
  testMode: testModeReducer,
  theme: themeReducer,
  tickets: ticketsReducer,
  user: userReducer,
  userSshKey: userSshKeyReducer,
  view: viewReducer,
  voucher: voucherReducer,
  notifications: notificationsReducer,
  memberData: memberDataReducer,
  organizationInvitations,
  membersList: membersListReducer,
  loggedInMember: loggedInMemberReducer,
  team: teamReducer,
  teamProjectAccesses: teamProjectAccessesReducer,
  teamMembers: teamMembersReducer,
  teamList: teamListReducer,
  memberTeams: memberTeamsReducer,
  accessTeams: projectTeamAccessReducer,
  memberProjects: memberProjectsReducer,
  userAccess: userAccessReducer,
  enforceMFA: enforceMFAReducer,
  banners: bannersReducer,
  git: git.reducer,
  auth401modals: auth401modalsReducer,
  observabilitySandbox: observabilitySandboxReducer,
  observabilityPipeline: observabilityPipelineReducer
});

export default rootReducer;
