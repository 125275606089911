import React from "react";

import ModalTrial from "Components/ModalTrial";
import Button, { AddButton } from "ds/Button";

import { CreateProjectButtonProps } from "../types";

const BillingDetailsModal = ({
  isModalOpen,
  setIsModalOpen,
  organizationId,
  isLink,
  linkVariant = "link",
  linkStyle,
  openModal,
  label
}: CreateProjectButtonProps) => {
  return (
    <>
      <ModalTrial
        isOpen={isModalOpen!}
        closeModal={() => setIsModalOpen?.(false)}
        organizationNameId={organizationId}
        kind={"very_hard"}
      />
      {isLink ? (
        <Button
          id="create_project"
          analyticId="create_project"
          variant={linkVariant}
          style={linkStyle}
          onClick={openModal}
        >
          {label}
        </Button>
      ) : (
        <AddButton
          id="create_project"
          analyticId="create_project"
          onClick={openModal}
        >
          {label}
        </AddButton>
      )}
    </>
  );
};

export default BillingDetailsModal;
