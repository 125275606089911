import { createAsyncThunk } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import { isJson } from "Libs/utils";
import { AsyncThunkOptionType } from "Reducers/types";

import type { ProjectLevelVariable } from "platformsh-client";

export const deleteVariable = createAsyncThunk<
  ProjectLevelVariable,
  {
    organizationId: string;
    projectId: string;
    variable: ProjectLevelVariable;
  },
  AsyncThunkOptionType<{ error: Error }>
>("app/project/variable/delete", async ({ variable }, { rejectWithValue }) => {
  await variable.delete().catch(err => {
    const errMessage = isJson(err) ? JSON.parse(err) : err.message;
    logger(
      { errMessage, variableId: variable.id },
      {
        action: "projectDeleteVariable"
      }
    );
    return rejectWithValue({ error: err });
  });
  return variable;
});
