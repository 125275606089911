import React, { ReactNode } from "react";

import { sendHeapEventRadio } from "Store/analytics/heapFunctions";

import * as S from "./Radio.styles";

export interface Props {
  /** Field value */
  value: string;
  /** The label value describing the option */
  label: ReactNode;
  id: string;
  /** Marks this as a required field */
  required?: boolean;
  /** Field disabled */
  disabled?: boolean;
  /** If false, field is invalid;*/
  isValid?: boolean;
  /** Allows to customize the wrapper */
  className?: string;
  optionDetails?: string;
  onClick?: (e: React.MouseEvent) => void;
}

/**
 * How to import:  `import * as Radio from 'ds/Radio';`
 *
 * Component is based on Radix primitive: https://www.radix-ui.com/docs/primitives/components/radio-group
 */

export const Input = ({
  value,
  label,
  id,
  disabled,
  required,
  isValid = true,
  className,
  optionDetails,
  onClick,
  ...props
}: Props) => {
  const onRadioClick = e => {
    onClick && onClick(e);
    sendHeapEventRadio(id);
  };
  return (
    <>
      <S.Wrapper className={className} onClick={onRadioClick}>
        <S.Item
          {...props}
          value={value}
          id={id}
          disabled={disabled}
          required={required}
          isValid={isValid}
          tabIndex={0}
        >
          <S.Indicator isValid={isValid} />
        </S.Item>
        <S.Label disabled={disabled}>
          {label}
          {optionDetails && <S.OptionDetails> {optionDetails}</S.OptionDetails>}
        </S.Label>
      </S.Wrapper>
    </>
  );
};

export default Input;
