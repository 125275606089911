import React, { useCallback, useMemo, useState } from "react";

import { useIntl } from "react-intl";
import {
  Country,
  getCountryCallingCode,
  isValidPhoneNumber,
  parsePhoneNumber
} from "react-phone-number-input";
import PhoneField from "react-phone-number-input/input";
import countryList from "react-select-country-list";

import {
  StepPropType,
  StepsList
} from "Containers/PhoneNumberVerificationModal/types";
import { Button, ButtonWrapper } from "ds/Button";
import * as Input from "ds/Input";
import Label from "ds/Label";
import Select from "ds/Select";
import { BodyText } from "ds/Typography";
import { selectUserProfileByMeUsername } from "Reducers/profile";
import { useAppSelector } from "Store/hooks";

import * as C from "../Steps.styles";

import * as S from "./Phone.styles";

type CountryProps = {
  label: string;
  value: string;
};

const Phone = ({
  goToStep,
  handleChangeConfig,
  onCancel,
  config: { phone: defaultPhoneNumber, showErrorMessage }
}: StepPropType) => {
  const intl = useIntl();

  const profile = useAppSelector(selectUserProfileByMeUsername);
  const options: CountryProps[] = useMemo(() => countryList().getData(), []);
  const defaultCountry = parsePhoneNumber(defaultPhoneNumber)?.country;
  const [country, setCountry] = useState<CountryProps | undefined>(
    options.find(
      country =>
        country.value.toUpperCase() ===
        (defaultCountry || profile?.country || "US")
    )
  );
  const countryCode = getCountryCallingCode(country?.value as Country);
  const [phoneNumber, setPhoneNumber] = useState<string>(
    defaultPhoneNumber || `+${countryCode}`
  );

  const handlePhoneChange = (num: string = "") => {
    const formattedPhoneNumber = parsePhoneNumber(num);
    const countryCodeChanging = !num.startsWith(countryCode);
    setPhoneNumber(
      formattedPhoneNumber?.nationalNumber && !countryCodeChanging
        ? `+${countryCode}${formattedPhoneNumber.nationalNumber}`
        : num
    );
    setCountry(
      options.find(option => option.value === formattedPhoneNumber?.country) ??
        country
    );
  };

  const handleCountryChange = (selected: CountryProps) => {
    setCountry(selected);
    setPhoneNumber(`+${getCountryCallingCode(selected.value as Country)}`);
  };

  const phoneNumberIsValid = useCallback(
    () => phoneNumber && isValidPhoneNumber(phoneNumber),
    [phoneNumber]
  );

  const canSubmit = useMemo(() => phoneNumberIsValid(), [phoneNumberIsValid]);

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault;
    if (!phoneNumberIsValid()) return;
    handleChangeConfig(StepsList.phone, phoneNumber);
    goToStep(StepsList.code);
  };

  return (
    <C.Wrapper>
      <BodyText>
        {intl.formatMessage({
          id: "kyc_verification.phone.intro"
        })}
      </BodyText>

      <S.Field>
        <Label htmlFor="country" isValid={true} required={true}>
          {intl.formatMessage({ id: "country" })}
        </Label>
        <Select
          id="country"
          isClearable={false}
          isMulti={false}
          isSearchable={true}
          onChange={selected => selected && handleCountryChange(selected)}
          options={options}
          placeholder={intl.formatMessage({ id: "select_country" })}
          value={country}
        />
      </S.Field>

      <S.InputRoot>
        <Label
          required
          isValid={!showErrorMessage}
          htmlFor="phone_number_verification"
        >
          {intl.formatMessage({
            id: "kyc_verification.phone.fields.phone.label"
          })}
        </Label>
        <Input.Input
          as={PhoneField}
          id="phone_number_verification"
          placeholder={intl.formatMessage({
            id: "kyc_verification.phone.fields.phone.placeholder"
          })}
          onChange={handlePhoneChange}
          value={phoneNumber}
          international
          withCountryCallingCode
          isValid={!showErrorMessage}
          width="100%"
        />
        {showErrorMessage && (
          <Input.Error>
            {intl.formatMessage({
              id: "kyc_verification.phone.error"
            })}
          </Input.Error>
        )}
      </S.InputRoot>

      <C.Help>
        <Button
          analyticId="kyc_verification.phone.switch_method"
          variant="link"
          onClick={() => {
            handleChangeConfig(StepsList.phone, phoneNumber);
            goToStep(StepsList.methods);
          }}
        >
          {intl.formatMessage({
            id: "kyc_verification.phone.switch_method"
          })}
        </Button>
      </C.Help>

      <ButtonWrapper justifyContent="end" spacing="modal">
        <Button
          analyticId="cancel"
          variant="secondary"
          type="button"
          aria-label={intl.formatMessage({ id: "cancel" })}
          onClick={onCancel}
        >
          {intl.formatMessage({ id: "cancel" })}
        </Button>
        <Button
          type="submit"
          aria-label={intl.formatMessage({
            id: "kyc_verification.phone.submit"
          })}
          analyticId="kyc_verification.phone.submit"
          disabled={!canSubmit}
          onClick={handleSubmit}
        >
          {intl.formatMessage({ id: "kyc_verification.phone.submit" })}
        </Button>
      </ButtonWrapper>
    </C.Wrapper>
  );
};

export default Phone;
