import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
  SerializedError
} from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import localForage from "localforage";

import { setDeep } from "Libs/objectAccess";
import client, { entities } from "Libs/platform";
import { getOwnerInfoName, isJson } from "Libs/utils";
import { selectFeatureOrganizationsEnabled } from "Reducers/featureFlags/featureFlags.selectors";
import {
  canCreateProjectOrganizationsSelector,
  organizationsSelector
} from "Reducers/organization";
import {
  projectsAllSelector,
  getOrganizationDescriptionIdFromProject
} from "Reducers/project/project";
import { AsyncThunkOptionType } from "Reducers/types";
import { RootState } from "Store/configureStore";

import type { APIObject, Result, Subscription } from "platformsh-client";

export const loadSubscriptions = createAsyncThunk<
  Subscription[],
  { organizationId: string },
  AsyncThunkOptionType
>("app/subscriptions/load", async () => {
  const platformLib = await import("Libs/platform");
  const client = platformLib.default;
  const subscriptions = await client.getSubscriptions({}, true);
  return subscriptions;
});

export const loadSubscription = createAsyncThunk<
  { subscription: Subscription; userName: string | undefined },
  { organizationId: string; projectId: string; id: string },
  AsyncThunkOptionType
>(
  "app/subscription/load",
  async ({ organizationId, projectId, id }, { getState }) => {
    let subscription = subscriptionSelector(getState(), {
      organizationId,
      projectId
    });

    if (!subscription) {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;
      subscription = await client.getSubscription(id);
    }

    const organizations = organizationsSelector(getState());

    return {
      subscription,
      userName: getOwnerInfoName({
        subscription,
        organizations
      })
    };
  }
);

export const addSubscription = createAsyncThunk<
  Result,
  { config: APIObject },
  AsyncThunkOptionType<unknown>
>("app/subscription/add", async ({ config }, { getState, rejectWithValue }) => {
  // If the user as no organization, we need to fallback to the old accounts API
  const organizations = canCreateProjectOrganizationsSelector(getState());
  const organizationEnabled = selectFeatureOrganizationsEnabled(getState());

  try {
    if (organizations.length && organizationEnabled) {
      return await client.createOrganizationSubscription(config);
    }
    return await client.createSubscription(config);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateSubscription = createAsyncThunk<
  {
    subscription: Subscription;
    projectId: string;
    userName?: string;
  },
  { updates: APIObject; subscription: Subscription },
  AsyncThunkOptionType
>(
  "app/subscription/update",
  async ({ updates, subscription }, { getState }) => {
    const response = await subscription.update({ ...updates });
    const updatedSubscription = new entities.Subscription(response.data);

    const organizations = organizationsSelector(getState());
    return {
      subscription: updatedSubscription,
      projectId: updatedSubscription.project_id,
      userName: getOwnerInfoName({
        subscription: updatedSubscription,
        organizations
      })
    };
  }
);

export const deleteSubscription = createAsyncThunk<
  {
    subscription: Subscription;
    projectId: string;
    userName?: string;
  },
  { subscription: Subscription },
  AsyncThunkOptionType
>(
  "app/subscription/delete",
  async ({ subscription }: { subscription: Subscription }, { getState }) => {
    const organizations = organizationsSelector(getState());

    await subscription.delete();

    const deletedProjectIds =
      (await localForage.getItem<string[]>("deletedProjectIds")) || [];
    const updatedDeletedProjectIds = deletedProjectIds.concat([
      subscription.project_id
    ]);
    await localForage.setItem("deletedProjectIds", updatedDeletedProjectIds);

    return {
      subscription,
      projectId: subscription.project_id,
      userName: getOwnerInfoName({ subscription, organizations })
    };
  }
);

const setError = (
  state: WritableDraft<SubscriptionState>,
  action: PayloadAction<unknown, string, unknown, SerializedError>
) => {
  let message =
    action.error.message || (action.error as { detail?: string }).detail;
  if (isJson(action.error.message)) {
    const errors = JSON.parse(action.error.message);
    message = errors.detail;
    if (errors?.detail?.errors?.length) message = errors.detail.errors[0];
  }

  state.errors = message;
  state.loading = false;
  state.status = "rejected";
};

type SubscriptionState = {
  data: Record<string, Record<string, Subscription>>;
  loading?: boolean;
  errors?: unknown;
  status?: "pending" | "added" | "rejected" | "deleted" | "updated";
  projectIdBySubscriptionId?: Record<string, string>;
  lastAdded?: unknown;
  httpStatus?: unknown;
};

const initialState: SubscriptionState = { data: {} };

const subscriptions = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // LOAD SUBSCRIPTIONS
      .addCase(loadSubscriptions.pending, state => {
        state.loading = true;
      })
      .addCase(loadSubscriptions.fulfilled, (state, { meta, payload }) => {
        const { organizationId } = meta.arg;

        state.loading = false;
        setDeep(
          state,
          ["data", organizationId],
          payload.reduce<Record<string, Subscription>>((acc, cu) => {
            const projectId = cu.project_id;
            // Omit project with no Id (usually in provisioning or failed status)
            if (projectId) acc[projectId] = cu;
            return acc;
          }, {})
        );
        state.projectIdBySubscriptionId = payload.reduce<
          Record<string, string>
        >((acc, cu) => {
          const projectId = cu.project_id;
          // Omit project with no Id (usually in provisioning or failed status)
          if (projectId) acc[cu.id] = projectId;
          return acc;
        }, {});
      })
      .addCase(loadSubscriptions.rejected, (state, action) =>
        setError(state, action)
      )

      // LOAD SUBSCRIPTION
      .addCase(loadSubscription.pending, state => {
        state.loading = true;
      })
      .addCase(loadSubscription.fulfilled, (state, { meta, payload }) => {
        const { projectId } = meta.arg;
        const { subscription, userName } = payload;
        state.loading = false;
        setDeep(
          state,
          ["data", userName!, projectId || subscription.project_id],
          subscription
        );
        setDeep(
          state,
          ["projectIdBySubscriptionId", subscription.id],
          projectId || subscription.project_id
        );
      })
      .addCase(loadSubscription.rejected, (state, action) =>
        setError(state, action)
      )

      // ADD SUBSCRIPTION
      .addCase(addSubscription.pending, state => {
        state.loading = true;
        state.status = "pending";
        state.errors = null;
      })
      .addCase(addSubscription.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.status = "added";
        state.lastAdded = payload.data.id;
      })
      .addCase(addSubscription.rejected, (state, { payload }) => {
        const { detail, status, title } =
          typeof payload === "string" ? JSON.parse(payload) : payload;
        state.errors = `${title}: ${detail}`;
        state.httpStatus = status;
        state.status = "rejected";
        state.loading = false;
      })

      // UPDATE SUBSCRIPTION
      .addCase(updateSubscription.pending, state => {
        state.loading = true;
        state.status = "pending";
        state.errors = null;
      })
      .addCase(updateSubscription.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.status = "updated";
        setDeep(
          state,
          ["data", payload.userName!, payload.projectId],
          payload.subscription
        );
      })
      .addCase(updateSubscription.rejected, (state, action) =>
        setError(state, action)
      )

      // DELETE SUBSCRIPTION
      .addCase(deleteSubscription.pending, state => {
        state.loading = true;
        state.status = "pending";
        state.errors = null;
      })
      .addCase(deleteSubscription.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.status = "deleted";
        delete state.data[payload.userName!][payload.projectId];
      })
      .addCase(deleteSubscription.rejected, () =>
        location.replace(location.origin)
      );
  }
});

export default subscriptions.reducer;

export const subscriptionSelector = (
  state: RootState,
  props: { organizationId: string; projectId?: string; id?: string }
): Subscription | undefined => {
  return (
    state.subscription?.data?.[props.organizationId]?.[props.projectId!] ||
    state.organizationSubscription?.data?.[props.organizationId]?.[props.id!]
  );
};

export const subscriptionsAllProjectsSelector = createSelector(
  (state: RootState) => {
    const projects = projectsAllSelector(state);
    const organizations = organizationsSelector(state);
    const projectSubscriptionData = projects.map(project => {
      const organisationNameId = getOrganizationDescriptionIdFromProject(
        project,
        organizations
      );
      return {
        organizationNameId: organisationNameId,
        projectId: project?.id,
        subscriptionId: project?.subscription_id
      };
    });

    return projectSubscriptionData.map(value =>
      subscriptionSelector(state, {
        organizationId: value.organizationNameId!,
        projectId: value.projectId,
        id: value.subscriptionId
      })
    );
  },
  subscriptions => subscriptions
);
