import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import styled from "styled-components";

export type { TooltipContentProps as ContentProps } from "@radix-ui/react-tooltip";

const Content = styled(TooltipPrimitive.Content).attrs(props => ({
  side: props.side || "bottom"
}))<{ side: string; $pointerPosition?: string }>`
  z-index: 1003;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  padding: 8px 16px;
  background-color: var(--mode-overrides-tooltip-bg);
  color: var(--theme-neutral-0);
  border-radius: 2px;
  filter: drop-shadow(var(--drop-shadow) var(--drop-shadow-color));

  & span {
    ${({ side, $pointerPosition }) => {
      if ($pointerPosition && ["bottom", "top"].includes(side)) {
        if ($pointerPosition.startsWith("fromLeft=")) {
          const left = $pointerPosition.replace("fromLeft=", "");
          return `left: ${left} !important;`;
        } else if ($pointerPosition.startsWith("fromRight=")) {
          const right = $pointerPosition.replace("fromRight=", "");
          return `
          left: unset !important;
          right: ${right} !important;
          `;
        }
      }
    }}
  }
`;

export default Content;
