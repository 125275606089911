import { useCallback } from "react";

import { organizationSelector } from "Reducers/organization";
import { useAppDispatch, useAppSelector } from "Store/hooks";

import {
  loggedInMemberDataSelector,
  isLoadingLoggedInMemberSelector,
  loggedImMemberErrorSelector
} from "../loggedInMember.selectors";
import { loadLoggedInMember } from "../thunks/loadLoggedInMember";

import type { RootState } from "Store/configureStore";

export const useLoggedInMember = (organizationId: string) => {
  const dispatch = useAppDispatch();
  const memoizedOrganizationSelector = useCallback(
    (state: RootState) => organizationSelector(state, { organizationId }),
    [organizationId]
  );
  const organization = useAppSelector(memoizedOrganizationSelector);
  const isLoading = useAppSelector(isLoadingLoggedInMemberSelector);
  const data = useAppSelector(loggedInMemberDataSelector);
  const error = useAppSelector(loggedImMemberErrorSelector);

  const load = useCallback(() => {
    if (organization?.hasLink("members"))
      dispatch(loadLoggedInMember(organization.id));
  }, [dispatch, organization]);

  return {
    isLoading,
    data,
    load,
    error
  } as const;
};
