import React from "react";

import { VendorLogoContainer } from "./style";

type VendorIconPropType = {
  width?: number;
  height?: number;
  path: string;
};

const VendorIcon = ({ width, path, height }: VendorIconPropType) => (
  <VendorLogoContainer width={width} height={height}>
    <img src={path} alt="logo" />
  </VendorLogoContainer>
);

export default VendorIcon;
