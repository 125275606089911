import getUrl from "Libs/getUrl";

import useDecodedParams from "./useDecodedParams";

import type { GetUrlProps } from "Libs/getUrl";

const useUrls = (
  keys: GetUrlProps["key"] | GetUrlProps["key"][],
  props: GetUrlProps["props"] = {}
) => {
  const urlParams = useDecodedParams();

  const iterableKeys = keys instanceof Array ? keys : [keys];

  const retrievedUrls = iterableKeys.map(key =>
    getUrl({ key, props: { ...urlParams, ...props } })
  );

  return retrievedUrls;
};

export default useUrls;
