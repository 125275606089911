import { apiProxyRequest as request } from "Api/apiProxyRequest";

import type { Projects } from "Api/organizations/member/projectAccess/ProjectAccess.types";

export interface GetMemberProjectAccessParams {
  sort?: "project_title" | "-project_title";
  filter?: { organization_id?: string };
  page?: { size?: number };
}

export const getMemberProjectAccess = (
  userId: string,
  params?: GetMemberProjectAccessParams
) => request<Projects>(`/users/${userId}/project-access`, "GET", params);
