import { apiProxyRequest as request } from "Api/apiProxyRequest";

import type { TeamMember } from "Api/teams/teams.types";

export type ListTeamMembersResponse = {
  items: Array<TeamMember>;
  _links: {
    "ref:users:0": { href: string };
    self: { href: string };
    previous?: { href: string };
    next?: { href: string };
  };
};

export type ListTeamMemberOptions = {
  page?: {
    before?: string;
    after?: string;
    size?: number;
  };
  sort?: "created_at" | "-created_at" | "updated_at" | "-updated_at";
};

export const listTeamMembers = (
  teamId: string,
  options?: ListTeamMemberOptions
) =>
  request<ListTeamMembersResponse>(`/teams/${teamId}/members`, "GET", options);
