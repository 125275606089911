import { createAsyncThunk } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import { loadOneProject, updateProject } from "Reducers/project/project";
import { AsyncThunkOptionType } from "Reducers/types";

export const updateDefaultDomain = createAsyncThunk<
  undefined,
  {
    domainId: string;
    projectId: string;
    organizationId: string;
  },
  AsyncThunkOptionType<{ error: Error }>
>(
  "app/project/domain/default_domain",
  async (
    { domainId, projectId, organizationId },
    { dispatch, rejectWithValue }
  ) => {
    try {
      await dispatch(
        updateProject({
          organizationDescriptionId: organizationId,
          projectDescriptionId: projectId,
          data: { default_domain: domainId }
        })
      );

      await dispatch(
        loadOneProject({
          projectId,
          organizationId
        })
      );
    } catch (err) {
      if (err instanceof Error) {
        logger(
          { errMessage: err.message, domainId },
          { action: "projectDefaultDomain" }
        );

        return rejectWithValue({ error: err });
      }
    }
  }
);
