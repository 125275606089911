import { createAsyncThunk } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import client from "Libs/platform";

import { selectUserProfile } from "../profile.selectors";

import { loadUserProfile } from "./loadUserProfile.thunk";

import type { AsyncThunkOptionType } from "Reducers/types";

export const deleteProfilePicture = createAsyncThunk<
  undefined,
  { username: string },
  AsyncThunkOptionType
>(
  "app/profile/delete-user-picture",
  async ({ username }, { dispatch, getState, rejectWithValue }) => {
    const uuid = selectUserProfile(getState(), username)?.id;
    try {
      if (uuid) {
        await client.deleteProfilePicture(uuid);
        dispatch(loadUserProfile(uuid));
      }
    } catch (error) {
      logger(error as Error, { action: "DELETE_USER_PROFILE_FAILED" });
      return rejectWithValue(error);
    }
  }
);
