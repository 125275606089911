import React from "react";

import Icon from "ds/Icon";
import { WrappingIconProps } from "ds/Icon/Icon";

const User = (props: WrappingIconProps) => {
  return (
    <Icon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5C13.1954 5 14 5.80457 14 7C14 8.19543 13.1954 9 12 9C10.8046 9 10 8.19543 10 7C10 5.80457 10.8046 5 12 5ZM12 3C14.3 3 16 4.7 16 7C16 9.3 14.3 11 12 11C9.7 11 8 9.3 8 7C8 4.7 9.7 3 12 3ZM15.1642 15.4587C16.3433 15.7701 17.3273 16.1945 17.9268 16.6441C16.498 18.0403 14.3332 19 12 19C9.66679 19 7.50197 18.0403 6.07321 16.6441C6.67268 16.1945 7.65669 15.7701 8.83579 15.4587C10.0191 15.1461 11.1965 15 12 15C12.8035 15 13.9809 15.1461 15.1642 15.4587ZM19.2 15.1C20.1 15.8 20.3 17.2 19.4 18C17.6 19.8 14.9 21 12 21C9.10001 21 6.40001 19.8 4.60001 18C3.80001 17.2 3.90001 15.8 4.80001 15.1C6.60001 13.7 10 13 12 13C14 13 17.4 13.7 19.2 15.1Z"
      />
    </Icon>
  );
};

export default User;
