import { useRef, useState, useCallback } from "react";

import { v4 as uuidV4 } from "uuid";

type ObserveCallback = (e: ResizeObserverEntry) => void;
type ObserveSubscription = { callback: ObserveCallback; element: Element };

const observer = new ResizeObserver(entries => {
  for (const entry of entries) {
    const key = entry.target.getAttribute(attribute);

    if (!key) return;

    const subscription = subscriptions.get(key);
    if (!subscription) return;
    subscription.callback(entry);
  }
});

const subscriptions = new Map<string, ObserveSubscription>();
const attribute = "data-overflow-observed";

const unsubscribe = (id: string) => {
  const subscription = subscriptions.get(id);
  if (subscription) {
    observer?.unobserve(subscription.element);
    subscriptions.delete(id);
  }
};

const subscribe = (callback: ObserveCallback, element: Element) => {
  const id = uuidV4();

  element.setAttribute(attribute, id);
  subscriptions.set(id, { callback, element });
  observer.observe(element);

  return id;
};

export const useHasOverflow = () => {
  const id = useRef<string>();
  const [hasOverflow, setHasOverflow] = useState(false);

  const ref = useCallback((element: HTMLElement) => {
    if (id.current && !element) unsubscribe(id.current);

    if (!element) return;

    id.current = subscribe(entry => {
      const scrollWidth = entry.target.scrollWidth;
      setHasOverflow(Math.ceil(entry.contentRect.width) < scrollWidth);
    }, element);
  }, []);

  return [hasOverflow, ref] as const;
};
