import {
  organizationByDescriptionIdSelector,
  organizationSelector
} from "Reducers/organization";
import { useAppSelector } from "Store/hooks";

export const useIsOrganizationSecurityEnabled = (organizationId: string) => {
  const organization = useAppSelector(
    state =>
      organizationSelector(state, { organizationId }) ||
      organizationByDescriptionIdSelector(state, {
        organizationDescriptionId: organizationId
      })
  );

  return [
    !!organization?.hasLink("mfa-enforcement"),
    organization === undefined
  ] as const;
};
