import React, { ReactNode } from "react";

import { useTheme } from "styled-components";

import AppVersion from "Components/AppVersion";
import MaxWidthContainer from "Components/MaxWidthContainer";
import { getFeatureFlag } from "Reducers/featureFlags";

import * as S from "./styles";

export type PageLayoutProps = {
  children: ReactNode;
  footer?: boolean;
  className?: string;
};

const announcementsEnabled = getFeatureFlag("ENABLE_ANNOUNCEMENTS");

const AnnouncementModal = React.lazy(
  () => import("Containers/announcements/Modal")
);

const PageLayout = ({
  className = "",
  children,
  footer = true
}: PageLayoutProps) => {
  const theme = useTheme();

  return (
    <>
      <S.Main
        id="main"
        className={`main theme-${theme.name} ${className}`}
        role="main"
      >
        <MaxWidthContainer>
          {children}
          {announcementsEnabled && <AnnouncementModal />}
        </MaxWidthContainer>
      </S.Main>
      {footer && <AppVersion />}
    </>
  );
};
export default PageLayout;
