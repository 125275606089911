import { createSlice } from "@reduxjs/toolkit";

import { setDeep } from "Libs/objectAccess";
import { SetErrorMessageAction } from "Reducers/types";

import { BackupsActionState, backup } from "./";

const initialState: BackupsActionState = {
  data: {},
  loading: false
};

const backups = createSlice({
  name: "backup",
  initialState,
  reducers: {
    clearBackup: (state, { payload }) => {
      const { organizationId, projectId, environmentId } = payload;
      if (state.data?.[organizationId]?.[projectId]?.[environmentId]) {
        delete state.data[organizationId][projectId][environmentId];
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(backup.pending, state => {
        state.loading = true;
        state.errors = undefined;
      })
      .addCase(backup.fulfilled, (state, { meta, payload }) => {
        const { organizationId, projectId, environmentId } = meta.arg;

        state.loading = false;
        state.errors = undefined;
        setDeep(
          state,
          ["data", organizationId, projectId, environmentId],
          payload
        );
      })
      .addCase(backup.rejected, (state, action: SetErrorMessageAction) => {
        state.loading = false;
        state.errors = { message: action.payload.message };
      });
  }
});

export const { clearBackup } = backups.actions;
export default backups.reducer;
