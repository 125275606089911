import { createAsyncThunk } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import client from "Libs/platform";
import { getEnvironmentId, getProjectId } from "Libs/utils";
import { gitProjectSelector } from "Reducers/project/project";
import { AsyncThunkOptionType } from "Reducers/types";

import { setActivityFilterNames, getActivityTypes } from "../activity.slice";

import type { Activity } from "platformsh-client";

export const loadMoreProjectActivities = createAsyncThunk<
  {
    activities: Activity[];
    hasMore: boolean;
    organizationDescriptionId: string;
    projectDescriptionId: string;
    filters: string[];
  },
  {
    projectDescriptionId: string;
    organizationDescriptionId: string;
    filterNames: string[];
  },
  AsyncThunkOptionType
>(
  `app/activity/project/update`,
  async (
    { organizationDescriptionId, projectDescriptionId, filterNames },
    { dispatch, getState, rejectWithValue }
  ) => {
    const context = "project";

    let loadedActivities = Object.values(
      getState().activity.byProject.data?.[organizationDescriptionId]?.[
        projectDescriptionId
      ] || {}
    );

    const filters = getActivityTypes(filterNames);
    dispatch(setActivityFilterNames({ context, filterNames }));
    let startsAt: boolean | string = false;

    if (filters.length !== 0) {
      loadedActivities = loadedActivities.filter(activity =>
        filters ? filters.indexOf(activity.type) !== -1 : true
      );
    }

    loadedActivities.forEach(activity => {
      if (
        !startsAt ||
        new Date(activity.created_at).getTime() <
          new Date(startsAt as string).getTime()
      )
        startsAt = activity.created_at;
    });
    try {
      const project = gitProjectSelector(getState(), {
        organizationId: organizationDescriptionId,
        projectId: projectDescriptionId
      });

      if (typeof project === "undefined")
        return rejectWithValue("Project not found");

      const startDate = startsAt ? new Date(startsAt) : undefined;
      const activities = await project.getActivities(filters, startDate);

      let hasMore = false;
      if (activities.length > 9) {
        hasMore = true;
        activities.pop();
      }

      return {
        activities,
        hasMore,
        organizationDescriptionId,
        projectDescriptionId,
        filters
      };
    } catch (err: any) {
      if (![404, 403].includes(err.code)) {
        logger(err, {
          action: `app/activity/${context}/update_sucess`,
          meta: {
            organizationDescriptionId,
            projectDescriptionId,
            filters
          }
        });
      }

      return rejectWithValue(err);
    }
  }
);
export const loadMoreEnvironmentActivities = createAsyncThunk<
  {
    activities: Activity[];
    hasMore: boolean;
    organizationDescriptionId: string;
    projectDescriptionId: string;
    environmentDescriptionId: string;
    filters: string[];
  },
  {
    projectDescriptionId: string;
    organizationDescriptionId: string;
    environmentDescriptionId: string;
    filterNames: string[];
  },
  AsyncThunkOptionType
>(
  `app/activity/environment/update`,
  async (
    {
      organizationDescriptionId,
      projectDescriptionId,
      environmentDescriptionId,
      filterNames
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    const context = "environment";

    let loadedActivities = Object.values(
      getState().activity.byEnvironment.data[organizationDescriptionId]?.[
        projectDescriptionId
      ]?.[environmentDescriptionId] || {}
    );

    const filters = getActivityTypes(filterNames);
    dispatch(setActivityFilterNames({ context, filterNames }));
    let startsAt: boolean | string = false;

    if (filters.length !== 0) {
      loadedActivities = loadedActivities.filter(activity =>
        filters ? filters.indexOf(activity.type) !== -1 : true
      );
    }

    loadedActivities.forEach(activity => {
      if (
        !startsAt ||
        new Date(activity.created_at).getTime() <
          new Date(startsAt as string).getTime()
      )
        startsAt = activity.created_at;
    });
    try {
      const id = getEnvironmentId(
        getState,
        organizationDescriptionId,
        projectDescriptionId,
        environmentDescriptionId
      );
      const startDate = startsAt ? new Date(startsAt) : undefined;
      const activities =
        context === "environment"
          ? await client.getEnvironmentActivities(
              getProjectId(getState, projectDescriptionId)!,
              encodeURIComponent(id ?? ""),
              filters && filters[0] !== "all_type" ? filters : "",
              startDate
            )
          : [];

      let hasMore = false;
      if (activities.length > 9) {
        hasMore = true;
        activities.pop();
      }

      return {
        activities,
        hasMore,
        organizationDescriptionId,
        projectDescriptionId,
        environmentDescriptionId,
        filters
      };
    } catch (err: any) {
      if (![404, 403].includes(err.code)) {
        logger(err, {
          action: `app/activity/${context}/update_sucess`,
          meta: {
            organizationDescriptionId,
            projectDescriptionId,
            environmentDescriptionId,
            filters
          }
        });
      }

      return rejectWithValue(err);
    }
  }
);

export const loadMoreIntegrationActivities = createAsyncThunk<
  {
    activities: Activity[];
    hasMore: boolean;
    organizationDescriptionId: string;
    projectDescriptionId: string;
    integrationDescriptionId: string;
    filters: string[];
  },
  {
    projectDescriptionId: string;
    organizationDescriptionId: string;
    integrationDescriptionId: string;
    filterNames: string[];
  },
  AsyncThunkOptionType
>(
  `app/activity/integration/update`,
  async (
    {
      organizationDescriptionId,
      projectDescriptionId,
      integrationDescriptionId,
      filterNames
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    const context = "integration";

    let loadedActivities = Object.values(
      getState().activity.byIntegration.data?.[organizationDescriptionId]?.[
        projectDescriptionId
      ]?.[integrationDescriptionId] || {}
    );

    const filters = getActivityTypes(filterNames);
    dispatch(setActivityFilterNames({ context, filterNames }));
    let startsAt: boolean | string = false;

    if (filters.length !== 0) {
      loadedActivities = loadedActivities.filter(activity =>
        filters ? filters.indexOf(activity.type) !== -1 : true
      );
    }

    loadedActivities.forEach(activity => {
      if (
        !startsAt ||
        new Date(activity.created_at).getTime() <
          new Date(startsAt as string).getTime()
      )
        startsAt = activity.created_at;
    });
    try {
      const startDate = startsAt ? new Date(startsAt) : undefined;
      const activities = await client.getIntegrationActivities(
        context === "integration"
          ? projectDescriptionId
          : getProjectId(getState, projectDescriptionId)!,
        encodeURIComponent(integrationDescriptionId ?? ""),
        filters && filters[0] !== "all_type" ? filters : "",
        startDate
      );

      let hasMore = false;
      if (activities.length > 9) {
        hasMore = true;
        activities.pop();
      }

      return {
        activities,
        hasMore,
        organizationDescriptionId,
        projectDescriptionId,
        integrationDescriptionId,
        filters
      };
    } catch (err: any) {
      if (![404, 403].includes(err.code)) {
        logger(err, {
          action: `app/activity/${context}/update_sucess`,
          meta: {
            organizationDescriptionId,
            projectDescriptionId,
            integrationDescriptionId,
            filters
          }
        });
      }

      return rejectWithValue(err);
    }
  }
);
