import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "Store/configureStore";

export const teamStateSelector = (state: RootState) => state.team;

export const teamStateByIdSelector = createSelector(
  teamStateSelector,
  (_: RootState, { teamId }: { teamId: string }) => teamId,
  (teamState, teamId) => teamState.data[teamId]
);

export const teamDataByIdSelector = createSelector(
  teamStateByIdSelector,
  teamState => teamState?.data
);

export const teamIsLoadingByIdSelector = createSelector(
  teamStateByIdSelector,
  teamState => teamState?.isLoading
);

export const teamErrorByIdSelector = createSelector(
  teamStateByIdSelector,
  teamState => teamState?.error
);
