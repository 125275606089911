import styled from "styled-components";

import * as Typography from "ds/Typography";

export const Description = styled(Typography.BodyText).attrs(() => ({
  size: "small",
  as: "div"
}))``;

export default Description;
