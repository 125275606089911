import React from "react";

import Icon from "ds/Icon";
import { WrappingIconProps } from "ds/Icon/Icon";

const InfoSm = (props: WrappingIconProps) => {
  return (
    <Icon {...props}>
      <path d="M12 4C16.4 4 20 7.6 20 12C20 16.4 16.4 20 12 20C7.6 20 4 16.4 4 12C4 7.6 7.6 4 12 4ZM12 11C11.4 11 11 11.4 11 12V16C11 16.6 11.4 17 12 17C12.6 17 13 16.6 13 16V12C13 11.4 12.6 11 12 11ZM12 8C11.4 8 11 8.4 11 9C11 9.6 11.4 10 12 10C12.6 10 13 9.6 13 9C13 8.4 12.6 8 12 8Z" />
    </Icon>
  );
};

export default InfoSm;
