import { createIntl, createIntlCache, IntlShape } from "react-intl";

import { getUserLanguage } from "Libs/utils";
import * as defaultLocales from "src/locales/";
import * as vendorLocales from "src/vendor_resources/locales/";

let intl: IntlShape | undefined;

export const getIntl = () => {
  if (typeof intl === "undefined") {
    // This is optional but highly recommended
    // since it prevents memory leak
    const cache = createIntlCache();

    const { language, languageWithoutRegionCode } = getUserLanguage();
    const DEFAULT_LOCALE = "en";
    const currentLocale = vendorLocales[languageWithoutRegionCode]
      ? languageWithoutRegionCode
      : DEFAULT_LOCALE;

    const defaultMessages: Record<string, string> = {
      ...defaultLocales[currentLocale],
      ...defaultLocales.links
    };

    let vendorMessages: Record<string, string> = {};
    ["links", currentLocale].forEach(key => {
      if (vendorLocales[key])
        vendorMessages = { ...vendorMessages, ...vendorLocales[key] };
    });

    intl = createIntl(
      {
        locale: language,
        messages: {
          ...defaultMessages,
          ...vendorMessages
        },
        wrapRichTextChunksInFragment: true
      },
      cache
    );
  }

  return intl;
};
