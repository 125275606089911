import styled from "styled-components";

import Search, { Icon } from "ds/Search";
import { Search as SearchPrimitive } from "ds/Search/Search.styles";

export const SearchInput = styled(Search)`
  padding-left: 8px;
  svg:last-child {
    display: none;
  }
  border-bottom: none;
  input {
    caret-color: white;
    color: white;
  }
  svg:hover {
    cursor: default;
  }

  :has(${SearchPrimitive}:hover) {
    ${Icon} {
      fill: var(--theme-neutral-0);
    }
  }

  ${SearchPrimitive} {
    &::placeholder {
      font-size: 14px;
      color: var(--theme-neutral-600);
      font-family: "Open Sans", Helvetica, Arial, sans-serif;
    }

    &:hover {
      &::placeholder {
        color: var(--theme-neutral-0);
      }
    }

    &:focus {
      &::placeholder {
        color: (--mode-vector-neutral-default);
      }
    }
  }

  ${Icon} {
    fill: var(--mode-vector-neutral-default);
  }
`;
