import React from "react";

import * as S from "./Item.styles";

import type { DropdownMenuItemProps } from "@radix-ui/react-dropdown-menu";

interface ItemProps extends DropdownMenuItemProps {
  analyticId?: string;
  ref?: React.ForwardedRef<HTMLDivElement>;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  isSelected?: boolean;
}

const Item = ({ onClick, analyticId, children, ...props }: ItemProps) => {
  return (
    <S.Item
      data-heap-identifier={analyticId}
      onClick={props?.disabled ? undefined : onClick}
      {...props}
    >
      {children}
    </S.Item>
  );
};

export default Item;
