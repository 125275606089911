import React, { memo } from "react";

import { Provider, Viewport } from "ds/Toast";
import { useAppSelector } from "Store/hooks";

import Notification from "./Notification";
import { selectNotifications } from "./Notifications.slice";
import useNotification from "./useNotification";

const Notifications = () => {
  const notifications = useAppSelector(selectNotifications);
  const { remove } = useNotification();

  return (
    <Provider>
      {notifications.map(notification => (
        <Notification
          key={notification.id}
          onRemove={remove}
          {...notification}
        />
      ))}
      <Viewport />
    </Provider>
  );
};

export default memo(Notifications);
