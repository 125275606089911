import React, { memo } from "react";

import * as Toast from "ds/Toast";

import * as S from "./Notification.styles";

import type { Notification, NotificationId } from "./Notifications.types";

export interface NotificationProps extends Notification {
  onRemove: (id: NotificationId) => void;
}

const ToastNotification = ({
  id,
  flaggedForRemoval,
  priority,
  link,
  onRemove,
  title,
  description
}: NotificationProps) => (
  <S.Root duration={Infinity} $animateOut={!!flaggedForRemoval}>
    <Toast.Icon variant={priority} />
    <Toast.Content>
      {title && <Toast.Title>{title}</Toast.Title>}
      {description && <Toast.Description>{description}</Toast.Description>}
      <Toast.Close aria-label="close" onClick={() => onRemove(id)} />
      {link && <Toast.Link {...link.location}>{link.title}</Toast.Link>}
    </Toast.Content>
  </S.Root>
);

export default memo(ToastNotification);
