import React from "react";

import { useNavigate, useLocation } from "react-router-dom";

import PageLayout from "Components/PageLayout";
import NavBar from "Containers/NavBar";
import PluginApp from "src/organization/pages/project/app/Page";

import { Layout } from "./Routes.styles";

const Routes = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <>
      <NavBar
        push={navigate}
        role="navigation"
        currentPathName={pathname}
        fixed={true}
      />
      <PageLayout>
        <Layout>
          <PluginApp route="/" type="page" />
        </Layout>
      </PageLayout>
    </>
  );
};

export default Routes;
