import React from "react";

import { string } from "prop-types";
import { useParams } from "react-router-dom";

import Context from "../components/Context";

const PluginApp = ({ route, type, ...props }) => {
  const { appId } = useParams();
  const apps = JSON.parse(localStorage.getItem("plugin-apps")) || {};
  if (!apps?.installed) {
    return null;
  }

  const app = apps.installed[appId];

  if (!app) {
    return null;
  }
  const hook = app.hooks.find(h => h.route === route && h.type === type);
  if (!hook) {
    return null;
  }

  return <Context manifestPath={app._self} {...props} />;
};

PluginApp.propTypes = {
  route: string.isRequired,
  type: string.isRequired
};

export default PluginApp;
