import { default as React, useEffect, useState } from "react";

import { useIntl } from "react-intl";

import { CloseButton } from "ds/Button";
import Tag from "ds/Tag";
import {
  BANNER_NAME,
  registerBanner,
  setBannerVisible
} from "Reducers/banners";
import { useTrialInfo } from "Reducers/organization/hooks/useTrialnfo";
import { useAppDispatch } from "Store/hooks";

import * as S from "./sellableBanners.styles";

export type BannerContainerProps = {
  children: React.ReactNode;
  size: "large" | "medium" | "small";
  isOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  closeable?: boolean;
  bannerName: BANNER_NAME;
  freeOnTrial?: boolean;
};

const Container = {
  large: S.ContainerLarge,
  medium: S.ContainerMedium,
  small: S.ContainerSmall
};

const BannerContainer = (props: BannerContainerProps) => {
  const {
    onOpenChange,
    isOpen,
    closeable = true,
    size = "large",
    bannerName,
    children,
    freeOnTrial
  } = props;
  const [isVisible, setIsVisible] = useState(!!isOpen);
  const dispatch = useAppDispatch();
  const intl = useIntl();

  useEffect(() => {
    setIsVisible(!!isOpen);
  }, [isOpen]);

  useEffect(() => {
    dispatch(setBannerVisible({ name: bannerName, isVisible }));
  }, [bannerName, isVisible, dispatch]);

  useEffect(() => {
    dispatch(registerBanner({ name: bannerName }));
  }, [bannerName, dispatch]);

  const { isTrial } = useTrialInfo();
  const showTrialBanner = freeOnTrial && isTrial;

  const smallSize = size === "small";
  const Wrapper = Container[size];
  return isOpen ? (
    <Wrapper>
      <S.Content small={smallSize}>
        {showTrialBanner && (
          <Tag variant="rose">
            {intl.formatMessage({ id: "free_during_trial" })}
          </Tag>
        )}
        <div>{children} </div>
      </S.Content>
      {closeable && (
        <S.CloseWrapper
          small={smallSize}
          onClick={() => {
            onOpenChange?.(false);
            setIsVisible(false);
          }}
        >
          <CloseButton />
        </S.CloseWrapper>
      )}
    </Wrapper>
  ) : null;
};

export default BannerContainer;
