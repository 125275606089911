import { useCallback } from "react";

import { organizationByDescriptionIdSelector } from "Reducers/organization";
import { useAppDispatch, useAppSelector } from "Store/hooks";

import {
  getOrganizationProfile,
  organizationProfileSelector,
  selectIsLoadingOrganizationProfile
} from "../profile";

export const useOrganizationProfile = (organizationName: string) => {
  const dispatch = useAppDispatch();
  const organization = useAppSelector(state =>
    organizationByDescriptionIdSelector(state, {
      organizationDescriptionId: organizationName
    })
  );

  const isLoading = useAppSelector(state =>
    selectIsLoadingOrganizationProfile(state, organizationName)
  );
  const organizationProfile = useAppSelector(state =>
    organizationProfileSelector(state, { organizationId: organizationName })
  );

  const load = useCallback(() => {
    if (organization)
      dispatch(getOrganizationProfile({ organizationId: organization.name }));
  }, [organization, dispatch]);

  return { organizationProfile, isLoading, load };
};
