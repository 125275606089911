import React from "react";

import useMediaQuery from "Hooks/useMediaQuery";

import Button, { ButtonProps } from "./Button";

export type ActionButtonProps = ButtonProps & {
  content?: never;
  /** A valid media query which will decide when to display the text */
  expandWhen: string;
};

/**
 * The action button displays an icon button until the provided media query
 * returns true, then will display the children next to the icon, behaving like
 * a mixed content button.
 */
const ActionButton = ({
  expandWhen,
  children,
  ...props
}: ActionButtonProps) => {
  const content = useMediaQuery(expandWhen) ? "mixed" : "icon";

  const childrenArray = React.Children.toArray(children);

  // Assume the first child is going to be the icon
  const icon = childrenArray[0];
  // Assume the rest will be the text
  const text = childrenArray.slice(1);

  return (
    <Button
      {...props}
      content={content}
      onClick={event => {
        if (props.onClick) {
          props.onClick(event);
        }

        if (elementHasBlur(document.activeElement)) {
          document.activeElement.blur();
        }
      }}
    >
      {icon}
      {content === "mixed" && text}
    </Button>
  );
};

const elementHasBlur = (
  element: any
): element is HTMLButtonElement | HTMLAnchorElement =>
  typeof element.blur === "function";

export default ActionButton;
