import React, { useCallback } from "react";

import { useIntl } from "react-intl";

import NewProjectButton from "Components/NewProjectButton";
import { search, selectSearchQuery } from "Reducers/app/search";
import { useAppDispatch, useAppSelector } from "Store/hooks";

import FloatBox from "../FloatBox";

import * as S from "./styles";

type Props = {
  organizationId: string;
};

const Input = React.memo(function Input() {
  const dispatch = useAppDispatch();
  const query = useAppSelector(selectSearchQuery);
  const intl = useIntl();

  const onChangeSearch = useCallback(
    (value: string) => {
      dispatch(search(value));
    },
    [dispatch]
  );

  return (
    <S.SearchInput
      id="project-search-input"
      placeholder={intl.formatMessage({
        id: "dashboard.search_placeholder"
      })}
      customWidth="80%"
      onChange={onChangeSearch}
      value={query}
    />
  );
});

const SearchBar = ({ organizationId }: Props) => (
  <FloatBox>
    <Input />
    <NewProjectButton organizationId={organizationId} />
  </FloatBox>
);

export default SearchBar;
