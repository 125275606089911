import { listTeams, ListTeamsOptions } from "Api/teams/listTeams.request";
import { paginatedEndpointSliceFactory } from "Reducers/sliceFactory";

type Options = {
  organizationId: string;
  options?: ListTeamsOptions;
};

const { slice, hooks, selectors } = paginatedEndpointSliceFactory(
  "teamList",
  (options: Options) =>
    listTeams({
      ...options.options,
      filter: {
        organization_id: options.organizationId,
        ...(options?.options?.filter || {})
      }
    }),
  options => options.organizationId,
  async response => response
);

export const teamListReducer = slice.reducer;

export const {
  useDataByKey: useTeams,
  useAllData: useAllTeams,
  useInvalidateKey: useInvalidateTeams
} = hooks;

export const teamsCountSelector = selectors.selectByKey(
  state => state?.data?.count
);
