import React from "react";

import Icon from "ds/Icon";
import { WrappingIconProps } from "ds/Icon/Icon";

const Backup = (props: WrappingIconProps) => {
  return (
    <Icon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 13H20C20.55 13 21 13.45 21 14V20C21 20.55 20.55 21 20 21H4C3.45 21 3 20.55 3 20V14C3 13.45 3.45 13 4 13ZM5 17C5 18.1 5.9 19 7 19C8.1 19 9 18.1 9 17C9 15.9 8.1 15 7 15C5.9 15 5 15.9 5 17ZM4 3H20C20.55 3 21 3.45 21 4V10C21 10.55 20.55 11 20 11H4C3.45 11 3 10.55 3 10V4C3 3.45 3.45 3 4 3ZM5 7C5 8.1 5.9 9 7 9C8.1 9 9 8.1 9 7C9 5.9 8.1 5 7 5C5.9 5 5 5.9 5 7Z"
      />
    </Icon>
  );
};

export default Backup;
