import React from "react";

import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import config from "console_config";
import { getSupportUrl } from "Libs/getUrl/getUrl";
import { selectCurrentUserUsername } from "Reducers/app/selectors";

const SupportRedirect = () => {
  const username = useSelector(selectCurrentUserUsername);
  return (
    <Navigate
      to={getSupportUrl({ accountUrl: config.URL_ACCOUNTS, username })}
    />
  );
};

export default SupportRedirect;
