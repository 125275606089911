import React from "react";

import { createBrowserRouter, RouteObject } from "react-router-dom";

import config from "console_config";

const errorNotFound = {
  path: "*",
  Component: React.lazy(
    () => import("src/root/pages/auth/pages/error/NotFound")
  )
};

const registrationRoutes: Array<RouteObject> = config.ENABLE_REGISTRATION
  ? [
      {
        path: "email-confirmation-error",
        Component: React.lazy(
          () => import("src/root/pages/auth/pages/login/EmailLinkError")
        )
      },
      {
        path: "register/*",
        children: [
          {
            path: "error",
            Component: React.lazy(
              () => import("src/root/pages/auth/pages/registration/Error")
            )
          },
          {
            path: "form/:step?",
            Component: config.ENABLE_SINGLE_STEP_SINGUP_FLOW
              ? React.lazy(
                  () =>
                    import(
                      "src/root/pages/auth/pages/registration/steps/welcome/Welcome"
                    )
                )
              : React.lazy(
                  () => import("src/root/pages/auth/pages/registration/Form")
                )
          },
          {
            path: "",
            Component: React.lazy(
              () => import("src/root/pages/auth/pages/registration/init")
            )
          },
          errorNotFound
        ]
      },
      {
        path: "reset-password/link-error",
        Component: React.lazy(
          () => import("src/root/pages/auth/pages/password/Error")
        )
      },
      {
        path: "reset-password/*",
        Component: React.lazy(
          () => import("src/root/pages/auth/pages/password/index")
        ),
        children: [
          {
            path: "new",
            Component: React.lazy(
              () => import("src/root/pages/auth/pages/password/NewPasswordForm")
            )
          },
          {
            path: "success",
            Component: React.lazy(
              () =>
                import("src/root/pages/auth/pages/password/NewPasswordSuccess")
            )
          },
          {
            path: "check-inbox",
            Component: React.lazy(
              () =>
                import(
                  "src/root/pages/auth/pages/password/NewPasswordCheckInbox"
                )
            )
          },
          {
            path: "",
            Component: React.lazy(
              () => import("src/root/pages/auth/pages/password/Form")
            )
          },
          errorNotFound
        ]
      },
      {
        path: "",
        Component: React.lazy(
          () => import("src/root/pages/auth/pages/login/index")
        ),
        children: [
          {
            path: "tfa",
            Component: React.lazy(
              () => import("src/root/pages/auth/pages/login/Tfa")
            )
          },
          {
            path: "recover",
            Component: React.lazy(
              () => import("src/root/pages/auth/pages/login/Recover")
            )
          },
          {
            path: "",
            Component: React.lazy(
              () => import("src/root/pages/auth/pages/login/Form")
            )
          },
          errorNotFound
        ]
      }
    ]
  : [];

const ssoRoutes: Array<RouteObject> = config.ENABLE_SSO
  ? [
      {
        path: "sso/forgot-password-error",
        Component: React.lazy(
          () => import("src/root/pages/auth/pages/sso/ResetPasswordError")
        )
      },
      {
        path: "sso/signup",
        Component: React.lazy(
          () => import("src/root/pages/auth/pages/sso/signup")
        )
      },
      {
        path: "sso/*",
        Component: React.lazy(
          () => import("src/root/pages/auth/pages/sso/login/index")
        ),
        children: [
          {
            path: "tfa",
            Component: React.lazy(
              () => import("src/root/pages/auth/pages/sso/login/Tfa")
            )
          },
          {
            path: "recover",
            Component: React.lazy(
              () => import("src/root/pages/auth/pages/sso/login/Recover")
            )
          },
          errorNotFound
        ]
      }
    ]
  : [];

const routes = createBrowserRouter([
  {
    path: "/*",
    Component: React.lazy(() => import("src/root/pages/auth/Page")),
    children: [
      {
        path: "not-found",
        Component: React.lazy(
          () => import("src/root/pages/auth/pages/error/NotFound")
        )
      },
      {
        path: "error",
        Component: React.lazy(
          () => import("src/root/pages/auth/pages/error/Custom")
        )
      },
      {
        path: "invitation-error",
        Component: React.lazy(
          () => import("src/root/pages/auth/pages/error/Invitation")
        )
      },
      {
        path: "internal-server-error",
        Component: React.lazy(
          () => import("src/root/pages/auth/pages/error/InternalServer")
        )
      },
      {
        path: "email-confirmation",
        Component: React.lazy(
          () =>
            import("src/root/pages/auth/pages/registration/EmailConfirmation")
        )
      },
      {
        path: "consent",
        Component: React.lazy(
          () => import("src/root/pages/auth/pages/consent/Consent")
        )
      },
      {
        path: "country",
        Component: React.lazy(
          () => import("src/root/pages/auth/pages/country/index")
        )
      },
      ...ssoRoutes,
      ...registrationRoutes,
      errorNotFound
    ]
  }
]);

export default routes;
