import styled from "styled-components";

export interface BodyTextProps {
  size?: "extraSmall" | "small" | "medium";
  weight?: "bold" | "semiBold" | "regular";
  $color?: "granite" | "ebony-light" | "slate" | "ebony";
}

export const BodyText = styled.p<BodyTextProps>`
  font-family: "Open Sans";

  ${({ $color }) => {
    switch ($color) {
      case "granite":
        return "color:var(--section-granite-color,var(--granite))";
      case "slate":
        return "color:var(--section-slate-color,var(--slate))";
      case "ebony":
        return "color:var(--section-ebony-color,var(--ebony))";
      case "ebony-light":
        return "color:var(--section-granite-color,var(--granite))";
      default:
        return "color:var(--section-ebony-light-color,var(--ebony-light))";
    }
  }};

  line-height: 24px;
  margin: 0;

  font-size: ${({ size }) => {
    switch (size) {
      case "extraSmall":
        return "13px";
      case "small":
        return "14px";
      case "medium":
      default:
        return "15px";
    }
  }};

  font-weight: ${({ weight }) => {
    switch (weight) {
      case "bold":
        return "700";
      case "semiBold":
        return "600";
      case "regular":
      default:
        return "400";
    }
  }};
`;

export default BodyText;
