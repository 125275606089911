import React, { useEffect } from "react";

export function useClickOutside(
  ref: React.RefObject<HTMLElement> | undefined,
  callback: (e) => void
) {
  const handleClick = e => {
    if (ref?.current && !ref.current.contains(e.target)) {
      callback(e);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
}
