const links = {
  "links.CLI_repository": "https://docs.upsun.com/administration/cli.html",
  "links.website": "https://upsun.com",
  "links.pricing": "https://upsun.com/pricing/",
  "links.faq": "https://upsun.com/faq/",
  "links.blog.first_project_incentive":
    "https://upsun.com/blog/first-project-incentive/",
  "links.faq.whats-the-upsun-first-project-incentive":
    "https://upsun.com/faq/#whats-the-upsun-first-project-incentive",
  "links.subscribe.pricing": "https://upsun.com/pricing/",
  "links.log_forwarding_documentation":
    "https://docs.upsun.com/anchors/observability/logs/forward-logs.html",
  "links.blackfire.pricing": "https://www.blackfire.io/pricing",
  "links.documentation.create_apps": "https://docs.upsun.com/create-apps.html",
  "links.documentation.create_config":
    "https://docs.upsun.com/create-apps.html",
  "links.documentation.build_deploy":
    "https://docs.upsun.com/learn/overview/build-deploy.html",
  "links.documentation.deploy.commit":
    "https://docs.upsun.com/get-started.html",
  "links.documentation.define_routes":
    "https://docs.upsun.com/anchors/routes.html",
  "links.documentation.teams":
    "https://docs.upsun.com/anchors/admin/users/teams.html",
  "links.documentation.access": "https://docs.upsun.com/anchors/access.html",
  "links.documentation.admin_backups":
    "https://docs.upsun.com/anchors/admin/backups.html",
  "links.documentation.paused_environment":
    "https://docs.upsun.com/anchors/environments/paused.html",
  "links.documentation.active_environment":
    "https://docs.upsun.com/anchors/environments/active.html",
  "links.documentation.backups_schedule":
    "https://docs.upsun.com/anchors/backups/schedule.html",
  "links.documentation.container_configuration":
    "https://docs.upsun.com/anchors/config/apps.html",
  "links.documentation.CLI_API_token":
    "https://docs.upsun.com/anchors/cli/tokens.html",
  "links.documentation.availability_incident_handling_procedure":
    "https://docs.platform.sh/dedicated/overview/monitoring.html#availability-incident-handling-procedure",
  "links.documentation.getting_started_CLI":
    "https://docs.upsun.com/anchors/cli.html",
  "links.documentation.CLI":
    "https://docs.upsun.com/administration/cli.html#cli-command-line-interface",
  "links.documentation.app.variables":
    "https://docs.upsun.com/create-apps/app-reference/single-runtime-image.html#variables",
  "links.documentation.add_ssh_keys":
    "https://docs.upsun.com/anchors/ssh/add-key.html",
  "links.documentation.create_new_project":
    "https://docs.upsun.com/anchors/create-project.html",
  "links.documentation.deploy_key":
    "https://docs.upsun.com/anchors/deploy-key.html",
  "links.documentation.dedicated_cluster":
    "https://docs.platform.sh/dedicated/overview.html",
  "links.documentation.dns": "https://docs.upsun.com/anchors/dns.html",
  "links.documentation.download_code":
    "https://docs.upsun.com/anchors/download-code.html",
  "links.documentation.domain": "https://docs.upsun.com/anchors/domain.html",
  "links.documentation.subdomain":
    "https://docs.upsun.com/anchors/subdomains.html",
  "links.documentation.own_code_project_configuration":
    "https://docs.upsun.com/anchors/project-configuration.html",
  "links.documentation.getting_started":
    "https://docs.upsun.com/anchors/getting-started.html",
  "links.documentation.inactive_environment":
    "https://docs.upsun.com/anchors/environments/inactive.html",
  "links.documentation.paused_crons":
    "https://docs.upsun.com/anchors/crons/paused.html",
  "links.documentation.going_live":
    "https://docs.upsun.com/anchors/go-live.html",
  "links.documentation.mysql_import_data":
    "https://docs.upsun.com/anchors/import-data/mysql.html",
  "links.documentation.postgres_import_data":
    "https://docs.upsun.com/anchors/import-data/postgresql.html",
  "links.documentation.integrations":
    "https://docs.upsun.com/anchors/integrations.html",
  "links.documentation.migrating_tutorial":
    "https://docs.upsun.com/anchors/migrating.html",
  "links.documentation.set_domain":
    "https://docs.upsun.com/anchors/domains.html",
  "links.documentation.pricing": "https://docs.upsun.com/anchors/pricing.html",
  "links.documentation.routes": "https://docs.upsun.com/anchors/routes.html",
  "links.documentation.routes_SSI":
    "https://docs.upsun.com/anchors/routes/ssi.html",
  "links.documentation.services":
    "https://docs.upsun.com/anchors/services.html",
  "links.documentation.SSH_keys":
    "https://docs.upsun.com/anchors/ssh/keys.html",
  "links.documentation.variables":
    "https://docs.upsun.com/anchors/variables.html",
  "links.documentation.variables.run_and_build_time":
    "https://docs.upsun.com/anchors/variables/availability.html",
  "links.documentation.yaml_configuration":
    "https://docs.upsun.com/anchors/configuration/yaml.html",
  "links.documentation.app.storage_mounts":
    "https://docs.upsun.com/anchors/apps/mounts.html",
  "links.documentation.app": "https://docs.upsun.com/anchors/apps.html",
  "links.documentation.backups": "https://docs.upsun.com/anchors/backups.html",
  "links.documentation.users":
    "https://docs.upsun.com/anchors/admin/users.html",
  "links.documentation.WCAG": "https://platform.sh/wcag/",
  "links.documentation.metric":
    "https://docs.upsun.com/anchors/observability/metrics.html",
  "links.documentation.metrics":
    "https://docs.upsun.com/anchors/observability/metrics.html",
  "links.documentation.metrics.grid":
    "https://docs.upsun.com/anchors/observability/metrics/grid.html",
  "links.documentation.metrics.dedicated":
    "https://docs.platform.sh/increase-observability/metrics/dedicated.html",
  "links.documentation.metrics.dedication":
    "https://docs.platform.sh/increase-observability/metrics/dedicated-generation-3.html",
  "links.documentation.integrations.activity":
    "https://docs.upsun.com/integrations/activity.html",
  "links.documentation.integrations.activity_scripts":
    "https://docs.upsun.com/anchors/integrations/activity-scripts.html",
  "links.documentation.integrations.source":
    "https://docs.upsun.com/anchors/integrations/source.html",
  "links.documentation.app.timezone":
    "https://docs.upsun.com/anchors/apps/timezone.html",
  "links.documentation.organizations":
    "https://docs.upsun.com/anchors/organizations.html",
  "links.documentation.redeploy":
    "https://docs.upsun.com/anchors/redeploy.html",
  "links.documentation.SSH":
    "https://docs.upsun.com/anchors/ssh/authenticate.html",
  "links.documentation.workers":
    "https://docs.upsun.com/anchors/apps/workers.html",
  "links.documentation.source_operations":
    "https://docs.upsun.com/anchors/apps/source-ops.html",
  "links.documentation.runtime_operations":
    "https://docs.upsun.com/anchors/apps/runtime-ops.html",
  "links.documentation.live_backups":
    "https://docs.upsun.com/anchors/live-backups.html",
  "links.documentation.automated_updates":
    "https://docs.upsun.com/anchors/automated-updates.html",
  "links.documentation.trustcenter":
    "https://docs.upsun.com/anchors/trust-center.html",
  "links.documentation.configure_project.certificates":
    "https://docs.upsun.com/anchors/certificates.html",
  "links.documentation.environments.default_environment":
    "https://docs.upsun.com/anchors/environments/default.html",
  "links.documentation.organizations.manage-your-organization-users":
    "https://docs.upsun.com/anchors/admin/users/org.html",
  "links.documentation.flexible_resources":
    "https://docs.upsun.com/manage-resources.html",
  "links.documentation.environments.cancel_activity":
    "https://docs.upsun.com/anchors/environments/cancel-activity.html",
  "links.documentation.administration.servers":
    "https://docs.upsun.com/anchors/admin/servers.html",
  "links.documentation.scaling": "https://docs.upsun.com/manage-resources.html",
  "links.documentation.configure_project":
    "https://docs.upsun.com/anchors/project-configuration.html",
  "links.documentation.resources":
    "https://docs.upsun.com/manage-resources.html",
  "links.resources.green":
    "https://docs.upsun.com/anchors/resources/green.html",
  "link.application_metrics_increase_observability":
    "https://docs.upsun.com/increase-observability/application-metrics.html",
  "links.documentation.resources.configuration":
    "https://docs.upsun.com/anchors/resources/configuration.html",
  "links.documentation.resources.configuration.advanced_container_profiles":
    "https://docs.upsun.com/anchors/resources/configuration.html#advanced-container-profiles",
  "links.documentation.manage_resources.environment.merge":
    "https://docs.upsun.com/manage-resources.html#environment-merge",
  "links.documentation.manage_resources.environment.sync":
    "https://docs.upsun.com/manage-resources.html#environment-sync",
  "links.documentation.manage_resources.horizontal_scaling":
    "https://docs.upsun.com/anchors/resources/configuration.html#horizontal-scaling",
  "links.documentation.manage_resources_resource.init":
    "https://docs.upsun.com/manage-resources/resource-init.html",
  "links.manage.resources.init.define.strategy":
    "https://docs.upsun.com/manage-resources/resource-init.html#define-a-resource-initialization-strategy",
  "links.manage.resources.init":
    "https://docs.upsun.com/manage-resources/resource-init.html",
  "links.manage.resources.init.min.resources":
    "https://docs.upsun.com/manage-resources/resource-init.html#minimum-resources",
  "links.manage.resources.adjust.resources":
    "https://docs.upsun.com/manage-resources/adjust-resources.html",
  "links.documentation.overview": "https://docs.upsun.com/learn/overview.html",
  "links.documentation.add_services":
    "https://docs.upsun.com/add-services.html",
  "links.documentation.env_vars_options":
    "https://docs.upsun.com/development/variables/set-variables.html#environment-variable-options",
  "links.documentation.file_transfer":
    "https://docs.upsun.com/development/file-transfer.html",
  "links.documentation.hooks":
    "https://docs.upsun.com/development/troubleshoot.html#build-and-deploy-hooks",
  "links.blackfire.connect": "https://blackfire.io/session/connect/upsun",
  "links.documentation.integrations.github":
    "https://docs.upsun.com/integrations/source/github.html",
  "links.documentation.understanding_metrics":
    "https://docs.upsun.com/increase-observability/metrics/understand-metrics.html",
  "links.submit_idea": "https://next.platform.sh/tabs/6-launched/submit-idea",
  "links.discord": "https://discord.gg/PkMc2pVCDV",
  "links.documentation.environments.backup":
    "https://docs.upsun.com/environments/backup.html",
  "links.documentation.environments.backup.manual_backups":
    "https://docs.upsun.com/environments/backup.html#manual-backups",
  "links.documentation.environments.backup.set_backup_limit":
    "https://docs.upsun.com/environments/backup.html#set-a-limit-for-backups",
  "links.documentation.environments.backup.multiple_automated_schedules":
    "https://docs.upsun.com/environments/backup.html#configure-multiple-automated-backup-schedules",
  "links.documentation.settings.variables.variable.prefix":
    "https://docs.upsun.com/development/variables.html#variable-prefixes",
};

export default links;
