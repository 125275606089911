import styled from "styled-components";

import SubNavBar from "Components/SubNavBar";
import * as SNavBar from "Components/SubNavBar/styles";

const DropdownTab = styled.div`
  display: flex;
  font-weight: 400;
  text-transform: capitalize;
  a:not(.button) {
    text-transform: uppercase;
    font-size: 15px !important;
    height: 48px !important;
    text-decoration: none;
    color: var(--link-ebony-light-color, var(--ebony-light));
    &.active,
    &:hover {
      color: var(
        --link-color-primary-800-color,
        var(--color-primary-800)
      ) !important;
    }

    :after {
      content: none;
    }

    :focus,
    :active {
      border: 1px solid transparent;
      box-shadow: none;
    }
  }
  &.right {
    margin-left: auto;
    padding-right: 0;
  }
  &:first-child {
    padding-left: 0;
  }
`;

const Tab = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  height: 80%;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 1.6px;
  a:not(.button) {
    text-decoration: none;
    text-transform: uppercase;
    color: var(--link-ebony-light-color, var(--ebony-light));
    &.active {
      color: ${props =>
        props.subNavActive || "var(--link-night-color,var(--night))"};
    }
    :after {
      content: none;
    }

    :focus,
    :active {
      border: 1px solid transparent;
      box-shadow: none;
    }
  }
  &.right {
    margin-left: auto;
    padding-right: 0;
  }
  &:first-child {
    padding-left: 0;
  }
`;

// TODO: Find solution for background-color: var(--link-snow-border-color,var(--snow));
export const NavBar = styled(SubNavBar)`
  .mobile-subnvarbar {
    padding: 2px 0;
  }
  ${SNavBar.SubNavBarLayout} {
    height: 34px;
    min-height: 34px;
    max-height: 34px;
    padding: 0;

    a:not(.button) {
      font-size: 13px;
      &:hover,
      &:focus,
      &:active {
        color: var(--tab-snow-color, var(--snow));
      }

      &.active:before {
        background-color: var(--tab-snow-border-color, var(--snow));
      }
    }

    @media (min-width: 768px) {
      ${SNavBar.SubNavBarTabWrapper} {
        position: absolute;
        width: fit-content;
        padding-top: 18px;
        padding-left: 0;
        box-sizing: content-box;
      }

      a:not(.button) {
        padding-left: 0;
        &.active {
          &:before {
            left: 0;
            width: calc(100% - 10px);
          }
        }
      }
    }
  }

  ${DropdownTab}, ${Tab} {
    a:not(.button) {
      color: var(--link-granite-color, var(--granite));
      &.active {
        color: var(--link-snow-color, var(--snow));
      }
    }
  }
`;
