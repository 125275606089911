import SimpleBar from "simplebar-react";
import styled from "styled-components";

import "simplebar/dist/simplebar.min.css";

export const Scrollbox = styled(SimpleBar)`
  .simplebar-scrollbar::before {
    background-color: var(--section-granite-background-color, var(--granite));
    width: 4px;
    border-radius: 2px;
  }
  .simplebar-scrollbar.simplebar-visible:before {
    opacity: 1;
  }
  .simplebar-track {
    &.simplebar-vertical {
      margin: 4px 0;
    }
    &.simplebar-horizontal {
      margin: 0 4px;
    }
  }
`;
