import styled from "styled-components";

import { smallShadow } from "ds/Shadows";
import DsTag from "ds/Tag";

export const Tag = styled(DsTag)`
  width: min-content;
  display: flex;
  white-space: nowrap;
  align-items: center;
  gap: 14px;
  padding: 2px 0 2px 8px;
  ${smallShadow}
  ${props =>
    props.variant === "snow" && "color: " + "var(--mode-text-neutral-default)"};
`;
