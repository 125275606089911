import * as Toast from "@radix-ui/react-toast";
import styled from "styled-components";

const Viewport = styled(Toast.Viewport)`
  position: absolute;
  bottom: 32px;
  left: 32px;
  padding: 0;
  margin: 0;
  gap: 8px;
  display: flex;
  flex-direction: column;
  z-index: 1001;
`;

export default Viewport;
