import { createSlice } from "@reduxjs/toolkit";

import { setDeep } from "Libs/objectAccess";
import { normalize } from "Libs/utils";

import {
  addVariable,
  getVariables,
  updateVariable,
  deleteVariable
} from "./thunks";

import type { ProjectLevelVariable } from "platformsh-client";

type VariableData = {
  [organizationId: string]:
    | {
        [projectId: string]:
          | {
              [variableId: string]: ProjectLevelVariable;
            }
          | undefined;
      }
    | undefined;
};

type ProjectVariableSettingsState = Readonly<{
  data: VariableData;
  loading: boolean;
  status?: "added" | "deleted" | "idle" | "pending" | "rejected" | "updated";
  errors?: Error;
}>;

const initialState: ProjectVariableSettingsState = { data: {}, loading: true };

const variables = createSlice({
  name: "app/project/variable",
  initialState,
  reducers: {
    clearProjectVariableError(state) {
      state.errors = undefined;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getVariables.pending, state => {
        state.loading = true;
      })
      .addCase(getVariables.fulfilled, (state, action) => {
        const { organizationId, projectId } = action.meta.arg;
        state.data = {
          [organizationId]: { [projectId]: normalize(action.payload, "id") }
        };
        state.loading = false;
        state.status = "idle";
      })
      .addCase(getVariables.rejected, (state, action) => {
        state.errors = action.payload?.error;
        state.loading = false;
        state.status = "rejected";
      })
      .addCase(addVariable.pending, state => {
        state.status = "pending";
        state.errors = undefined;
      })
      .addCase(addVariable.fulfilled, (state, action) => {
        const { organizationId, project } = action.meta.arg;
        if (action.payload) {
          setDeep(
            state,
            ["data", organizationId, project.id, action.payload.id],
            action.payload
          );
          state.status = "added";
        }
      })
      .addCase(addVariable.rejected, (state, action) => {
        state.errors = action.payload?.error;
        state.status = "rejected";
      })
      .addCase(updateVariable.pending, state => {
        state.status = "pending";
        state.errors = undefined;
      })
      .addCase(updateVariable.fulfilled, (state, action) => {
        const { organizationId, projectId } = action.meta.arg;
        if (action.payload) {
          setDeep(
            state,
            ["data", organizationId, projectId, action.payload.id],
            action.payload
          );
          state.status = "updated";
        }
      })
      .addCase(updateVariable.rejected, (state, action) => {
        state.errors = action.payload?.error;
        state.status = "rejected";
      })
      .addCase(deleteVariable.pending, state => {
        state.status = "pending";
        state.errors = undefined;
      })
      .addCase(deleteVariable.fulfilled, (state, action) => {
        const { organizationId, projectId } = action.meta.arg;
        delete state.data[organizationId]![projectId]![action.payload.id];
        state.status = "deleted";
      })
      .addCase(deleteVariable.rejected, (state, action) => {
        state.errors = action.payload?.error;
        state.status = "rejected";
      });
  }
});

export default variables.reducer;

export const { clearProjectVariableError } = variables.actions;
