import React, { useMemo } from "react";

import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";

import config from "console_config";
import useDecodedParams from "Hooks/useDecodedParams";
import useSelectorWithUrlParams from "Hooks/useSelectorWithUrlParams";
import {
  checkGoLive,
  getSubscriptionEditUrl,
  splitStringByChar
} from "Libs/utils";
import { environmentSelector } from "Reducers/environment";
import {
  selectExternalUrlEnabled,
  selectFeatureBillingEnabled
} from "Reducers/featureFlags/featureFlags.selectors";
import { organizationByDescriptionIdSelector } from "Reducers/organization";
import { gitProjectSelector } from "Reducers/project/project";
import { subscriptionSelector } from "Reducers/subscription";
import { useAppSelector } from "Store/hooks";

import * as S from "./GoLiveButton.styles";

const GoLiveButton = () => {
  const intl = useIntl();
  const location = useLocation();

  const environment = useSelectorWithUrlParams(environmentSelector);
  const { organizationId, projectId } = useDecodedParams<{
    organizationId: string;
    projectId: string;
  }>();

  const billingEnabled = useAppSelector(selectFeatureBillingEnabled);
  const project = useSelectorWithUrlParams(gitProjectSelector);

  const organization = useAppSelector(state =>
    organizationByDescriptionIdSelector(state, {
      organizationDescriptionId: organizationId
    })
  );

  const subscription = useAppSelector(state => {
    return subscriptionSelector(state, {
      organizationId,
      projectId,
      id: project?.subscription_id
    });
  });

  const subscriptionEditUrl = useMemo(
    () => getSubscriptionEditUrl({ project, subscription, organization }),
    [project, subscription, organization]
  );

  const goLive = useMemo(
    () => checkGoLive(project, organization),
    [project, organization]
  );
  const externalUrlEnabled = useAppSelector(selectExternalUrlEnabled);

  const showGoLive = environment?.is_main && goLive;
  const disableGoLive =
    !billingEnabled && !config.CUSTOM_URL_SUBSCRIPTION_UPGRADE;

  if (disableGoLive) return null;

  if (!showGoLive || goLive === "no-permission") {
    return null;
  }

  if (goLive === "live") {
    return <S.Live>{intl.formatMessage({ id: "live" })}</S.Live>;
  }

  const { pathname } = splitStringByChar(subscriptionEditUrl?.url || "", "#");

  const internalProps = {
    to: pathname,
    state: { from: "environment", url: location.pathname }
  };
  const externalProps = {
    href: subscriptionEditUrl?.url!
  };
  const linkProps = externalUrlEnabled ? externalProps : internalProps;

  return goLive === "development" ? (
    <S.Button {...linkProps} analyticId="button_go_live">
      {intl.formatMessage({ id: "button_go_live" })}
    </S.Button>
  ) : (
    <S.Button
      to={`/${organizationId}/${projectId}/-/settings/domains`}
      analyticId="button_go_live"
    >
      {intl.formatMessage({ id: "button_go_live" })}
    </S.Button>
  );
};

export default GoLiveButton;
