import styled from "styled-components";

import UnstyledButton from "ds/Button";
import { semiBoldAlias } from "Libs/theme";

export const Button = styled(UnstyledButton)`
  padding-left: 16px;
  padding-right: 16px;
`;

export const Live = styled.span`
  display: inline-block;
  background-color: var(
    --section-color-primary-100-background-color,
    var(--color-primary-100)
  );
  color: var(--section-color-primary-600-color, var(--color-primary-600));
  font-size: 12px;
  line-height: 24px;
  height: 24px;
  padding: 0 8px;
  ${semiBoldAlias()};
  border-radius: 2px;
`;
