import * as Tabs from "@radix-ui/react-tabs";
import styled, { css as styledCSS } from "styled-components";

import { semiBoldAlias } from "Libs/theme";

export type TriggerProps = {
  /** "link" | "block" Applies only to vertical trigger components */
  variant?: "link" | "block" | "command_menu";
};

const commandMenuStyles = styledCSS`
  &[data-state="active"] {
    color: var(--mode-text-primary-default);
    border-color: var(--mode-text-primary-default);
  }
`;

export const verticalTrigger = styledCSS<TriggerProps>`
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 24px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  gap: 16px;
  ${semiBoldAlias()};
  font-family: "Open Sans";
  font-style: normal;
  font-size: 15px;
  line-height: 24px;
  border: 0;
  margin: 0;

  transition-duration: 0.25s;
  transition-property: background-color, color, box-shadow;
  cursor: pointer;

  ${({ variant }) =>
    (variant === "link" || !variant) &&
    `
      padding-left: 24px;
      background-color: transparent;
      color: var(--mode-text-neutral-default-weak);

      :hover {
        background-color: var(--mode-bg-neutral-weakest);
        box-shadow: 0 4px 8px var(--mode-shadow-default);
      }

      :active {
        color: var(--mode-text-primary-default);
      }
    `};

  ${({ variant }) =>
    variant === "block" &&
    `
      padding-left: 16px;
      color: var(--mode-text-neutral-default-weak);
      background-color: var(--mode-bg-neutral-weakest);

      :active {
        color: var(--mode-text-primary-default);
      }
    `};

  &[data-state="active"] {
    color: var(--mode-text-primary-default);
    box-shadow: 0 4px 8px var(--mode-shadow-default);

    ${({ variant }) =>
      (variant === "link" || !variant) &&
      `
        background-color: var(--mode-bg-neutral-weakest);
      `};
  }
`;

export const horizontalTrigger = styledCSS<TriggerProps>`
  font-family: "Open Sans";
  font-style: normal;
  ${semiBoldAlias()};
  font-size: 13px;
  line-height: 16px;
  background-color: transparent;
  border: none;
  padding-bottom: 8px;
  padding-left: 0;
  padding-top: 0;
  padding-right: 0;
  border: 0;
  margin: 0;
  border-bottom: 2px solid transparent;
  cursor: pointer;

  transition-timing: 0.25s;
  transition-property: color, border-bottom-color;

  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--mode-text-neutral-default-weak);

  ${({ variant }) =>
    variant === "command_menu"
      ? commandMenuStyles
      : styledCSS`
   &[data-state="active"] {
    color: var(--mode-text-neutral-default);
    border-color: var(--mode-border-neutral-strongest);
  }
  `}

`;

export const Trigger = styled(Tabs.Trigger)<TriggerProps>`
  &[data-orientation="vertical"] {
    ${verticalTrigger};
  }

  &[data-orientation="horizontal"] {
    ${horizontalTrigger};
  }
`;

export default Trigger;
