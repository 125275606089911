import React from "react";

import { useIntl } from "react-intl";

import Button, { AddButton } from "ds/Button";
import Modal from "ds/Modal";
import { BodyText, Title } from "ds/Typography";

import { CreateProjectButtonProps } from "../types";

const GenericModal = ({
  isModalOpen,
  setIsModalOpen,
  message,
  isLink,
  linkStyle,
  linkVariant = "link",
  openModal,
  label
}: CreateProjectButtonProps) => {
  const intl = useIntl();

  return (
    <>
      <Modal
        isOpen={isModalOpen!}
        onOpenChange={() => setIsModalOpen?.(!isModalOpen)}
        id="generic"
      >
        <Title tag="h3">
          {intl.formatMessage({
            id: "create_project_button.generic.title"
          })}
        </Title>
        <BodyText>{message}</BodyText>
      </Modal>
      {isLink ? (
        <Button
          id="create_project"
          variant={linkVariant}
          style={linkStyle}
          onClick={openModal}
          analyticId="create_project"
        >
          {label}
        </Button>
      ) : (
        <AddButton
          id="create_project"
          analyticId="create_project"
          onClick={openModal}
        >
          {label}
        </AddButton>
      )}
    </>
  );
};

export default GenericModal;
