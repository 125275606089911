import { css as styledCSS } from "styled-components";

export type ShadowVariants = "flat" | "small" | "medium" | "large" | "xl";

export const flatShadow = `box-shadow: none;`;

export const smallShadow = `box-shadow: var(--shadow-small);`;

export const mediumShadow = `box-shadow: var(--shadow-medium);`;

export const largeShadow = `box-shadow: 0px 6px 24px var(--mode-shadow-default);`;

export const xlShadow = `box-shadow: var(--shadow-xl);`;

export const Shadow = styledCSS<{ shadowVariant: ShadowVariants }>`
  ${({ shadowVariant }) => {
    switch (shadowVariant) {
      case "small":
        return smallShadow;
      case "medium":
        return mediumShadow;
      case "large":
        return largeShadow;
      case "xl":
        return xlShadow;
      default:
      case "flat":
        return flatShadow;
    }
  }};
`;
