// This hook is just for use when you want to be able to toggle a feature flag
// on and off in the UI. If you want to just consume the value of a feature flag
// in your component , which is probably what you need, access the value using a regular
// useSelector call. See src/reducers/featureFlags/featureFlags.selectors.ts

import { useAppDispatch, useAppSelector } from "Store/hooks";

import { createFeatureFlagSelector } from "./featureFlags.selectors";
import { setFeatureFlag } from "./featureFlags.thunks";
import { FeatureFlagName } from "./types";

export const useFeatureFlagToggle = (
  flagName: FeatureFlagName
): [boolean, (x: boolean) => void] => {
  const dispatch = useAppDispatch();

  const value = useAppSelector(createFeatureFlagSelector(flagName));
  const setValue = (value: boolean) => {
    dispatch(setFeatureFlag({ flagName, flagValue: value }));
  };

  return [value, setValue];
};
