import { createAsyncThunk } from "@reduxjs/toolkit";

import { resolveRefUsers } from "Api/ref/resolveRefUsers";
import { getUserAccessByUserId } from "Api/userAccess/getUserAccessByUserId.request";

type GetUserAccessByUserIdThunkArgs = {
  projectId: string;
  userId: string;
};

export const getUserAccessByUserIdThunk = createAsyncThunk(
  "userAccess/getUserAccessByUserId",
  async (args: GetUserAccessByUserIdThunkArgs) => {
    const access = await getUserAccessByUserId(args.projectId, args.userId);
    const user = await resolveRefUsers(access);

    return { access, user };
  }
);
