import styled from "styled-components";

export const TriggerLabel = styled.div`
  margin-left: auto;
  font-family: "Open Sans";
  font-style: normal;
  font-size: 13px;
  line-height: 16px;
  color: var(--tab-granite-color, var(--granite));
  display: flex;
  align-items: center;
`;

export default TriggerLabel;
