import React, { useMemo } from "react";

import { useMonthNames } from "./config";
import { MonthSelect } from "./styles";

type MonthPickerPropType = {
  currentMonth?: number;
  onChange: (year: number) => void;
  disabledMonths?: Array<number>;
};

const MonthPicker = (props: MonthPickerPropType) => {
  const {
    onChange,
    currentMonth = new Date().getMonth(),
    disabledMonths
  } = props;
  const monthNames = useMonthNames();

  const monthOptions = useMemo(
    () =>
      monthNames.map((value, monthIndex) => ({
        value,
        label: value,
        isDisabled: disabledMonths?.includes(monthIndex)
      })),
    [disabledMonths, monthNames]
  );

  const handleChange = (option: any) => {
    onChange(monthNames.indexOf(option.value));
  };

  return (
    <MonthSelect
      classNamePrefix={"date-select"}
      onChange={handleChange}
      value={monthOptions.find(m => m.value == monthNames[currentMonth])}
      options={monthOptions}
      menuPortalTarget={null}
    />
  );
};

export default MonthPicker;
