import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getSetupOptionsThunk } from "./thunks/getSetupOptions.thunk";

import type { SetupOptions } from "Api/types/SetupOptions";
import type { CommonState } from "Reducers/types";

export type SetupOptionsConfig = {
  template?: string;
  title?: string;
  defaultBranch?: {
    main?: string;
  };
  region?: string;
  organization?: unknown;
};

export type SetupOptionsState = CommonState<SetupOptions> & {
  config?: SetupOptionsConfig;
};

export const initialSetupOptionsState: SetupOptionsState = {
  loading: undefined
};

const setupOptionsSlice = createSlice({
  name: "setupOptions",
  initialState: initialSetupOptionsState,
  reducers: {
    resetSetupOptionsConfig: state => {
      state.config = undefined;
    },
    saveSetupOptionsConfig: (
      state,
      action: PayloadAction<SetupOptionsConfig>
    ) => {
      state.config = {
        ...state.config,
        ...action.payload
      };
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getSetupOptionsThunk.pending, state => {
        state.error = undefined;
        state.loading = true;
      })
      .addCase(getSetupOptionsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getSetupOptionsThunk.rejected, (state, action) => {
        state.error = action.error;
        state.loading = false;
      });
  }
});

export const { resetSetupOptionsConfig, saveSetupOptionsConfig } =
  setupOptionsSlice.actions;

export const setupOptionsReducer = setupOptionsSlice.reducer;
