import * as PopoverPrimitive from "@radix-ui/react-popover";
import styled from "styled-components";

import { largeShadow } from "ds/Shadows";

/* This represent the space between the trigger and the content */
const CONTENT_TRIGGER_GAP = "4px";

export const Content = styled(PopoverPrimitive.Content)`
  z-index: 1001;
  position: relative;
  width: 300px;
  box-sizing: border-box;
  pointer-events: auto;

  /* This gap filler is required otherwise once the user reaches the gap with the
   * cursor the content won't be accessible anymore */
  :before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    height: ${CONTENT_TRIGGER_GAP};
  }

  /* Reposition the filler and the container so it works regardless of the
   * position of the containre */
  &[data-side="bottom"] {
    top: ${CONTENT_TRIGGER_GAP};
    &:before {
      top: -${CONTENT_TRIGGER_GAP};
    }
  }

  &[data-side="top"] {
    bottom: ${CONTENT_TRIGGER_GAP};
    &:before {
      bottom: -${CONTENT_TRIGGER_GAP};
    }
  }

  background-color: var(--mode-bg-neutral-weakest);
  padding: 16px;
  outline: none;
  ${largeShadow}
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
`;
