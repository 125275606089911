import React from "react";

import * as Dropdown from "@radix-ui/react-dropdown-menu";

import useUniqueId from "Libs/useUniqueId";
import { sendHeapEventDropdownItem } from "Store/analytics/heapFunctions";

import * as S from "./CheckboxItem.styles";

const CheckboxItem = React.forwardRef<
  React.ElementRef<typeof Dropdown.CheckboxItem>,
  React.ComponentProps<typeof Dropdown.CheckboxItem> & { isValid?: boolean }
>(function CheckboxItem(
  { children, id, onCheckedChange, ...props },
  forwardedRef
) {
  const uniqueID = useUniqueId();
  const onSelect = () => {
    sendHeapEventDropdownItem({
      analyticId: id,
      type: "checkbox",
      checked: props.checked
    });
  };
  return (
    <S.Item
      {...props}
      onSelect={event => {
        event.preventDefault();
        onSelect();
        if (props.onSelect) {
          props.onSelect(event);
        }
      }}
      ref={forwardedRef}
    >
      <S.Checkbox
        id={id}
        label={children}
        checked={!!props.checked}
        onCheckedChanged={onCheckedChange}
        forId={id || `checkbox-${uniqueID}`}
        isValid={props.isValid}
        disabled={props.disabled}
      />
    </S.Item>
  );
});

export default CheckboxItem;
