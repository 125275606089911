import { createAsyncThunk } from "@reduxjs/toolkit";

import { getOrganizationInvitations } from "Api/organizations/invitation/getOrganizationInvitations";

import type { GetOrganizationInvitationsParameters } from "Api/organizations/invitation/getOrganizationInvitations";

export interface LoadOrganzationInvitationsParams {
  organizationId: string;
  parameters?: GetOrganizationInvitationsParameters;
}

export const loadOrganizationInvitations = createAsyncThunk(
  `organization/invitations/loadInvitations`,
  ({ organizationId, parameters }: LoadOrganzationInvitationsParams) =>
    getOrganizationInvitations(organizationId, parameters)
);
