import React, { ComponentProps, useState } from "react";

import { Root as PrimitiveRoot } from "@radix-ui/react-popover";

import { Provider } from "./dialog.context";

const Root = (props: ComponentProps<typeof PrimitiveRoot>) => {
  const [isOpen, setIsOpen] = useState(props.defaultOpen || false);

  return (
    <Provider value={{ isOpen, setIsOpen }}>
      <PrimitiveRoot open={isOpen} {...props} />
    </Provider>
  );
};

export default React.memo(Root);
