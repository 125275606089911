import styled from "styled-components";

import { CopyButton as DsCopyButton } from "ds/Button";

export const Heading = styled.span`
  display: block;
  margin-bottom: 8px;
  font-size: 13px;
  font-weight: 600;
  color: var(--modal-slate-color, var(--slate));
`;

export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  ${Heading} {
    margin-bottom: 0;
  }
`;

export const CodeContainer = styled.div<{
  timestamp?: string;
  isInArea?: boolean;
  hasMargin: boolean;
}>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-radius: 2px;
  border-radius: var(
    --code-section-border-radius,
    var(--section-2px-border-radius, var(--2px))
  );
  background-color: var(
    --code-modal-background-color,
    var(--modal-ebony-background-color, var(--ebony))
  );
  ${({ hasMargin }) =>
    hasMargin &&
    `
    margin: 10px 0 20px;
  `}
  word-wrap: break-word;

  &::before {
    content: "${props => props.timestamp}";
    display: none;
    position: absolute;
    top: 12px;
    right: 16px;
    transform: translateY(-50%);
    font-size: 12px;
    font-weight: 600;
    font-family: var(--ff-mono), monospace;
    color: var(--modal-gold-color, var(--gold));
  }
`;

export const Code = styled.code<{
  singleLine: boolean;
  withCopyButton: boolean;
}>`
  box-sizing: border-box;
  width: ${({ withCopyButton }) =>
    withCopyButton ? "calc(100% - 25px)" : "100%"};
  height: 100%;
  overflow-x: auto;
  font-size: 12px;
  font-family: var(--ff-mono), monospace;
  font-weight: 400;
  line-height: 18px;
  background-color: transparent;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ${({ singleLine }) =>
    singleLine ? "text-wrap: nowrap;" : "white-space: pre-line !important;"}
  &::-webkit-scrollbar {
    display: none;
  }
  color: var(--modal-snow-color, var(--snow));
`;

export const CopyButton = styled(DsCopyButton)`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 40px;
  background-color: var(
    --copy-button-button-background-color,
    var(--button-snow-background-color, var(--snow))
  );

  border-radius: var(
    --code-section-border-radius,
    var(--section-2px-border-radius, var(--2px))
  );
  border-bottom-left-radius: var(
    --button-0-border-bottom-left-radius,
    var(--0)
  );
  border-top-left-radius: var(--button-0-border-top-left-radius, var(--0));

  border-color: ${"var(--copy-button-button-border-color,var(--button-periwinkle-grey-border-color,var(--periwinkle-grey)))"};
  + ${Code} {
    padding-right: calc(20px + 16px);
  }
  svg {
    color: var(--icon-slate-color, var(--slate));
  }
  &:hover {
    background-color: var(
      --button-color-primary-600-background-color,
      var(--color-primary-600)
    );
    border: none;
    svg {
      color: #fff;
    }
  }
`;

export const LineNumber = styled.pre<{ isMonocolor: boolean }>`
  box-sizing: border-box;
  width: 32px;
  margin: 0;
  padding-right: 4px;
  font-family: var(--ff-mono), monospace;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  text-align: right;
  color: var(--button-periwinkle-grey-color, var(--periwinkle-grey));
  ${props =>
    props.isMonocolor
      ? "background-color:var(--section-ebony-background-color,var(--ebony))"
      : "background-color:var(--section-ebony-light-background-color,var(--ebony-light))"};
`;

export const Area = styled.div`
  ${CodeContainer} {
    padding: 0;
    border-radius: 0;
    align-items: stretch;
    word-break: break-all;
    ${Code} {
      line-height: 24px;
      width: 100%;
      padding: 0px 92px 0px 16px;
      overflow: hidden;
    }
    :hover {
      &::before {
        display: flex;
      }
      ${LineNumber} {
        color: var(--modal-snow-color, var(--snow));
        background-color: var(
          --button-color-primary-100-background-color,
          var(--color-primary-100)
        );
      }
      background-color: #4b577d;
    }
  }

  & > *:first-of-type {
    align-items: flex-end;
    ${LineNumber} {
      border-top-left-radius: 2px;
      padding-top: 16px;
    }

    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }

  & > *:last-child {
    ${LineNumber} {
      border-bottom-left-radius: 2px;
    }
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  div[data-test-id="virtuoso-scroller"] {
    *:first-of-type {
      padding-top: 0;
    }

    div[data-test-id="virtuoso-item-list"] > *:first-child {
      ${CodeContainer} {
        align-items: flex-end;
      }
      ${LineNumber} {
        padding-top: 16px;
      }
    }
  }
`;
