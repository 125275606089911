import styled from "styled-components";

import * as Dropdown from "ds/Dropdown";

export const DropdownTrigger = styled(Dropdown.Trigger)`
  color: var(--mode-overrides-header-text);
  &[data-state="open"] {
    color: var(--mode-text-primary-default);
  }
`;

export const DropdownChevron = styled(Dropdown.Chevron)`
  color: var(--mode-overrides-header-vector);
`;

export const DropdownSeparator = styled(Dropdown.Separator)`
  margin: 16px 8px;
`;
