import { getIntl } from "Libs/intl";

const intl = getIntl();

const gitInfo = {
  title: "integration.setup.categories.git.info.title",
  description: "integration.setup.categories.git.info.text",
  links: [
    { href: "links.documentation.integrations.source", title: "learnmore" }
  ]
};

export type ThirdParty = {
  type: string;
  description: string;
  base_url?: string;
  link?: string;
  category: string;
  enabled?: boolean;
  info?: {
    title: string;
    description: string;
    links?: { href: string; title: string }[];
  };
  fields?: {
    id: string;
    required?: boolean;
    type?: string;
    optional?: boolean;
    sensitive?: boolean;
    separator?: string;
    kind?: string;
    default?: string;
    fields?: {
      id: string;
      required: boolean;
      sensitive: boolean;
    }[];
  }[];
  options?: string[];
  actionLinks?: { href: string; title: string }[];
};

export const THIRD_PARTIES: ThirdParty[] = [
  // GIT
  {
    type: "bitbucket",
    description: "integration.type.bitbucket.description",
    base_url: "https://bitbucket.com",
    category: "git",
    info: gitInfo,
    fields: [
      {
        id: "repository",
        required: true
      },
      {
        id: "app_credentials",
        type: "object",
        optional: true,
        fields: [
          {
            id: "key",
            required: true,
            sensitive: true
          },
          {
            id: "secret",
            required: true,
            sensitive: true
          }
        ]
      }
    ],
    options: [
      "fetch_branches",
      "prune_branches",
      "resync_pull_requests",
      "build_pull_requests"
    ]
  },
  {
    type: "bitbucket_server",
    description: "integration.type.bitbucket_server.description",
    category: "git",
    info: gitInfo,
    fields: [
      {
        id: "url",
        required: true
      },
      {
        id: "username",
        required: true
      },
      {
        id: "token",
        required: true,
        sensitive: true
      },
      {
        id: "project",
        required: true
      },
      {
        id: "repository",
        required: true
      }
    ],
    options: [
      "fetch_branches",
      "prune_branches",
      "pull_requests_clone_parent_data",
      "build_pull_requests"
    ]
  },
  {
    type: "github",
    description: "integration.type.github.description",
    base_url: "https://github.com",
    link: "https://github.com/settings/tokens/new",
    category: "git",
    info: gitInfo,
    fields: [
      {
        id: "token",
        required: true,
        sensitive: true
      }
    ],
    options: [
      "fetch_branches",
      "prune_branches",
      "build_draft_pull_requests",
      "pull_requests_clone_parent_data",
      "build_pull_requests",
      "build_pull_requests_post_merge"
    ]
  },
  {
    type: "gitlab",
    description: "integration.type.gitlab.description",
    base_url: "https://gitlab.com",
    link: "https://gitlab.com/-/user_settings/personal_access_tokens",
    category: "git",
    info: gitInfo,
    fields: [
      {
        id: "token",
        required: true,
        sensitive: true
      }
    ],
    options: [
      "fetch_branches",
      "prune_branches",
      "build_wip_merge_requests",
      "build_merge_requests",
      "merge_requests_clone_parent_data"
    ]
  },
  // HEALTH
  {
    type: "health.email",
    description: "integration.type.health.email.description",
    category: "health",
    fields: [
      {
        id: "from_address"
      },
      {
        id: "recipients",
        type: "array", // [string]
        separator: ",",
        required: true,
        kind: "textarea"
      }
    ]
  },
  {
    type: "health.pagerduty",
    description: "integration.type.health.pagerduty.description",
    link: "https://support.pagerduty.com/docs/services-and-integrations",
    category: "health",
    fields: [
      {
        id: "routing_key",
        required: true
      }
    ]
  },
  {
    type: "health.slack",
    description: "integration.type.health.slack.description",
    link: "https://api.slack.com/bot-users",
    category: "health",
    fields: [
      {
        id: "token",
        required: true
      },
      {
        id: "channel",
        required: true
      }
    ]
  },
  {
    type: "health.webhook",
    description: "integration.type.health.webhook.description",
    category: "health",
    fields: [
      {
        id: "url",
        required: true
      },
      {
        id: "shared_key"
      }
    ]
  },
  // CUSTOM
  {
    type: "webhook",
    description: "integration.type.webhook.description",
    category: "custom",
    fields: [
      {
        id: "url",
        required: true
      },
      {
        id: "shared_key"
      },
      {
        id: "excluded_environments",
        type: "array", // [string]
        separator: ","
      },
      {
        id: "states",
        type: "array", // [string]
        separator: ",",
        default: "complete"
      },
      {
        id: "environments",
        type: "array", // [string]
        separator: ",",
        default: "*"
      },
      {
        id: "events",
        type: "array", // [string]
        separator: ",",
        default: "*"
      }
    ]
  },
  {
    type: "script",
    description: "integration.type.script.description",
    link: intl.formatMessage({
      id: "links.documentation.integrations.activity"
    }),
    category: "custom",
    fields: [
      {
        id: "script",
        kind: "ide",
        required: true
      },
      {
        id: "events",
        type: "array", // [string]
        separator: ",",
        default: "*"
      },
      {
        id: "states",
        type: "array", // [string]
        separator: ",",
        default: "complete"
      },
      {
        id: "environments",
        type: "array", // [string]
        separator: ",",
        default: "*"
      },
      {
        id: "excluded_environments",
        type: "array", // [string]
        separator: ","
      }
    ]
  }
];
