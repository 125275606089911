import { createSlice } from "@reduxjs/toolkit";

import { getSetupCatalogThunk } from "./thunks/getSetupCatalog.thunk";

import type { SetupCatalog } from "Api/types/SetupCatalog";
import type { CommonState } from "Reducers/types";

export type SetupCatalogState = CommonState<SetupCatalog[]>;

export const initialSetupCatalogState: SetupCatalogState = {
  data: [],
  loading: false
};

const setupCatalogSlice = createSlice({
  name: "setupCatalog",
  initialState: initialSetupCatalogState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getSetupCatalogThunk.pending, state => ({
        ...state,
        error: undefined,
        loading: true
      }))
      .addCase(getSetupCatalogThunk.fulfilled, (state, action) => ({
        ...state,
        data: action.payload,
        loading: false
      }))
      .addCase(getSetupCatalogThunk.rejected, (state, action) => ({
        ...state,
        error: action.error,
        loading: false
      }));
  }
});

export const setupCatalogReducer = setupCatalogSlice.reducer;
