import {
  listMemberTeams,
  ListMemberTeamsOptions
} from "Api/organizations/member/listMemberTeams.request";
import { paginatedEndpointSliceFactory } from "Reducers/sliceFactory";

type Options = {
  memberId: string;
  organizationId: string;
  options?: ListMemberTeamsOptions;
};

const { slice, hooks } = paginatedEndpointSliceFactory(
  "memberTeams",
  (options: Options) =>
    listMemberTeams(options.memberId, {
      ...options.options,
      filter: {
        organization_id: options.organizationId,
        ...options.options?.filter
      }
    }),
  // Member ids are not unique across organizations, so a member of organization
  // `A` and `B` can have the same id. To avoid conflicts, we use the organizationId
  // as part of the key.
  options => `${options.organizationId}:${options.memberId}`,
  async response => response
);

export const memberTeamsReducer = slice.reducer;

export const {
  useDataByKey: useGetMemberTeams,
  useAllData: useGetAllMemberTeams,
  useInvalidateKey: useInvalidateMemberTeams
} = hooks;
