import styled, { css as styledCSS } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-wrap: wrap;
  @media (min-width: 768px) {
    align-items: flex-start;
    flex-direction: row;
  }
`;

interface BrandingMessageProps {
  variant?: string;
}
export const BrandingMessage = styled.h2<BrandingMessageProps>`
  color: var(--mode-text-neutral-default);
  line-height: 1;
  font-size: 12px;
  font-weight: 700;
  margin: -4px 0;
  padding: 0;
  @media (min-width: 768px) {
    position: relative;
    line-height: 40px;
    padding-right: 16px;
    ${props =>
      props.variant !== "standalone" &&
      styledCSS`
        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 12px;
          width: 1px;
          height: 18px;
          right: 0;
          background-color: var(--header-ebony-light-background-color,var(--ebony-light));
        }
      `}
  }
`;

export const LogoWrapper = styled.div`
  display: inline-block;
  img {
    width: 100%;
  }
`;
