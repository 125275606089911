import { apiProxyRequest as request } from "Api/apiProxyRequest";

import { Invitation } from "./invitation.types";

export type GetOrganizationInvitationsSortOptions =
  | "updated_at"
  | "-updated_at";

export interface GetOrganizationInvitationsParameters {
  sort: GetOrganizationInvitationsSortOptions;
  filter: { state: { in: string } };
}

export const getOrganizationInvitations = (
  organizationId: string,
  parameters?: GetOrganizationInvitationsParameters
) =>
  request<Array<Invitation>>(
    `/organizations/${organizationId}/invitations`,
    "GET",
    parameters
  );
