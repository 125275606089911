import { createSlice } from "@reduxjs/toolkit";

import { getAllBackups } from "./thunks/getAllBackups.thunk";
import { BackupsStoredRootStateType } from "./types";

const initialState: BackupsStoredRootStateType = {
  isLoading: false,
  backupsManual: [],
  backupsAutomated: []
};

const backupsStoredReducer = createSlice({
  name: "backupsStored",
  initialState,
  reducers: {},
  extraReducers: builder => {
    // GET ALL BACKUPS
    builder
      .addCase(getAllBackups.pending, state => {
        state.isLoading = true;
      })
      .addCase(getAllBackups.fulfilled, (state, { payload }) => {
        const { backupsManual, backupsAutomated } = payload;
        state.backupsManual = backupsManual;
        state.backupsAutomated = backupsAutomated;
        state.isLoading = false;
      })
      .addCase(getAllBackups.rejected, state => {
        state.isLoading = false;
      });
  }
});

export default backupsStoredReducer.reducer;
