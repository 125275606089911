import React from "react";

import { CopyButton } from "ds/Button";

import * as S from "./Code.styles";

export const Heading = S.Heading;

export const Toolbar = S.Toolbar;

type BaseCodeProps = {
  children: React.ReactNode;
  className?: string;
  singleLine?: boolean;
  isMonocolor?: boolean;
  id?: string;
};

type RegularCodeProps = {
  isCopyable: boolean;
  separateCopyButton?: boolean;
  onCopy?: () => void;
} & BaseCodeProps;

type BlockCodeProps = {
  lineNumber: number;
  timestamp: string;
} & BaseCodeProps;

const isRegularCode = (props: any): props is RegularCodeProps =>
  !!props.isCopyable || props.separateCopyButton;

const isBlockCode = (props: any): props is BlockCodeProps => !!props.lineNumber;

const Block = ({ children, ...props }: RegularCodeProps | BlockCodeProps) => {
  let showCopyButton = false;
  let showSeparateCopyButton = false;
  let onCopy = () => {};
  let isInArea = false;
  let lineNumber = 0;
  let timestamp = "";

  if (isRegularCode(props)) {
    showCopyButton = props.isCopyable;
    showSeparateCopyButton = !!props.separateCopyButton;
    onCopy = props.onCopy ?? onCopy;
  }

  if (isBlockCode(props)) {
    isInArea = true;
    lineNumber = props.lineNumber;
    timestamp = props.timestamp;
  }

  return (
    <>
      <S.CodeContainer
        timestamp={timestamp}
        isInArea={isInArea}
        className={props.className}
        id={props.id}
        hasMargin={showSeparateCopyButton}
      >
        {isInArea && (
          <S.LineNumber isMonocolor={props?.isMonocolor ?? false}>
            {lineNumber}
          </S.LineNumber>
        )}
        <S.Code
          singleLine={props?.singleLine ?? false}
          withCopyButton={showCopyButton}
          data-testid="code-block"
        >
          {children}
        </S.Code>
        {showCopyButton && (
          <S.CopyButton
            text={typeof children === "string" ? children : ""}
            withText={false}
            onClick={onCopy}
            analyticId={props.id}
          />
        )}
      </S.CodeContainer>

      {showSeparateCopyButton && (
        <CopyButton
          analyticId={props.id}
          text={typeof children === "string" ? children : ""}
          withText
          onClick={onCopy}
          variant="outline"
        />
      )}
    </>
  );
};

export default Block;
