import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { useAppSelector } from "Store/hooks";

// Effective use of this action will be to have a cleanup hook when the
// component that is displaying the banners before it unmounts should
// remove the banner from the store. This is to ensure that when changing
// routes previous banners with higher visibility priority does not prevent
// another with low priority from being displayed

export const BANNER_PRIORITY = {
  continuous_profiling: { priority: 0 },
  observability_suite: { priority: 0 }
} as const;

export type BANNER_NAME = keyof typeof BANNER_PRIORITY;
type SetBannerVisibleProps = { isVisible: boolean; name: BANNER_NAME };

const initialState: Array<{ name: BANNER_NAME; precedence: number }> = [];

// list registered banners. A is a registered banner that can be displayed
// it has a high precedence or one of those with high precedence
export const useRegisteredBanners = () => {
  const banners = useAppSelector(state => state.banners);
  return banners;
};

// check if this banner is registered
export const useBannerIsRegistered = (name: BANNER_NAME) =>
  useRegisteredBanners().some(b => b.name === name);

const bannerSlice = createSlice({
  name: "banners",
  initialState,
  reducers: {
    setBannerVisible: (
      state,
      { payload }: PayloadAction<SetBannerVisibleProps>
    ) => {
      const { isVisible, name } = payload;
      //filter the banner out if it is turned off

      if (!isVisible) return state.filter(b => b.name !== name);
    },
    registerBanner: (state, { payload }) => {
      const { name } = payload;
      const precedence = BANNER_PRIORITY?.[name]?.priority;
      // for a banner to be visible it must have a lower or equal priority to
      // already showing banners, lower value takes high priority
      const makeVisible = state.every(b => b.precedence >= precedence);

      if (!makeVisible) {
        // this banner has lower priority than one or more of the banner displayed
        // lets not insert it
        return state;
      }
      //Remove banner of lower priority before inserting one of higher priority
      const update = state.filter(
        b => b.precedence <= precedence && b.name !== name
      );

      update.push({ precedence, name });
      return update;
    }
  }
});

export const { setBannerVisible, registerBanner } = bannerSlice.actions;

export default bannerSlice.reducer;
