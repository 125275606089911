import { Organization, entities } from "platformsh-client";

import { apiProxyRequest as request } from "Api/apiProxyRequest";

type GetUserOrganizationsResponse = {
  count: number;
  items: Array<Organization>;
  _links: {
    self: { href: string };
    next?: { href: string };
    previous?: { href: string };
  };
};

type GetUserOrganizationsOptions = {
  filter?: { vendor: Array<string> };
  sort?: string;
  page?: {
    size?: number;
  };
};

export const getUserOrganizations = (
  userId: string,
  options?: GetUserOrganizationsOptions
) =>
  request<GetUserOrganizationsResponse>(
    `/users/${userId}/organizations`,
    "GET",
    options
  );

export const getAllUserOrganizations = async (
  userId: string,
  options?: GetUserOrganizationsOptions
) => {
  const response = await getUserOrganizations(userId, options);
  const organizations = response.items;
  if (response._links.next) {
    let next: GetUserOrganizationsResponse["_links"]["next"] =
      response._links.next;
    while (next) {
      const nextResponse = await request<GetUserOrganizationsResponse>(
        next.href
      );
      organizations.push(...nextResponse.items);
      next = nextResponse._links.next;
    }
  }
  return organizations.map(
    organization => new entities.Organization(organization)
  );
};
