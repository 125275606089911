import styled, { css as styledCSS } from "styled-components";

import { semiBoldAlias } from "Libs/theme";

export const LoadingWrapper = styled.span<{ iconOnly?: boolean }>`
  display: flex;
  align-items: center;
  padding-top: 50px;
  ${props =>
    props.iconOnly &&
    styledCSS`
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 0;
    `};
`;

export const MessageWrapper = styled.span`
  display: inline-block;
  color: var(--section-color-primary-600-color, var(--color-primary-600));
  ${semiBoldAlias()};
  &:first-letter {
    text-transform: uppercase;
  }
`;
