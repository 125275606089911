import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "Store/configureStore";

import { makePermissionsMap, Permission, permissionsKeys } from "./permissions";

export const NAME = "organizationInvitations/filter";

const initialState = {
  permissions: makePermissionsMap(true),
  search: "",
  sort: "updated_at" as SortOptions,
  filter: { state: { in: "pending,error" as FilterState } }
};

export type SortOptions = "updated_at" | "-updated_at";
export type FilterState = "pending,error";

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    togglePermission: (state, action: PayloadAction<Permission>) => ({
      ...state,
      permissions: {
        ...state.permissions,
        [action.payload]: !state.permissions[action.payload]
      }
    }),
    setSearch: (state, action: PayloadAction<string>) => ({
      ...state,
      search: action.payload
    }),
    setSort: (state, action: PayloadAction<SortOptions>) => ({
      ...state,
      sort: action.payload
    }),
    setFilterState: (state, action: PayloadAction<FilterState>) => ({
      ...state,
      filter: { state: { in: action.payload } }
    }),
    resetFilters: () => initialState
  }
});

export const {
  togglePermission,
  setSearch,
  setSort,
  setFilterState,
  resetFilters
} = slice.actions;

export default slice.reducer;

export const selectFilters = (state: RootState) =>
  state.organizationInvitations.filters;

export const selectPermissions = (state: RootState) =>
  selectFilters(state).permissions;

/** Returns a list with the selected permissions */
export const selectSelectedPermissions = createSelector(
  [selectPermissions],
  selectedPermissions =>
    (Object.entries(selectedPermissions) as Array<[Permission, boolean]>)
      .filter(([, isEnabled]) => isEnabled)
      .map(([permission]) => permission)
);

/** Returns whether all the permissions are selected  */
export const selectAreAllPermissionsSelected = createSelector(
  [selectPermissions],
  permissions =>
    Object.values(permissions).filter(permissionValue => permissionValue)
      .length === permissionsKeys.length
);

export const selectSearch = (state: RootState) => selectFilters(state).search;

export const selectSort = (state: RootState) => selectFilters(state).sort;

export const selectFilterState = (state: RootState) =>
  selectFilters(state).filter;
