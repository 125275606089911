import { createSlice } from "@reduxjs/toolkit";

import { setDeep } from "Libs/objectAccess";

import { getOrganizationPaymentSource } from "./thunks/getOrganizationPaymentSource.thunk";

import type { OrganizationPaymentSource } from "platformsh-client";

const setError = (state, action) => {
  state.errors = action.error.message;
  state.loading = false;
};

export type PaymentSourceState = Readonly<{
  data: {
    [organizationId: string]: OrganizationPaymentSource | undefined;
  };
  loading: boolean | "idle";
  errors?: Error | "";
}>;

const initialState: PaymentSourceState = {
  data: {},
  loading: "idle",
  errors: ""
};

const organizationPaymentSource = createSlice({
  name: "organizationPaymentSource",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getOrganizationPaymentSource.pending, state => {
        state.loading = true;
      })
      .addCase(getOrganizationPaymentSource.fulfilled, (state, action) => {
        const organizationId = action?.meta?.arg.organizationId || "";
        setDeep(state, ["data", organizationId], action.payload);
        state.loading = false;
        state.errors = "";
      })
      .addCase(getOrganizationPaymentSource.rejected, (state, action) =>
        setError(state, action)
      );
  }
});

export default organizationPaymentSource.reducer;
