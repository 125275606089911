import React, { SVGAttributes } from "react";

import * as S from "./Icon.styles";

const DEFAULT_ICON_SIZE = 24;

export type IconProps = SVGAttributes<SVGSVGElement>;

export type WrappingIconProps = Omit<IconProps, "children">;

const Icon = (props: IconProps) => (
  <S.Layout
    fill="currentcolor"
    viewBox="0 0 24 24"
    strokeWidth={0}
    width={DEFAULT_ICON_SIZE}
    height={DEFAULT_ICON_SIZE}
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  />
);

Icon.displayName = "Icon";

export default Icon;
