import ChromeHeadlessIcon from "./chrome-headless";
import CommonLispIcon from "./common-lisp";
import DotNetIcon from "./dotnet";
import ElasticsearchIcon from "./elasticsearch";
import GenericIcon from "./generic";
import GolangIcon from "./golang";
import InfluxdbIcon from "./influxdb";
import JahiaIcon from "./jahia";
import JavaIcon from "./java";
import KafkaIcon from "./kafka";
import MariadbIcon from "./mariadb";
import MemcacheIcon from "./memcache";
import MemcachedIcon from "./memcached";
import MongodbIcon from "./mongodb";
import NetworkStorageIcon from "./network-storage";
import NfsIcon from "./nfs";
import NodejsIcon from "./nodejs";
import OpensearchIcon from "./opensearch";
import OracleIcon from "./oracle";
import OracleMysqlIcon from "./oracle-mysql";
import PagespeedIcon from "./pagespeed";
import PhpIcon from "./php";
import PlaceholderIcon from "./placeholder";
import PostgresqlIcon from "./postgresql";
import PythonIcon from "./python";
import RabbitmqIcon from "./rabbitmq";
import RedisIcon from "./redis";
import RedisPersistentIcon from "./redis-persistent";
import RouterIcon from "./router";
import RubyIcon from "./ruby";
import RustIcon from "./rust";
import SolrIcon from "./solr";
import VarnishIcon from "./varnish";
import VaultKmsIcon from "./vault-kms";

const technologyIcons = {
  "chrome-headless": ChromeHeadlessIcon,
  "common-lisp": CommonLispIcon,
  dotnet: DotNetIcon,
  elasticsearch: ElasticsearchIcon,
  golang: GolangIcon,
  influxdb: InfluxdbIcon,
  jahia: JahiaIcon,
  java: JavaIcon,
  kafka: KafkaIcon,
  mariadb: MariadbIcon,
  memcache: MemcacheIcon,
  memcached: MemcachedIcon,
  mongodb: MongodbIcon,
  mysql: MariadbIcon, // we use the same icon for both
  "network-storage": NetworkStorageIcon,
  nfs: NfsIcon,
  nodejs: NodejsIcon,
  opensearch: OpensearchIcon,
  "oracle-mysql": OracleMysqlIcon,
  oracle: OracleIcon,
  pagespeed: PagespeedIcon,
  php: PhpIcon,
  postgresql: PostgresqlIcon,
  python: PythonIcon,
  rabbitmq: RabbitmqIcon,
  "redis-persistent": RedisPersistentIcon,
  redis: RedisIcon,
  router: RouterIcon,
  ruby: RubyIcon,
  rust: RustIcon,
  solr: SolrIcon,
  varnish: VarnishIcon,
  "vault-kms": VaultKmsIcon,
  generic: GenericIcon
};

export default function getTechnologyIcon(
  technologyName,
  x,
  y,
  width,
  height,
  color
) {
  if (technologyIcons[technologyName]) {
    return technologyIcons[technologyName](x, y, width, height, color);
  } else {
    // Fallback to a placeholder icon
    return PlaceholderIcon(x, y, width, height, color);
  }
}
