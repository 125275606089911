import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "Store/configureStore";

import { ServiceDecodedParams, AppServiceWorker } from "./";

export const serviceFilePath = {
  service: {
    translation: "app_services_file_path",
    file: ".platform/services.yaml"
  },
  app: {
    translation: "app_config_file",
    file: ".platform.app.yaml"
  },
  routes: {
    translation: "app_routes_file_path",
    file: ".platform/routes.yaml"
  }
};
export const servicesSelector = (state: RootState) => state.service;

export const serviceDataSelector = createSelector(
  servicesSelector,
  serviceState => serviceState?.data
);

export const serviceCurrentDeploymentSelector = createSelector(
  serviceDataSelector,
  (
    _: RootState,
    params: { organizationId: string; projectId: string; environmentId: string }
  ) => params,
  (serviceData, { organizationId, projectId, environmentId }) =>
    serviceData?.[organizationId]?.[projectId]?.[environmentId]?.["current"]
);

export const selectCurrentService = createSelector(
  serviceCurrentDeploymentSelector,
  (_: RootState, params: ServiceDecodedParams) => params,
  (
    currentDeploymentData,
    { appName }
  ): { class: string; icon?: string; name: string } | undefined =>
    currentDeploymentData?.[appName]
);

export const selectCurrentServiceFilePath = createSelector(
  selectCurrentService,
  appNameService => {
    if (!appNameService) return undefined;
    if (appNameService.class === "service") {
      return serviceFilePath.service;
    }
    if (appNameService.class === "app") {
      return serviceFilePath.app;
    }

    return serviceFilePath.routes;
  }
);

export const selectCurrentServiceWorkers = createSelector(
  serviceCurrentDeploymentSelector,
  (_: RootState, params: ServiceDecodedParams) => params,

  (currentDeploymentData, { appName }) => {
    const workers = currentDeploymentData?.["workers"] || [];

    const serviceWorkers = Object.values(workers).filter(worker =>
      (worker as { name: string })?.name.startsWith(`${appName}--`)
    ) as AppServiceWorker[];

    return serviceWorkers;
  }
);

export const selectCurrentServiceCronJobs = createSelector(
  selectCurrentService,
  appNameService => {
    const crons = appNameService?.["crons"];

    const cronsEntries = Object.entries(crons);
    if (!cronsEntries.length) return [];

    const serviceCronJobs = cronsEntries.map(([key, value]) => ({
      ...(value as object),
      name: key
    }));

    return serviceCronJobs;
  }
);
