import { createAsyncThunk } from "@reduxjs/toolkit";

import { loadProjectSettingRequest } from "Api/projectSettings/loadProjectSetting.request";
import logger from "Libs/logger";

import { ProjectSetting } from "../";

import type { AsyncThunkOptionType } from "Reducers/types";

export const loadProjectSetting = createAsyncThunk<
  ProjectSetting,
  { projectId: string },
  AsyncThunkOptionType
>(
  "app/project_settings/loadOne",
  async ({ projectId }, { rejectWithValue }) => {
    try {
      const projectSetting = await loadProjectSettingRequest(projectId);

      return projectSetting;
    } catch (err) {
      logger(err, {
        action: "project_settings/loadOne"
      });

      return rejectWithValue(err);
    }
  }
);
