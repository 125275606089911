// To add a new feature flag:
// - Name it ENABLE_<FEATURE_NAME>
// - Add it to ./environment.ts
// - Add it to ./ types.ts
// - If you want it to be toggabble by staff add a checkbox in src/common/containers/testing-widget/TestingModal.tsx

import { createSlice } from "@reduxjs/toolkit";

import {
  getFeatureFlagFromEnvironment,
  getAllFeatureFlagsFromEnvironment
} from "./environment";
import { setFeatureFlag, resetFeatureFlags } from "./featureFlags.thunks";
import { getFeatureFlagFromLocalStorage } from "./localStorage";
import { FEATURE_FLAGS, FeatureFlagName } from "./types";

// Can be used outside of redux, in cases where the store is not available.
export function getFeatureFlag(featureName: FeatureFlagName) {
  return (
    getFeatureFlagFromLocalStorage(featureName) ??
    getFeatureFlagFromEnvironment(featureName)
  );
}

function getFeatureFlagsInitialState(): Record<FeatureFlagName, boolean> {
  return FEATURE_FLAGS.reduce(
    (acc, flagName) => {
      acc[flagName] = getFeatureFlag(flagName);
      return acc;
    },
    {} as Record<FeatureFlagName, boolean>
  );
}

const featureFlagsSlice = createSlice({
  name: "featureFlags",
  initialState: getFeatureFlagsInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(setFeatureFlag.fulfilled, (state, action) => {
        const { flagName, flagValue } = action.payload;
        state[flagName] = flagValue;
      })
      .addCase(resetFeatureFlags.fulfilled, () => {
        return getAllFeatureFlagsFromEnvironment();
      });
  }
});

export default featureFlagsSlice.reducer;
