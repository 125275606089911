import styled from "styled-components";

import Input from "ds/Input";
import Select from "ds/Select";

export const PickerWrapper = styled.div`
  width: max-content;
  .react-datepicker {
    border: none;
    border-radius: 0;
    background-color: var(--section-snow-background-color, var(--snow));
    * {
      font-family: "Open Sans", Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-smoothing: antialiased;
    }
    .react-datepicker__week {
      margin: 5px 0;
    }
    .react-datepicker__header {
      background-color: var(--section-snow-background-color, var(--snow));
      border: none;
    }

    .react-datepicker__children-container {
      width: auto;
    }

    .react-datepicker__day-names {
      margin-bottom: -10px;
      line-height: 20px;
      .react-datepicker__day-name {
        font-weight: 600;
        font-size: 12px;
        color: var(--section-granite-color, var(--granite));
      }
    }

    .react-datepicker__day--outside-month {
      visibility: hidden;
      content: "";
      cursor: disabled;
      background-color: grey;
    }

    .react-datepicker__day-names .react-datepicker__day-name,
    .react-datepicker__day {
      // Controls the width of the date cells hence the width of calendar
      padding: 1px;
    }

    .react-datepicker__day {
      font-weight: 600;
      font-size: 13px;
      border-radius: 0;
      margin: 0;
      padding: 1px 3px;
      color: var(--section-ebony-light-color, var(--ebony-light));
    }

    .react-datepicker__day:is(.react-datepicker__day--range-start),
    .react-datepicker__day--selected {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }

    .react-datepicker__day:is(.react-datepicker__day--range-end) {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }

    .react-datepicker__day--today:not(.react-datepicker__day--in-range) {
      border-radius: 2px;
    }

    .react-datepicker__day--keyboard-selected {
      // Dont heighlight today on other month
      background-color: transparent;
    }

    div.react-datepicker__day--range-start,
    div.react-datepicker__day--selected:not(.react-datepicker__day--today),
    div.react-datepicker__day--range-end,
    .react-datepicker__day--selected:hover {
      // Give higher precedence with element name and selector
      // else will be overriden by .react-datepicker__day--in-range
      color: var(--section-snow-background-color, var(--snow));
      background-color: ${"var(--section-color-primary-600-background-color,var(--color-primary-600))"};
    }

    .react-datepicker__day--today {
      &:after {
        display: block;
        position: absolute;
        top: -3px;
        right: -3px;
        border: 3px solid var(--section-snow-background-color, var(--snow));
        border-radius: 360%;
        content: "";
        width: 4px;
        height: 4px;

        background-color: ${"var(--section-color-primary-600-background-color,var(--color-primary-600))"};
      }
    }

    .react-datepicker__day--in-selecting-range {
      background-color: var(
        --section-color-primary-100-background-color,
        var(--color-primary-100)
      );
      color: ${"var(--section-color-primary-600-background-color,var(--color-primary-600))"};
    }

    .react-datepicker__day--today,
    .react-datepicker__day--in-range {
      position: relative;
      background-color: var(
        --section-color-primary-100-background-color,
        var(--color-primary-100)
      );
      color: ${"var(--section-color-primary-600-background-color,var(--color-primary-600))"};
    }

    .react-datepicker__day--disabled {
      color: (--mode-vector-neutral-default);
    }
  }
`;

export const MonthYearPickerWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 2px;
  justify-content: space-between;
`;

export const YearNavigationButton = styled.button`
  all: inherit;
  * {
    visibility: ${({ disabled }) => (disabled ? "hidden" : "visible")};
  }

  svg {
    path {
      fill: var(--mode-vector-neutral-default);
    }
    cursor: pointer;
  }
  svg.next {
    transform: rotate(-90deg);
  }
  svg.prev {
    transform: rotate(90deg);
  }
`;

export const MonthSelect = styled(Select)`
  width: 100%;
  .date-select__single-value,
  .year-date-select__single-value {
    font-weight: 600;
    font-size: 13px;
    color: var(--section-slate-color, var(--slate));
    text-overflow: initial;
  }
  .date-select__value-container {
    padding: auto;
    padding-left: 0;
    padding-right: 0;
    width: 74px;
  }
  .date-select__control {
    border-width: 0;
  }
  .date-select__option--is-disabled,
  .year-date-select__option--is-disabled {
    display: none;
    cursor: not-allowed;
    color: var(--section-granite-color, var(--granite));
    :hover {
      background: none;
      color: var(--section-granite-color, var(--granite));
    }
  }
`;

export const YearSelect = styled(MonthSelect)`
  width: 82%;
`;

export const UnavigableCalendarDate = styled("span")`
  font-weight: 600;
  font-size: 14px;
  color: ${props => props.color || "var(--section-slate-color,var(--slate))"};
`;

export const TimeText = styled.div`
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 8px;
  color: ${props => props.color || "var(--section-slate-color,var(--slate))"};
`;

export const TimeInputContainer = styled.div``;

export const TimeInputWrapper = styled.div`
  margin-top: 8px;
  display: inline-block;
  flex-direction: column;
  &.float-right {
    float: right;
  }
`;

export const TimeInput = styled(Input)`
  width: fit-content;
  display: inline-block;
  input {
    max-width: 100px;
    color: var(--section-ebony-light-color, var(--ebony-light));
    font-size: 15px;
    ${({ isValid }) => (isValid ? "auto" : "border:1px solid red")};
  }
`;

export const TimeWrapper = styled.div`
  padding: 0px 13px;
`;

export const ButtonWrapper = styled.div<{ isFlex?: boolean }>`
  margin-top: 24px;
  margin-bottom: 16px;
  margin-right: 32px;
  display: ${({ isFlex }) => (isFlex ? "flex" : "block")};
  gap: 8px;
`;

export const RangeDetail = styled.div`
  font-weight: 700;
  font-size: 15px;
  color: var(--section-ebony-light-color, var(--ebony-light));
  padding-top: 12px;
`;

export const DualDateLayout = styled.div`
  padding-bottom: 15px;
  position: relative;
  background-color: var(--section-snow-background-color, var(--snow));
  width: max-content;
  .react-datepicker {
    border: none;
  }
  ${TimeInputContainer} {
    display: flex;
    gap: 16px;

    ${TimeInputWrapper} {
      display: flex;
      flex-direction: revert;
      align-items: baseline;
      gap: 16px;
      float: none;
      justify-content: unset;
    }

    ${TimeText} {
      font-weight: 600;
      font-size: 14px;
      color: var(--section-ebony-light-color, var(--ebony-light));
    }
  }
  ${ButtonWrapper} {
    margin-right: 0px;
    justify-content: end;
  }
`;

export const DualDatePickerContainer = styled.div`
  display: flex;
`;

export const TimeFrameContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;
export const TimeFrame = styled.div`
  display: flex;
  align-items: baseline;
  gap: 10px;
`;

export const Separator = styled.hr`
  height: 1px;
  background-color: var(
    --menu-periwinkle-grey-background-color,
    var(--periwinkle-grey)
  );
`;

export const TimeZoneText = styled(RangeDetail)`
  color: var(--section-granite-color, var(--granite));
  font-weight: 600;
  font-size: 13px;
  padding-top: 2px;
`;

export const RangeDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 12px;
`;

export const MonthYearSelectorContainer = styled.div`
  display: flex;
  gap: 4px;
`;

export const TimeframeLabel = styled.div`
  font-weight: 400;
  font-size: 15px;
  color: var(--section-ebony-color, var(--ebony));
`;
