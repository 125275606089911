import React from "react";

import Favicon from "react-favicon";
import { useTheme } from "styled-components";

import config from "console_config";
import { Link } from "ds/Button";
import BrandLogoDark from "Vendor_resources/images/brand-logo-dark.svg";
import BrandLogoLight from "Vendor_resources/images/brand-logo-light.svg";
import Favico from "Vendor_resources/images/favicon.ico";
import LogoImgDark from "Vendor_resources/images/logo-ui-dark.svg";
import LogoImg from "Vendor_resources/images/logo-ui.svg";

import * as S from "./Logo.styles";

interface Props {
  disabled?: boolean;
  href?: string;
  variant?: string;
  isFullLogo?: boolean;
}

/**
 * renders a custom Logo
 * @param isFullLogo if this is set to true, it will render the full branded logo instead of just the brands icon
 */
const Logo = ({ disabled = false, href, variant, isFullLogo }: Props) => {
  const theme = useTheme();
  const linkProps = href ? { href } : { to: "/" };
  const LogoDark = isFullLogo ? BrandLogoLight : LogoImgDark;
  const LogoLight = isFullLogo ? BrandLogoDark : LogoImg;

  return (
    <S.Wrapper>
      <Favicon url={Favico} />
      <Link
        id="test-logo"
        analyticId="test-logo"
        aria-label="Logo"
        disabled={disabled}
        {...linkProps}
      >
        <S.LogoWrapper>
          <img
            height={config.CUSTOM_LOGO_HEIGHT || undefined}
            src={theme.name === "dark" ? LogoDark : LogoLight}
            alt="logo"
          />
        </S.LogoWrapper>
      </Link>

      {config.CUSTOM_BRANDING_MESSAGE && (
        <S.BrandingMessage variant={variant}>
          {config.CUSTOM_BRANDING_MESSAGE}
        </S.BrandingMessage>
      )}
    </S.Wrapper>
  );
};

export default Logo;
