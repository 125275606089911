import React from "react";

import styled, { css as styledCSS } from "styled-components";

export const Title = styled.div<{ tag: React.ElementType }>`
  font-family: "Open Sans";
  color: var(--mode-text-neutral-default);

  ${({ tag }) => {
    switch (tag) {
      case "h2":
        return styledCSS`
          font-weight: 700;
          font-size: 20px;
          line-height: 32px;`;
      case "h3":
        return styledCSS`
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;`;
      case "h4":
        return styledCSS`
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;`;
      case "h5":
        return styledCSS`
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.6px;`;
      default:
        return styledCSS`
          font-weight: 700;
          font-size: 32px;
          line-height: 40px;`;
    }
  }}
`;
export default Title;
