import { createAsyncThunk } from "@reduxjs/toolkit";

import { resolveRefUsers } from "Api/ref/resolveRefUsers";
import { listProjectUserAccess } from "Api/userAccess/listProjectUserAccess.request";

export const listUserAccessesByProjectIdThunk = createAsyncThunk(
  "userAcecss/listUserAccessesByProjectIdThunk",
  async ({ projectId }: { projectId: string }) => {
    const accesses = await listProjectUserAccess(projectId);
    const userData = await resolveRefUsers(accesses);

    return { userData, accesses };
  }
);
