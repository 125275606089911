import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";

import { setDeep } from "Libs/objectAccess";
import { entities } from "Libs/platform";
import { RootState } from "Store/configureStore";

import type { Activity, SourceOperation } from "platformsh-client";

export interface SourceOperationsStateType {
  loading: boolean;
  error?: unknown;
  operationName?: string;
  data: {
    [organizationId: string]: {
      [projectId: string]: {
        [environmentId: string]: SourceOperation[];
      };
    };
  };
}

type LoadSourceOperationsPropType = {
  organizationId: string;
  projectId: string;
  environmentId: string;
};

export const loadSourceOperations = createAsyncThunk(
  "app/project/environment/sourceOps/load",
  async (
    { projectId, environmentId }: LoadSourceOperationsPropType,
    { rejectWithValue }
  ) => {
    try {
      const sourceOperations = await entities.SourceOperation.query({
        projectId,
        environmentId: encodeURIComponent(environmentId)
      });
      return sourceOperations as SourceOperation[];
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

type RunSourceOperationsPropType = {
  sourceOperation: SourceOperation;
  variables: Record<string, string>;
  onRuntimeOperationCompleted: (activity: Activity) => void;
};

export const runOperation = createAsyncThunk(
  "app/project/environment/sourceOps/run",
  async (
    {
      sourceOperation,
      variables,
      onRuntimeOperationCompleted
    }: RunSourceOperationsPropType,
    { rejectWithValue }
  ) => {
    try {
      await sourceOperation.run(variables).then(onRuntimeOperationCompleted);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const sourceOperations = createSlice({
  name: "sourceOperations",
  initialState: {
    loading: false,
    error: null
  } as Partial<SourceOperationsStateType>,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(loadSourceOperations.fulfilled, (state, { payload, meta }) => {
        const { projectId, organizationId, environmentId } = meta.arg;
        state.loading = false;
        setDeep(
          state,
          ["data", organizationId, projectId, environmentId],
          payload
        );
      })
      .addCase(loadSourceOperations.rejected, state => {
        state.loading = false;
      })
      .addCase(loadSourceOperations.pending, state => {
        state.loading = true;
        delete state.error;
      })
      .addCase(runOperation.pending, state => {
        state.loading = true;
        delete state.error;
      })
      .addCase(runOperation.fulfilled, state => {
        state.loading = false;
        delete state.error;
      })
      .addCase(runOperation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const selectSourceOperations = createSelector(
  (state: RootState) => state.sourceOperations,
  (_: RootState, props: LoadSourceOperationsPropType) => props,
  (sourceOperations, { organizationId, projectId, environmentId }) =>
    sourceOperations.data?.[organizationId]?.[projectId]?.[environmentId] || []
);

export const selectSourceOperationsAreLoading = createSelector(
  (state: RootState) => state.sourceOperations,
  sourceOperations => sourceOperations.loading
);

export const selectSourceOperationsError = createSelector(
  (state: RootState) => state.sourceOperations,
  sourceOperations => sourceOperations.error
);

export default sourceOperations.reducer;
