import React, { useMemo, ReactNode } from "react";

import * as Dialog from "ds/Dialog";

import HiddenCount from "./HiddenCount.styles";
import List from "./List.styles";
import * as S from "./OverflowList.styles";
import useObserver from "./useObserver";

export interface OverflowListProps<T> {
  id?: string;
  className?: string;
  elements: Array<T>;
  overflowDirection?: "vertical" | "horizontal";
  renderHiddenElement: (element: T, index: number) => ReactNode;
  renderElement: (element: T, index: number) => ReactNode;
  PortalWrapper?: React.ElementType;
}

const OverflowList = <T,>({
  id,
  className,
  elements,
  overflowDirection = "horizontal",
  renderElement,
  renderHiddenElement,
  PortalWrapper = React.Fragment
}: OverflowListProps<T>) => {
  const [wrapper, lastVisibleElement, hiddenCount] = useObserver();

  const mapped = useMemo(
    () => elements.map(renderElement),
    [elements, renderElement]
  );
  return (
    <S.Layout $ready={lastVisibleElement !== undefined} className={className}>
      <List id={id} ref={wrapper} $overflowDirection={overflowDirection}>
        {mapped}
      </List>
      {!!hiddenCount && !!lastVisibleElement && (
        <Dialog.Root>
          <Dialog.Trigger analyticId="overflow_list">
            <HiddenCount
              $overflowDirection={overflowDirection}
              $right={lastVisibleElement}
              $isVisible={!!hiddenCount}
            >
              +{hiddenCount}
            </HiddenCount>
          </Dialog.Trigger>
          <Dialog.Portal>
            <PortalWrapper>
              <S.Content align="end">
                <S.Description>
                  {elements
                    .slice(elements.length - hiddenCount)
                    .map(renderHiddenElement)}
                </S.Description>
              </S.Content>
            </PortalWrapper>
          </Dialog.Portal>
        </Dialog.Root>
      )}
    </S.Layout>
  );
};

export default OverflowList;
