import React from "react";

import { useLocation } from "react-router-dom";

import { AddButton, Link } from "ds/Button";

import { CreateProjectButtonProps } from "../types";

const CreateProjectButton = ({
  isLink = false,
  linkStyle = {},
  linkVariant = "primary",
  label
}: CreateProjectButtonProps) => {
  const { pathname } = useLocation();
  const internalProps = {
    to: "/projects/create-project",
    state: { locationAtCreateProject: pathname }
  };

  return isLink ? (
    <Link
      analyticId="create_project"
      id="create_project"
      variant={linkVariant}
      style={linkStyle}
      to={internalProps.to}
    >
      {label}
    </Link>
  ) : (
    <AddButton
      id="create_project"
      {...internalProps}
      analyticId="create_project"
    >
      {label}
    </AddButton>
  );
};

export default CreateProjectButton;
