import React from "react";

import momentjs from "moment";
import momentTZ from "moment-timezone";

import { MOMENTJS_TIME_FORMAT } from "Constants/constants";
import Tooltip from "ds/Tooltip";

const ActivityDate = ({ date }: { date: string }) => {
  const momentDate = momentjs(date);

  return (
    <Tooltip
      tooltip={`UTC${momentTZ(momentDate).format("Z")}`}
      analyticId="activity_date"
    >
      {momentDate.format(MOMENTJS_TIME_FORMAT)}
    </Tooltip>
  );
};

export default ActivityDate;
