import React, { ErrorInfo } from "react";

import config from "console_config";
import { CopyButton, Link } from "ds/Button";
import logger from "Libs/logger";

import FullPageError from "../FullPageError";
import { CodeWrapper } from "../FullPageError/FullPageError";

import { ButtonWrapper, Buttons } from "./AppErrorBoundary.styles";

type AppErrorBoundaryProps = {
  children: React.ReactNode;
};

type AppErrorBoundaryState = {
  hasError: boolean;
  error?: Error;
  info?: ErrorInfo;
};

class AppErrorBoundary extends React.Component<
  AppErrorBoundaryProps,
  AppErrorBoundaryState
> {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    // Display fallback UI
    this.setState({
      hasError: true,
      error: error,
      info: info
    });
    const spinner = document.getElementById("pre-loading");
    const fallback = document.getElementById("fallback");
    if (spinner) {
      spinner.remove();
    }
    if (fallback) {
      fallback.remove();
    }

    logger(error);
  }

  render() {
    const { hasError, error } = this.state;

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <FullPageError
          title="It's not you - it's us"
          message="The following error broke our application before it could load."
        >
          <div className="code">
            <CodeWrapper>{error?.stack}</CodeWrapper>
          </div>
          <Buttons>
            <CopyButton text={error?.stack!} />
            <ButtonWrapper>
              <Link variant="primary" to="/">
                Back to projects
              </Link>
              <Link
                href={
                  config.CUSTOM_URL_SUPPORT
                    ? config.CUSTOM_URL_SUPPORT
                    : `${config.URL_ACCOUNTS}/support`
                }
                target="_blank"
                variant="outline"
              >
                Report
              </Link>
            </ButtonWrapper>
          </Buttons>
        </FullPageError>
      );
    }
    return this.props.children;
  }
}

export default AppErrorBoundary;
