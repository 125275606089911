import { apiProxyRequest as request } from "Api/apiProxyRequest";
import {
  listProjectTeamAccess,
  ListProjectTeamAccessOptions
} from "Api/organizations/projects/listProjectTeamAccess.request";
import { Team } from "Api/teams/teams.types";
import { paginatedEndpointSliceFactory } from "Reducers/sliceFactory";

type SliceAccessArgs = {
  projectId: string;
  options?: ListProjectTeamAccessOptions;
};

const { slice, hooks, selectors } = paginatedEndpointSliceFactory(
  "accessTeams",
  ({ projectId, options }: SliceAccessArgs) =>
    listProjectTeamAccess(projectId, options),
  meta => meta.projectId,
  async response => {
    let items: Array<Team> = [];

    if (response._links["ref:teams:0"]) {
      const refTeams = await request<Record<string, Team>>(
        response._links["ref:teams:0"].href
      );
      items = response.items.map(team => refTeams[team.team_id]);
    }

    return {
      ...response,
      items
    };
  }
);

export const projectTeamAccessReducer = slice.reducer;

export const {
  useDataByKey: useGetProjectTeamAccesses,
  useAllData: useGetAllProjectTeamAccesses,
  useInvalidateKey: useInvalidateProjectTeamAccess
} = hooks;

export const projectAccessTeamCountSelector = selectors.selectByKey(
  state => state?.data?.items.length
);
