import { createAsyncThunk } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import { AsyncThunkOptionType } from "Reducers/types";

import { DeleteCertificateProps } from "../";

import type { Certificate } from "platformsh-client";

/**
 * Delete one Certificate
 *
 * @param {string} organizationDescriptionId
 * @param {string} projectDescriptionId
 * @param {object} certificate
 *
 */

export const deleteCertificate = createAsyncThunk<
  Certificate | undefined,
  DeleteCertificateProps,
  AsyncThunkOptionType
>(
  "app/projectCertificate/delete_certificate",
  async (
    { organizationDescriptionId, projectDescriptionId, certificate },
    { rejectWithValue }
  ) => {
    try {
      await certificate?.delete();

      return certificate;
    } catch (err: unknown) {
      logger(err, {
        action: "deleteCertificate",
        organizationDescriptionId,
        projectDescriptionId,
        certificate
      });
      return rejectWithValue(err);
    }
  },
  {
    condition: ({ certificate }: DeleteCertificateProps): boolean => {
      // If there is no certificate, stop "deleteCertificate"
      if (!certificate) return false;

      return true;
    }
  }
);
