import styled from "styled-components";

import * as Typography from "ds/Typography";

export const Description = styled(Typography.BodyText).attrs(() => ({
  weight: "normal",
  size: "small"
}))`
  margin-top: 8px;
`;

export default Description;
