import { apiProxyRequest } from "Api/apiProxyRequest";
import { ProjectSetting } from "Reducers/projectSettings";

import type {
  EnvTypeDataRetention,
  BackupDefaultConfig
} from "Reducers/projectSettings";

export const updateBackupScheduleRequest = ({
  backupRetentionData,
  projectId,
  environmentType
}: {
  backupRetentionData: BackupDefaultConfig;
  projectId: string;
  environmentType: EnvTypeDataRetention;
}) =>
  apiProxyRequest<Record<string, Record<string, ProjectSetting>>>(
    `/projects/${projectId}/settings`,
    "PATCH",
    {
      data_retention: {
        [environmentType]: backupRetentionData
      }
    }
  );
