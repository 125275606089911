import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import { AddButton, Link } from "ds/Button";
import { meSelector } from "Reducers/app/selectors";
import { selectMembers } from "Reducers/organization/members/membersList.selectors";
import { useAppSelector } from "Store/hooks";

import { useCanUserCreateProjectInOrganization } from "../hooks";
import {
  CreateProjectButtonProps,
  createProjectInOrganizationTypes
} from "../types";

import BillingDetailsModal from "./BillingDetailsModal";
import CreateFirstOrganizationModal from "./CreateFirstOrganizationModal";
import GenericModal from "./GenericModal";
import LoadingButton from "./LoadingButton";
import NotEnoughPermissionsButton from "./NotEnoughPermissionsButton";
import PhoneModal from "./PhoneModal";
import RestrictedOrganizationModal from "./RestrictedOrganizationModal";
import TicketModal from "./TicketModal";
import TrialLimitsReachedModal from "./TrialLimitsReachedModal";

const CreateProjectInOrganizationMap = {
  [createProjectInOrganizationTypes.createFirstOrganization]:
    CreateFirstOrganizationModal,
  [createProjectInOrganizationTypes.idle]: LoadingButton,
  [createProjectInOrganizationTypes.notEnoughPermissions]:
    NotEnoughPermissionsButton,
  [createProjectInOrganizationTypes.ticket]: TicketModal,
  [createProjectInOrganizationTypes.phone]: PhoneModal,
  [createProjectInOrganizationTypes.trialResourcesLimitReached]:
    TrialLimitsReachedModal,
  [createProjectInOrganizationTypes.billingDetails]: BillingDetailsModal,
  [createProjectInOrganizationTypes.restrictedOrg]: RestrictedOrganizationModal,
  [createProjectInOrganizationTypes.creditCard]: null,
  [createProjectInOrganizationTypes.generic]: GenericModal
} as const;

const CreateProjectInOrganizationButton = ({
  organizationId,
  isLink = false,
  linkStyle = {},
  linkVariant = "primary",
  onOpenModal,
  onCloseModal,
  label,
  onAuthorized,
  disabled = false
}: CreateProjectButtonProps) => {
  const { pathname } = useLocation();
  const members = useAppSelector(selectMembers);
  const me = useAppSelector(meSelector);
  const { type, message } = useCanUserCreateProjectInOrganization({
    organizationId
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const internalProps = onAuthorized
    ? ({ onClick: onAuthorized } as unknown as { to: string })
    : {
        to: `/${organizationId ?? "-"}/create-project`,
        state: { locationAtCreateProject: pathname }
      };
  const userPermissionsInOrganization = members.find(
    member => member.user?.id === me?.id
  )?.member?.permissions;
  const isOrgAdmin = userPermissionsInOrganization?.includes("admin");

  const openModal = e => {
    e.preventDefault();
    onOpenModal?.();
    setIsModalOpen(true);
  };

  useEffect(() => {
    return () => {
      if (isModalOpen && onCloseModal) onCloseModal();
    };
  }, [isModalOpen, onCloseModal]);
  const CreateProjectInOrganizationComponent =
    CreateProjectInOrganizationMap[type!];

  return type && CreateProjectInOrganizationComponent ? (
    <CreateProjectInOrganizationComponent
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      openModal={openModal}
      isLink={isLink}
      linkVariant={linkVariant}
      linkStyle={linkStyle}
      message={message}
      isOrgAdmin={isOrgAdmin}
      organizationId={organizationId}
      label={label}
      disabled={disabled}
    />
  ) : isLink ? (
    <Link
      id="create_project"
      variant={linkVariant}
      style={linkStyle}
      disabled={disabled}
      {...internalProps}
      analyticId="create_project"
    >
      {label}
    </Link>
  ) : (
    <AddButton
      id="create_project"
      variant={linkVariant}
      disabled={disabled}
      {...internalProps}
      analyticId="create_project"
    >
      {label}
    </AddButton>
  );
};

export default CreateProjectInOrganizationButton;
