import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { permissionsKeys } from "../invitations/permissions";

import { loadMembers } from "./thunks/loadMembers.thunk";
import { loadMoreMembers } from "./thunks/loadMoreMembers.thunk";
import { toggleSelectAllMembers } from "./thunks/toggleSelectAllMembers.thunk";

import type {
  MemberData,
  OrganizationMembers,
  OrganizationMemberPermissions,
  OrganizationMembersSortOptions
} from "Api/organizations/member/Member.types";
import type { RefUser } from "Api/ref/User.types";

export interface MembersListState {
  members: Array<MemberData>;
  count: number;
  isLoading: boolean;
  error: unknown;
  next?: string;
  isLoadingMore: boolean;
  sort?: OrganizationMembersSortOptions;
  // Each element on the array represents the member ID
  selected: Array<string>;
  searchFilter: string;
  permissions: Record<OrganizationMemberPermissions, boolean>;
}

const initialState: MembersListState = {
  members: [],
  count: 0,
  isLoading: false,
  error: undefined,
  next: undefined,
  isLoadingMore: false,
  sort: "created_at",
  selected: [],
  searchFilter: "",
  permissions: permissionsKeys.reduce(
    (permissions, permission) => ({
      ...permissions,
      [permission]: true
    }),
    {} as Record<OrganizationMemberPermissions, boolean>
  )
};

/** Joins both member and ref data into a single array of elements. */
const memberDataToArray = ({
  members,
  users
}: {
  members: OrganizationMembers;
  users?: { [userId: string]: RefUser };
}): Array<MemberData> =>
  members.items.map(member => ({
    member: member,
    user: users?.[member.user_id]
  }));

const membersList = createSlice({
  name: "membersList",
  initialState,
  reducers: {
    togglePermission: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      permissions: {
        ...state.permissions,
        [payload]: !state.permissions[payload]
      }
    }),
    setSearchFilter: (state, action: PayloadAction<string>) => ({
      ...state,
      searchFilter: action.payload
    }),
    setSort: (
      state,
      { payload }: PayloadAction<OrganizationMembersSortOptions>
    ) => ({
      ...state,
      sort: payload
    }),
    setSelectedMembers: (state, { payload }: PayloadAction<Array<string>>) => ({
      ...state,
      // Destructuring the payload  just to make sure the array won't change
      selected: [...payload]
    }),
    toggleSelectedMember: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      selected: state.selected.includes(payload)
        ? state.selected.filter(member => member !== payload)
        : [...state.selected, payload]
    }),
    resetFilters: state => ({
      ...state,
      searchFilter: initialState.searchFilter,
      permissions: initialState.permissions,
      sort: initialState.sort
    })
  },
  extraReducers(builder) {
    builder
      .addCase(loadMembers.pending, state => ({
        ...state,
        isLoading: true,
        members: []
      }))
      .addCase(loadMembers.fulfilled, (state, action) => ({
        ...state,
        isLoading: false,
        error: undefined,
        next: action.payload.members._links.next?.href,
        count: action.payload.members.count,
        members: memberDataToArray(action.payload)
      }))
      .addCase(loadMembers.rejected, (state, action) => ({
        ...state,
        isLoading: false,
        error: action.error
      }));

    builder
      .addCase(loadMoreMembers.pending, state => ({
        ...state,
        isLoadingMore: true
      }))
      .addCase(loadMoreMembers.fulfilled, (state, action) => ({
        ...state,
        isLoadingMore: false,
        error: undefined,
        next: action.payload.members._links.next?.href,
        members: state.members?.concat(memberDataToArray(action.payload))
      }))
      .addCase(loadMoreMembers.rejected, (state, action) => ({
        ...state,
        isLoadingMore: false,
        error: action.error,
        next: undefined
      }));

    builder.addCase(toggleSelectAllMembers.fulfilled, (state, action) => ({
      ...state,
      selected: action.payload
    }));
  }
});

export const membersListReducer = membersList.reducer;

export const {
  resetFilters,
  togglePermission,
  setSearchFilter,
  setSort,
  setSelectedMembers,
  toggleSelectedMember
} = membersList.actions;
