import styled from "styled-components";

import Button from "ds/Button";

export const BannerWrapper = styled.div`
  color: var(--theme-gradient-sellable-text);
  text-align: center;

  font-family: "Open Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  align-items: center;
`;

export const SubscribeButton = styled(Button)`
  margin-left: 8px;
`;

export const ReactivateButton = styled(Button)`
  margin-left: 8px;
  white-space: nowrap;
`;

export const BillingIconWrapper = styled.div`
  margin-right: 8px;
  display: inline-flex;
  align-items: flex-start;
`;

export const LearnMoreLink = styled(Button)`
  font-weight: 600;
  margin-left: 6px;
`;
