import React, { useMemo } from "react";

import moment from "moment";
import { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import { useIntl } from "react-intl";

import ChevronIcon from "ds/Icons/Chevron";

import { useMonthNames } from "./config";
import MonthPicker from "./MonthPicker";
import {
  MonthYearPickerWrapper,
  MonthYearSelectorContainer,
  RangeDetail,
  RangeDetailsContainer,
  TimeZoneText,
  UnavigableCalendarDate,
  YearNavigationButton
} from "./styles";
import { DateRangeType } from "./types";
import YearPicker from "./YearPicker";

type RangeHeaderProps = {
  navigable?: boolean;
  split?: boolean;
  endDate?: Date;
  yearOptions: number[];
  selectFuture?: boolean;
  startDate?: Date;
  maximumRange?: DateRangeType;
  asCalendar?: boolean;
} & ReactDatePickerCustomHeaderProps;

/**
 * Check if the User can navigate back and forth between months
 * and not exceed the maximum and minimum range limit allowed
 */
const canNextMonth = (props: {
  monthDate: Date;
  maximumRange?: DateRangeType;
}) => {
  const { monthDate, maximumRange } = props;

  if (!maximumRange) {
    return { canPrev: true, canNext: true };
  }

  const nextMonthDate = moment(maximumRange?.end)
    .month(monthDate?.getMonth())
    .year(monthDate.getFullYear())
    .add(1, "month");
  const prevMonthDate = moment(maximumRange?.start)
    .month(monthDate?.getMonth())
    .year(monthDate.getFullYear())
    .add(-1, "month");
  const canPrev = moment(maximumRange?.start).isSameOrBefore(prevMonthDate);
  const canNext = moment(maximumRange?.end).isSameOrAfter(nextMonthDate);

  return { canPrev, canNext };
};

const RangeHeader = ({
  decreaseMonth,
  date,
  increaseMonth,
  changeYear,
  changeMonth,
  split,
  startDate,
  endDate,
  yearOptions,
  navigable,
  monthDate,
  maximumRange,
  asCalendar
}: RangeHeaderProps) => {
  const zone = moment.tz.guess();
  const timezoneText = `${zone} ${moment.tz(zone).format("z Z")}`;
  const { start, end } = maximumRange || {};
  const intl = useIntl();

  const { canNext, canPrev } = useMemo(
    () =>
      canNextMonth({
        maximumRange,
        monthDate
      }),
    [maximumRange, monthDate]
  );

  const monthNames = useMonthNames();

  const disabledMonths = useMemo(
    /**
     * Get Months that are lower than maximum range start limit month
     * and higher than maximum range end limit month
     * */
    () =>
      start &&
      end &&
      monthNames
        .filter((monthName, monthIndex) => {
          const mDate = new Date(start);
          mDate.setMonth(monthIndex);
          mDate.setFullYear(monthDate.getFullYear());
          return !moment(mDate).isBetween(start, end, null, "[]");
        })
        .map(m => monthNames.indexOf(m)),

    [end, monthDate, monthNames, start]
  );

  const disabledYears = useMemo(
    /**
     * Get Years that are lower than maximum range start limit year
     * and higher than maximum range end limit year
     * */
    () =>
      start &&
      end &&
      yearOptions.filter(year => {
        const isBeforeMaxRangeStart = moment(start).isSameOrBefore(
          moment(start).year(year)
        );
        const isAfterMaxRangeEnd = moment(end).isSameOrAfter(
          moment(end).year(year)
        );
        return !isBeforeMaxRangeStart && isAfterMaxRangeEnd;
      }),
    [start, end, yearOptions]
  );

  const currentMonth = intl.formatMessage({
    id: moment(date).format("MMMM").toLowerCase()
  });

  return (
    <div>
      {!split && (
        <RangeDetailsContainer>
          {asCalendar ? (
            <RangeDetail>
              {moment(startDate).format("HH:mm MMM DD")}
            </RangeDetail>
          ) : (
            <RangeDetail>
              {moment(startDate).format("HH:mm MMM DD")} -
              {endDate ? moment(endDate).format(" HH:mm MMM DD") : ""}
            </RangeDetail>
          )}
          <TimeZoneText>{timezoneText}</TimeZoneText>
        </RangeDetailsContainer>
      )}
      <MonthYearPickerWrapper>
        {navigable ? (
          <>
            <YearNavigationButton
              disabled={!canPrev}
              onClick={canPrev ? decreaseMonth : undefined}
            >
              <ChevronIcon className="prev" />
            </YearNavigationButton>
            <MonthYearSelectorContainer>
              <MonthPicker
                currentMonth={date?.getMonth()}
                onChange={changeMonth}
                disabledMonths={disabledMonths}
              />
              <YearPicker
                onChange={changeYear}
                currentYear={date?.getUTCFullYear()}
                yearOptions={yearOptions}
                disabledYears={disabledYears}
              />
            </MonthYearSelectorContainer>
            <YearNavigationButton
              disabled={!canNext}
              onClick={canNext ? increaseMonth : undefined}
            >
              <ChevronIcon className="next" />
            </YearNavigationButton>
          </>
        ) : (
          <>
            <YearNavigationButton
              disabled={!canPrev}
              onClick={canPrev ? decreaseMonth : undefined}
            >
              <ChevronIcon className="prev" />
            </YearNavigationButton>
            <UnavigableCalendarDate>
              {`${currentMonth} ${moment(date).format("YYYY")}`}
            </UnavigableCalendarDate>
            <YearNavigationButton
              disabled={!canNext}
              onClick={canNext ? increaseMonth : undefined}
            >
              <ChevronIcon className="next" />
            </YearNavigationButton>
          </>
        )}
      </MonthYearPickerWrapper>
    </div>
  );
};

export default RangeHeader;
