import { isJson } from "Libs/utils";

import { apiProxyRequest as request } from "./apiProxyRequest";

export interface Link {
  href: string;
  method?: string;
}

export interface ResourceWithLinks {
  _links: { [key: string]: Link };
}

export type PaginatedResource<T = unknown> = {
  items: Array<T>;
  _links: { next?: { href: string } };
};

export const hasLink = <
  Resource extends ResourceWithLinks,
  Key extends keyof Resource["_links"] & string
>(
  resource: Resource,
  key: Key
) => resource._links[key];

/**
 * Makes sure the resource has the requested hal link before invoking the
 * callback.  This can be specially useful for links that can be present bsaed
 * on the user access
 */
export const resolveIfHasLink = <
  Resource extends ResourceWithLinks,
  Callback extends (...args: any) => any,
  Key extends keyof Resource["_links"] & string
>(
  resource: Resource,
  key: Key,
  resolveFunction: Callback
) => (hasLink(resource, key) ? resolveFunction : undefined);

export type APIError = { status?: string; code?: number; title: string } & (
  | {
      message: string;
      detail?: never;
    }
  | {
      message?: never;
      // Some errors can have extra details for instance, on a post payload it could
      // explain which fields are not valid
      detail: string;
    }
);

const checkFields = (obj: object): obj is APIError =>
  (("message" in obj && typeof obj.message === "string") ||
    ("detail" in obj && typeof obj.detail === "string")) &&
  "title" in obj &&
  typeof obj.title === "string";

export const validateAPIError = (potentialError: unknown) => {
  // In most cases the error thrown by the psh-client is a string that needs
  // parsing so this function works based on that premise.
  if (typeof potentialError === "string" && isJson(potentialError)) {
    const error = JSON.parse(potentialError);
    if (checkFields(error)) {
      return error;
    }
  }

  // This check is relevant to check that the error is defined, otheriwse it could be null
  if (!!potentialError && typeof potentialError === "object")
    if (checkFields(potentialError)) {
      return potentialError;
    }

  return undefined;
};

export const resolveNextPage = async <Response extends PaginatedResource>(
  responseOrURL: Response | string
) => {
  const url =
    typeof responseOrURL === "string"
      ? responseOrURL
      : responseOrURL._links.next?.href;

  if (!url) return;

  return request<Response>(url);
};
