import React from "react";

import { useIntl } from "react-intl";

import Tooltip from "ds/Tooltip";

import RequiredIcon from "./RequiredIcon.styles";
import Root from "./Root.styles";
import Text, { TextProps } from "./Text.styles";

export interface LabelProps extends TextProps {
  children?: React.ReactNode;
  className?: string;
  htmlFor?: string;
  required?: boolean;
}

const Label = ({
  htmlFor,
  disabled = false,
  isValid = true,
  required,
  className,
  children
}: LabelProps) => {
  const intl = useIntl();

  return (
    <Root htmlFor={htmlFor} className={className}>
      <Text disabled={disabled} isValid={isValid} as="span">
        {children}
      </Text>
      {required && (
        <Tooltip
          analyticId="label.field.required"
          side="top"
          tooltip={intl.formatMessage({
            id: "label.field.required",
            defaultMessage: "Required field"
          })}
        >
          <RequiredIcon isValid={isValid} />
        </Tooltip>
      )}
    </Root>
  );
};

export default Label;
