import moment from "moment";

import { DateRangeType, TimeDurationType } from "./types";

/**
 * Align Date in forward direction by ensuring
 * the lowest date remains the startDate
 */
export const alignDateRange = ({ start, end }: { start: Date; end: Date }) => {
  return moment(start).isBefore(moment(end))
    ? { start, end }
    : { start: end, end: start };
};

export const parseTime = (timeInput, defaultTime = "00:00") => {
  const isValidTimeString = (v: string) => {
    // Validate that v has a valid hour and minute in format HH:mm
    const hourMinutePtn = /^([0-2][0-9]{0,1}):([0-5][0-9]{0,2})$/;
    const isHH = input => /^[0-2][0-3]{0,1}$/.test(input);
    const isHH2 = input => /^[0-2][0-3][:]{0,1}$/.test(input);
    const isHH3 = input => /^[01][0-9][:]{0,1}$/.test(input);
    return isHH(v) || isHH3(v) || isHH2(v) || hourMinutePtn.test(v) || !v;
  };

  /**
   * HH:mm length should be less than 5 characters, lets truncate
   * extra string so the input is always less than 6 characters
   */
  if (timeInput.length > 5) timeInput = timeInput.slice(0, 5);

  const pattern2 = /^([0-9][0-9]{1})(\d{0,2})$/;
  const isHH = input => /^[0-2][0-9]{1}$/.test(input);
  const isMM = input => /^[0-5][0-9]{1}$/.test(input);

  let timeString =
    timeInput.length <= 2
      ? timeInput.replace(":", "")
      : timeInput.replace(pattern2, "$1:$2");

  timeString = isValidTimeString(timeString) ? timeString : defaultTime;
  const [hour, minute] = timeString.split(":");
  const isHour = isHH(hour);
  const isMinute = isMM(minute);
  return { time: timeString, isValid: isHour && isMinute, minute, hour };
};

export const limitRange = (props: {
  range?: Partial<DateRangeType>;
  maximumRange?: DateRangeType;
}) => {
  const { maximumRange, range } = props;
  const r = { ...range };
  if (maximumRange) {
    if (!moment(range?.start).isBetween(maximumRange.start, maximumRange.end)) {
      r.start = maximumRange.start;
    }
    if (
      // End date should not be outside of maximum range and is not the same as start range
      range?.end &&
      !moment(range?.end).isSame(r.start) &&
      !moment(range?.end).isBetween(r.start, maximumRange.end)
    ) {
      r.end = maximumRange.end;
    }
    return r;
  }
  return moment(r.start).isBefore(r.end) ? r : undefined;
};

/**
 * Convert time duration type to Date representation
 * @param timeDuration
 * @param rel
 * @returns todays Date if no time duration is provided
 */
export const calculateDate = (
  timeDuration?: TimeDurationType,
  rel: "past" | "future" = "past"
) => {
  return timeDuration
    ? moment()
        .add(
          timeDuration
            ? rel === "past"
              ? -timeDuration.range
              : timeDuration.range
            : 0,
          timeDuration?.type
        )
        .toDate()
    : undefined;
};
