import config from "console_config";

export const trackEventHeap = (name, event) => {
  if (!config.ID_HEAP || !window.heap) return;
  window.heap.track(name, event);
};
const HeapEvents = {};
const createHeapEvent = async (action, store) => {
  let event = HeapEvents[action?.type]
    ? await HeapEvents[action?.type](action, store)
    : null;
  return event;
};

export default async function heapTracker(action, store) {
  const heapEvent = await createHeapEvent(action, store);

  if (heapEvent !== null) {
    window?.dataLayer?.push(heapEvent);
  }
}
