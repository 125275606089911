import React, { useState } from "react";

import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import config from "console_config";
import HeaderMenuMobile from "Containers/NavBar/HeaderMenu/mobile";
import {
  ButtonTheme,
  CustomMenuLink,
  FloatingContainer,
  Layout,
  ThemeText,
  ThemeUIText,
  UserMenu
} from "Containers/NavBar/styles";
import TestingModal from "Containers/testing-widget";
import { MenuActiveTag } from "Containers/testing-widget/TestingModal.styles";
import { getProfileUrl } from "Libs/getUrl/getUrl";
import { hasSafeRole } from "Libs/utils";
import { selectCurrentUserRoles } from "Reducers/app/selectors";
import { getFeatureFlag } from "Reducers/featureFlags";
import { selectFeatureStaffTestModeEnabled } from "Reducers/featureFlags/featureFlags.selectors";
import { updateUserProfile } from "Reducers/profile";
import { isActiveSelector } from "Reducers/testMode";
import { useAppDispatch, useAppSelector } from "Store/hooks";

import UserInfo from "../desktop/UserInfo";
import { CreateOrgSeparator } from "../styles";

import * as S from "./styles";

import type { Me, AuthUser, AccountsProfile } from "platformsh-client";

interface Props {
  user: Me;
  profile?: AccountsProfile & AuthUser;
  accountUrl: string;
  setIsOpen: (boolean) => void;
  isOpen?: boolean;
  setIsUserMobileMenu: (boolean) => void;
}

const UserMobileMenu = ({
  user,
  accountUrl,
  profile,
  setIsOpen,
  isOpen,
  setIsUserMobileMenu
}: Props) => {
  const dispatch = useAppDispatch();
  const formatMessage = useIntl().formatMessage;
  const [openTestingModal, setOpenTestingModal] = useState(false);

  const profileUrl = getProfileUrl({
    accountUrl,
    username: user.username
  });

  const memberRoles = useAppSelector(selectCurrentUserRoles);
  const canTest = memberRoles && hasSafeRole(memberRoles);
  const isTestModeActive = useSelector(isActiveSelector);

  const staffTestModeEnabled = useAppSelector(
    selectFeatureStaffTestModeEnabled
  );

  const changeColorTheme = (mode: string) => {
    dispatch(
      updateUserProfile({
        id: profile?.id!,
        ui_colorscheme: mode
      })
    );
  };

  const organizationEnabled = getFeatureFlag("ENABLE_ORGANIZATION");

  return (
    <Layout>
      <FloatingContainer>
        <>
          <HeaderMenuMobile
            onClickBack={() => setIsUserMobileMenu(false)}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
          />

          <UserMenu>
            <>
              {organizationEnabled && (
                <UserInfo
                  displayName={profile?.display_name || user.display_name}
                  picture={profile?.picture || user.picture}
                  email={profile?.email || user.email}
                />
              )}
              <CreateOrgSeparator />

              <S.UserMenuUl>
                {config.CUSTOM_URL_VENDOR_CONSOLE &&
                  config.CUSTOM_CONSOLE_TITLE && (
                    <li>
                      <CustomMenuLink
                        label={config.CUSTOM_CONSOLE_TITLE}
                        url={
                          typeof config.CUSTOM_URL_VENDOR_CONSOLE === "string"
                            ? config.CUSTOM_URL_VENDOR_CONSOLE
                            : ""
                        }
                      />
                    </li>
                  )}

                <li>
                  <CustomMenuLink
                    label={formatMessage({ id: "menu.my_profile" })}
                    url={profileUrl || ""}
                    onClick={() => setIsOpen(false)}
                  />
                </li>

                {getFeatureFlag("ENABLE_CONNECTED_ACCOUNTS_ROUTE") && (
                  <li>
                    <CustomMenuLink
                      label={formatMessage({ id: "connected_accounts" })}
                      url={`/-/users/${user.username}/settings/accounts`}
                      onClick={() => setIsOpen(false)}
                    />
                  </li>
                )}

                <li>
                  <CustomMenuLink
                    label={formatMessage({ id: "tabs.security" })}
                    onClick={() => setIsOpen(false)}
                    url={`/-/users/${user.username}/settings/security`}
                  />
                </li>

                <li>
                  <CustomMenuLink
                    label={formatMessage({ id: "tabs.tokens" })}
                    url={`/-/users/${user.username}/settings/tokens`}
                    onClick={() => setIsOpen(false)}
                  />
                </li>
                <li>
                  <CustomMenuLink
                    label={formatMessage({ id: "tabs.ssh_keys" })}
                    onClick={() => setIsOpen(false)}
                    url={`/-/users/${user.username}/settings/ssh-keys`}
                  />
                </li>
                <CreateOrgSeparator />
                <li>
                  <ThemeText>{formatMessage({ id: "theme" })}</ThemeText>
                  <S.ButtonThemeContainer>
                    <ButtonTheme
                      selectedTheme={"light"}
                      onClick={() => {
                        changeColorTheme("light");
                      }}
                    >
                      <ThemeUIText selectedTheme={"light"}>
                        {formatMessage({ id: "light" })}
                      </ThemeUIText>
                    </ButtonTheme>
                    <ButtonTheme
                      selectedTheme={"dark"}
                      onClick={() => {
                        changeColorTheme("dark");
                      }}
                    >
                      <ThemeUIText selectedTheme={"dark"}>
                        {formatMessage({ id: "dark" })}
                      </ThemeUIText>{" "}
                    </ButtonTheme>
                    <ButtonTheme
                      selectedTheme={"contrast"}
                      onClick={() => {
                        changeColorTheme("contrast");
                      }}
                    >
                      <ThemeUIText selectedTheme={"contrast"}>
                        {formatMessage({ id: "WCAG" })}
                      </ThemeUIText>{" "}
                    </ButtonTheme>
                  </S.ButtonThemeContainer>
                </li>
                <CreateOrgSeparator />

                {canTest && staffTestModeEnabled && (
                  <li>
                    <CustomMenuLink
                      onClick={() => {
                        setOpenTestingModal(true);
                      }}
                    >
                      {formatMessage({ id: "menu.staff_test_mode" })}
                      {isTestModeActive && (
                        <MenuActiveTag variant="yellow">
                          {formatMessage({
                            id: "test_mode.active_label"
                          })}
                        </MenuActiveTag>
                      )}
                    </CustomMenuLink>
                  </li>
                )}
              </S.UserMenuUl>
            </>
            <TestingModal
              isOpen={openTestingModal}
              onClose={() => setOpenTestingModal(false)}
            />
          </UserMenu>
        </>
      </FloatingContainer>
    </Layout>
  );
};

export default UserMobileMenu;
