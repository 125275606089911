import { createAsyncThunk } from "@reduxjs/toolkit";

import { fetchObservabilityPipelineConfiguration } from "Api/observability/pipeline/fetchObservabilityPipelineConfiguration.request";
import logger from "Libs/logger";

import { ObservabilityPipelineProjectUrls } from "../types";

import type { AsyncThunkOptionType } from "Reducers/types";

export const loadObservabilityPipelineConfiguration = createAsyncThunk<
  ObservabilityPipelineProjectUrls | undefined,
  { projectId: string; machineName: string },
  AsyncThunkOptionType
>(
  "app/observability_pipeline/configuration",
  async ({ projectId, machineName }, { rejectWithValue }) => {
    try {
      return await fetchObservabilityPipelineConfiguration(
        projectId,
        machineName
      );
    } catch (err) {
      logger(err, {
        action: "app/observability_pipeline/configuration"
      });

      return rejectWithValue(err);
    }
  }
);
