import React from "react";

import * as Tabs from "@radix-ui/react-tabs";

import { sendHeapEventTab } from "Store/analytics/heapFunctions";

const TabsRoot = props => {
  const onTabsValueChange = tabValue => {
    props.onValueChange?.(tabValue);
    sendHeapEventTab({ analyticId: tabValue });
  };

  return <Tabs.Root {...props} onValueChange={onTabsValueChange} />;
};

export default TabsRoot;
