import React, { useContext, useEffect, ReactNode } from "react";

import { sendHeapEventDialog } from "Store/analytics/heapFunctions";

import { DialogContext } from "./dialog.context";
import * as S from "./Trigger.styles";
type SendHeapEventProps = { isOpen: boolean; analyticId?: string };

type Props = {
  children: ReactNode;
  sendHeapEvent?: (arg: SendHeapEventProps) => void;
  analyticId?: string;
};

const Trigger = ({ children, analyticId }: Props) => {
  const dialogContext = useContext(DialogContext);

  useEffect(() => {
    sendHeapEventDialog?.({ isOpen: dialogContext.isOpen, analyticId });
  }, [dialogContext.isOpen, analyticId]);

  return (
    <S.Trigger
      asChild
      $hoverState={dialogContext.isOpen}
      onMouseEnter={() => dialogContext.setIsOpen(true)}
      onMouseLeave={() => dialogContext.setIsOpen(false)}
    >
      {children}
    </S.Trigger>
  );
};

export default Trigger;
