import React, { HTMLProps, useRef, useState } from "react";

import Close from "./Close.styles";
import Icon from "./Icon.styles";
import Root from "./Root.styles";
import SearchPrimitive from "./Search.styles";

export interface SearchProps
  extends Omit<HTMLProps<HTMLInputElement>, "onChange" | "ref" | "as"> {
  onChange: (value: string) => void;
  customWidth?: string;
  onBlurState?: boolean;
  closedByDefault?: boolean;
  showClose?: boolean;
}

const Search = ({
  className,
  onChange,
  customWidth,
  onBlurState,
  closedByDefault,
  showClose = true,
  ...props
}: SearchProps) => {
  const searchInput = useRef<HTMLInputElement>(null);
  const [isActive, setIsActive] = useState(closedByDefault ? false : true);

  return (
    <Root
      onBlur={onBlurState ? () => setIsActive(!onBlurState) : undefined}
      $isActive={isActive}
      className={className}
      customWidth={customWidth}
    >
      <Icon
        aria-label="Open search"
        $isActive={isActive}
        onClick={() => {
          setIsActive(true);
          searchInput.current?.focus();
        }}
      />
      <SearchPrimitive
        ref={searchInput}
        $isActive={isActive}
        onFocus={() => setIsActive(true)}
        onChange={event => onChange(event.currentTarget.value)}
        {...props}
      />
      {showClose && (
        <Close
          aria-label="Clear search"
          $isActive={isActive}
          onFocus={() => setIsActive(true)}
          onBlur={() => setIsActive(!!props.value)}
          onClick={() => {
            onChange("");
            setIsActive(false);
          }}
        />
      )}
    </Root>
  );
};

// Re-rendering this component might be expensive, since it will be embed on
// other components, we don't want to decrease the performance of those
// components by adding unwanted renders.
export default React.memo(Search);
