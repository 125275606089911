import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";

import logger from "Libs/logger";
import { setDeep } from "Libs/objectAccess";
import { AppDispatch, RootState } from "Store/configureStore";

import type { Activity } from "platformsh-client";

type SyncThunkPropType = {
  organizationId: string;
  projectId: string;
  environmentId: string;
  options: {
    syncData: boolean;
    syncCode: boolean;
    syncResources?: boolean;
  };
};

type SyncError = {
  code: string;
  detail: {
    error: string;
  };
  message?: string;
  status: string;
  title: string;
};

const sync = createAsyncThunk<
  Activity,
  SyncThunkPropType,
  { dispatch: AppDispatch; state: RootState }
>(
  "environment/sync",
  async (
    { options, organizationId, projectId, environmentId },
    { getState, rejectWithValue }
  ) => {
    try {
      const environment =
        getState().environment?.data?.[organizationId]?.[projectId]?.[
          environmentId
        ];

      if (!environment) return rejectWithValue("Environment not found");

      const args: [boolean, boolean] | [boolean, boolean, boolean] = [
        options.syncData,
        options.syncCode
      ];

      if (typeof options.syncResources !== "undefined") {
        args.push(options.syncResources);
      }

      const sync = await environment.synchronize(...args);
      return sync;
    } catch (err: any) {
      logger(err, {
        action: "sync",
        meta: {
          organizationId,
          environmentId,
          projectId
        }
      });
      return rejectWithValue(err);
    }
  }
);

interface SyncState {
  isLoading: boolean;
  errors: string | null;
  data: {
    [organizationId: string]: {
      [projectId: string]: {
        [environmentId: string]: Activity;
      };
    };
  };
}

export const initialState: SyncState = {
  isLoading: false,
  errors: null,
  data: {}
};

const syncSlice = createSlice({
  name: "sync",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(sync.pending, state => {
        state.isLoading = true;
        state.errors = null;
      })
      .addCase(sync.fulfilled, (state, { meta: { arg }, payload }) => {
        state.isLoading = false;
        state.errors = null;
        setDeep(
          state,
          ["data", arg.organizationId, arg.projectId, arg.environmentId],
          payload
        );
      })
      .addCase(sync.rejected, (state, { payload, error }) => {
        state.isLoading = false;
        const payloadError = payload as SyncError;
        const errorObject = payloadError ?? error;
        const errorMessage =
          errorObject?.detail?.error || errorObject?.message || null;

        state.errors = errorMessage;
      });
  }
});

const selectSyncState = (state: RootState) => state.synchronisation;

export const selectSynchronisationData = createSelector(
  selectSyncState,
  sync => sync.data
);

export const selectIsSynchronisationLoading = createSelector(
  selectSyncState,
  sync => sync.isLoading
);

export const selectSynchronisationErrors = createSelector(
  selectSyncState,
  sync => sync.errors
);

export default syncSlice.reducer;
export { sync };
