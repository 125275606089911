import React from "react";

import { DropdownMenuItemProps } from "@radix-ui/react-dropdown-menu";

import { sendHeapEventDropdownItem } from "Store/analytics/heapFunctions";

import Item from "./Item";
import * as S from "./LinkItem.styles";

interface LinkItemProps extends DropdownMenuItemProps {
  children: React.ReactNode;
  href?: string;
  to?: string | { pathname: string; state: { from: string | undefined } };
  analyticId?: string;
  disabled?: boolean;
  ref?: React.ForwardedRef<HTMLDivElement>;
  isSelected?: boolean;
}

const LinkItem = React.forwardRef<HTMLDivElement, LinkItemProps>(
  function LinkItem(
    {
      children,
      href,
      to,
      analyticId,
      disabled,
      onSelect,
      isSelected,
      ...itemProps
    }: LinkItemProps,
    ref
  ) {
    const toProp = typeof to === "string" ? to : to?.pathname;
    const onSelectHandler = e => {
      sendHeapEventDropdownItem({
        analyticId: analyticId || href || toProp,
        type: "link"
      });
      onSelect?.(e);
    };
    return (
      <Item
        asChild
        ref={ref}
        {...itemProps}
        disabled={disabled}
        onSelect={onSelectHandler}
        analyticId={(analyticId || href || toProp) ?? ""}
        isSelected={isSelected}
      >
        {href ? (
          <S.Link href={href}>{children}</S.Link>
        ) : to ? (
          <S.Link to={toProp!}>{children}</S.Link>
        ) : (
          <S.Link href={href!} to={toProp!}>
            {children}
          </S.Link>
        )}
      </Item>
    );
  }
);

export default LinkItem;
