import React from "react";

import Icon from "ds/Icon";
import { WrappingIconProps } from "ds/Icon/Icon";

const Security = (props: WrappingIconProps) => {
  return (
    <Icon {...props}>
      <g clipPath="url(#clip0_5158_5229)">
        <path
          d="M12.0001 3C14.3359 5.06658 17.3846 6.14257 20.5001 6C20.9537 7.54302 21.0925 9.16147 20.9082 10.7592C20.7239 12.3569 20.2204 13.9013 19.4275 15.3005C18.6346 16.6998 17.5685 17.9254 16.2925 18.9045C15.0166 19.8836 13.5569 20.5962 12.0001 21C10.4433 20.5962 8.98356 19.8836 7.70762 18.9045C6.43169 17.9254 5.36559 16.6998 4.57267 15.3005C3.77976 13.9013 3.27621 12.3569 3.09195 10.7592C2.9077 9.16147 3.04649 7.54302 3.50008 6C6.61561 6.14257 9.66425 5.06658 12.0001 3Z"
          data-stroke
          style={{
            stroke: "var(--mode-vector-neutral-default)",
            fill: "transparent"
          }}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 11C11 11.2652 11.1054 11.5196 11.2929 11.7071C11.4804 11.8946 11.7348 12 12 12C12.2652 12 12.5196 11.8946 12.7071 11.7071C12.8946 11.5196 13 11.2652 13 11C13 10.7348 12.8946 10.4804 12.7071 10.2929C12.5196 10.1054 12.2652 10 12 10C11.7348 10 11.4804 10.1054 11.2929 10.2929C11.1054 10.4804 11 10.7348 11 11Z"
          data-stroke
          style={{
            stroke: "var(--mode-vector-neutral-default)"
          }}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 12V14.5"
          data-stroke
          style={{
            stroke: "var(--mode-vector-neutral-default)"
          }}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5158_5229">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default Security;
