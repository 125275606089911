import styled from "styled-components";

import { UserMenu } from "Containers/NavBar/styles";

export const UserMainMenu = styled(UserMenu)`
  a {
    display: inline-block;
  }
`;

export const ButtonThemeContainer = styled.div`
  display: flex;
  height: 24px;
  margin-top: 8px;
  margin-bottom: 16px;
  align-items: center;
  justify-content: left;
`;

export const LinksUl = styled.ul`
  padding-left: 0cqb;
`;

export const UserMenuUl = styled.ul`
  padding-left: 8px;
`;
