import * as LabelPrimitive from "@radix-ui/react-label";
import styled from "styled-components";

import { Layout } from "ds/Tooltip/Trigger.styles";

export const Root = styled(LabelPrimitive.Root)`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  max-height: 38px;

  ${Layout} {
    display: flex;
  }
`;

export default Root;
