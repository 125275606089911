import { createAsyncThunk } from "@reduxjs/toolkit";

import { getOrganizationId } from "Libs/utils";
import { AsyncThunkOptionType } from "Reducers/types";

import type { OrganizationPaymentSource } from "platformsh-client";

export const getOrganizationPaymentSource = createAsyncThunk<
  OrganizationPaymentSource | undefined,
  { organizationId: string; shouldIncludeNonChargeable?: boolean },
  AsyncThunkOptionType
>(
  "app/organization/paymentSource",
  async ({ organizationId, shouldIncludeNonChargeable }, { getState }) => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;
    const includeNonChargeable = shouldIncludeNonChargeable ? 1 : 0;

    const orgId = getOrganizationId(getState, organizationId);
    const paymentSource = orgId
      ? await client.getOrganizationPaymentSource(orgId, includeNonChargeable)
      : undefined;

    return paymentSource;
  }
);
