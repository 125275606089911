// Boilerplate source https://github.com/coryhouse/react-slingshot
import "regenerator-runtime/runtime";
import React from "react";

import { createRoot } from "react-dom/client";
import TagManager from "react-gtm-module";
import { RawIntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { BrowserRouter, RouterProvider } from "react-router-dom";

import AppErrorBoundary from "Components/AppErrorBoundary";
import config from "console_config";
import { ANALYTICS_ENABLED } from "Constants/constants";
import { getIntl } from "Libs/intl";

import App from "./root/pages/Routes";
import { setTracking } from "./root/pages/utils/cookie";
import authRoutes from "./routes.auth";
import consoleRoutes from "./routes.console";
import configureAppStore from "./store/configureStore";

import("Vendor_resources/images/favicon.ico");

// If on Auth UI, store the tracking on a singleton
// to forward it in the request to the Auth Server
if (window.location.origin === config.URL_AUTH) {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.forEach((v, k) => {
    if (k !== "_gclid" && !k.startsWith("_utm_") && k !== "trial_type") {
      urlParams.delete(k);
    }
  });

  setTracking(urlParams);
}

if (ANALYTICS_ENABLED) {
  const tagManagerArgs = {
    gtmId: config.ID_GOOGLE_TAG_MANAGER,
    cookieDomain: config.CUSTOM_COOKIE_DOMAIN
  };
  TagManager.initialize(tagManagerArgs);
}

const store = configureAppStore();
const isAuth = window.location.origin === config.URL_AUTH;

const fallbackContainer = document.getElementById("fallback");
setTimeout(() => {
  const appContainer = document.getElementById("app");
  if (fallbackContainer && appContainer.hasChildNodes()) {
    fallbackContainer.parentNode
      ? fallbackContainer.parentNode.removeChild(fallbackContainer)
      : "";
  }
}, 10000);

if (config.ID_PLATFORM_TREE) {
  //  eslint-disable-next-line no-console
  console.log(`Build ID: %c${config.ID_PLATFORM_TREE}`, "color: green");
}

const root = createRoot(document.getElementById("app"));
root.render(
  <AppErrorBoundary>
    <Provider store={store}>
      <RawIntlProvider value={getIntl()}>
        <React.Suspense fallback={<div />}>
          {/* TODO Once the console app has been migrated we can remove the if and the browser router on the else condition */}
          {window.location.origin === config.URL_AUTH ? (
            <RouterProvider
              router={isAuth ? authRoutes : consoleRoutes}
              fallbackElement={<div />}
            />
          ) : (
            <BrowserRouter>
              <App />
            </BrowserRouter>
          )}
        </React.Suspense>
      </RawIntlProvider>
    </Provider>
  </AppErrorBoundary>
);
