import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "Store/configureStore";

const selectSelf = (state: RootState) => state.discount;

export const discountsSelector = createSelector(
  selectSelf,
  discounts => discounts.data
);

export const discountsLoadingSelector = createSelector(
  selectSelf,
  discounts => discounts.loading
);

export const discountsErrorsSelector = createSelector(
  selectSelf,
  discounts => discounts.errors
);
