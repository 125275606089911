import { createSelector } from "@reduxjs/toolkit";

import type { RootState } from "Store/configureStore";

export const projectStateSelector = (state: RootState) => state.view.comments;

export const newCommentSelector = createSelector(
  [projectStateSelector],
  commentsState => commentsState.newComment
);

export const hasMoreCommentsSelector = createSelector(
  [projectStateSelector],
  commentsState => commentsState.hasMore
);

export const isLoadingCommentsSelector = createSelector(
  [projectStateSelector],
  commentsState => commentsState.isLoadingComment
);

export const commentsSelector = createSelector(
  [projectStateSelector],
  commentsState => commentsState.comments
);

export const commentPageSelector = createSelector(
  [projectStateSelector],
  commentsState => commentsState.commentPage
);
