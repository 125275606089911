import styled from "styled-components";

import * as Dropdown from "ds/Dropdown";
import { Scrollbox as ScrollboxPrimitive } from "ds/Scrollbox";
import Search, { Icon } from "ds/Search";
import { Search as SearchPrimitive } from "ds/Search/Search.styles";
import * as Typography from "ds/Typography";
import { semiBoldAlias } from "Libs/theme";

export const NavBarLink = styled.nav`
  flex-shrink: 2;
  position: relative;
  height: 56px;
  box-sizing: border-box;
  width: 100%;

  > span {
    color: var(--navbar-snow-color, var(--snow));
    text-decoration: none;
    font-size: 16px;
    z-index: 3;
    position: relative;
  }
  &.header-arrow {
    ${semiBoldAlias()};
  }
  &.header-arrow-open:focus {
    outline: none;
  }
  .navbar-link button,
  .navbar-link a {
    font-size: 15px;
    line-height: 22px;
    font-weight: normal;
    color: #fff;
    padding: 0 0 0 24px;
    width: 100%;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    &:hover,
    &:focus {
      outline: none;
    }
    &:focus {
      span.active {
        position: absolute;
        top: 27px;
        left: 12px;
        display: ${props =>
          props.theme.name === "contrast" ? "inline-block" : "none"};
        height: 4px;
        width: 4px;
        border-radius: 4px;
        background: #fff;
      }
    }

    &.chevron-link {
      width: 43px;
      min-width: 43px;
      padding: 0 1px 0 0;
      svg {
        path {
          fill: var(--icon-periwinkle-grey-fill, var(--periwinkle-grey));
        }
      }
    }
    &.chevron-link .open,
    &.chevron-link :hover {
      svg {
        path {
          fill: var(--icon-snow-fill, var(--snow));
        }
      }
    }
  }

  &.active,
  &:hover {
    background-color: var(
      --navbar-link-section-background-color,
      var(--section-dark-tint-background-color, var(--dark-tint))
    );

    border-radius: var(
      --navbar-layout-section-border-radius,
      var(--section-0px-border-radius, var(--0px))
    );
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    .navbar-link .chevron-link svg {
      opacity: 1;
    }
  }
  span.chevron {
    min-width: 42px;
    height: 24px;
    border-left: 1px solid var(--link-slate-border-color, var(--slate));
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    &.open {
      border-left: none;
      border-right: 1px solid var(--link-slate-color, var(--slate));
    }
  }
  &:not(.active):hover {
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-width: 0 1px 0 0;
    button.chevron-link,
    a.chevron-link {
      margin-right: -2px;
    }
    span.chevron {
      width: 40px;
      min-width: 40px;
    }
  }
  @media (min-width: 768px) {
    width: 232px;
  }
`;

export const Label = styled(Typography.Label)`
  color: var(--mode-text-neutral-inverse-strong);
  opacity: 0.7;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0;
  font-size: 10px;
`;

export const DropdownContentEnvironments = styled(Dropdown.Content)`
  width: 300px;
  padding: 0 0 8px 0;
`;

export const SearchInput = styled(Search)`
  height: 57px;
  padding-left: 24px;
  background-color: var(--mode-bg-neutral-weaker);

  :has(${SearchPrimitive}:focus) {
    ${Icon} {
      fill: var(--mode-vector-neutral-strong);
    }
  }

  ${SearchPrimitive} {
    padding-left: 5px;
    &::placeholder {
      font-size: 14px;
      line-height: 24px;
      color: var(--mode-text-neutral-default-weak);
    }

    &:focus {
      &::placeholder {
        color: var(--mode-text-neutral-weakest);
      }
    }
  }

  ${Icon} {
    fill: var(--mode-vector-neutral-default);
  }
`;

export const NoScroll = styled.div`
  padding: 8px 0;
`;

export const Scrollbox = styled(ScrollboxPrimitive)`
  height: 332px;
  .simplebar-content-wrapper {
    padding: 8px 12px 8px 0;
  }
`;

export const Environments = styled.div`
  padding: 0 8px;
`;

export const Empty = styled(Typography.BodyText)`
  margin: 8px 0 0 16px;
`;
