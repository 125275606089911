import React, { ComponentProps } from "react";

import * as LabelPrimitive from "@radix-ui/react-label";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import styled, { css as styledCSS } from "styled-components";

export const Group = styled(RadioGroupPrimitive.Root)`
  display: flex;
  flex-direction: column;
`;

export const Indicator = styled(
  ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isValid,
    ...props
  }: { isValid?: boolean; analyticId?: string } & ComponentProps<
    typeof RadioGroupPrimitive.Indicator
  >) => <RadioGroupPrimitive.Indicator {...props} />
)<{ isValid?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;

  &:after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--mode-bg-primary-default);
    ${({ isValid }) =>
      !isValid &&
      styledCSS`
    background-color: var(--mode-bg-critical-default);

    `}
  }
`;

export const Item = styled(
  ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isValid,
    ...props
  }: { isValid?: boolean } & ComponentProps<
    typeof RadioGroupPrimitive.Item
  >) => <RadioGroupPrimitive.Item {...props} />
)<{ isValid?: boolean }>`
  all: unset;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  border: 1px solid;
  border-color: var(--mode-border-neutral-default);

  &[data-state="checked"] {
    border-color: var(--mode-border-primary-strong);
  }
  :disabled {
    box-shadow: none;
    opacity: 0.5;
    cursor: not-allowed;
  }
  ${({ isValid }) =>
    isValid &&
    styledCSS`
    &:focus-visible {
    border-color: var(--mode-border-primary-strong);
    box-shadow: 0px 0px 2px
        var(--mode-border-primary-strong),
      inset 0 0 2px ${"var(--mode-border-primary-strong)"};
  }

`};

  ${({ isValid }) =>
    !isValid &&
    styledCSS`
    border-color: var(--mode-border-critical-default);

    &[data-state="checked"] {
      border-color: var(--mode-border-critical-default);
    }
  `};
`;

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

interface LabelProps {
  disabled?: boolean;
}

export const Label = styled(LabelPrimitive.Root)<LabelProps>`
  font-family: "Open Sans";
  margin-left: 12px;
  font-size: 15px;
  line-height: 24px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-top: -3px;

  ${({ disabled }) =>
    disabled &&
    styledCSS`
      cursor: not-allowed;
      color: var(--mode-text-neutral-weakest-disabled);
    `};
`;

export const OptionDetails = styled.span`
  color: var(--mode-text-neutral-weakest);
`;
