import { createSelector } from "@reduxjs/toolkit";
import isObject from "lodash/isObject";

import type { RootState } from "Store/configureStore";

export const addonsStateSelector = (state: RootState) => state.addons;

export const loadingAddonsSelector = createSelector(
  addonsStateSelector,
  addonsState => addonsState.loadingAddons
);

export const addonsDataSelector = createSelector(
  addonsStateSelector,
  addonsState => addonsState.data
);

export const addonsSelector = createSelector(
  addonsDataSelector,
  (_: RootState, params: { organizationId: string }) => params,
  (data, { organizationId }) => data?.[organizationId]
);

export const addonsCurrentSelector = createSelector(
  addonsSelector,
  addons => addons?.current
);

export const addonsErrorSelector = createSelector(
  addonsStateSelector,
  addonsState => addonsState?.error
);

export const isAddonsLegacySelector = createSelector(
  addonsCurrentSelector,
  currentAddons => {
    const usingNewestAPiShape =
      isObject(currentAddons?.user_management) ||
      isObject(currentAddons?.support_level);
    return !usingNewestAPiShape;
  }
);

export const addonsBreakdownSelector = createSelector(
  addonsCurrentSelector,
  currentAddons => {
    const userManagement = currentAddons?.user_management as {
      standard: number;
    };
    const daysRemaining = userManagement?.standard ?? 0;
    const addonIsUndefined = typeof currentAddons === "undefined";

    return {
      currentAddons,
      userManagement,
      daysRemaining,
      addonIsUndefined
    };
  }
);

export const addonSellableDaysSelector = createSelector(
  addonsSelector,
  addons =>
    (
      addons?.available?.user_management as {
        standard: number;
      }
    )?.standard || 0
);
