import React, { useCallback, useEffect, useRef, useState } from "react";

export interface CodeFieldPropType {
  codeLength?: number;
  onChange: (code: string) => void;
  isInvalid?: boolean;
}

import * as S from "./CodeField.styles";

const FIELD_ID = "code-field-";

const CodeField = ({
  codeLength = 6,
  onChange,
  isInvalid = false
}: CodeFieldPropType) => {
  // Create a default array with "" value
  const [code, setCode] = useState<string[]>(new Array(codeLength).fill(""));
  const refs = useRef(new Array(codeLength));

  const setFocus = useCallback(
    (index: number) => {
      if (index >= 0 && index < codeLength) refs.current[index]?.focus();
    },
    [codeLength]
  );

  useEffect(() => {
    const firstEmpty = Object.values(code).findIndex(elt => elt === "");
    // If all inputs are filled, focus the last one
    setFocus(firstEmpty < 0 ? length : firstEmpty);
    onChange(Object.values(code).join(""));
  }, [code, onChange, setFocus]);

  const getIndex = (id: string) => {
    return Number(id.replace(FIELD_ID, ""));
  };

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();
    const { target } = evt;
    const index = getIndex(target.id);
    const value = target.value.slice(-1).replace(/\D/g, " ");
    if (value !== " ") setCode({ ...code, [index]: value });
  };

  const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    const { key, target } = evt;
    const index = getIndex((target as Element).id);
    if (key === "Backspace" && code[index] === "") {
      evt.preventDefault();
      if (code[index] === "") setFocus(index - 1);
    }
  };

  const handleFocus = (evt: React.FocusEvent<HTMLInputElement>) => {
    evt.preventDefault();
    const { target } = evt;
    const index = getIndex(target.id);
    // Go to the previous field if is empty
    if (!code[index - 1]) setFocus(index - 1);

    if (index + 1 === codeLength) return;
    // Can't focus on previous field if next is not empty
    if (code[index] && code[index + 1]) setFocus(index + 1);
  };

  const handlePaste = (evt: React.ClipboardEvent<HTMLInputElement>) => {
    evt.preventDefault();
    const { clipboardData, target } = evt;
    const index = getIndex((target as Element).id);
    if (index !== 0) return;
    const pastedValue = clipboardData
      .getData("text")
      .replace(/\D/g, "")
      .slice(0, codeLength);
    setCode([
      ...pastedValue,
      ...new Array(codeLength - pastedValue.length).fill("")
    ]);
  };

  return (
    <S.Wrapper>
      {Object.keys(code).map((_, i) => (
        <S.Field
          key={i}
          id={`${FIELD_ID}${i}`}
          type="text"
          value={code[i]}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          onPaste={handlePaste}
          ref={el => (refs.current[i] = el)}
          aria-invalid={isInvalid}
        />
      ))}
    </S.Wrapper>
  );
};

export default CodeField;
