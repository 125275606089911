import styled, { css } from "styled-components";

import * as Dropdown from "ds/Dropdown";

export const DropdownTrigger = styled(Dropdown.Trigger)<{
  $isNav: boolean;
}>`
  ${({ $isNav }) =>
    $isNav &&
    css`
      :hover {
        background-color: var(--mode-bg-neutral-inverse-weaker);
      }
    `}
`;

export const DropdownSeparator = styled(Dropdown.Separator)`
  margin: 16px 8px;
`;
