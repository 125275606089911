import styled, { css as styledCSS } from "styled-components";

export const Code = styled.span<{ variant: string }>`
  font-family: var(--ff-mono), monospace;
  width: fit-content;
  border-radius: var(
    --ds-code-label-border-radius,
    var(--label-2px-border-radius, var(--2px))
  );
  line-height: 24px;
  font-size: 12px;

  ${({ variant }) => {
    return variant === "inline"
      ? styledCSS`
            color: var(--inline-code-section-color,var(--section-color-primary-600-color,var(--color-primary-600)));
            background-color:var(--inline-code-section-background-color,var(--section-color-primary-100-background-color,var(--color-primary-100)));
            padding: 0px 8px;
            font-weight: 600;
            display: inline-block;
            `
      : styledCSS`
            color: var(--section-ebony-light-color,var(--ebony-light));
            `;
  }}
`;
export default Code;
