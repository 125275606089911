import React from "react";

import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { withTheme } from "styled-components";

import config from "console_config";
import { isActiveSelector } from "Reducers/testMode";

import { Layout, WithIconWrapper, Heart } from "./AppVersion.styles";
import { getTextColor } from "./util";

import type { DefaultTheme } from "styled-components";

type AppVersionProps = {
  theme: DefaultTheme;
};

const AppVersion = ({ theme }: AppVersionProps) => {
  const isTestModeActive = useSelector(isActiveSelector);
  const heartColor = getTextColor(theme);
  const footerText = config.CUSTOM_FOOTER_TEXT || (
    <WithIconWrapper>
      with <Heart color={heartColor} />
    </WithIconWrapper>
  );
  const appVersion = `${config.VERSION_NUMBER}${
    isTestModeActive ? "+testmode" : ""
  }`;

  return (
    <Layout className="version" marginTop={0}>
      <FormattedMessage
        id="footer.message"
        values={{
          appVersion,
          footerText
        }}
      />
    </Layout>
  );
};

export default withTheme(AppVersion);
