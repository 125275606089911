import styled from "styled-components";

import { CloseButton } from "ds/Button";

export const CloseBtn = styled(CloseButton)`
  position: absolute;
  right: 24px;
`;

export default CloseBtn;
