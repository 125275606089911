import { useState, useRef, useCallback, useEffect } from "react";

const useObserver = () => {
  const intersectionObserver = useRef<IntersectionObserver>();
  const mutationObserver = useRef<MutationObserver>();

  const [state, setState] = useState<{
    lastVisibleElement?: number;
    hiddenCount?: number;
  }>({ lastVisibleElement: undefined, hiddenCount: undefined });

  const refCallback = useCallback((element: HTMLDivElement) => {
    // Cleanup in case the referenced element is suddenly gone
    if (!element) {
      intersectionObserver.current?.disconnect();
      mutationObserver.current?.disconnect();
      return;
    }

    intersectionObserver.current = new IntersectionObserver(
      entries => {
        const children = element.children;

        // Exiting early so we don't have to perform more complex logic
        // on the rest of the function
        if (!children.length) return;

        // Setting the default last visible element to the last child or the
        // first one if the list is empty. Down this will be overriden if
        // there's any hidden element.
        let lastVisibleElementIndex = Math.max(children.length - 1, 0);

        // First pass to gather which element fits within the box and is fully
        // visible
        for (let i = 0; i < entries.length; i++) {
          const { isIntersecting, target } = entries[i];
          const element = target as HTMLElement;
          const isVisible =
            isIntersecting || (!isIntersecting && children.length === 1);

          element.style.transform = isVisible ? "scale(1)" : "scale(0)";
        }

        // Second pass to fix the margins on the last visible element so the
        // hidden counter will fit
        for (let i = 0; i < children.length; i++) {
          const child = children[i] as HTMLElement;

          if (child.style.transform === "scale(1)") {
            child.style.marginRight = "0";
          }

          if (child.style.transform === "scale(0)") {
            lastVisibleElementIndex = Math.max(i - 1, 0);
            (
              children[lastVisibleElementIndex] as HTMLElement
            ).style.marginRight = "16px";
            break;
          }
        }

        // Processing the starting point on the next tick so the browser has
        // time to do all the reflows required after setting up the margin
        setTimeout(() => {
          const rootLeft = element.getBoundingClientRect().left;
          const lastVisibleElement =
            children[lastVisibleElementIndex].getBoundingClientRect().right -
            rootLeft;

          setState({
            lastVisibleElement,
            hiddenCount: Math.max(
              children.length - (lastVisibleElementIndex + 1),
              0
            )
          });
        }, 0);
      },
      {
        root: element,
        threshold: 1 // The element must be fully rendered within the container
      }
    );

    Array.from(element.children).forEach(child =>
      intersectionObserver.current?.observe(child)
    );

    // Listening for child changes is a must otherwise adding new children to
    // the list without a full re render of the component will end up on
    // missing updates.
    mutationObserver.current = new MutationObserver(mutations => {
      for (const mutation of mutations) {
        if (mutation.type === "childList") {
          for (const addition of mutation.addedNodes)
            intersectionObserver.current?.observe(addition as Element);
          for (const deletion of mutation.removedNodes)
            intersectionObserver.current?.observe(deletion as Element);
        }
      }
    });
    mutationObserver.current.observe(element, { childList: true });
  }, []);

  // Cleanup
  useEffect(
    () => () => {
      mutationObserver.current?.disconnect();
      intersectionObserver.current?.disconnect();
    },
    [mutationObserver, intersectionObserver]
  );

  return [refCallback, state.lastVisibleElement, state.hiddenCount] as const;
};
export default useObserver;
