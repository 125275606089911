import React from "react";

import ReactSelect, { Props, GroupBase } from "react-select";

import { sendHeapEventSelect } from "Store/analytics/heapFunctions";

import * as S from "./Select.styles";

const Select = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>({
  styles,
  onChange,
  ...props
}: Props<Option, IsMulti, Group>) => (
  <ReactSelect
    menuPortalTarget={
      document.querySelector<HTMLDivElement>("#portal-root") || document.body
    }
    styles={{
      input: S.input,
      control: S.control,
      indicatorSeparator: S.indicatorSeparator,
      placeholder: S.placeholder,
      indicatorsContainer: S.indicatorsContainer,
      singleValue: S.singleValue,
      menu: S.menu,
      menuPortal: S.menuPortal,
      option: S.option,
      groupHeading: S.groupHeading,
      group: S.group,
      valueContainer: S.valueContainer,
      ...styles
    }}
    {...props}
    onChange={(...args) => {
      onChange && onChange(...args);
      const analyticId = props.id || props.name;
      const selectedValue = (args[0] as any).value;
      sendHeapEventSelect(analyticId, selectedValue);
    }}
  />
);

export default Select;
