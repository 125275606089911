import { useCallback } from "react";

import { useIntl } from "react-intl";

import { Invitation } from "Api/organizations/invitation/invitation.types";
import { useNotification } from "Containers/Notifications";
import { sleep } from "Libs/utils";
import {
  invite as inviteThunk,
  resend as resendThunk,
  isInviteRejectedReturn,
  isRevokeRejectedReturn,
  isResendRejectedReturn,
  revokeInvitation,
  setSelected
} from "Reducers/organization/invitations/invitations";
import {
  selectSort,
  selectFilterState
} from "Reducers/organization/invitations/invitations.filters";
import { loadOrganizationInvitations } from "Reducers/organization/invitations/thunks/loadOrganizationInvitations.thunk";
import { useLoadMembers } from "Reducers/organization/members/hooks/useLoadMembers";
import { useAppDispatch, useAppSelector } from "Store/hooks";

import type { OrganizationMemberPermissions } from "Api/organizations/member/Member.types";

const useInvitations = (organizationId: string) => {
  const dispatch = useAppDispatch();
  const notify = useNotification();
  const intl = useIntl();
  const loadMembers = useLoadMembers(organizationId);
  const sort = useAppSelector(selectSort);
  const filter = useAppSelector(selectFilterState);

  const load = useCallback(
    () =>
      dispatch(
        loadOrganizationInvitations({
          organizationId,
          parameters: { sort, filter }
        })
      ),
    [organizationId, sort, filter, dispatch]
  );

  const revoke = useCallback(
    async (invitations: Array<Invitation>) => {
      const action = await dispatch(
        revokeInvitation({ organizationId, invitations })
      );

      if (isRevokeRejectedReturn(action)) {
        return;
      }

      const { fulfilled, rejected } = action.payload;

      if (fulfilled.length) {
        notify.success(
          intl.formatMessage(
            { id: "organization.invitation.revoke.success.title" },
            { count: fulfilled.length }
          ),
          intl.formatMessage(
            { id: "organization.invitation.revoke.success.description" },
            {
              email: fulfilled.map(invitation => invitation.email).join(", ")
            }
          )
        );
        load();
      }

      if (rejected.length) {
        rejected.map(invitation =>
          notify.error(
            intl.formatMessage({
              id: "organization.invitation.revoke.rejected.title"
            }),
            intl.formatMessage(
              { id: "organization.invitation.revoke.rejected.description" },
              { email: invitation.email }
            )
          )
        );
      }
    },
    [dispatch, organizationId, intl, load, notify]
  );

  const invite = useCallback(
    async (
      emails: Array<string>,
      permissions: Array<OrganizationMemberPermissions>
    ) => {
      const action = await dispatch(
        inviteThunk({
          organizationId,
          emails,
          permissions,
          force: false
        })
      );

      if (isInviteRejectedReturn(action)) {
        return;
      }
      const { fulfilled, rejected } = action.payload;

      if (fulfilled.length) {
        notify.success({
          title: intl.formatMessage(
            { id: "organization.invitation.sent.title" },
            { count: fulfilled.length }
          ),
          description: intl.formatMessage(
            { id: "organization.invitation.sent.description" },
            { email: fulfilled.join(", ") }
          )
        });

        // The API may take a bit of time to settle the data and if we try to
        // reload members immediately, we might not get the last members added
        await sleep(500);
        await Promise.all([loadMembers(), load()]);
      }

      if (rejected.length) {
        rejected.forEach(rejection =>
          notify.error(
            intl.formatMessage({ id: "organization.invitation.failure.title" }),
            intl.formatMessage(
              { id: "organization.invitation.failure.description" },
              { email: rejection.email }
            )
          )
        );
      }

      return action.payload;
    },
    [load, organizationId, dispatch, intl, loadMembers, notify]
  );

  const select = useCallback(
    (invitations: string | Array<string>) => {
      dispatch(setSelected(invitations));
    },
    [dispatch]
  );

  const resend = useCallback(
    async (invitations: Array<Invitation>) => {
      const action = await dispatch(
        resendThunk({
          organizationId,
          invitations
        })
      );

      if (isResendRejectedReturn(action)) {
        return;
      }

      const { fulfilled, rejected } = action.payload;

      if (fulfilled.length) {
        notify.success(
          intl.formatMessage(
            { id: "organization.invitation.resent.title" },
            { count: fulfilled.length }
          ),
          intl.formatMessage(
            { id: "organization.invitation.resent.description" },
            {
              count: fulfilled.length,
              email: fulfilled.map(({ email }) => email).join(", ")
            }
          )
        );
      }

      if (rejected.length) {
        rejected.forEach(rejection =>
          notify.error(
            intl.formatMessage({ id: "organization.invitation.failure.title" }),
            intl.formatMessage(
              { id: "organization.invitation.failure.description" },
              { email: rejection.invitation.email }
            )
          )
        );
      }

      return action.payload;
    },
    [dispatch, intl, notify, organizationId]
  );

  return {
    invite,
    load,
    revoke,
    resend,
    select
  };
};

export default useInvitations;
