import { createSlice } from "@reduxjs/toolkit";

import { setDeep } from "Libs/objectAccess";

import { getDiscounts } from "./thunks/getDiscounts.thunk";
import { AllowanceDiscounts } from "./types";

export type DiscountState = {
  data: AllowanceDiscounts | undefined;
  errors?: string | undefined;
  loading?: boolean;
};

const initialState: DiscountState = {
  data: undefined
};

const discounts = createSlice({
  name: "app/discounts",
  initialState,
  reducers: {
    initForm(state) {
      delete state.errors;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getDiscounts.pending, state => {
        delete state.errors;
        setDeep(state, ["loading"], true);
      })
      .addCase(getDiscounts.fulfilled, (state, { payload }) => {
        setDeep(state, ["data"], payload);
        setDeep(state, ["loading"], false);
      })
      .addCase(getDiscounts.rejected, (state, { error }) => {
        setDeep(state, ["errors"], error.message);
        setDeep(state, ["loading"], false);
      });
  }
});

export const { initForm } = discounts.actions;
export default discounts.reducer;
