import { Buffer } from "buffer";

export const generateKey = async () => {
  const keyPair = await window.crypto.subtle.generateKey(
    {
      name: "RSASSA-PKCS1-v1_5",
      modulusLength: 2048,
      publicExponent: new Uint8Array([1, 0, 1]),
      hash: "SHA-256"
    },
    true,
    ["sign", "verify"]
  );

  return keyPair;
};

export const generateExternalKey = async (cryptoKey: CryptoKey) => {
  const webKey = await window.crypto.subtle.exportKey("jwk", cryptoKey);

  const base64Key = window.btoa(JSON.stringify(webKey));

  return base64Key;
};

export const generateSignature = async (
  privateKey: CryptoKey,
  data: Uint8Array
) => {
  const signature = await window.crypto.subtle.sign(
    "RSASSA-PKCS1-v1_5",
    privateKey,
    data
  );

  const buffer = Buffer.from(signature);
  const signatureBase64 = buffer.toString("base64");

  return signatureBase64;
};
