import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import styled from "styled-components";

import * as Dropdown from "ds/Dropdown";

export const Contend = styled(Dropdown.Content)`
  padding: 10px 16px 16px;
  border-radius: var(
    --box-wrapper-section-border-radius,
    var(--section-8px-border-radius, var(--8px))
  );
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Separator = styled(DropdownMenu.Separator)`
  height: 1px;
  margin-left: -16px;
  margin-right: -16px;
  background-color: var(--mode-border-neutral-default);
  margin-bottom: 16px;
`;
