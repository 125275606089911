import styled from "styled-components";

import IconCritical from "ds/Icons/Error";
import IconInformation from "ds/Icons/Info";
import IconLow from "ds/Icons/SuccessIcon";
import IconNormal from "ds/Icons/Warning";

export const Root = styled.div`
  flex: 0 0 24px;
  height: 24px;
`;

export const Normal = styled(IconNormal)`
  fill: var(--icon-gold-fill, var(--gold));
`;

export const Low = styled(IconLow)`
  fill: var(--icon-java-fill, var(--java));
`;

export const Information = styled(IconInformation)`
  fill: var(--icon-color-primary-600-fill, var(--color-primary-600));
`;

export const Critical = styled(IconCritical)`
  fill: var(--icon-red-fill, var(--red));
`;
