import { apiProxyRequest as request } from "Api/apiProxyRequest";

export type GetMFAEnforcementStatusResponse = {
  enforce_mfa: boolean;
  _links: {
    enable?: {
      href: string;
    };
    disable?: {
      href: string;
    };
    self: {
      href: string;
    };
  };
};

export const getStatus = (organizationId: string) =>
  request<GetMFAEnforcementStatusResponse>(
    `/organizations/${organizationId}/mfa-enforcement`
  );
