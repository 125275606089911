import { useCallback } from "react";

import { RootState } from "Store/configureStore";
import { useAppSelector } from "Store/hooks";

import useDecodedParams from "./useDecodedParams";

const useSelectorWithUrlParams = <T>(
  selector: (state: RootState, props: any) => T,
  params = {}
) => {
  const urlsParams = useDecodedParams();

  const memoizeSelector = useCallback(
    (state: RootState) => selector(state, { ...urlsParams, ...params }),
    [selector, urlsParams, params]
  );

  return useAppSelector(memoizeSelector);
};

export default useSelectorWithUrlParams;
