import {
  ConsoleAccessRole,
  EnvironmentAccessRole,
  ProjectAccessEnvironmentType
} from "platformsh-client";

// We use a role of no-access to be able to show environment types in console
// where the user has no access, but the API does not use this, it only accepts
// environments where a user has permission. That's why we need to filter them
// out.
export const transformEnvironmentsForAPI = (
  environments: {
    type: ProjectAccessEnvironmentType;
    role: ConsoleAccessRole;
  }[]
) => {
  return (
    environments.filter(e => e.role !== "no-access") as {
      type: ProjectAccessEnvironmentType;
      role: EnvironmentAccessRole;
    }[]
  ).map(e => ({
    type: e.type,
    role: e.role
  }));
};
