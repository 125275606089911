import { apiProxyRequest as request } from "Api/apiProxyRequest";
import { RefUser } from "Api/ref/User.types";
import { resolveIfHasLink } from "Api/utils";

import type { OrganizationMember } from "Api/organizations/member/Member.types";

export const getMember = (organizationId: string, userId: string) =>
  request<OrganizationMember>(
    `/organizations/${organizationId}/members/${userId}`
  );

export const resolveMemberLinks = (organizationMember: OrganizationMember) => ({
  user: () =>
    request<{ [userId: string]: RefUser }>(
      organizationMember._links["ref:users:0"].href
    ),
  update: resolveIfHasLink(
    organizationMember,
    "update",
    (updatedMember: Partial<OrganizationMember>) =>
      request(
        organizationMember._links.update!.href,
        organizationMember._links.update!.method,
        updatedMember
      )
  )
});
