import { apiProxyRequest as request } from "Api/apiProxyRequest";

import type { TeamProjectAccess } from "Api/teams/teams.types";

export type ListTeamProjectAccessesOptions = {
  page?: {
    size?: string;
    before?: string;
    after?: string;
  };
  sort?:
    | "project_title"
    | "-project_title"
    | "granted_at"
    | "-granted_at"
    | "updated_at"
    | "-updated_at";
};

export type ListTeamProjectAccessesResponse = {
  items: Array<TeamProjectAccess>;
  _links: {
    "ref:projects:0"?: { href: string };
    self: { href: string };
    previous?: { href: string };
    next?: { href: string };
  };
};

export const listTeamProjectAccesses = (
  teamId: string,
  options?: ListTeamProjectAccessesOptions
) =>
  request<ListTeamProjectAccessesResponse>(
    `/teams/${teamId}/project-access`,
    "GET",
    options
  );
