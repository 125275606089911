import client from "Libs/platform";

import { encodeMessage } from "./crypto/encodeMessage";
import {
  generateKey,
  generateExternalKey,
  generateSignature
} from "./crypto/signature";
import { getIdentityTokenRequest } from "./getIdentityToken.request";

export const generateAdminAgentHeaders = async ({
  body
}: {
  body?: unknown;
}) => {
  const token = await client.getAccessToken();
  const { privateKey, publicKey } = await generateKey();

  const dateString = new Date().toUTCString();

  const publicKeyBase64 = await generateExternalKey(publicKey);

  const identityToken = await getIdentityTokenRequest({
    accessToken: token.access_token,
    publicKeyBase64
  });

  const messageEncoded = encodeMessage(dateString, body);
  const signatureBase64 = await generateSignature(privateKey, messageEncoded);

  return {
    "X-Date": dateString,
    "X-Identity-Token": `Bearer ${identityToken}`,
    "X-Client-Signature": signatureBase64
  };
};
