import styled from "styled-components";

export const Layout = styled.section`
  border-radius: var(
    --box-wrapper-section-border-radius,
    var(--section-2px-border-radius, var(--2px))
  );
  margin-bottom: 32px;
  width: 100%;
  background-color: var(--section-snow-background-color, var(--snow));
`;
