import React from "react";

import { ClockIconWrapper } from "./style";

const Clock = () => (
  <ClockIconWrapper>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM10.8991 4.94955C10.8991 4.42513 10.474 4 9.94956 4C9.42513 4 9 4.42513 9 4.94956V10.3304L13.653 13.0917C14.0761 13.3428 14.6226 13.2037 14.8742 12.7809C15.1261 12.3576 14.987 11.8102 14.5635 11.5585L10.8991 9.38081V4.94955Z"
        fill="currentcolor"
      />
    </svg>
  </ClockIconWrapper>
);

export default Clock;
