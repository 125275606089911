import React from "react";

import MaxWidthContainer from "Components/MaxWidthContainer";

interface Props {
  children: React.ReactNode;
}

const FloatBox = ({ children }: Props) => (
  <div className="float-box-wrapper">
    <MaxWidthContainer>
      <div className="float-box">{children}</div>
    </MaxWidthContainer>
  </div>
);

export default FloatBox;
