import config from "console_config";

export const CLI_COMMAND_NAME = config.CUSTOM_CLI_COMMAND || "platform";

export const APP_NAME = config.CUSTOM_APP_NAME
  ? String(config.CUSTOM_APP_NAME)
  : "Platform.sh";

export const CLI_DOWNLOAD_URL =
  config.CUSTOM_URL_CLI_DOWNLOAD || "https://platform.sh/cli/installer";

export const CONFIG_DIR_NAME = config.CUSTOM_CONFIG_DIR_NAME || ".platform";

export const DOCS_STATUS_CHECK_URL = config.CUSTOM_URL_DOCS_STATUS_CHECK;
