import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getMember,
  resolveMemberLinks
} from "Api/organizations/member/getMember.request";
import { selectCurrentUserUUID } from "Reducers/app/selectors";
import { RootState } from "Store/configureStore";

import { loggedInMemberDataSelector } from "../loggedInMember.selectors";

export const loadLoggedInMember = createAsyncThunk(
  "organizations/loadLoggedInMember",
  async (organizationId: string | undefined, { getState }) => {
    const state = getState() as RootState;
    const data = loggedInMemberDataSelector(state);

    // Prevents reloading the organization member information if is
    // already on the store.
    if (
      !organizationId ||
      (data && data?.member.organization_id === organizationId)
    ) {
      return data;
    }

    const id = selectCurrentUserUUID(state);

    if (!id) {
      return;
    }

    const member = await getMember(organizationId, id);
    const api = resolveMemberLinks(member);
    const user = await api.user();

    return { member, user: user[member.id] };
  }
);
