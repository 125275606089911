import { createSlice } from "@reduxjs/toolkit";

import { resume } from "./thunks";
import { ResumeState } from "./types";

const initialState: ResumeState = {
  data: null,
  isLoading: false
};
const resumeSlice = createSlice({
  name: "resume",
  initialState,
  reducers: {
    clearResume: state => {
      state.isLoading = false;
      state.error = undefined;
      state.data = null;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(resume.pending, state => {
        state.isLoading = true;
      })
      .addCase(resume.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        state.data = action.payload;
      })
      .addCase(resume.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  }
});

export const { clearResume } = resumeSlice.actions;

export default resumeSlice.reducer;
