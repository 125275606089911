import config from "console_config";
import { normalizeUrlPath } from "Libs/utils";
import { getFeatureFlag } from "Reducers/featureFlags";

import { urlKeys } from "./urlKeys";

import type { UrlKeyOptions, UrlKeysType, GetUrlProps } from "./types";

const doNotEncodeParams = ["queryParams"];

const getPath = (
  pathObject: UrlKeysType[string],
  props: GetUrlProps["props"],
  key: UrlKeyOptions,
  noEncode: GetUrlProps["noEncode"]
) => {
  if (!pathObject?.params) return pathObject.path;

  let pathCopy = pathObject.path;

  pathObject.params.forEach(param => {
    if (props?.[param] !== "" && !props?.[param]) {
      throw new Error(`${param} is required for the key of ${key}`);
    } else {
      const skipEncode =
        (noEncode && noEncode?.includes(param)) ||
        doNotEncodeParams.includes(param);

      const replacementParam = skipEncode
        ? props[param]
        : encodeURIComponent(props[param]);

      pathCopy = pathCopy.replace(`:${param}`, replacementParam);
    }
  });

  return pathCopy;
};

export const getUrl = ({ key, props = {}, noEncode = false }: GetUrlProps) => {
  const url: string[] = [];
  const paths = key.split(".");
  let obj = urlKeys;

  for (const elt of paths) {
    const currentPath = obj[elt];
    const result = getPath(currentPath, props, key, noEncode);

    url.push(result);
    if (currentPath?.subpath) {
      obj = currentPath.subpath;
    }
  }

  const slash = /^(auth|accountUrl)/.test(key) ? "" : "/";

  return normalizeUrlPath(`${slash}${url.join("/")}`);
};

export const getBillingUrl = ({
  username,
  accountUrl
}: {
  username: string;
  accountUrl?: string;
}) => {
  if (config.CUSTOM_URL_BILLING) {
    return String(config.CUSTOM_URL_BILLING);
  }

  let billingUrl = getUrl({ key: "users.billing", props: { username } });

  const featureAccountPagesEnabled = getFeatureFlag("ENABLE_ACCOUNT_PAGES");
  if (!featureAccountPagesEnabled && accountUrl) {
    billingUrl = getUrl({
      key: "accountUrl.user.orders",
      props: { accountUrl }
    });
  }

  return billingUrl;
};

export const getProfileUrl = ({
  username,
  accountUrl
}: {
  username: string;
  accountUrl?: string;
}) => {
  let profileUrl = getUrl({ key: "users.profile", props: { username } });

  const featureAccountPagesEnabled = getFeatureFlag("ENABLE_ACCOUNT_PAGES");
  if (!featureAccountPagesEnabled && accountUrl) {
    profileUrl = getUrl({ key: "accountUrl.user", props: { accountUrl } });
  }

  return profileUrl;
};

export const getSupportUrl = ({
  username,
  accountUrl
}: {
  username: string;
  accountUrl?: string;
}) => {
  if (config.CUSTOM_URL_SUPPORT) {
    return String(config.CUSTOM_URL_SUPPORT);
  }

  let supportUrl = getUrl({ key: "users.tickets", props: { username } });

  const featureAccountPagesEnabled = getFeatureFlag("ENABLE_ACCOUNT_PAGES");
  if (!featureAccountPagesEnabled && accountUrl) {
    supportUrl = getUrl({
      key: "accountUrl.user.orders",
      props: { accountUrl }
    });
  }

  return supportUrl;
};
