import { createAsyncThunk } from "@reduxjs/toolkit";

import { apiProxyRequest } from "Api/apiProxyRequest";
import { resolveMembersLinks } from "Api/organizations/member/getMembers.request";

import { selectNextPageURL } from "../membersList.selectors";

import type { OrganizationMembers } from "Api/organizations/member/Member.types";
import type { RootState } from "Store/configureStore";

// Load the next page based on the current store state
export const loadMoreMembers = createAsyncThunk(
  "organization/members/loadMore",
  async (_, { getState }) => {
    const state = getState() as RootState;
    const next = selectNextPageURL(state);

    if (!next) {
      throw new Error("Unable to load more members - missing next page");
    }

    const members = await apiProxyRequest<OrganizationMembers>(next);
    const api = resolveMembersLinks(members);
    const users = await api.users?.();

    return { members, users };
  }
);
