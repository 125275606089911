import { Link as DsLink } from "react-router-dom";
import styled from "styled-components";

import Tick from "ds/Icons/Tick";
import Search, { Icon, Close } from "ds/Search";
import { Search as SearchPrimitive } from "ds/Search/Search.styles";

export const Container = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 99;

  display: flex;

  width: fit-content;
  height: 320px;

  box-shadow: var(--shadow-medium);
  background-color: var(--mode-bg-neutral-weakest);
  border-radius: var(--box-wrapper-section-border-radius);
`;

export const LeftSection = styled.div`
  box-sizing: border-box;
  width: 252px;
  padding: 16px 0;
  border-radius: var(--box-wrapper-section-border-radius) 0 0
    var(--box-wrapper-section-border-radius);
  position: relative;

  label {
    color: var(--mode-text-neutral-weakest);
  }

  &:has(> form) {
    padding: 0 0 16px 0;
    & > label {
      display: block;
      padding: 16px 0 0 24px;
    }
  }

  form {
    background-color: var(--mode-bg-neutral-weakest);
    padding: 0;
    border-radius: var(--box-wrapper-section-border-radius) 0 0 0;
    height: 48px;

    .search-image {
      top: 12px;
      left: 16px;
    }

    input {
      background-color: var(--mode-bg-neutral-weakest) !important;
      padding-left: 46px;

      &::placeholder {
        color: var(--mode-text-neutral-weakest) !important;
      }
    }
  }
`;

export const RightSection = styled.div`
  box-sizing: border-box;
  min-width: 236px;
  position: relative;
  z-index: 99999999;
  padding: 24px 32px;
  display: flex;
  flex-direction: column;

  background-color: var(--mode-bg-neutral-weaker);
  border-radius: 0 var(--box-wrapper-section-border-radius)
    var(--box-wrapper-section-border-radius) 0;

  label {
    margin-bottom: 8px;
    display: block;
  }
`;

export const OrganizationListContainer = styled.ul<{
  isSearchBoxVisible: boolean;
  shouldOverscroll?: boolean;
}>`
  box-sizing: border-box;
  all: unset;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-top: ${props => (props.isSearchBoxVisible ? "12px" : "0px")};
  max-height: ${props => (props.isSearchBoxVisible ? "207px" : "216px")};
  overflow-y: ${props => (props.shouldOverscroll ? "auto" : "none")};
  color: var(--mode-text-neutral-default-weak);
  & > label {
    margin: 0 16px;
    padding: 4px 8px;
  }
`;

export const OrganizationListItem = styled.li<{ $isActive: boolean }>`
  cursor: pointer;
  padding: 4px 8px;
  display: flex;
  margin: 0 16px;
  justify-content: space-between;
  align-items: center;
  line-height: 24px;

  ${({ $isActive }) =>
    !$isActive
      ? `&:hover {
    color: var(--mode-text-primary-default);
    background-color: var(--mode-bg-primary-weakest);
  }`
      : `
    color: var(--mode-text-neutral-default-weak);
    background-color: var(--mode-bg-neutral-weaker);
  `}
`;

export const OrganizationListItemContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  box-sizing: border-box;
  min-width: 0;
  font-size: 15px;
`;

export const OrganizationListItemName = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1;
  white-space: nowrap;
  font-size: 15px;
`;

export const OrganizationListTick = styled(Tick)`
  flex: 0 0 24px;
`;

export const ListItem = styled.li<{ $isActive: boolean }>`
  flex: 1;
  box-sizing: border-box;
  all: unset;
  padding: 4px 8px;
  display: flex;
  margin: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 15px;
  line-height: 24px;
  color: var(--mode-text-neutral-default-weak);
  cursor: pointer;

  border-radius: var(--box-wrapper-section-border-radius);

  .menu-list {
    all: unset;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    max-width: calc(100% - 24px);
  }

  ${({ $isActive }) =>
    !$isActive
      ? `&:hover {
    color: var(--mode-text-primary-default);
    background-color: var(--mode-bg-primary-weakest);
  }`
      : `
    color: var(--mode-text-neutral-default-weak);
    background-color: var(--mode-bg-neutral-weaker);
  `}
`;

export const ListItemIcon = styled.img`
  height: 16px;
  width: 16px;
`;

export const ListItemContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
`;

export const NewOrganizationButtonContainer = styled.div<{
  shouldShowShadow?: boolean;
}>`
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000000;
  padding: 16px 22px;

  display: flex;

  border-radius: 0 0 0 var(--box-wrapper-section-border-radius);
  background-color: var(--mode-bg-neutral-weakest);
  box-shadow: ${({ shouldShowShadow }) =>
    shouldShowShadow ? "var(--shadow-medium)" : "none"};

  svg {
    margin-right: 2px !important;
  }

  & a {
    path {
      fill: var(--mode-text-primary-default) !important;
    }
  }
`;

export const Loader = styled.div`
  width: 24px;
  height: 24px;
  & > * {
    width: 24px;
    height: 24px;
  }
`;

export const Link = styled(DsLink)`
  all: unset;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 24px;
  color: var(--mode-text-neutral-default-weak);
  cursor: pointer;
  padding: 6px 8px;
  margin-left: -10px;
  display: flex;
  align-items: center;
  gap: 16px;

  &:hover {
    color: var(--mode-text-primary-default);
    background-color: var(--mode-bg-primary-weakest);

    & [data-stroke] {
      stroke: var(--mode-text-primary-default) !important;
    }

    & path:not([data-stroke]) {
      fill: var(--mode-text-primary-default) !important;
    }
  }
`;

export const InviteUserButtonContainer = styled.div`
  box-sizing: border-box;
  padding: 16px 22px;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000000;
  border-radius: 0 0 var(--box-wrapper-section-border-radius) 0;

  & button {
    path {
      fill: var(--mode-text-primary-default) !important;
    }
  }

  svg {
    margin-right: 2px !important;
  }
`;

export const SearchInput = styled(Search)`
  height: 32px;
  box-sizing: border-box;
  padding: 0px 0px 10px 15px;
  width: 100%;

  box-shadow: 0 1px 0
    var(--mode-bg-neutral-weaker)
  border-bottom: none;

  ${Close} {
    display: none;
  }

  :has(${SearchPrimitive}:focus) {
    ${Icon} {
      fill: var(--mode-vector-neutral-strong);
    }
  }

  ${SearchPrimitive} {
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    padding-left: 5px;
    color: var(--input-night-color, var(--night));

    &::placeholder {
      font-size: 14px;
      line-height: 24px;
      color: var(--mode-text-neutral-weakest);
    }

    &:focus {
      &::placeholder {
        color: var(--mode-text-neutral-weakest);
      }
    }
  }

  ${Icon} {
    fill: var(--mode-vector-neutral-default);
  }
`;
