import { createSlice } from "@reduxjs/toolkit";

import {
  organizeCertificatesIntoProjectObject,
  addCertificate,
  loadCertificates,
  deleteCertificate
} from "./thunks";
import { CertificateStateType } from "./types";

const initialState: CertificateStateType = {
  isNew: false,
  editedLine: 0,
  loading: false,
  all: [],
  data: {},
  updateLoading: false
};

const projectCertificateReducer = createSlice({
  name: "app/projects/certificates",
  initialState: initialState,
  reducers: {
    editLine: (
      state,
      action: { payload: { index: number; isNew: boolean } }
    ) => {
      state.editedLine = action.payload.index;
      state.isNew = action.payload.isNew;
    },
    cancelAddCertificate: state => {
      state.editedLine = false;
      state.isNew = false;
      state.error = undefined;
    }
  },
  extraReducers: builder => {
    builder
      // LOAD CERTIFICATES
      .addCase(loadCertificates.pending, state => {
        state.loading = true;
      })
      .addCase(
        loadCertificates.fulfilled,
        (state, { meta: { arg }, payload }) => {
          if (
            typeof payload?.projectCertificates !== "undefined" &&
            payload?.projectDescriptionId
          ) {
            const { projectCertificates, projectDescriptionId } = payload;
            const { organizationDescriptionId } = arg;

            const organizedCertificates = organizeCertificatesIntoProjectObject(
              projectCertificates,
              organizationDescriptionId,
              projectDescriptionId
            );

            state.loading = false;
            state.all = projectCertificates;
            state.data = organizedCertificates;
          }
        }
      )
      .addCase(loadCertificates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ADD CERTIFICATE
      .addCase(addCertificate.pending, state => {
        state.updateLoading = true;
      })
      .addCase(
        addCertificate.fulfilled,
        (state, { payload, meta: { arg } }) => {
          if (
            typeof payload?.certificate !== "undefined" &&
            payload?.projectDescriptionId
          ) {
            const { projectDescriptionId, certificate } = payload;
            const { organizationDescriptionId } = arg;

            const all = [certificate, ...state.all].filter(certificate => {
              if (typeof certificate === "object") {
                return typeof certificate?.id !== "undefined";
              }
              return false;
            });

            const organizedCertificates = organizeCertificatesIntoProjectObject(
              all,
              organizationDescriptionId,
              projectDescriptionId
            );

            state.data = organizedCertificates;
            state.all = all;
            state.editedLine = false;
            state.isNew = false;
            state.updateLoading = false;
            state.error = undefined;
          }
        }
      )
      .addCase(addCertificate.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action?.payload,
          updateLoading: false
        };
      })
      // DELETE CERTIFICATE
      .addCase(
        deleteCertificate.fulfilled,
        (state, { payload, meta: { arg } }) => {
          const { organizationDescriptionId, projectDescriptionId } = arg;

          const all = state.all.filter(certificate => {
            if (
              typeof certificate === "object" &&
              typeof certificate?.id !== "undefined"
            ) {
              return certificate.id !== payload?.id;
            }
            return false;
          });

          const organizedCertificates = organizeCertificatesIntoProjectObject(
            all,
            organizationDescriptionId,
            projectDescriptionId
          );

          state.all = all;
          state.data = organizedCertificates;
        }
      );
  }
});

export const { editLine, cancelAddCertificate } =
  projectCertificateReducer.actions;

export default projectCertificateReducer.reducer;
