import { apiProxyRequest } from "Api/apiProxyRequest";

import type { ProjectSetting } from "Reducers/projectSettings";

export const updateBuildResourcesRequest = (
  projectId: string,
  buildResources: { cpu: number; memory: number }
) =>
  apiProxyRequest<Record<string, Record<string, ProjectSetting>>>(
    `/projects/${projectId}/settings`,
    "PATCH",
    {
      build_resources: buildResources
    }
  );
