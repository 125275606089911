import styled from "styled-components";

import * as Dialog from "ds/Dialog";

export const Layout = styled.div<{ $ready?: boolean }>`
  position: relative;

  /* This small transition is better than the data flashing on the screen */
  opacity: ${({ $ready }) => ($ready ? 1 : 0)};
  transition: 0.075s opacity ease-in;
`;

export const Content = styled(Dialog.Content)`
  display: flex;
  width: 224px;
  max-height: 201px;
  border-radius: 2px;
  padding: 8px 0 8px 16px;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: var(--section-granite-background-color, var(--granite));
  }
`;

export const Description = styled(Dialog.Description)`
  overflow: auto;
  flex: 1;
`;
