import styled from "styled-components";

export const COL_WIDTH = 2.3529;
export const DEFAULT_COL_SIZE = 6.17647;
export const calcColumn = (columnSize: number, totalColumns: number) => {
  return columnSize * totalColumns + COL_WIDTH * (totalColumns - 1);
};
export default styled.div`
  width: 100%;
  margin: 0 auto;
  // Only use columns for areas that should be a fixed width.
  // The main area should have a fluid width.
  .col-1 {
    @media (min-width: 1024px) {
      width: ${DEFAULT_COL_SIZE}%;
      min-width: ${DEFAULT_COL_SIZE}%;
    }
  }
  .col-2 {
    @media (min-width: 1024px) {
      width: ${calcColumn(DEFAULT_COL_SIZE, 2)}%;
      min-width: ${calcColumn(DEFAULT_COL_SIZE, 2)}%;
    }
  }
  .col-3 {
    @media (min-width: 1024px) {
      width: ${calcColumn(DEFAULT_COL_SIZE, 3)}%;
      min-width: ${calcColumn(DEFAULT_COL_SIZE, 3)}%;
    }
  }
  .col-4 {
    @media (min-width: 1024px) {
      width: ${calcColumn(DEFAULT_COL_SIZE, 4)}%;
      min-width: ${calcColumn(DEFAULT_COL_SIZE, 4)}%;
    }
  }
  .col-5 {
    @media (min-width: 1024px) {
      width: ${calcColumn(DEFAULT_COL_SIZE, 5)}%;
      min-width: ${calcColumn(DEFAULT_COL_SIZE, 5)}%;
    }
  }
  .col-6 {
    @media (min-width: 1024px) {
      width: ${calcColumn(DEFAULT_COL_SIZE, 6)}%;
      min-width: ${calcColumn(DEFAULT_COL_SIZE, 6)}%;
    }
  }
  .col-7 {
    @media (min-width: 1024px) {
      width: ${calcColumn(DEFAULT_COL_SIZE, 7)}%;
      min-width: ${calcColumn(DEFAULT_COL_SIZE, 7)}%;
    }
  }
  .col-8 {
    @media (min-width: 1024px) {
      width: ${calcColumn(DEFAULT_COL_SIZE, 8)}%;
      min-width: ${calcColumn(DEFAULT_COL_SIZE, 8)}%;
    }
  }
  .col-9 {
    @media (min-width: 1024px) {
      width: ${calcColumn(DEFAULT_COL_SIZE, 9)}%;
      min-width: ${calcColumn(DEFAULT_COL_SIZE, 9)}%;
    }
  }
  .col-10 {
    @media (min-width: 1024px) {
      width: ${calcColumn(DEFAULT_COL_SIZE, 10)}%;
      min-width: ${calcColumn(DEFAULT_COL_SIZE, 10)}%;
    }
  }
  .col-11 {
    @media (min-width: 1024px) {
      width: ${calcColumn(DEFAULT_COL_SIZE, 11)}%;
      min-width: ${calcColumn(DEFAULT_COL_SIZE, 11)}%;
    }
  }
  .col-12 {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }
  &.create-project-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;
