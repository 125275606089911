import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "Store/configureStore";

export type Reasons = "mfa" | "org_mfa" | "recent_auth" | "org_sso";

export type Auth401RecentAuth = {
  error: string;
  error_description: string;
  release: (v: boolean) => void;
  reject: () => void;
  type: "recent_auth";
};

export type Auth401MFA = {
  error: string;
  release: (v: boolean) => void;
  reject: () => void;
  type: "mfa";
};

export type Auth401OrganizationMFA = {
  acr_values: string;
  amr: Array<string>;
  error: string;
  error_description: string;
  organization_id: string;
  release: (v: boolean) => void;
  reject: () => void;
  type: "org_mfa";
};

export type Auth401OrganizationSSO = {
  amr: Array<string>;
  error: string;
  error_description: string;
  organization_id: string;
  release: (v: boolean) => void;
  reject: () => void;
  type: "org_sso";
};

type Auth401ModalsState = Record<Reasons, Auth401Response[]>;

export type Auth401Response =
  | Auth401MFA
  | Auth401OrganizationMFA
  | Auth401OrganizationSSO
  | Auth401RecentAuth;

const initialState: Auth401ModalsState = {
  mfa: [],
  org_mfa: [],
  org_sso: [],
  recent_auth: []
};

const auth401modalsSlice = createSlice({
  name: "auth401modals",
  initialState,
  reducers: {
    setReason: (state, action: PayloadAction<Auth401Response>) => {
      state[action.payload.type].push(action.payload);
    },
    removeReasonsOfType: (
      state,
      action: PayloadAction<{ reason: Reasons; status: boolean }>
    ) => {
      state[action.payload.reason].forEach(r =>
        r.release(action.payload.status)
      );
      state[action.payload.reason] = [];
    }
  },
  extraReducers: builder => {
    // Reset the state when the user navigates to a new page
    builder.addCase("@@router/LOCATION_CHANGE", () => initialState);
  }
});

export const auth401modalsSelector = (state: RootState) => state.auth401modals;
export const hasAuth401OrganizationMFA = createSelector(
  auth401modalsSelector,
  state => state.org_mfa.length > 0
);
export const hasAnyAuth401Modal = createSelector(auth401modalsSelector, state =>
  Object.values(state).some(
    (responses: Auth401Response[]) => responses.length > 0
  )
);

export const { setReason, removeReasonsOfType } = auth401modalsSlice.actions;

export const auth401modalsReducer = auth401modalsSlice.reducer;
