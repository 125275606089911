import styled from "styled-components";

export const Icon = styled.div`
  padding-right: 16px;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
