import styled, { css as styledCSS } from "styled-components";

import Button from "./Button";

export const Icon = styled.span`
  color: var(--mode-text-neutral-default-weak);
  height: 22px;
  width: 22px;

  > :first-child {
    height: 22px;
    width: 22px;
  }
`;

export const Layout = styled(Button)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: auto;

  :hover {
    background-color: transparent;
    ${Icon} {
      color: var(--button-slate-color, var(--slate));
    }
  }

  :active {
    color: var(--button-ebony-color, var(--ebony));
  }

  ${({ disabled }) =>
    disabled &&
    styledCSS`
      color: var(--button-periwinkle-grey-color,var(--periwinkle-grey));
    `}
`;

export const Text = styled.span`
  margin-right: 16px;
  font-size: 13px;
  line-height: 16px;
  color: var(--mode-text-neutral-default);
  font-weight: 400;
`;
