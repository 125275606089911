import { createAsyncThunk } from "@reduxjs/toolkit";

import { AsyncThunkOptionType } from "Reducers/types";

import type { ProjectLevelVariable } from "platformsh-client";

export const getVariables = createAsyncThunk<
  ProjectLevelVariable[],
  { projectId: string; organizationId: string },
  AsyncThunkOptionType<{ error: Error }>
>("app/project/variables", async ({ projectId }) => {
  const platformLib = await import("Libs/platform");
  const client = platformLib.default;
  const variables = await client.getProjectVariables(projectId);
  return variables;
});
