import styled, { css as styledCSS } from "styled-components";

export interface RootProps {
  $isActive: boolean;
  customWidth?: string;
}

export const Root = styled.div<RootProps>`
  display: flex;
  position: relative;
  align-items: center;
  border-bottom: 1px solid transparent;
  transition:
    border-bottom-color,
    width 0.25s;
  /* Slightly larger width than the icon to avoid flickering on focus */
  width: 28px;
  overflow: hidden;
  box-sizing: border-box;

  ${({ $isActive, customWidth }) =>
    $isActive &&
    styledCSS`
      width: ${customWidth ?? "200px"};
      border-bottom: 1px solid ${"var(--input-periwinkle-grey-border-color,var(--periwinkle-grey))"};
    `};
`;

export default Root;
