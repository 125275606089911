import { createAsyncThunk } from "@reduxjs/toolkit";

import type { Project } from "platformsh-client";

/**
 * Load project success
 *
 * @param {Project} project
 * @param {string} organizationId
 *
 */

export const loadProjectSuccess = createAsyncThunk(
  "app/project_reducer/project_load_project_success",
  ({
    project,
    organizationId
  }: {
    project: Project;
    organizationId: string;
  }) => {
    return { project, organizationId };
  }
);
