import styled from "styled-components";

export interface InputProps {
  $isActive: boolean;
}

export const Search = styled.input<InputProps>`
  border: none;
  outline: none;
  background: none;
  box-sizing: border-box;
  color: var(--mode-text-neutral-default-weak);
  caret-color: var(--mode-text-neutral-default);
  flex: 1;
  width: 100%;
  text-overflow: ellipsis;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;

  &::placeholder {
    font-size: 14px;
    line-height: 24px;
    color: var(--mode-text-neutral-weakest);
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
  }

  ${({ $isActive }) =>
    $isActive &&
    `
      padding-left: 16px;
      padding-right: 8px;
  `}
`;

export default Search;
