import useDecodedParams from "Hooks/useDecodedParams";
import { getTrial, getTrialConfig } from "Libs/utils";
import { organizationByDescriptionIdSelector } from "Reducers/organization";
import {
  organizationProfileSelector,
  organizationProfileErrorSelector
} from "Reducers/organization/profile";
import { currentTestTrialSelector } from "Reducers/testMode";
import { useAppSelector } from "Store/hooks";

import {
  organizationPaymentSourceLoadingSelector,
  organizationPaymentSourceSelector
} from "../paymentSource/paymentSource.selectors";

const ALWAYS_SHOW_TRIAL_VALUE = 999;

export const useTrialInfo = ({
  showCta = false
}: { showCta?: boolean } = {}) => {
  const { organizationId } = useDecodedParams<{ organizationId: string }>();
  const currentTestTrial = useAppSelector(currentTestTrialSelector);
  const organizationProfile = useAppSelector(state =>
    organizationProfileSelector(state, {
      organizationId: organizationId
    })
  );
  const organizationProfileError = useAppSelector(state =>
    organizationProfileErrorSelector(state, {
      organizationId: organizationId
    })
  );
  const paymentSource = useAppSelector(state =>
    organizationPaymentSourceSelector(state, { organizationId })
  );
  const loadingPaymentSource = useAppSelector(
    organizationPaymentSourceLoadingSelector
  );

  const organization = useAppSelector(state =>
    organizationByDescriptionIdSelector(state, {
      organizationDescriptionId: organizationId
    })
  );
  const hasPaymentSourcePermission = organization?.hasLink("payment-source");
  const current_trial = currentTestTrial || organizationProfile?.current_trial;
  // If a trial is created but not activated (psh edgecase only) we want to show the trial info
  // as if it was active, but the days_remaining are set for some reason to -19996 so we can't
  // pass them directly, so we pass a hardcoded value to ensure the trial is shown as active
  const isCreated = current_trial?.active && current_trial?.expiration === null;

  const config = getTrialConfig({
    trialDays: isCreated
      ? ALWAYS_SHOW_TRIAL_VALUE
      : current_trial?.days_remaining,
    showCta
  })!;

  const chargeablePaymentTypes = ["credit-card", "stripe_sepa_debit"];
  const isChargeablePaymentType =
    paymentSource?.type && chargeablePaymentTypes.includes(paymentSource?.type);

  const paymentType = isChargeablePaymentType
    ? paymentSource?.type && paymentSource?.chargeable
    : paymentSource?.type;

  const isSuspended = config?.tagText === "suspended";

  const isTrial = hasPaymentSourcePermission
    ? !organizationProfileError &&
      (currentTestTrial?.active ||
        getTrial(organizationProfile) ||
        isSuspended) &&
      !paymentType &&
      !loadingPaymentSource
    : false;

  return {
    config,
    isTrial,
    isSuspended,
    trialDays: current_trial?.days_remaining
  };
};
