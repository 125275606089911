import { createSlice } from "@reduxjs/toolkit";

import { RefUser } from "Api/ref/User.types";

import loadMemberData from "./thunks/loadMemberData.thunk";

import type { OrganizationMember } from "Api/organizations/member/Member.types";

export interface EditMember {
  member?: OrganizationMember;
  user?: { [key: string]: RefUser };
  isLoading: boolean;
  error: unknown;
}

const initialState: EditMember = {
  member: undefined,
  user: undefined,
  isLoading: false,
  error: undefined
};

const memberDataSlice = createSlice({
  name: "memberData",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(loadMemberData.pending, state => ({
        ...state,
        error: undefined,
        isLoading: true
      }))
      .addCase(loadMemberData.fulfilled, (state, action) => ({
        ...state,
        member: action.payload.member,
        user: action.payload.user,
        isLoading: false
      }))
      .addCase(loadMemberData.rejected, (state, action) => ({
        ...state,
        error: action.error,
        isLoading: false
      }));
  }
});

export default memberDataSlice.reducer;
