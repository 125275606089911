import { createAsyncThunk } from "@reduxjs/toolkit";

import { getSetupOptionsRequest } from "Api/getSetupOptions.request";
import logger from "Libs/logger";

export type SetupOptionsThunkArguments =
  | {
      organizationId?: string;
      template?: string;
    }
  | undefined;

export const getSetupOptionsThunk = createAsyncThunk(
  "setup/options",
  (
    { organizationId, template, ...others }: SetupOptionsThunkArguments = {},
    { rejectWithValue }
  ) => {
    try {
      return getSetupOptionsRequest({ organizationId, template, ...others });
    } catch (error) {
      logger(error, { action: "setup_load_options" });

      return rejectWithValue(error);
    }
  }
);
