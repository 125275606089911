import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "Store/configureStore";

export const paymentSourceSelector = (state: RootState) =>
  state.organizationPaymentSource;

export const organizationPaymentSourceLoadingSelector = createSelector(
  paymentSourceSelector,
  organizationPaymentSource => organizationPaymentSource?.loading
);

export const organizationPaymentSourceSelector = createSelector(
  paymentSourceSelector,
  (_: RootState, params: { organizationId: string }) => params,
  (organizationPaymentSource, { organizationId }) => {
    return organizationPaymentSource?.data?.[organizationId];
  }
);

export const organizationPaymentSourceErrorsSelector = createSelector(
  paymentSourceSelector,
  organizationPaymentSource => organizationPaymentSource?.errors
);
