const en = {
  "footer.message": "Version {appVersion} Upsun",
  CLI_command_name: "upsun",
  app_environment_variable_name: "$UPSUN_VARIABLES",
  configuration_folder: ".upsun",
  app_name: "Upsun",
  app_config_file: "config.yaml",
  app_service_file_path: "config.yaml",
  app_services_file_path: "config.yaml",
  app_routes_file_path: "config.yaml",
  app_applications_file_path: "config.yaml",
  try_us_for_free: "Try Upsun for free",
  no_credit_card_signup: " ",
  "auth.left_panel.title": "You’ll know we’re a fit from the Git-go",
  "auth.left_panel.description": "In just 10ish minutes, you can explore Upsun’s essential features with the demo app.  Free 15-day trial,* your own application, and ample resources are all you need to try Upsun on for size.",
  "auth.left_panel.disclaimer":
    "*Free 15-day trial includes: 1 organization, with 1 project and 2 running environments; unlimited containers; unlimited users; and up to 4.5 CPUs, 12 GB memory, and 20 GB network storage running concurrently. At the end of the trial, your project will be suspended until you add a valid payment method to your account.{br}See available <link>pricing</link> for details.",
  "auth.left_panel.free_trial": "",
  "auth.left_panel.items": "",
  "auth.left_panel.deploy_for_free.title": "Deploy for free today",
  "auth.left_panel.deploy_for_free.description":
    "Our 5-day free trial  includes: 1 organization, with 1 project and 2 running environments; unlimited containers; unlimited users; and up to 4.5 CPUs, 12 GB memory, and 20 GB network storage running concurrently. At the end of the trial, your project will be suspended until you add a valid payment method to your account. See available pricing for details.",
  "custom.auth.video_src": "https://www.youtube-nocookie.com/embed/8hg-wHeH_68",
  "login_with_platformsh_account.login":
    "You may use your existing Platform.sh account",
  "login_with_platformsh_account.signup": "You can use a Platform.sh account",
  "login_with_platformsh_account.dialog":
    "Upsun is powered by Platform.sh. To access your Upsun projects, simply log in with your Platform.sh credentials.",
  credit_card_number: "Credit card",
  create_first_project_description:
    "Use your own code, migrate a project or try a demo project to get started.",
  "create_first_project_description.paid":
    "Use your own code, migrate a project, or try a demo project to get started. Remember, with First Project Incentive, we’ll cover the equivalent costs of your first user license and project fee every month. To get started, just add on your resources.",
  consent_subtitle: "{app_name} is powered by Platform.sh",
  "onboarding.setup.migrate.install.code":
    "brew install platformsh/tap/upsun-cli",
  "onboarding.setup.scratch.install.code":
    "brew install platformsh/tap/upsun-cli",
  "onboarding.setup.demo.install.code.brew":
    "brew install platformsh/tap/upsun-cli",
  "onboarding.setup.demo.install.code.scoop":
    "scoop bucket add platformsh https://github.com/platformsh/homebrew-tap.git && scoop install upsun",
  "onboarding.setup.demo.clone.code":
    "git clone https://github.com/platformsh/demo-project.git upsun-demo && cd upsun-demo",
  "project.setup.cli.install": "brew install platformsh/tap/upsun-cli",
  "project.setup.cli.install.homebrew": "brew install platformsh/tap/upsun-cli",
  "project.setup.cli.install.scoop":
    "scoop bucket add platformsh https://github.com/platformsh/homebrew-tap.git && scoop install upsun",
  "services.noconfiguration": "Upsun support coming soon.",
  "environment.services.configuration.routes":
    "Routes describe how an incoming HTTP request is going to be handled by {appName}.",
  "tabs.metrics": "resources",
  metrics: "resources",
  view_metrics: "View graphs",
  "metrics.title": "Resources",
  "services.metrics.error": "Resource collection was not able to load",
  "services.metrics.title": "Resources",
  "metrics.dedicated.heading": "Infrastructure Resources",
  "metrics.grid.heading": "Resources",
  "metrics.split.heading": "Infrastructure Resources",
  "metrics.grid.view": "View graphs",
  "metrics.grid.hide": "Hide graphs",
  "metrics.error.empty.header": "Your resources are almost here!",
  "metrics.error.empty.paragraph":
    "Resources collection will start once you redeploy your environment.",
  "metrics.service.emtpy":
    "There is no resources data for {serviceKind}/{serviceName}",
  "metrics.error.generic.paragraph":
    "Resources page is temporarily unavailable, please try again in a few minutes.",
  "auth.signup": "sign up for early access",
  start_trial: "Start your 5-day free trial",
  "integration.setup.categories.observability_suite.sentence": "",
  "banner.trial.state_2":
    "Your free trial ends in {daysRemaining}. Contact your organization owner to subscribe to Upsun.",
  "banner.trial.state_2_cta":
    "Your free trial expires in {daysRemaining} - subscribe at any time.",
  "banner.trial.state_3":
    "Your free trial has ended. Contact your organization owner to subscribe to Upsun",
  "banner.trial.state_3_cta":
    "Your free trial has now ended. Subscribe to keep your projects up and running and take advantage of our First Project Incentive.",
  "trial.modal.subscribe.title": "Subscribe to continue using Upsun",
  "trial.modal.subscribe.body":
    "Your free trial has expired. Contact your organization owner to subscribe to Upsun.",
  "trial.modal.subscribe.body_cta":
    "Your free trial has expired. Subscribe to Upsun to continue building your projects.",
  "trial_state.trial_ending": "Trial expires soon",
  subscribe: "Subscribe",
  "create_organization.diclaimer": "*First new organizations qualify for a free trial.",
  "welcome.disclaimer": "*Continuing will start your 15-day free trial.",
  "welcome.button.start_free_trial": "Start free trial*",
};

export default en;
