import styled from "styled-components";

import { Layout as ButtonLayout } from "ds/Button/Button.styles";

import { Props } from "./Banner";

type LayoutProps = Required<Pick<Props, "priority">>;

const PRIORITIES = {
  information: {
    color: "var(--theme-neutral-900)",
    background: "var(--color-platform-pink-600)",
    closeButtonColor: "var(--theme-neutral-800)"
  },
  high: {
    color: "var(--theme-neutral-900)",
    background: "var(--theme-warning-600)",
    closeButtonColor: "var(--theme-neutral-800)"
  },
  critical: {
    color: "var(--theme-neutral-0)",
    background: "var(--theme-critical-600)",
    closeButtonColor: "var(--theme-neutral-0)"
  },
  activity: {
    color: "var(--mode-text-neutral-default)",
    background: "var(--mode-bg-neutral-weakest)",
    closeButtonColor: "var(--mode-vector-neutral-default)"
  }
};

export const Layout = styled.div<LayoutProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  position: relative;

  height: var(--banner-40px-height, var(--40px));
  padding-right: 8px;
  padding-left: 40px;

  font-family: Open Sans, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  background: ${({ priority }) => PRIORITIES[priority]?.background};
  color: ${({ priority }) => PRIORITIES[priority]?.color};
  a {
    color: inherit;
    font-weight: 600;
    :after {
      background-color: ${({ priority }) => PRIORITIES[priority]?.color};
    }
  }

  ${ButtonLayout} {
    margin-left: 40px;
    color: ${({ priority }) => PRIORITIES[priority]?.color};
    );
  }
`;

export const TextContainer = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ButtonContainer = styled.div<LayoutProps>`
  position: absolute;
  right: 14px;

  & svg path {
    fill: ${({ priority }) => PRIORITIES[priority]?.closeButtonColor};
  }
  &:hover svg path {
    fill: ${({ priority }) => PRIORITIES[priority]?.closeButtonColor};
  }
`;
