import React from "react";

import * as S from "./styles";

interface Props {
  onClick?: () => void;
}

const Hamburger = ({ onClick }: Props) => (
  <S.LinesContainer onClick={onClick}>
    <S.Line />
    <S.Line />
  </S.LinesContainer>
);

export default Hamburger;
