import consoleConfig from "console_config";

import { FeatureFlagName } from "./types";

const environmentFeatureFlags: Record<FeatureFlagName, boolean> =
  Object.freeze(consoleConfig);

export const getFeatureFlagFromEnvironment = (feature: FeatureFlagName) =>
  environmentFeatureFlags[feature];

export const getAllFeatureFlagsFromEnvironment = () => environmentFeatureFlags;
