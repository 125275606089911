import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import config from "console_config";

import {
  Data,
  Incident,
  Page,
  ScheduledMaintenance,
  StatusPageStatus
} from "./types";

export const getSystemStatus = createAsyncThunk("system/status", async () => {
  if (!config.ID_STATUS_PAGE || !window.StatusPage) return;

  const status = new window.StatusPage.page({
    page: config.ID_STATUS_PAGE
  });

  return new Promise<Data>(resolve => {
    status.get("summary", {
      success: data => resolve(data)
    });
  });
});

export type SystemStatusState = {
  loading: boolean | "idle";
  errors?: string;
  status?: StatusPageStatus & Page;
  incidents: Incident[];
  maintenances: ScheduledMaintenance[];
};

const initialState: SystemStatusState = {
  loading: "idle",
  incidents: [],
  maintenances: []
};

const systemStatus = createSlice({
  name: "systemStatus",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getSystemStatus.pending, state => {
        state.loading = true;
      })
      .addCase(getSystemStatus.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.error.message;
      })
      .addCase(getSystemStatus.fulfilled, (state, action) => {
        const payload = action.payload;

        state.loading = false;

        if (!payload?.status) {
          return;
        }

        state.status = { ...payload.status, ...payload.page };
        state.incidents = payload.incidents;
        state.maintenances = payload.scheduled_maintenances;
      });
  }
});

export default systemStatus.reducer;
