import React, { useEffect, useMemo, useState } from "react";

import moment from "moment";
import { useIntl } from "react-intl";

import Button from "ds/Button";

import Clock from "./clock";
import RangePicker from "./RangePicker";
import {
  ButtonWrapper,
  DualDateLayout,
  TimeZoneText,
  RangeDetailsContainer,
  RangeDetail,
  Separator,
  TimeFrame,
  TimeFrameContainer,
  TimeWrapper,
  DualDatePickerContainer,
  TimeframeLabel
} from "./styles";
import TimePicker from "./TimePicker";
import TimePickerSplit from "./TimePickerSplit";
import { DateRangeType, TimeDurationType } from "./types";
import { limitRange, calculateDate } from "./utils";

const DATE_FORMAT = "HH:mm MMM DD, YY";

type Props = {
  asCalendar?: boolean;
  dualView?: boolean;
  navigable?: boolean;
  maxDuration?: TimeDurationType;
  defaultRangeDuration?: TimeDurationType;
  range?: DateRangeType;
  onSubmit?: (range: DateRangeType) => void;
  onChange?: (range: DateRangeType) => void;
  validateChange?: (range: DateRangeType) => boolean;
  onCancel?: () => void;
  disableButtonAction?: boolean;
  disableTimePicker?: boolean;
};

const DateRangePicker = (props: Props) => {
  const intl = useIntl();
  const zone = moment.tz.guess();
  const timezoneText = `${zone} ${moment.tz(zone).format("z Z")}`;

  const {
    onSubmit,
    range,
    dualView = false,
    navigable,
    maxDuration,
    defaultRangeDuration,
    onChange,
    validateChange,
    onCancel,
    asCalendar,
    disableButtonAction,
    disableTimePicker
  } = props;

  const defaultStartDate = calculateDate(defaultRangeDuration);
  const endDateLimit = useMemo(() => new Date(), []);
  const startDateLimit = useMemo(
    () => calculateDate(maxDuration),
    [maxDuration]
  );

  const maximumRange = useMemo(
    () =>
      startDateLimit
        ? {
            start: startDateLimit,
            end: endDateLimit
          }
        : undefined,
    [endDateLimit, startDateLimit]
  );

  const limitedRange = useMemo(() => {
    // Ensure the range passed does not exceed the maximum selectable range
    // if it does, limit range to maximum selectable
    if ((range?.start && range?.end) || defaultStartDate) {
      return limitRange({
        range: {
          start: range?.start || defaultStartDate,
          end: range?.end || endDateLimit
        },
        maximumRange
      });
    }
  }, [defaultStartDate, endDateLimit, maximumRange, range?.end, range?.start]);

  const [start, setStartDate] = useState(limitedRange?.start);
  const [end, setEndDate] = useState<Date | undefined>(limitedRange?.end);

  const onDateChange = (range: DateRangeType) => {
    /**
     * Route all Changes here to take advantage of external validation function
     * which could help in limiting the change offset that could possibly be caused
     * by the time input
     **/
    const limitedRange = limitRange({
      range,
      maximumRange
    });

    onChange?.(range);
    const isChangeValid = validateChange ? validateChange(range) : true;

    if (isChangeValid) {
      setStartDate(limitedRange?.start || range.start);
      setEndDate(limitedRange?.end || range.end);
    }
  };

  const handleSubmit = () => start && onSubmit?.({ start, end });

  const handleCancel = () => {
    range && onDateChange(range);
    onCancel?.();
  };

  useEffect(() => {
    onDateChange({ start: limitedRange?.start, end: limitedRange?.end });
    // "onDateChange" cannot be added as a dependency or it will cause an infinite loop when on "Staff test mode"
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitedRange]);

  const includeDateIntervals = maximumRange ? [maximumRange] : undefined;
  const disableTimeWrapper = !!(disableButtonAction && disableTimePicker);

  return dualView ? (
    <DualDateLayout>
      <RangeDetailsContainer>
        <RangeDetail>
          {`${moment(start).format(DATE_FORMAT)} - ${
            end ? moment(end).format(DATE_FORMAT) : ""
          }`}
        </RangeDetail>

        <TimeZoneText>{timezoneText}</TimeZoneText>
      </RangeDetailsContainer>
      <DualDatePickerContainer>
        <RangePicker
          includeDateIntervals={includeDateIntervals}
          dualView
          range={{ start, end }}
          selected={start}
          onChange={onDateChange}
          yearRange={5}
          navigable={navigable}
          maximumRange={maximumRange}
        />
        <RangePicker
          includeDateIntervals={includeDateIntervals}
          selected={end}
          range={{ start, end }}
          dualView
          onChange={onDateChange}
          yearRange={5}
          navigable={navigable}
          maximumRange={maximumRange}
        />
      </DualDatePickerContainer>
      <Separator />
      <TimeWrapper>
        <TimeFrameContainer>
          <TimeFrame>
            <Clock />
            <TimeframeLabel>TimeFrame</TimeframeLabel>
          </TimeFrame>
          <TimePickerSplit onChange={onDateChange} start={start} end={end} />
        </TimeFrameContainer>
        <ButtonWrapper>
          <Button disabled={!start || !end} onClick={handleSubmit}>
            Apply
          </Button>
          <Button onClick={handleCancel} variant="secondary">
            Cancel
          </Button>
        </ButtonWrapper>
      </TimeWrapper>
    </DualDateLayout>
  ) : (
    <RangePicker
      asCalendar={asCalendar}
      includeDateIntervals={includeDateIntervals}
      range={{ start, end }}
      onChange={onDateChange}
      yearRange={5}
      navigable={navigable}
      maximumRange={maximumRange}
    >
      {!disableTimeWrapper && (
        <TimeWrapper>
          {!disableTimePicker && (
            <TimePicker onChange={onDateChange} start={start} end={end} />
          )}

          {!disableButtonAction && (
            <ButtonWrapper isFlex={!disableTimePicker}>
              <Button disabled={!start || !end} onClick={handleSubmit}>
                {intl.formatMessage({ id: "apply" })}
              </Button>
              <Button onClick={handleCancel} variant="secondary">
                {intl.formatMessage({ id: "cancel" })}
              </Button>
            </ButtonWrapper>
          )}
        </TimeWrapper>
      )}
    </RangePicker>
  );
};
export default DateRangePicker;
