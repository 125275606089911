import React from "react";

import Icon from "ds/Icon";
import { WrappingIconProps } from "ds/Icon/Icon";

const Loading = (props: WrappingIconProps) => {
  return (
    <Icon {...props}>
      <circle
        cx="12"
        cy="12"
        fill="none"
        r="10"
        stroke="var(--theme-primary-100)"
        strokeWidth="2"
      />
      <circle
        cx="12"
        cy="12"
        fill="none"
        r="10"
        stroke="var(--theme-primary-600)"
        strokeWidth="2"
        strokeLinecap="round"
        transform="rotate(177.406 20 20)"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          calcMode="linear"
          values="0 12 12;180 12 12;720 12 12"
          keyTimes="0;0.5;1"
          dur="1.3s"
          begin="0s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="stroke-dasharray"
          calcMode="linear"
          values="6.283185307179586 56.548667764616276;50.26548245743669 12.566370614359172;6.283185307179586 56.548667764616276"
          keyTimes="0;0.5;1"
          dur="1.3"
          begin="0s"
          repeatCount="indefinite"
        />
      </circle>
    </Icon>
  );
};

export default Loading;
