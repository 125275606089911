import React from "react";

import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const LeftArt = ({ type }) => {
  const uid = useUniqueId();

  let color1 = "white",
    color2 = "white";

  if (type === "info") {
    color1 = "#FFBDBB";
    color2 = "#6D144F";
  }

  return (
    <svg
      width="74"
      height="19"
      viewBox="0 0 74 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={`left-art-${uid}`}
    >
      <title id={`left-art-${uid}`}>Left art</title>
      <path
        d="M60 9L61.3506 12.6494L65 14L61.3506 15.3506L60 19L58.6495 15.3506L55 14L58.6495 12.6494L60 9Z"
        fill={color1}
      />
      <path
        d="M36 0L38.1609 5.83908L44 8L38.1609 10.1609L36 16L33.8391 10.1609L28 8L33.8391 5.83908L36 0Z"
        fill={color2}
      />
      <circle
        r="1.5"
        transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 72.5 2.5)"
        fill={color2}
      />
      <circle
        r="1.5"
        transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 17.5 17.5)"
        fill={color2}
      />
      <circle
        r="1.5"
        transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 1.5 2.5)"
        fill={color1}
      />
    </svg>
  );
};

LeftArt.propTypes = {
  type: PropTypes.string
};

export default LeftArt;
