import { createAsyncThunk } from "@reduxjs/toolkit";

import { PROJECT_ID_FIELD } from "Constants/constants";
import logger from "Libs/logger";
import { AsyncThunkOptionType } from "Reducers/types";

import { LoadCertificatesReturnPayload, LoadCertificatesProps } from "../";

/**
 * Get all Certificates
 *
 * @param {string} organizationDescriptionId
 * @param {object} project
 *
 */

export const loadCertificates = createAsyncThunk<
  LoadCertificatesReturnPayload,
  LoadCertificatesProps,
  AsyncThunkOptionType
>(
  "app/projectCertificates/load_certificates",
  async ({ organizationDescriptionId, project }, { rejectWithValue }) => {
    try {
      const projectCertificates = await project.getCertificates();

      return {
        projectCertificates,
        projectDescriptionId: project[PROJECT_ID_FIELD]
      };
    } catch (err) {
      logger(err, {
        action: "loadCertificates",
        organizationDescriptionId,
        project
      });
      return rejectWithValue(err);
    }
  },
  {
    condition: ({ project }: LoadCertificatesProps) => {
      // If there is no project, stop "loadCertificates"
      if (!project) return false;

      return true;
    }
  }
);
