import { organizationsSelector } from "Reducers/organization";
import { organizationProfileSelector } from "Reducers/organization/profile";
import { useAppSelector } from "Store/hooks";

import {
  CreateProjectInOrganizationTypes,
  createProjectInOrganizationTypes
} from "./types";

export function useCanUserCreateProjectInOrganization({ organizationId }): {
  canCreate: boolean;
  type: CreateProjectInOrganizationTypes | null;
  message?: string;
} {
  const organizationProfile = useAppSelector(state =>
    organizationProfileSelector(state, { organizationId })
  );
  const organizations = useAppSelector(organizationsSelector);

  const isTrialResourceLimitReached =
    organizationProfile?.resources_limit &&
    (organizationProfile?.resources_limit?.used.totals.projects ?? 0) >=
      (organizationProfile?.resources_limit?.limit?.projects ?? 0);

  const hasOrganizations = Object.values(organizations ?? {})?.length !== 0;

  // User has organizations but the create project button didn't received an
  // organizationId (It is being used in the all projects page)
  if (!organizationId && hasOrganizations) {
    return { canCreate: true, type: null };
  }

  if (organizationProfile?.requiredAction?.action === "permission") {
    return {
      canCreate: false,
      type: createProjectInOrganizationTypes.notEnoughPermissions
    };
  }

  // If we are here it means the user doesn't have organizations
  // because we chekc that above, so we show the create an org to
  // create a project modal
  if (!organizationId) {
    return {
      canCreate: false,
      type: createProjectInOrganizationTypes.createFirstOrganization
    };
  }

  if (isTrialResourceLimitReached) {
    return {
      canCreate: false,
      type: createProjectInOrganizationTypes.trialResourcesLimitReached
    };
  }

  if (organizationProfile?.requiredAction?.action === "billing_details") {
    return {
      canCreate: false,
      type: createProjectInOrganizationTypes.billingDetails
    };
  }

  if (organizationProfile?.requiredAction?.action === "ticket") {
    return {
      canCreate: false,
      type: createProjectInOrganizationTypes.restrictedOrg
    };
  }

  if (organizationProfile?.requiredAction?.type === "ticket") {
    return { canCreate: false, type: createProjectInOrganizationTypes.ticket };
  }

  if (organizationProfile?.requiredAction?.type === "phone") {
    return { canCreate: false, type: createProjectInOrganizationTypes.phone };
  }

  if (organizationProfile?.requiredAction?.type === "credit-card") {
    return {
      canCreate: true,
      type: createProjectInOrganizationTypes.creditCard
    };
  }

  if (organizationProfile?.requiredAction?.action === "retry") {
    return { canCreate: false, type: createProjectInOrganizationTypes.idle };
  }

  if (
    !organizationProfile?.canCreate &&
    organizationProfile?.requiredAction?.message
  ) {
    return {
      canCreate: false,
      type: createProjectInOrganizationTypes.generic,
      message: organizationProfile?.requiredAction?.message
    };
  }

  if (organizationProfile?.canCreate) {
    return { canCreate: true, type: null };
  }

  return { canCreate: false, type: "idle" };
}
