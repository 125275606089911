import styled, { css as styledCSS } from "styled-components";

export interface LabelProps {
  textStyle?: string;
  $color?: "neutral-600" | "slate" | "default-weak";
}

export const Label = styled.label<LabelProps>`
  font-family: "Open Sans";
  ${({ $color }) => {
    switch ($color) {
      case "neutral-600":
        return "color:var(--section-granite-color,var(--granite))";
      case "default-weak":
        return "color:var(--mode-text-neutral-default-weak)";
      case "slate":
      default:
        return "color:var(--section-slate-color,var(--slate))";
    }
  }};
  line-height: 16px;

  ${({ textStyle }) => {
    switch (textStyle) {
      case "extraSmall":
        return styledCSS`
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            `;
      case "uppercaseControl":
        return styledCSS`
            font-weight: 600;
            font-size: 13px;
            letter-spacing: 2px;
            text-transform: uppercase;`;
      case "lowercaseControl":
        return styledCSS`
            font-weight: 600;
            font-size: 14px;
          `;
      default:
        return styledCSS`
            font-weight: 600;
            font-size: 13px;
           `;
    }
  }}
`;
export default Label;
