import { createAsyncThunk } from "@reduxjs/toolkit";

import { ErrorWithCode } from "Api/types/ErrorWithCode";
import logger from "Libs/logger";
import client from "Libs/platform";
import { getOrganizationId } from "Libs/utils";
import { AsyncThunkOptionType } from "Reducers/types";

import type { GetOrganizationOrdersPropType, DataOrders } from "../types";

export const getOrganizationOrdersThunk = createAsyncThunk<
  DataOrders,
  GetOrganizationOrdersPropType,
  AsyncThunkOptionType<{ error: ErrorWithCode }>
>(
  "organization/orders",
  async ({ organizationId }, { getState, rejectWithValue }) => {
    const id = getOrganizationId(getState(), organizationId);

    if (!id) return {};

    try {
      const orders = await client.getOrganizationOrders(id, {});
      const ordersGrouped = orders.reduce<DataOrders>(
        (groupedOrders, order) => {
          if (order.status === "recurring_open") {
            return {
              recurring: {
                ...groupedOrders.recurring,
                [order.id]: order
              },
              previous: {
                ...groupedOrders.previous
              }
            };
          } else {
            return {
              recurring: {
                ...groupedOrders.recurring
              },
              previous: {
                ...groupedOrders.previous,
                [order.id]: order
              }
            };
          }
        },
        {}
      );

      return ordersGrouped;
    } catch (_error: unknown) {
      const error = _error as ErrorWithCode;

      if (![404, 403].includes(error.code)) {
        logger(error, {
          action: "organization_load_orders",
          orgId: organizationId
        });
      }

      return rejectWithValue({ error });
    }
  }
);
