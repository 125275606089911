import { apiProxyRequest as request } from "Api/apiProxyRequest";

import type { Team } from "./teams.types";

export type ListTeamsOptions = {
  filter?: {
    organization_id?: string;
    updated_at?: string;
  };
  page?: {
    size?: string;
    before?: string;
    after?: string;
  };
  sort?:
    | "label"
    | "-label"
    | "created_at"
    | "-created_at"
    | "updated_at"
    | "-updated_at";
};

export type ListTeamsResponse = {
  count: number;
  items: Array<Team>;
  _links: {
    self: { href: string };
    previous?: { href: string };
    next?: { href: string };
  };
};

export const listTeams = (options?: ListTeamsOptions) =>
  request<ListTeamsResponse>("/teams", "GET", options);
