import { useEffect } from "react";

import { useLoggedInMember } from "./useLoggedInMember";

/** A hook that returns the logged in member and loads it if it's not already loaded. */
export const useAndLoadLoggedInMember = (organizationId: string) => {
  const { data, load } = useLoggedInMember(organizationId);

  useEffect(() => {
    load();
  }, [load]);

  return data;
};
