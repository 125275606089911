import { createSlice } from "@reduxjs/toolkit";

import { redeploy } from "./thunks";
import { RedeployState } from "./types";

const initialState: RedeployState = {
  isLoading: false,
  data: undefined
};

const redeploySlice = createSlice({
  name: "redeploy",
  initialState,
  reducers: {
    clearRedeploy: state => {
      state.isLoading = false;
      state.error = undefined;
      state.data = undefined;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(redeploy.pending, state => {
        state.isLoading = true;
      })

      .addCase(redeploy.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        state.data = action.payload;
      })
      .addCase(redeploy.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  }
});

export const { clearRedeploy } = redeploySlice.actions;

export default redeploySlice.reducer;
