import { useEffect } from "react";

import { useLocation } from "react-router-dom";

import { useAppDispatch } from "Store/hooks";

export const useTrackPreviousRoutes = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch({ type: "@@router/LOCATION_CHANGE", payload: { location } });
  }, [dispatch, location]);
};
