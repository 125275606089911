import styled from "styled-components";

import * as Dropdown from "ds/Dropdown";
import { item } from "ds/Dropdown/Dropdown.styles";
import {
  Button as GoLiveBtn,
  Live
} from "src/common/containers/NavBar/Environment/GoLiveButton/GoLiveButton.styles";

export const GoLiveButtonContainer = styled.div`
  ${GoLiveBtn} {
    ${item}
  }
  ${Live} {
    margin-left: 16px;
  }
`;

export const DropdownTrigger = styled(Dropdown.Trigger)`
  :hover {
    background-color: var(--mode-bg-neutral-inverse-weaker);
  }
`;

export const DropdownSeparator = styled(Dropdown.Separator)`
  margin: 16px 8px;
`;
