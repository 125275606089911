import { entities } from "platformsh-client";

import logger from "Libs/logger";
import { loadEnvironmentFromEventSuccess } from "Reducers/environment";
import { getFeatureFlag } from "Reducers/featureFlags";
import { SubscribeType, loadProjectSuccess } from "Reducers/project/project";
import { createAppAsyncThunk } from "Store/createAppAsyncThunk";

/**
 * Subscribe
 *
 * @param {Project} project
 *
 */
export const subscribe = createAppAsyncThunk(
  "app/project_reducer/project_subscribe",
  async (
    { project, organizationId }: SubscribeType,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const projectSubscription = await project.subscribe();

      const environmentSocketEnabled = getFeatureFlag(
        "ENABLE_ENVIRONMENT_SOCKET"
      );

      // This import must be asynchronous otherwise tests will break
      const activityReducerModule = await import("Reducers/activity");
      const loadActivitySuccess = activityReducerModule.loadActivitySuccess;

      projectSubscription.addEventListener("resource/activity", message => {
        if (!message.data) {
          return false;
        }
        const activityRawMessage = JSON.parse(message.data);

        dispatch(
          /**
           * /resource/activity is an EventSource driven API that sends events when new activity happens on the project’s activity feed. You should be able to trigger this event by doing something like adding a project variable
           */
          loadActivitySuccess({
            activity: new entities.Activity(
              activityRawMessage.data,
              activityRawMessage.data._links.self.href
            )
          })
        );
      });

      projectSubscription.addEventListener("resource/project", message => {
        if (!message.data) {
          return false;
        }
        const projectRawMessage = JSON.parse(message.data);

        dispatch(
          loadProjectSuccess({
            project: new entities.Project(
              projectRawMessage.data,
              projectRawMessage.data._links.self.href
            ),
            organizationId
          })
        );
      });

      environmentSocketEnabled &&
        projectSubscription.addEventListener(
          "resource/environment",
          message => {
            if (!message.data) {
              return false;
            }

            const environmentRawMessage = JSON.parse(message.data);

            dispatch(
              /**
               * /resource/activity is an EventSource driven API that sends events when new activity happens on the project’s activity feed. You should be able to trigger this event by branching from an environment
               */
              loadEnvironmentFromEventSuccess({
                environment: new entities.Environment(
                  environmentRawMessage.data,
                  environmentRawMessage.data._links.self.href
                ),
                organizationId
              })
            );
          }
        );
    } catch (err) {
      logger(err, {
        action: "project_subscribe"
      });

      return rejectWithValue({ error: err as string });
    }
  }
);
