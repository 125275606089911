import { createAsyncThunk } from "@reduxjs/toolkit";

import { entities } from "Libs/platform";
import { getCommonError } from "Reducers/sliceFactory";
import { AsyncThunkOptionType } from "Reducers/types";

import { GetGitTreeParam } from "../types";

import type { Tree } from "platformsh-client";

const getGitTree = createAsyncThunk<
  Tree | undefined,
  GetGitTreeParam,
  AsyncThunkOptionType
>("git/getGitTree", async ({ projectId, commitId }, { rejectWithValue }) => {
  try {
    if (!projectId || !commitId) return;
    const data = await entities.Tree.get(projectId, commitId);
    return data;
  } catch (error) {
    return rejectWithValue(getCommonError(error));
  }
});

export default getGitTree;
