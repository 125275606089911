import * as Toast from "@radix-ui/react-toast";
import styled from "styled-components";

import DsTag from "ds/Tag";

export const Layout = styled.div`
  position: relative;
  margin-right: 14px;
  margin-left: -18px;
`;

export const Tag = styled(DsTag)`
  margin-top: 4px;
`;

export const ToastDescription = styled.div`
  line-height: 24px;
  margin: 8px 0 0;
  font-size: 14px;
  color: var(--section-ebony-light-color, var(--ebony-light));
`;

export const ToastViewport = styled(Toast.Viewport)`
  position: absolute;
  left: 0;
  padding: 0;
  margin: 0;
  gap: 8px;
  display: flex;
  flex-direction: column;
  z-index: 1001;
`;
