import React from "react";

import * as PrimitiveDropdown from "@radix-ui/react-dropdown-menu";

import { sendHeapEventDropdown } from "Store/analytics/heapFunctions";

import type { DropdownMenuProps } from "@radix-ui/react-dropdown-menu";

export const Root = ({
  modal,
  id,
  children,
  ...props
}: DropdownMenuProps & { id?: string }) => {
  return (
    <PrimitiveDropdown.Root
      modal={modal || false}
      {...props}
      onOpenChange={(...args) => {
        sendHeapEventDropdown(id);
        props.onOpenChange && props.onOpenChange(...args);
      }}
    >
      {children}
    </PrimitiveDropdown.Root>
  );
};
