import { apiProxyRequest } from "Api/apiProxyRequest";
import { EnvironmentTypeAccess } from "Api/organizations/member/projectAccess/ProjectAccess.types";

export type ExtendedAccessOrganization = {
  created_at: string;
  id: string;
  label: string;
  name: string;
  owner_id: string;
  updated_at: string;
};

export type ExtendedAccessProject = {
  created_at: string;
  id: string;
  organization_id: string;
  region: string;
  // I haven't been able to find any documentation on this field
  status: string;
  subscription_id: string;
  title: string;
  updated_at: string;
};

export type ExtendedAccessElement = {
  granted_at: string;
  organization_id: string;
  permissions: Array<EnvironmentTypeAccess>;
  resource_id: string;
  resource_type: "organization" | "project";
  updated_at: string;
  user_id: string;
};

export type ExtendedAccessElementWithMetadata = ExtendedAccessElement & {
  organization_label: string;
  organization_name: string;
  project_title?: string;
  project_region?: string;
  project_subscription_id?: string;
};

export type ExtendedAccessResponse = {
  items: Array<ExtendedAccessElement>;
  _links: {
    /** Zero or more links to organizations present on the extended-access. */
    [x: `ref:organizations:${number}`]: { href: string };
    /** Zero or more links to projects present on the extended-access. */
    [x: `ref:projects:${number}`]: { href: string };
  };
};

export const getUserExtendedAccess = async (userId: string) => {
  const response = await apiProxyRequest<ExtendedAccessResponse>(
    `/users/${userId}/extended-access`
  );
  const organizations = await Promise.allSettled(
    Object.keys(response._links)
      .filter(key => key.startsWith("ref:organizations:"))
      .map(key =>
        apiProxyRequest<Record<string, ExtendedAccessOrganization>>(
          response._links[key].href
        )
      )
  ).then(responses =>
    responses.reduce(
      (acc, response) => {
        if (response.status === "fulfilled") {
          return { ...acc, ...response.value };
        }
        return acc;
      },
      {} as Record<string, ExtendedAccessOrganization>
    )
  );

  const projects = await Promise.allSettled(
    Object.keys(response._links)
      .filter(key => key.startsWith("ref:projects:"))
      .map(key =>
        apiProxyRequest<Record<string, ExtendedAccessProject | null>>(
          response._links[key].href
        )
      )
  ).then(responses =>
    responses.reduce(
      (acc, response) => {
        if (response.status === "fulfilled") {
          return {
            ...acc,
            ...(Object.fromEntries(
              Object.entries(response.value).filter(
                ([, value]) => value !== null
              )
            ) as Record<string, ExtendedAccessProject>) // Need to type this since TS is not that clever
          };
        }
        return acc;
      },
      {} as Record<string, ExtendedAccessProject>
    )
  );

  const extendedAccess: Array<ExtendedAccessElementWithMetadata> =
    response.items.map(item => ({
      ...item,
      organization_label: organizations[item.organization_id]?.label,
      organization_name: organizations[item.organization_id]?.name,
      project_title: projects[item.resource_id]?.title,
      project_region: projects[item.resource_id]?.region,
      project_subscription_id: projects[item.resource_id]?.subscription_id
    }));

  return {
    extendedAccess,
    organizations,
    projects
  };
};
