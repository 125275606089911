import React from "react";

import PhoneNumberVerificationModal from "Containers/PhoneNumberVerificationModal";
import Button, { AddButton } from "ds/Button";

import { CreateProjectButtonProps } from "../types";

const PhoneModal = ({
  isModalOpen,
  setIsModalOpen,
  isLink,
  linkStyle,
  linkVariant = "link",
  openModal,
  label
}: CreateProjectButtonProps) => {
  return (
    <>
      <PhoneNumberVerificationModal
        isOpen={isModalOpen}
        onOpenChange={() => setIsModalOpen?.(!isModalOpen)}
      />
      {isLink ? (
        <Button
          analyticId="create_project"
          id="create_project"
          variant={linkVariant}
          style={linkStyle}
          onClick={openModal}
        >
          {label}
        </Button>
      ) : (
        <AddButton
          analyticId="create_project"
          id="create_project"
          onClick={openModal}
        >
          {label}
        </AddButton>
      )}
    </>
  );
};

export default PhoneModal;
