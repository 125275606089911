import React from "react";

import * as S from "./Icon.styles";
import { Priority } from "./Sticker.types";

export interface IconProps {
  priority: keyof typeof Priority;
  className?: string;
}

const getIcon = (priority: keyof typeof Priority) => {
  switch (priority) {
    case Priority.low:
      return <S.Low />;
    case Priority.critical:
      return <S.Critical />;
    case Priority.normal:
      return <S.Normal />;
    case Priority.information:
    default:
      return <S.Information />;
  }
};

const Icon = ({ priority, className }: IconProps) => (
  <S.Root className={className}>{getIcon(priority)}</S.Root>
);

export default Icon;
