import { Link as RouterLink } from "react-router-dom";
import styled, { css as styledCSS } from "styled-components";

import { Scrollbox as ScrollboxPrimitive } from "ds/Scrollbox";
import Search, { Icon, Close } from "ds/Search";
import { Search as SearchPrimitive } from "ds/Search/Search.styles";

export const MenuLayout = styled.div`
  padding: 0;
`;

export const Link = styled(RouterLink)<{ $isCurrent: boolean }>`
  position: relative;
  display: block !important;
  width: auto;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-height: 32px;
  cursor: pointer;
  color: var(--section-ebony-light-color, var(--ebony-light));

  ${({ $isCurrent, theme }) =>
    $isCurrent &&
    theme.name === "contrast" &&
    styledCSS`
      &::before {
        content: "";
        position: absolute;
        top: 14px;
        left: 4px;
        height: 4px;
        width: 4px;
        border-radius: 4px;
        background-color: var(--link-color-primary-600-background-color,var(--color-primary-600));
      }
  `}

  > span span {
    display: inline;
  }
`;

export const Empty = styled.div`
  color: var(--section-night-color, var(--night));
  line-height: 32px;
  padding: 0 16px;
  font-size: 14px;
`;

export const Scrollbox = styled(ScrollboxPrimitive)`
  height: 332px;
  .simplebar-content-wrapper {
    padding: 8px;
  }
`;
export const NoScroll = styled.div`
  height: 332px;
  padding: 8px;
  box-sizing: border-box;
`;

export const SearchInput = styled(Search)`
  height: 56px;
  background-color: var(--mode-bg-neutral-weaker);
  padding-left: 24px;

  ${Close} {
    display: none;
  }

  :has(${SearchPrimitive}:focus) {
    ${Icon} {
      fill: var(--mode-vector-neutral-strong);
    }
  }

  ${SearchPrimitive} {
    padding-left: 5px;
    &::placeholder {
      font-size: 14px;
      line-height: 24px;
      color: var(--mode-text-neutral-default-weak);
    }

    &:focus {
      &::placeholder {
        color: var(--mode-text-neutral-weakest);
      }
    }
  }

  ${Icon} {
    fill: var(--mode-vector-neutral-default);
  }
`;
