import { createAsyncThunk } from "@reduxjs/toolkit";

import { ACTIVITY_TYPES } from "Constants/constants";
import logger from "Libs/logger";
import client from "Libs/platform";
import { loadEnvironments } from "Reducers/environment";
import { AsyncThunkOptionType } from "Reducers/types";
import { AppDispatch } from "Store/configureStore";

import { setActivityFilterNames, getActivityTypes } from "../activity.slice";
import { LoadProjectActivities } from "../types";

import type { Activity } from "platformsh-client";

const checkDeletedEnvironments = (
  dispatch: AppDispatch,
  activities: Activity[],
  organizationId: string,
  projectId: string
) => {
  if (
    activities.some(
      activity => activity.type === ACTIVITY_TYPES.ENVIRONMENT.DELETE
    )
  ) {
    dispatch(
      loadEnvironments({
        organizationId,
        projectId
      })
    );
  }
};

export const loadProjectActivities = createAsyncThunk<
  {
    activities: Activity[];
    hasMore: boolean;
    organizationDescriptionId: string;
    projectDescriptionId: string;
  },
  LoadProjectActivities,
  AsyncThunkOptionType
>(
  "app/activity/project/load",
  async (
    { projectDescriptionId, organizationDescriptionId, filterNames, context },
    { dispatch, rejectWithValue }
  ) => {
    dispatch(setActivityFilterNames({ context, filterNames }));
    const filters = getActivityTypes(filterNames);
    // Don't make any request when no filter is selected
    if (!filters.length) {
      return {
        activities: [],
        hasMore: false,
        organizationDescriptionId,
        projectDescriptionId
      };
    }
    try {
      const activities = await client.getProjectActivities(
        projectDescriptionId,
        filters
      );

      let hasMore = false;
      if (activities.length > 9) {
        hasMore = true;
        activities.pop();
      }
      checkDeletedEnvironments(
        dispatch,
        activities,
        organizationDescriptionId,
        projectDescriptionId
      );
      return {
        activities,
        hasMore,
        organizationDescriptionId,
        projectDescriptionId
      };
    } catch (error: any) {
      if (![404, 403, 400].includes(error.code)) {
        logger(error, {
          action: "app/activity/project/load_failure",
          meta: {
            organizationDescriptionId,
            projectDescriptionId
          }
        });
      }

      return rejectWithValue(error);
    }
  }
);
