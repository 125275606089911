import { useCallback } from "react";

import useDecodedParams from "Hooks/useDecodedParams";
import { organizationByDescriptionIdSelector } from "Reducers/organization";
import { RootState } from "Store/configureStore";
import { useAppSelector } from "Store/hooks";

/** Defines the parameters available on the base organization url. */
export interface OrganizationURLParams {
  organizationId?: string;
}

/**
 * This hook extracts the organization name from the url or the one provided and
 * returns the organization information.
 *
 * Even though the url param is named organizationId the content is the
 * organization name. This name can't be used to fetch anything related to the
 * organization.
 */
function useOrganizationFromURL(organizationName?: string) {
  const { organizationId: URLOrganizationName } =
    useDecodedParams<OrganizationURLParams>();

  const organizationSelector = useCallback(
    (state: RootState) =>
      organizationByDescriptionIdSelector(state, {
        organizationDescriptionId: organizationName || URLOrganizationName
      }),
    [URLOrganizationName, organizationName]
  );

  const organization = useAppSelector(organizationSelector);

  return organization;
}

export default useOrganizationFromURL;
