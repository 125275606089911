import { createSlice } from "@reduxjs/toolkit";

import { OrganizationProjectsList } from "Api/organizations/projects/OrganizationProjects.types";

import getOrganizationProjects from "./thunks/getOrganizationProjects";

export interface OrganizationProjects {
  organizationProjects?: OrganizationProjectsList;
  isLoading: boolean;
  error: unknown;
}

const initialState: OrganizationProjects = {
  organizationProjects: undefined,
  isLoading: false,
  error: undefined
};

const organizationProjectsSlice = createSlice({
  name: "organizationProjects",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getOrganizationProjects.pending, state => ({
        ...state,
        error: undefined,
        isLoading: true
      }))
      .addCase(getOrganizationProjects.fulfilled, (state, action) => ({
        ...state,
        organizationProjects: action.payload,
        isLoading: false
      }))
      .addCase(getOrganizationProjects.rejected, (state, action) => ({
        ...state,
        error: action.error,
        isLoading: false
      }));
  }
});

export default organizationProjectsSlice.reducer;
