export enum Variant {
  short = "short",
  long = "long",
  button = "button"
}

export enum Priority {
  critical = "critical",
  normal = "normal",
  low = "low",
  information = "information"
}
