import { createAsyncThunk } from "@reduxjs/toolkit";

import { getCommonError } from "Reducers/sliceFactory";
import { AsyncThunkOptionType } from "Reducers/types";

import type { Blob } from "platformsh-client";

const getGitBlobContent = createAsyncThunk<
  string | undefined,
  Blob | undefined,
  AsyncThunkOptionType
>("git/getGitBlobContent", async (blob, { rejectWithValue }) => {
  try {
    if (!blob) return;
    const data = await blob.getRawContent();

    return data;
  } catch (error) {
    return rejectWithValue(getCommonError(error));
  }
});

export default getGitBlobContent;
