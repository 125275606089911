export enum ProcessSignalNumber {
  SIGNULL = 0,
  SIGHUP = 1,
  SIGINT = 2,
  SIGQUIT = 3,
  SIGFPE = 8,
  SIGKILL = 9,
  SIGPIPE = 13,
  SIGALRM = 14,
  SIGTERM = 15,
  SIGCHLD = 17
}

export const ProcessSignalName = {
  [ProcessSignalNumber.SIGNULL]: "SIGNULL",
  [ProcessSignalNumber.SIGHUP]: "SIGHUP",
  [ProcessSignalNumber.SIGINT]: "SIGINT",
  [ProcessSignalNumber.SIGQUIT]: "SIGQUIT",
  [ProcessSignalNumber.SIGFPE]: "SIGFPE",
  [ProcessSignalNumber.SIGKILL]: "SIGKILL",
  [ProcessSignalNumber.SIGPIPE]: "SIGPIPE",
  [ProcessSignalNumber.SIGALRM]: "SIGALRM",
  [ProcessSignalNumber.SIGTERM]: "SIGTERM",
  [ProcessSignalNumber.SIGCHLD]: "SIGCHLD"
};
