import React from "react";

import Icon from "ds/Icon";
import { WrappingIconProps } from "ds/Icon/Icon";

const Search = (props: WrappingIconProps) => {
  return (
    <Icon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8 14.3L19.7 18.2C20 18.6 20 19.2 19.6 19.6C19.2 20 18.6 20 18.2 19.6L14.3 15.7C13.3 16.5 11.9 17 10.5 17C6.9 17 4 14.1 4 10.5C4 6.9 6.9 4 10.5 4C14.1 4 17 6.9 17.1 10.5C17.1 11.9 16.6 13.2 15.8 14.3ZM6 10.5C6 13 8 15 10.5 15C13 15 15 13 15 10.5C15 8 13 6 10.5 6C8 6 6 8 6 10.5Z"
      />
    </Icon>
  );
};

export default Search;
