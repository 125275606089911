import client from "Libs/platform";

import type { Ticket } from "platformsh-client";
import type { AppDispatch, GetState } from "Store/configureStore";

export type ListTicketState = {
  isLoading: boolean;
  hasMore: boolean;
  page: number;
  tickets?: Ticket[];
  error?: { message: string };
};

const initialState: ListTicketState = {
  isLoading: false,
  hasMore: false,
  page: 1
};

const defaultLoadFailureError = {
  message: "An error has occurred while loading tickets"
};

// Actions
const LOAD_START = "tickets/load_start";
const LOAD_FAILURE = "tickets/load_failure";
const LOAD_SUCCESS = "tickets/load_success";
const LOAD_MORE_SUCCESS = "tickets/load_more_success";

export type TicketPayload = {
  tickets: Ticket[];
  hasMore: boolean;
  page: number;
};

export type ListTicketAction =
  | { type: typeof LOAD_START }
  | { type: typeof LOAD_FAILURE; payload: { message: string } }
  | {
      type: typeof LOAD_SUCCESS;
      payload: TicketPayload;
    }
  | {
      type: typeof LOAD_MORE_SUCCESS;
      payload: TicketPayload;
    };

// Action Creators
export const loadStart = () => ({ type: LOAD_START });
export const loadSuccess = (payload: TicketPayload) => ({
  type: LOAD_SUCCESS,
  payload
});
export const loadFailure = (error?: { message: string }) => ({
  type: LOAD_FAILURE,
  payload: error
});

export const loadMoreSuccess = (payload: TicketPayload) => ({
  type: LOAD_MORE_SUCCESS,
  payload
});

// Thunks
export const load =
  (filters = {}, onSuccess = loadSuccess) =>
  async (dispatch: AppDispatch) => {
    dispatch(loadStart());
    try {
      const response = await client.getTickets(filters);
      let nextPage: string | null | undefined;

      if (response?._links?.next) {
        nextPage = new URL(response._links.next.href).searchParams.get("page");
      }

      dispatch(
        onSuccess({
          tickets: response?.tickets || [],
          hasMore: !!nextPage,
          page: Number(nextPage) - 1 || 1
        })
      );
    } catch (error) {
      dispatch(
        loadFailure({ message: (error as { message: string }).message })
      );
    }
  };

export const loadMore =
  (filters = {}) =>
  async (dispatch: AppDispatch, getState: GetState) => {
    const { tickets } = getState();
    if (tickets.hasMore) {
      await load(
        {
          ...filters,
          page: Number(tickets.page || 0) + 1
        },
        loadMoreSuccess
      )(dispatch);
    }
  };

export const reducer = (
  state = initialState,
  action?: ListTicketAction
): ListTicketState => {
  switch (action?.type) {
    case LOAD_START:
      return {
        ...state,
        isLoading: true
      };
    case LOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload || defaultLoadFailureError
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasMore: action.payload?.hasMore,
        page: action.payload?.page,
        tickets: action.payload?.tickets || []
      };
    case LOAD_MORE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasMore: action.payload?.hasMore,
        page: action.payload?.page,
        tickets: (state.tickets || []).concat(action.payload.tickets || [])
      };
    default:
      return state;
  }
};

export default reducer;
