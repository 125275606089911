import type { DeploymentService } from "platformsh-client";

export const getServiceIcon = (
  service?: DeploymentService,
  fallback: string = "placeholder"
) => {
  const iconName = service?.type?.split(":")[0] || fallback;
  const stack = service?.stack;

  if (iconName === "generic" && stack) {
    // If the stack is a runtime ( versioned e.g php@8.3 ) then we use the main which is
    // the first item in the deployment stacks
    const mainStack = stack[0];
    const mainStackName =
      typeof mainStack === "string" ? mainStack : Object.keys(mainStack)[0];
    const isRuntime = mainStackName.match(/^(.+)[@:].*$/);

    if (isRuntime) {
      return {
        iconName: isRuntime[1],
        isComposible: true,
        mainStackName: iconName,
        isRuntime: true
      };
    }
    return {
      iconName
    };
  }
  return { iconName };
};
