import React from "react";

export const Information = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C15.5 0 20 4.5 20 10C20 15.5 15.5 20 10 20C4.5 20 0 15.5 0 10C0 4.5 4.5 0 10 0ZM10 8C9.4 8 9 8.4 9 9V14C9 14.6 9.4 15 10 15C10.6 15 11 14.6 11 14V9C11 8.4 10.6 8 10 8ZM10 5C9.4 5 9 5.4 9 6C9 6.6 9.4 7 10 7C10.6 7 11 6.6 11 6C11 5.4 10.6 5 10 5Z"
        fill="#4786FF"
      />
    </svg>
  );
};

export default Information;
