import React from "react";

import { FormattedMessage } from "react-intl";

import LoadingIcon from "Icons/LoadingIcon";

import * as S from "./PageLoading.styles";

type LoadingProps = {
  iconOnly?: boolean;
  loadType?: string;
};

const PageLoading = ({ iconOnly, loadType }: LoadingProps) => (
  <S.LoadingWrapper iconOnly={iconOnly}>
    <LoadingIcon />
    {!iconOnly && (
      <S.MessageWrapper>
        <FormattedMessage id="loading" />
        {loadType ? " " + loadType : ""}...
      </S.MessageWrapper>
    )}
  </S.LoadingWrapper>
);

export default PageLoading;
