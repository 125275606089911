import { apiProxyRequest as request } from "Api/apiProxyRequest";

import type { RefUser } from "./User.types";

type RefUserResponse = { [UserId: string]: RefUser };

type RefURL = string;
type WithRefUsersLinks = {
  _links: {
    [x: `ref:users:${number}`]: { href: string };
  };
};
type ResolveRefUsersProps = WithRefUsersLinks | RefURL;

/**
 * Resolves the user data using either a response with the users link or just
 * the url of the ref users to be resolved.
 *
 * @throws if the url is not available
 */
export const resolveRefUsers = async (responseOrURL: ResolveRefUsersProps) => {
  const url =
    typeof responseOrURL === "string"
      ? responseOrURL
      : responseOrURL._links["ref:users:0"]?.href;

  if (!url) throw new Error('The "ref:users:0" link is not available');

  if (typeof responseOrURL === "string") return request<RefUserResponse>(url);

  return await Promise.allSettled(
    Object.keys(responseOrURL._links)
      .filter(key => key.startsWith("ref:users:"))
      .map(key => request<RefUserResponse>(responseOrURL._links[key].href))
  ).then(results =>
    results.reduce((acc, result) => {
      if (result.status === "fulfilled") {
        return { ...acc, ...result.value };
      }
      return acc;
    }, {} as RefUserResponse)
  );
};
