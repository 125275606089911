import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";

import logger from "Libs/logger";
import { setDeep } from "Libs/objectAccess";
import { AsyncThunkOptionType } from "Reducers/types";
import { RootState } from "Store/configureStore";

import type { OrganizationVoucher, Organization } from "platformsh-client";

export const getVouchers = createAsyncThunk(
  "app/vouchers",
  async ({ organization }: { organization?: Organization }) => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;
    const vouchers = await client.getOrganizationVouchers(organization!.id);
    return vouchers;
  }
);

export const addVoucher = createAsyncThunk<
  OrganizationVoucher,
  {
    organization?: Organization;
    code: string;
  },
  AsyncThunkOptionType<string>
>("app/voucher/add", async ({ organization, code }, { rejectWithValue }) => {
  try {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;
    const voucher = (await client.addOrganizationVoucher(
      organization!.id,
      code
    )) as unknown as OrganizationVoucher;
    return voucher;
  } catch (err: any) {
    let error: string = err.title;
    if (err instanceof Error) {
      logger(err, {
        action: "addVoucher",
        meta: {
          organizationId: organization!.id
        }
      });
      error = err.message;
    }
    return rejectWithValue(error);
  }
});

export type Voucher = {
  code: string;
  amount: string;
  currency: string;
  remaining_balance: number;
  orders: {
    order_id: string;
    status: string;
    billing_period_start: string;
    billing_period_end: string;
    order_total: string;
    order_discount: string;
    currency: string;
  }[];
};

export type VoucherState = {
  data: {
    [organizationName: string]: Voucher[] | undefined;
  };
  errors?: {
    [organizationName: string]: string | undefined;
  };
  loading?: {
    [organizationName: string]: boolean;
  };
  add?: {
    status?: "pending" | "added" | "rejected";
    errors?: string | undefined;
    loading?: boolean;
  };
};

const initialState: VoucherState = {
  data: {}
};

const vouchers = createSlice({
  name: "vouchers",
  initialState,
  reducers: {
    initForm(state) {
      delete state.add;
      delete state.errors;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getVouchers.pending, (state, { meta }) => {
        const { organization } = meta.arg;
        if (organization) {
          delete state.errors?.[organization.name];
          setDeep(state, ["loading", organization.name], true);
        }
      })
      .addCase(getVouchers.fulfilled, (state, { meta, payload }) => {
        const { organization } = meta.arg;
        if (organization) {
          setDeep(state, ["data", organization.name], payload.vouchers);
          setDeep(state, ["loading", organization.name], false);
        }
      })
      .addCase(getVouchers.rejected, (state, { error, meta }) => {
        const { organization } = meta.arg;
        if (organization) {
          setDeep(state, ["errors", organization.name], error.message);
          setDeep(state, ["loading", organization.name], false);
        }
      });

    builder
      .addCase(addVoucher.pending, state => {
        delete state.add?.errors;
        setDeep(state, ["add", "status"], "pending");
        setDeep(state, ["add", "loading"], true);
      })
      .addCase(addVoucher.fulfilled, state => {
        setDeep(state, ["add", "status"], "added");
        setDeep(state, ["add", "loading"], false);
      })
      .addCase(addVoucher.rejected, (state, { payload }) => {
        setDeep(state, ["add", "errors"], payload);
        setDeep(state, ["add", "status"], "rejected");
        setDeep(state, ["add", "loading"], false);
      });
  }
});

export const { initForm } = vouchers.actions;
export default vouchers.reducer;

const selectSelf = (state: RootState) => state.voucher;

export const vouchersSelector = createSelector(
  selectSelf,
  (_: RootState, params: { organizationId: string }) => params,
  (voucher, { organizationId }) => voucher.data[organizationId]
);

export const vouchersLoadingSelector = createSelector(
  selectSelf,
  (_: RootState, params: { organizationId: string }) => params,
  (voucher, { organizationId }) => voucher.loading?.[organizationId]
);

export const vouchersErrorsSelector = createSelector(
  selectSelf,
  (_: RootState, params: { organizationId: string }) => params,
  (voucher, { organizationId }) => voucher.errors?.[organizationId]
);

// Add voucher
export const addVoucherLoadingSelector = createSelector(
  selectSelf,
  voucher => voucher.add?.loading ?? false
);
export const addVoucherErrorsSelector = createSelector(
  selectSelf,
  voucher => voucher.add?.errors
);
export const addVoucherStatusSelector = createSelector(
  selectSelf,
  voucher => voucher.add?.status
);
