import React from "react";

import { useIntl } from "react-intl";

import { Button } from "ds/Button";
import * as Sticker from "ds/Sticker";
import { Title } from "ds/Typography";

import {
  OperationDescription,
  SourceOperationContainer,
  StickerText,
  ModalButtonWrapper,
  DocumentationInfo,
  RedirectLink,
  StickerRoot,
  ModalProButtonWrapper,
  DocLink
} from "../SourceOperation.style";

type ConfigureOperationNoticeProps = {
  onClose: () => void;
  isPro: boolean;
  isRuntime: boolean;
};
export const ConfigureOperationNotice = ({
  onClose,
  isPro,
  isRuntime
}: ConfigureOperationNoticeProps) => {
  const formatMessage = useIntl().formatMessage;
  const onContactSales = () => {
    window.location.replace(formatMessage({ id: "links.platform.contact" }));
  };

  return (
    <SourceOperationContainer data-testid="configure-source-operation">
      <Title tag="h3">
        {formatMessage({
          id: isRuntime
            ? "source_ops.runtime.configure.title"
            : "source_ops.configure.title"
        })}
      </Title>

      <div>
        <OperationDescription className="operation-notice">
          {formatMessage({
            id: isRuntime
              ? "source_ops.runtime.configure.message"
              : "source_ops.configure.message"
          })}
        </OperationDescription>
        {isPro ? (
          <>
            {" "}
            <DocumentationInfo>
              {formatMessage({ id: "source_ops.configure.pro.learnmore" })}{" "}
              <DocLink
                href={formatMessage({
                  id: "links.documentation.runtime_operations"
                })}
                target="_blank"
                rel="noreferrer"
                className="link"
              >
                {formatMessage({ id: "documentation" })}
              </DocLink>
              .
            </DocumentationInfo>
            <StickerRoot priority="information">
              <Sticker.Icon priority="information" />
              <StickerText>
                {formatMessage<React.ReactNode>(
                  { id: "source_ops.configure.pro.upgrade" },
                  {
                    linkSalesTeam: chunks => (
                      <RedirectLink
                        href={formatMessage({ id: "links.platform.contact" })}
                      >
                        {chunks}
                      </RedirectLink>
                    ),
                    linkPricingPage: chunks => (
                      <RedirectLink
                        href={formatMessage({ id: "links.pricing" })}
                      >
                        {chunks}
                      </RedirectLink>
                    )
                  }
                )}
              </StickerText>
            </StickerRoot>
          </>
        ) : (
          <OperationDescription className="operation-notice">
            {formatMessage<React.ReactNode>(
              { id: "source_ops.configure.learnmore" },
              {
                link: chunks => (
                  <DocLink
                    href={formatMessage({
                      id: isRuntime
                        ? "links.documentation.runtime_operations"
                        : "links.documentation.source_operations"
                    })}
                    target="_blank"
                    rel="noreferrer"
                    className="link"
                  >
                    {chunks}
                  </DocLink>
                )
              }
            )}{" "}
          </OperationDescription>
        )}
      </div>
      {isPro ? (
        <ModalProButtonWrapper spacing="modal">
          <Button
            variant="outline"
            onClick={onContactSales}
            analyticId="contact_sales"
          >
            {formatMessage({ id: "contact_sales" })}
          </Button>

          <Button variant="secondary" onClick={onClose} analyticId="cancel">
            {formatMessage({ id: "cancel" })}
          </Button>
        </ModalProButtonWrapper>
      ) : (
        <ModalButtonWrapper spacing="modal">
          <Button variant="secondary" onClick={onClose} analyticId="cancel">
            {formatMessage({ id: "cancel" })}
          </Button>
        </ModalButtonWrapper>
      )}
    </SourceOperationContainer>
  );
};

export default ConfigureOperationNotice;
