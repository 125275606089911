import React from "react";

import { Link as LinkRouter } from "react-router-dom";
import styled, { css as styledCSS } from "styled-components";

export const Wrapper = styled.li`
  padding: 0;
  display: inline-flex;
  align-items: center;
  position: relative;
  max-width: 150px;
  &:last-child {
    max-width: 100px;
  }
  @media (min-width: 1100px) {
    max-width: 250px;
  }
  @media (max-width: 970px) {
    max-width: 80px;
  }
  @media (max-width: 830px) {
    max-width: 50px;
  }
  ${props =>
    props.separator &&
    styledCSS`
      padding-right: 10px;
    `};

  ${props =>
    props.separator === "/" &&
    styledCSS`
      &:after {
        content: "/";
        position: absolute;
        color: #c9d0e4;
        right: -5px;
        line-height: 22px;
        padding: 6px 8px;
        font-size: 12px;
      }
    `};
  ${props =>
    props.separator === "chevron" &&
    styledCSS`
      &:after {
        content: "";
        position: absolute;
        right: 3px;
        border-color: ${"var(--darkerChevron-icon-fill,var(--icon-periwinkle-grey-fill,var(--periwinkle-grey)))"};
        border-style: solid;
        border-width: 2px 2px 0 0;
        border-radius: 2px;
        width: 6px;
        height: 6px;
        transform: rotate(45deg);
        margin-bottom: 8px;
      }
    `};
`;

export const Link = styled(({ isActive, ...props }) => (
  <LinkRouter {...props} />
))`
  color: ${props =>
    props.isActive
      ? "var(--mode-text-primary-weak)"
      : "var(--mode-overrides-header-text-weak)"} !important;
    } !important;
  line-height: 22px;
  padding: 6px 8px;
  font-size: 12px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  white-space: nowrap;

  overflow: hidden;
  text-overflow: ellipsis;

  &:focus,
  &:hover {
    background-color: rgb(from var( --mode-bg-primary-weakest) r g b / 12%);
    color: var(--mode-text-neutral-inverse-weak);
    outline: none;
  }

  ${props =>
    props.theme.name === "contrast" &&
    styledCSS`
      &:focus {
        background-color: transparent;
        box-shadow: 0 0 0 1px #4786ff;
      }

      &:hover {
        background-color: #98a0ab;
        color: #1a192b;
      }
    `};
`;
