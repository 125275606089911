import { createAsyncThunk } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import client from "Libs/platform";

import { LoadCommentsReturnPayload, LoadCommentsProps } from "../";

import type { AsyncThunkOptionType } from "Reducers/types";

/**
 *
 * @param {Number|string} ticketId Return comments related to that ticket
 * @param {Object} filters Object containing filters
 */
export const loadComments = createAsyncThunk<
  LoadCommentsReturnPayload | undefined,
  LoadCommentsProps,
  AsyncThunkOptionType<{ errors: unknown }>
>(
  "tickets/comments/load_comments",
  async ({ ticketId, filters }, { rejectWithValue }) => {
    try {
      ticketId = ticketId + "";

      const response = await client.loadComments(ticketId, {
        ...filters,
        all: 1
      });

      let nextPage: string | null = null;

      if (response?._links?.next) {
        nextPage = new URL(response._links.next.href).searchParams.get("page");
      }

      return {
        comments: response.comments,
        hasMore: !!nextPage,
        commentPage: Number(nextPage) - 1 || 1,
        ticketId
      };
    } catch (err: unknown) {
      logger(err, {
        action: "TICKETS_COMMENTS_LOAD",
        meta: {
          ticketId,
          filters
        }
      });
      return rejectWithValue({ errors: err });
    }
  }
);
