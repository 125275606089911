import React, { ComponentProps } from "react";

import Close from "ds/Icons/Close";
import CloseSm from "ds/Icons/CloseSm";

import { ButtonProps } from "./Button";
import * as S from "./CloseButton.styles";

export type CloseButtonProps = ButtonProps;

const CloseButton = React.forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof S.Icon> & CloseButtonProps
>(({ children, iconSize = "normal", ...props }, ref) => {
  return (
    <S.Layout variant="secondary" {...props} analyticId="close_button">
      {children && <S.Text>{children}</S.Text>}
      <S.Icon ref={ref} aria-label="close">
        {/* The svg icons below can be flagged as hidden since the button
        wrapping them can have an aria-label attached to it, flagging what's the
        purpose of this close button.  The default aria-label above can be
        overrwritten if the close button acts as a clear, or any other dismiss
        interaction. */}
        {iconSize === "small" ? (
          <CloseSm aria-hidden="true" />
        ) : (
          <Close aria-hidden="true" />
        )}
      </S.Icon>
    </S.Layout>
  );
});

CloseButton.displayName = "CloseButton";
export default CloseButton;
