import React, { useMemo, useState } from "react";

import { useIntl } from "react-intl";

import Modal from "ds/Modal";
import Loading from "ds/PageLoading";
import { Title } from "ds/Typography";
import { capitalize } from "Libs/utils";

import * as Steps from "./Steps";
import {
  ConfigProps,
  HandleChangeConfigKeyType,
  HandleChangeConfigValueType,
  MethodsList,
  StepsList
} from "./types";

const PhoneNumberVerificationModal = ({ isOpen, onOpenChange }) => {
  const intl = useIntl();

  const [currentStep, setStep] = useState<StepsList>(StepsList.phone);
  const [config, setConfig] = useState<ConfigProps>({
    code: "",
    methods: MethodsList.sms,
    phone: "",
    showErrorMessage: false
  });

  const goToStep = (step: StepsList) => {
    setStep(step);
  };

  const onCancel = () => {
    onOpenChange(false);
    setConfig({ ...config, methods: MethodsList.sms, showErrorMessage: false });
  };

  const StepComp = useMemo(
    () => Steps[capitalize(currentStep) as keyof typeof Steps],
    [currentStep]
  );

  const handleChangeConfig = (
    key: HandleChangeConfigKeyType,
    value: HandleChangeConfigValueType
  ) => {
    setConfig({ ...config, [key]: value });
  };

  if (!currentStep) return <Loading />;

  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
      <Title tag="h3">
        {intl.formatMessage({
          id: `kyc_verification.${currentStep?.toLowerCase()}.title`
        })}
      </Title>
      <StepComp
        goToStep={goToStep}
        onCancel={onCancel}
        handleChangeConfig={handleChangeConfig}
        config={config}
      />
    </Modal>
  );
};

export default PhoneNumberVerificationModal;
