import React from "react";

import * as Sticker from "ds/Sticker";

import * as S from "./Error.styles";

type ErrorProps = {
  children?: React.ReactNode | Record<string, unknown>;
};

const Error = ({ children }: ErrorProps) => {
  return children ? (
    <Sticker.Root priority="critical">
      <Sticker.Icon priority="critical" />
      <S.ErrorText>
        {typeof children === "object" ? JSON.stringify(children) : children}
      </S.ErrorText>
    </Sticker.Root>
  ) : null;
};

export default Error;
