import config from "console_config";
import apiConfig from "Constants/api_config";
import { request } from "Libs/platform";
import { getFeatureFlag } from "Reducers/featureFlags";

import type { SetupCatalog } from "./types/SetupCatalog";

export type GetSetupCatalogRequestArguments =
  | {
      catalogUrl?: string | null;
      organizationId?: string | null;
    }
  | undefined;

export const getSetupCatalogRequest = ({
  catalogUrl,
  organizationId
}: GetSetupCatalogRequestArguments = {}): Promise<SetupCatalog[]> => {
  const vendor = config.CUSTOM_CONSOLE_ID
    ? `?vendor=${config.CUSTOM_CONSOLE_ID}`
    : "";

  const organizationEnabled = getFeatureFlag("ENABLE_ORGANIZATION");

  if (catalogUrl) {
    return request(catalogUrl, "POST");
  }

  if (organizationId && organizationEnabled) {
    return request(
      `${apiConfig.api_url}/organizations/${organizationId}/setup/catalog${vendor}`,
      "GET"
    );
  }

  return request(
    `${apiConfig.api_url}/platform/setup/catalog${vendor}`,
    "POST"
  );
};
