import { getUserExtendedAccess } from "Api/users/getUserExtendedAccess";
import { createAppAsyncThunk } from "Store/createAppAsyncThunk";

type Args = {
  userId: string;
};

export const loadExtendedAccess = createAppAsyncThunk(
  "app/project_reducer/loadExtendedAccess",
  ({ userId }: Args) => getUserExtendedAccess(userId)
);
