import { createAsyncThunk } from "@reduxjs/toolkit";

import { getAllowanceDiscountsRequest } from "Api/discount/getDiscounts.request";
import logger from "Libs/logger";

import { AllowanceDiscounts } from "../types";

import type { AsyncThunkOptionType } from "Reducers/types";

export const getDiscounts = createAsyncThunk<
  AllowanceDiscounts,
  undefined,
  AsyncThunkOptionType
>("app/discount/get_discounts", async (_, { rejectWithValue }) => {
  try {
    const allowanceDiscounts = await getAllowanceDiscountsRequest();

    return allowanceDiscounts;
  } catch (err: unknown) {
    const error =
      (err as { title: string })?.title ??
      (err as { description: string })?.description ??
      "An error has occurred";

    logger(err, {
      action: "get_discounts"
    });

    return rejectWithValue(error);
  }
});
