import styled from "styled-components";

import Tag from "ds/Tag";

export interface HiddenCountProps {
  $overflowDirection: "vertical" | "horizontal";
  $right?: number;
  $isVisible: boolean;
}

export const HiddenCount = styled(Tag).attrs({
  variant: "gray"
})<HiddenCountProps>`
  display: block;
  &:hover {
    background-color: var(
      --section-periwinkle-grey-background-color,
      var(--periwinkle-grey)
    );
  }

  position: absolute;
  z-index: 1;
  margin-bottom: ${({ $overflowDirection }) =>
    $overflowDirection === "vertical" ? "2px" : 0};
  transform: translateX(${({ $right }) => ($right ? `${$right}px` : "100%")});
  bottom: 8px;
  ${({ $isVisible }) => !$isVisible && "display: none;"}
`;

export default HiddenCount;
