import { updateBuildResourcesRequest } from "Api/projectSettings/updateBuildResources.request";
import logger from "Libs/logger";
import { isJson } from "Libs/utils";
import { createAppAsyncThunk } from "Store/createAppAsyncThunk";

import type { ProjectSetting } from "../";
import type { AsyncThunkOptionType } from "Reducers/types";

export const updateBuildResources = createAppAsyncThunk<
  ProjectSetting,
  {
    projectId: string;
    buildResources: { cpu: number; memory: number };
  },
  AsyncThunkOptionType
>(
  "app/project/capability/update",
  async ({ projectId, buildResources }, { rejectWithValue }) => {
    try {
      const updatedProjectCapability = await updateBuildResourcesRequest(
        projectId,
        buildResources
      );

      return updatedProjectCapability?._embedded?.entity;
    } catch (error) {
      logger(error, {
        action: "capability_update"
      });
      const isJsonError = isJson(error);
      const updatedError = isJsonError ? JSON.parse(error)?.message : error;
      return rejectWithValue(updatedError);
    }
  }
);
