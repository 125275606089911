import styled from "styled-components";

import { Layout } from "../UserMenu/styles";

export const HelpLayout = styled(Layout)`
  margin-right: 24px;
  display: flex;
  align-items: center;
  padding-left: 8px;
`;

export const IconContainer = styled.div`
  padding-right: 8px;
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  color: var(--mode-text-neutral-default-weak);
  &:hover,
  &:focus,
  &:active {
    color: var(--mode-text-primary-default);
  }
  width: 61px;
  cursor: pointer;
`;

export const HelpText = styled.p`
  font-size: 15px;
`;
