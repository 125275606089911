import React, { useRef, useState } from "react";

import { useIntl } from "react-intl";

import MenuDropdownWrapper from "Components/MenuDropdownWrapper";
import useMediaQuery from "Hooks/useMediaQuery";
import { getSupportUrl } from "Libs/getUrl/getUrl";
import { docsUrl } from "Libs/utils";

import MenuLink from "../UserMenu/MenuLink";
import { CreateOrgSeparator } from "../UserMenu/styles";

import HelpButton from "./HelpButton";
import * as S from "./styles";

import type { Me } from "platformsh-client";

interface Props {
  user: Me;
  accountUrl: string;
}

const HelpMenu = ({ accountUrl, user }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const layoutRef = useRef(null);
  const intl = useIntl();
  const match = useMediaQuery("(max-width: 764px)");

  const submitIdeaUrl = intl.messages["links.submit_idea"];
  const discordUrl = intl.messages["links.discord"];

  const supportUrl = getSupportUrl({
    accountUrl,
    username: user.username
  });

  return (
    <S.HelpLayout ref={layoutRef}>
      {!match && (
        <HelpButton
          layoutRef={layoutRef}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
        />
      )}
      <MenuDropdownWrapper className="menu-dropdown" isOpen={isOpen}>
        <ul>
          <li>
            <MenuLink
              label={intl.formatMessage({ id: "menu.documentation" })}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => setIsOpen(false)}
              url={docsUrl}
            />
          </li>
          <li>
            <MenuLink
              label={intl.formatMessage({ id: "menu.support" })}
              url={supportUrl || ""}
            />
          </li>

          {discordUrl && (
            <li>
              <MenuLink
                label={intl.formatMessage({ id: "help.discord" })}
                url={discordUrl as string}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => setIsOpen(false)}
              />
            </li>
          )}

          {submitIdeaUrl && (
            <li>
              <CreateOrgSeparator isTop />
              <MenuLink
                url={submitIdeaUrl as string}
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.formatMessage({ id: "help.submit_idea" })}
              </MenuLink>
            </li>
          )}
        </ul>
      </MenuDropdownWrapper>
    </S.HelpLayout>
  );
};

export default HelpMenu;
