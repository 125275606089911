import { useState, useEffect, useCallback } from "react";

import localforage from "localforage";

/**
 * value: T
 *  The first element of the array is the stored value of the key.
 *  Since all the methods exposed by localForage are async, the initial value is
 *  always undefined until the storage has been succesfully read.
 *  If the key is not present on the store, that undefined will change to null.
 *  If there's a value already stored, that value will be returned.
 *  If there's an error, the value will remain as undefined.
 * setValue: (value: T) => void
 *  Persists the value.
 * error: Error
 * isLoading: True during the initial load
 */
const useLocalForage = <T>(key: string) => {
  const [state, setState] = useState<{
    isLoading: boolean;
    value?: T | null;
    error?: unknown;
  }>({
    isLoading: true
  });
  useEffect(() => {
    setState(state =>
      state.isLoading ? state : { ...state, isLoading: true }
    );
    localforage
      .getItem<T>(key)
      .then(value => {
        setState({
          value: typeof value === "undefined" ? null : value,
          error: undefined,
          isLoading: false
        });
      })
      .catch(error => {
        setState({ value: undefined, error, isLoading: false });
      });
  }, [key]);

  const setValue = useCallback(
    (value: T) => {
      localforage
        .setItem(key, value)
        .then(() => setState(state => ({ ...state, value, error: undefined })))
        .catch(error =>
          setState(state => ({ ...state, value: undefined, error }))
        );
    },
    [key]
  );

  return [state.value, setValue, state.error, state.isLoading] as const;
};

export default useLocalForage;
