import { createAsyncThunk } from "@reduxjs/toolkit";

import { AppDispatch } from "Store/configureStore";

import { getAllFeatureFlagsFromEnvironment } from "./environment";
import {
  persistFeatureFlagOverrideToLocalStorage,
  resetFeatureFlagOverridesOnLocalStorage
} from "./localStorage";
import { FeatureFlagName } from "./types";

export const setFeatureFlag = createAsyncThunk<
  { flagName: FeatureFlagName; flagValue: boolean },
  { flagName: FeatureFlagName; flagValue: boolean },
  { dispatch: AppDispatch }
>("featureFlags/setFeatureFlag", ({ flagName, flagValue }) => {
  persistFeatureFlagOverrideToLocalStorage(flagName, flagValue);
  return { flagName, flagValue };
});

export const resetFeatureFlags = createAsyncThunk<
  Record<FeatureFlagName, boolean>,
  undefined,
  { dispatch: AppDispatch }
>("featureFlags/resetFeatureFlags", () => {
  resetFeatureFlagOverridesOnLocalStorage();
  const allFeatureFlags = getAllFeatureFlagsFromEnvironment();
  return allFeatureFlags;
});
