export enum StepsList {
  code = "code",
  methods = "methods",
  phone = "phone"
}

export type ConfigProps = {
  [StepsList.code]: string;
  [StepsList.methods]: MethodsList;
  [StepsList.phone]: string;
  showErrorMessage: boolean;
};

export enum MethodsList {
  sms = "sms",
  call = "call"
}

export type StepPropType = {
  goToStep: (step: StepsList) => void;
  onCancel: () => void;
  handleChangeConfig: (
    key: keyof ConfigProps,
    value: ConfigProps[keyof ConfigProps]
  ) => void;
  config: ConfigProps;
};

export type HandleChangeConfigKeyType = Parameters<
  StepPropType["handleChangeConfig"]
>[0];

export type HandleChangeConfigValueType = Parameters<
  StepPropType["handleChangeConfig"]
>[1];
