import styled from "styled-components";

import { CloseButton } from "ds/Button";

export interface CloseProps {
  $isActive?: boolean;
}

export const Close = styled(CloseButton)<CloseProps>`
  margin-left: auto;
  pointer-events: ${({ $isActive }) => ($isActive ? "all" : "none")};
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0)};
  transition: opacity 0.25s;
  flex: 0 0 10px;

  svg {
    width: 14px !important;
    height: 14px !important;
  }
`;

export default Close;
