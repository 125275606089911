import { useCallback, useEffect, useRef } from "react";

import {
  createSelector,
  createSlice,
  PayloadAction,
  SerializedError,
  unwrapResult
} from "@reduxjs/toolkit";
import { castDraft } from "immer";
import { useSelector } from "react-redux";

import { PaginatedResource, resolveNextPage } from "Api/utils";
import { setDeep } from "Libs/objectAccess";
import { createAppAsyncThunk } from "Store/createAppAsyncThunk";
import { useAppDispatch } from "Store/hooks";

import { CommonErrorType } from "./types";

import type { RootState } from "Store/configureStore";

export const paginatedEndpointSliceFactory = <
  T,
  TransformedResource extends PaginatedResource<T>,
  Resource extends PaginatedResource<T>,
  Options extends object
>(
  name: string,
  fetchFirstPage: (options: Options) => Promise<Resource>,
  getKey: (options: Options) => string,
  transformResponse: (response: Resource) => Promise<TransformedResource>
) => {
  type PaginatedEndpointState = {
    data?: TransformedResource;
    isLoading: boolean;
    error?: SerializedError;
    isLoadingMore?: boolean;
  };

  type InitialState = Record<string, PaginatedEndpointState | undefined>;

  const fetchFirstPageThunk = createAppAsyncThunk(
    `${name}/fetchFirstPage`,
    (options: Options) => fetchFirstPage(options).then(transformResponse)
  );

  const fetchNextPageThunk = createAppAsyncThunk(
    `${name}/fetchNextPage`,
    (options: Options, { getState }) => {
      const state = getState();
      const next = state[name][getKey(options)]?.data?._links?.next?.href;

      if (!next) return;

      return resolveNextPage<Resource>(next).then(r =>
        r ? transformResponse(r) : undefined
      );
    }
  );

  const fetchAllPagesThunk = createAppAsyncThunk(
    `${name}/fetchAllPagesThunk`,
    async (options: Options) => {
      const response = await fetchFirstPage(options);
      if (response === undefined) return;

      const pages = [await transformResponse(response)];
      let next: Resource | undefined = response;

      while (next) {
        next = await resolveNextPage(next);
        if (next) pages.push(await transformResponse(next));
      }

      const items = pages.reduce<T[]>(
        (items, page) => items.concat(page.items),
        []
      );

      return {
        ...pages.pop()!,
        items
      };
    }
  );

  const initialState: InitialState = {};

  const slice = createSlice({
    name,
    initialState,
    reducers: {
      invalidateKey(state, action: PayloadAction<string>) {
        delete state[action.payload];
      }
    },
    extraReducers(builder) {
      builder.addCase(fetchAllPagesThunk.pending, (state, action) => {
        const key = getKey(action.meta.arg);
        setDeep(state, [key, "isLoading"], true);
        setDeep(state, [key, "error"], undefined);
      });
      builder.addCase(fetchAllPagesThunk.fulfilled, (state, action) => {
        const key = getKey(action.meta.arg);
        setDeep(state, [key, "isLoading"], false);
        state[key]!.data = castDraft(action.payload);
      });
      builder.addCase(fetchAllPagesThunk.rejected, (state, action) => {
        const key = getKey(action.meta.arg);
        setDeep(state, [key, "error"], action.error);
        setDeep(state, [key, "isLoading"], false);
      });

      builder.addCase(fetchNextPageThunk.pending, (state, action) => {
        const key = getKey(action.meta.arg);
        setDeep(state, [key, "isLoadingMore"], true);
        setDeep(state, [key, "error"], undefined);
      });
      builder.addCase(fetchNextPageThunk.fulfilled, (state, action) => {
        const key = getKey(action.meta.arg);
        setDeep(state, [key, "isLoadingMore"], false);
        if (action.payload) {
          state[key]!.data = castDraft({
            ...action.payload,
            items: [...(state[key]?.data?.items || []), ...action.payload.items]
          });
        }
      });
      builder.addCase(fetchFirstPageThunk.pending, (state, action) => {
        const key = getKey(action.meta.arg);
        setDeep(state, [key, "isLoading"], true);
        setDeep(state, [key, "error"], undefined);
      });
      builder.addCase(fetchFirstPageThunk.fulfilled, (state, action) => {
        const key = getKey(action.meta.arg);
        setDeep(state, [key, "isLoading"], false);
        state[key]!.data = castDraft(action.payload);
      });
      builder.addCase(fetchFirstPageThunk.rejected, (state, action) => {
        const key = getKey(action.meta.arg);
        setDeep(state, [key, "error"], action.error);
        setDeep(state, [key, "isLoading"], false);
      });
    }
  });

  const useFetchNextPage = () => {
    const dispatch = useAppDispatch();

    return useCallback(
      async (options: Options) =>
        unwrapResult(await dispatch(fetchNextPageThunk(options))),
      [dispatch]
    );
  };

  const useFetchPaginatedEndpoint = () => {
    const dispatch = useAppDispatch();

    return useCallback(
      async (options: Options) =>
        unwrapResult(await dispatch(fetchFirstPageThunk(options))),
      [dispatch]
    );
  };

  const useFetchAllPages = () => {
    const dispatch = useAppDispatch();

    return useCallback(
      async (options: Options) =>
        unwrapResult(await dispatch(fetchAllPagesThunk(options))),
      [dispatch]
    );
  };

  const useInvalidateKey = () => {
    const dispatch = useAppDispatch();

    return useCallback(
      (key: string) => dispatch(slice.actions.invalidateKey(key)),
      [dispatch]
    );
  };

  const stateSelector = (state: RootState) => state[name] as InitialState;
  //  @TODO
  //  Find a better way to track if the same request has been fired twice
  const pendingRequests: Array<string> = [];

  const useDataByKey = (fetchOptions: Options) => {
    const oldOptions = useRef(JSON.stringify(fetchOptions));
    const state = useSelector(
      (state: RootState) => stateSelector(state)[getKey(fetchOptions)]
    );
    const get = useFetchPaginatedEndpoint();
    const getNext = useFetchNextPage();

    const load = useCallback(() => get(fetchOptions), [get, fetchOptions]);

    const loadMore = useCallback(
      () =>
        getNext(fetchOptions)
          // We can have this dummy catch since the error will be returned
          // by the hook
          .catch(() => {}),
      [getNext, fetchOptions]
    );

    useEffect(() => {
      const newOptions = JSON.stringify(fetchOptions);
      const hasChanged = newOptions !== oldOptions.current;
      const hasOngoingRequest = pendingRequests.includes(newOptions);
      if ((state === undefined || hasChanged) && !hasOngoingRequest) {
        oldOptions.current = newOptions;
        pendingRequests.push(newOptions);
        load()
          .finally(() => {
            pendingRequests.splice(
              pendingRequests.indexOf(oldOptions.current),
              1
            );
          })
          // We can have this dummy catch since the error will be returned
          // by the hook
          .catch(() => {});
      }
    }, [load, state, fetchOptions]);

    const hasMore = !state
      ? undefined
      : !state.isLoading &&
        !state.isLoadingMore &&
        !!state.data?._links?.next?.href &&
        !state.error;

    return [
      // Not sure how to improve this, ts seems to not be able to resolve
      // the type here and replaces the type with unknown[]
      state?.data?.items as TransformedResource["items"] | undefined,
      state?.isLoading,
      state?.error,
      hasMore,
      state?.isLoadingMore,
      { load, loadMore }
    ] as const;
  };

  const pendingAllRequests: Array<string> = [];
  const useAllData = (fetchOptions: Options) => {
    const oldOptions = useRef(JSON.stringify(fetchOptions));
    const state = useSelector(
      (state: RootState) => (state[name] as InitialState)[getKey(fetchOptions)]
    );
    const get = useFetchAllPages();

    const load = useCallback(() => get(fetchOptions), [get, fetchOptions]);

    useEffect(() => {
      const newOptions = JSON.stringify(fetchOptions);
      const hasChanged = newOptions !== oldOptions.current;
      const hasOngoingRequest = pendingAllRequests.includes(newOptions);
      if (
        (state === undefined ||
          !!state.data?._links.next?.href ||
          hasChanged) &&
        !hasOngoingRequest
      ) {
        oldOptions.current = newOptions;
        pendingAllRequests.push(newOptions);
        load()
          .finally(() => {
            pendingAllRequests.splice(
              pendingAllRequests.indexOf(oldOptions.current),
              1
            );
          })
          // We can have this dummy catch since the error will be returned
          // by the hook
          .catch(() => {});
      }
    }, [fetchOptions, load, state]);

    return [
      state?.data?.items as TransformedResource["items"] | undefined,
      state?.isLoading,
      state?.error,
      { load }
    ] as const;
  };

  const selectAll = <Return>(fn: (state: InitialState) => Return) =>
    createSelector(stateSelector, fn);

  const selectByKey = <Return>(
    fn: (state: PaginatedEndpointState | undefined) => Return
  ) =>
    createSelector(
      stateSelector,
      (_: RootState, fetchOptions: Options) => getKey(fetchOptions),
      (state, key) => fn(state[key])
    );

  return {
    slice,
    hooks: {
      useFetchPaginatedEndpoint,
      useDataByKey,
      useAllData,
      useInvalidateKey
    },
    selectors: { selectAll, selectByKey }
  };
};

export const getCommonError = error => {
  let code;
  let message;

  if (typeof error !== "string") {
    //Git error format
    if (error?.code) code = error.code;
    if (error?.message) message = error.message;
    if (!message && error?.title) message = error.title;
    if (!message && error?.status) message = error.status;
    const detailErrors = Object.values(error?.detail || {});

    if (detailErrors.length && typeof error?.detail !== "string") {
      const getDetailedMessage = (detailMessages: string[]) =>
        detailMessages.reduce((a, b) => (a.length >= b.length ? a : b));
      //errors in details are preffered and more specific
      message = getDetailedMessage(detailErrors as string[]);
    }

    //Account error format
    //TODO to be implemented as is being encountered

    //Auth error format
    //TODO to be implemented as is being encountered
  }
  const err: CommonErrorType = { original: error, code, message };
  return { error: err };
};
