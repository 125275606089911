import { createSlice } from "@reduxjs/toolkit";

import { loadComments, sendComment, CommentsState, Comment } from "./";

export const defaultLoadFailureError =
  "An error has occurred while loading comments";
export const defaultSendFailureError =
  "An error has occurred while sending a comment";

const initialState: CommentsState = {
  comments: [],
  commentError: "",
  commentPage: 0,
  hasMore: false,
  isLoadingComment: false,
  isSendingComment: false
};

const commentsReducer = createSlice({
  name: "tickets/comments",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // LOAD COMMENTS
      .addCase(loadComments.pending, state => {
        state.isLoadingComment = true;
      })
      .addCase(loadComments.fulfilled, (state, action) => {
        if (action.payload) {
          const { comments, hasMore, commentPage, ticketId } = action.payload;

          const commentsFiltered = [
            ...state.comments,
            ...(comments as Comment[])
          ]
            .filter(
              (comment, i, arr) =>
                arr.findIndex(
                  item => item.comment_id === comment.comment_id
                ) === i && comment.ticket_id.toString() === ticketId
            )
            .sort((a, b) =>
              Date.parse(a.created_at) < Date.parse(b.created_at) ? 1 : -1
            );

          state.isLoadingComment = false;
          state.hasMore = hasMore;
          state.commentPage = commentPage;
          state.comments = commentsFiltered;
        }
      })
      .addCase(loadComments.rejected, (state, action) => {
        state.isLoadingComment = false;
        state.commentError = action.payload || defaultLoadFailureError;
      })
      // SENDING COMMENTS
      .addCase(sendComment.pending, state => {
        state.isSendingComment = true;
      })
      .addCase(sendComment.fulfilled, (state, action) => {
        state.isSendingComment = false;
        state.newComment = action.payload;
      })
      .addCase(sendComment.rejected, (state, action) => {
        state.isSendingComment = false;
        state.commentError = action.payload || defaultSendFailureError;
      });
  }
});

export default commentsReducer.reducer;
