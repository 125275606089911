import styled from "styled-components";

export const VendorLogoContainer = styled.div<{
  width?: number;
  height?: number;
  // width and hight could be any valid CSS dimension value
  // if it is a number either string quoted or Number literal, lets
  // treat as dimension without unit and use pixels otherwise
  // that isNaN(value) == true is an indicated that the dimension
  // is with a unit so lets use it as it is
}>`
  img {
    width: ${props =>
      props.width
        ? isNaN(props?.width)
          ? props.width
          : `${props.width}px`
        : "auto"};
    height: ${props =>
      props.height
        ? isNaN(props?.height)
          ? props.height
          : `${props.height}px`
        : "35px"};
  }
`;
