import { createAsyncThunk } from "@reduxjs/toolkit";

import logger from "Libs/logger";

import type { Account } from "platformsh-client";
import type { AsyncThunkOptionType } from "Reducers/types";

export const loadUser = createAsyncThunk<
  Account,
  { userId: string },
  AsyncThunkOptionType
>("app/users/load_user", async ({ userId }, { rejectWithValue }) => {
  try {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;
    return client.getAccount(userId);
  } catch (err) {
    logger(err, {
      action: "loadUser",
      userId
    });

    return rejectWithValue(err);
  }
});
