import * as Toast from "@radix-ui/react-toast";
import styled from "styled-components";

const Root = styled(Toast.Root)`
  background-color: var(--mode-bg-neutral-weakest);
  box-shadow: 0px 6px 24px var(--mode-shadow-default);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 16px;
  list-style: none;
  position: relative;
  display: flex;
  flex-direction: row;
  max-width: 400px;
  min-width: 400px;
`;

export default Root;
