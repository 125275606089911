import { createAsyncThunk } from "@reduxjs/toolkit";

import { ACTIVITY_TYPES } from "Constants/constants";
import logger from "Libs/logger";
import client from "Libs/platform";
import { isJson } from "Libs/utils";
import { loadEnvironments } from "Reducers/environment";
import { AsyncThunkOptionType } from "Reducers/types";
import { AppDispatch } from "Store/configureStore";

import { setActivityFilterNames, getActivityTypes } from "../activity.slice";
import { LoadEnvironmentActivities } from "../types";

import type { Activity } from "platformsh-client";

const checkDeletedEnvironments = (
  dispatch: AppDispatch,
  activities: Activity[],
  organizationId: string,
  projectId: string
) => {
  if (
    activities.some(
      activity => activity.type === ACTIVITY_TYPES.ENVIRONMENT.DELETE
    )
  ) {
    dispatch(
      loadEnvironments({
        organizationId,
        projectId
      })
    );
  }
};

export const loadEnvironmentActivities = createAsyncThunk<
  {
    activities: Activity[];
    hasMore: boolean;
    organizationDescriptionId: string;
    environmentDescriptionId: string;
    projectDescriptionId: string;
    environmentActivity: string[];
  },
  LoadEnvironmentActivities,
  AsyncThunkOptionType
>(
  "app/activity/environment/load",
  async (
    {
      projectDescriptionId,
      environmentDescriptionId,
      organizationDescriptionId,
      filterNames,
      context
    },
    { dispatch, rejectWithValue }
  ) => {
    dispatch(setActivityFilterNames({ context, filterNames }));
    const filters = getActivityTypes(filterNames);

    // Don't make any request when no filter is selected
    if (!filters.length) {
      return {
        activities: [],
        hasMore: false,
        organizationDescriptionId,
        environmentDescriptionId,
        projectDescriptionId,
        environmentActivity: []
      };
    }
    try {
      const encodedEnvId = encodeURIComponent(environmentDescriptionId);
      const activities = await client.getEnvironmentActivities(
        projectDescriptionId,
        encodedEnvId,
        filters
      );
      let hasMore = false;
      if (activities.length > 9) {
        hasMore = true;
        activities.pop();
      }

      checkDeletedEnvironments(
        dispatch,
        activities,
        organizationDescriptionId,
        projectDescriptionId
      );

      const environmentActivity = activities.map(activity => activity.id);

      return {
        activities,
        hasMore,
        organizationDescriptionId,
        environmentDescriptionId,
        projectDescriptionId,
        environmentActivity
      };
    } catch (err: any) {
      if (![404, 403].includes(err.code)) {
        const errorMessage = isJson(err)
          ? err
          : "An error occurred while attempting to load environment.";
        logger(errorMessage, {
          action: "environment_load_activities",
          meta: {
            organizationDescriptionId,
            environmentDescriptionId,
            projectDescriptionId
          }
        });
      }

      return rejectWithValue(err);
    }
  }
);
