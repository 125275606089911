export default (x, y, width, height /*, color*/) =>
  `<?xml version="1.0" encoding="iso-8859-1"?> <svg x="${x}px" y="${y}px" width="${width}px" height="${height}px" viewBox="0 0 28 19" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M27.3856 0.000489168C26.9529 0.0143163 27.0897 0.139003 26.1547 0.369109C25.2105 0.601474 24.0571 0.530222 23.0403 0.956649C20.005 2.22958 19.396 6.58029 16.6367 8.1388C14.5742 9.30382 12.4932 9.39674 10.6224 9.98299C9.39289 10.3685 8.04794 11.1592 6.93398 12.1191C6.06932 12.8645 6.04675 13.5199 5.14331 14.4548C4.17697 15.4548 1.30267 14.4717 0 16.0022C0.41964 16.4265 0.603613 16.5453 1.43059 16.4352C1.25938 16.7598 0.250101 17.0332 0.447668 17.5106C0.655551 18.0129 3.09555 18.3534 5.31362 17.0143C6.34661 16.3906 7.16938 15.4917 8.77818 15.2772C10.86 14.9997 13.2583 15.4551 15.6684 15.8027C15.311 16.8682 14.5935 17.5768 14.0188 18.4254C13.8408 18.6171 14.3763 18.6386 14.9871 18.5227C16.086 18.251 16.8779 18.0323 17.7072 17.5496C18.726 16.9565 18.8804 15.4359 20.1305 15.1068C20.8269 16.1774 22.7212 16.4302 23.8967 15.574C22.8653 15.282 22.5802 13.0866 22.9284 12.1191C23.2582 11.2034 23.584 9.73857 23.9162 8.52807C24.2727 7.22805 24.4043 5.58948 24.8358 4.92727C25.4851 3.93096 26.2025 3.58882 26.8253 3.02701C27.4482 2.4652 28.0183 1.91832 27.9996 0.632778C27.9935 0.218713 27.7794 -0.0120969 27.3856 0.000489168V0.000489168Z" fill="#002B64"/>
  <path d="M0.89593 17.419C2.47546 17.6453 3.43603 17.419 4.70445 16.8689C5.78368 16.4009 6.82601 15.4359 8.1005 15.0269C9.97229 14.4263 12.0249 15.0276 14.0258 15.1475C14.513 15.1768 14.9971 15.1771 15.475 15.1251C16.2203 14.667 16.2049 12.9534 16.9304 12.7965C16.909 15.2006 15.9234 16.6411 14.8926 18.036C17.0651 17.6523 18.3651 16.3957 19.2431 14.7175C19.5096 14.2086 19.7371 13.6611 19.9382 13.0873C20.2492 13.326 20.0729 14.0523 20.2293 14.4458C21.7256 13.6122 22.5825 11.7099 23.1499 9.78596C23.8064 7.5593 24.0752 5.30461 24.4985 4.6458C24.9118 4.00272 25.5549 3.60628 26.1419 3.19458C26.809 2.72651 27.4039 2.23867 27.5067 1.34676C26.8032 1.28167 26.6405 1.11896 26.5365 0.764404C26.1843 0.962935 25.8604 1.00544 25.4945 1.01634C25.177 1.026 24.8282 1.01183 24.4021 1.05558C20.8789 1.41745 20.4312 5.30072 18.1729 7.50215C18.0087 7.66222 17.831 7.81155 17.6432 7.95169C16.8524 8.54105 15.8821 8.96225 14.9899 9.30326C13.5457 9.85509 12.1726 9.89433 10.8176 10.3707C9.82297 10.7202 8.81225 11.2274 7.99524 11.7865C7.79095 11.9262 7.59849 12.0693 7.42145 12.2141C6.94201 12.6063 6.62732 13.0416 6.32291 13.4892C6.009 13.9506 5.70583 14.4252 5.24368 14.8786C4.49502 15.6137 1.69753 15.093 0.712659 15.7747C0.602883 15.8506 0.515686 15.9417 0.45636 16.051C0.993717 16.2951 1.35263 16.1453 1.9708 16.2137C2.05192 16.8003 0.695375 17.1489 0.89593 17.419V17.419Z" fill="#C49A6C"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M22.2414 13.1931C22.2834 13.866 22.6741 15.2013 23.019 15.5258C22.3435 15.69 21.1799 15.4186 20.8814 14.9421C21.0348 14.2542 21.833 13.6253 22.2414 13.1931V13.1931Z" fill="#C49A6C"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M23.2309 2.51259C23.7296 2.94562 24.7762 2.59808 24.5892 1.73653C23.8142 1.67238 23.3653 1.93537 23.2309 2.51259Z" fill="#002B64"/>
  <path d="M26.7081 1.50576C26.5754 1.78401 26.3214 2.14277 26.3214 2.8511C26.3203 2.97271 26.2291 3.05601 26.2275 2.86854C26.2344 2.17625 26.4177 1.87697 26.6123 1.48365C26.7028 1.32249 26.7573 1.38897 26.7081 1.50576Z" fill="#002B64"/>
  <path d="M26.5744 1.40096C26.418 1.66645 26.0411 2.15071 25.9788 2.85639C25.9673 2.97738 25.8689 3.05228 25.8839 2.86527C25.9518 2.17641 26.2529 1.74524 26.4815 1.37045C26.5852 1.21801 26.6339 1.28901 26.5744 1.40096Z" fill="#002B64"/>
  <path d="M26.4527 1.26205C26.2744 1.51337 25.6944 2.09495 25.5731 2.793C25.5513 2.91243 25.447 2.97908 25.4776 2.79394C25.6031 2.11301 26.1031 1.57784 26.3624 1.22391C26.4786 1.0805 26.5211 1.15539 26.4527 1.26205V1.26205Z" fill="#002B64"/>
  <path d="M26.344 1.10714C26.1322 1.3309 25.4407 2.07255 25.2227 2.74646C25.1841 2.86169 25.0715 2.91307 25.1281 2.734C25.3479 2.07753 25.9535 1.37045 26.2599 1.05654C26.3952 0.93088 26.4267 1.01107 26.344 1.10714Z" fill="#002B64"/>
  </svg>`;
