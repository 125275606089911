import { createAsyncThunk } from "@reduxjs/toolkit";

import { getIntl } from "Libs/intl";

import type { RootState, AppDispatch } from "./configureStore";

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: { error: string };
  extra: { getIntl: typeof getIntl };
}>();
