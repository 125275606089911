import { createSlice, SerializedError } from "@reduxjs/toolkit";

import { Team } from "Api/teams/teams.types";
import { setDeep } from "Libs/objectAccess";

import { getTeamThunk } from "./thunks/getTeam.thunk";

export type TeamState = {
  data?: Team;
  isLoading: boolean;
  error?: SerializedError;
};

export type TeamSliceState = {
  data: Record<string, TeamState | undefined>;
};

export const initialState: TeamSliceState = {
  data: {}
};

const slice = createSlice({
  name: "team",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getTeamThunk.pending, (state, action) => {
        setDeep(state, ["data", action.meta.arg, "isLoading"], true);
        setDeep(state, ["data", action.meta.arg, "error"], undefined);
      })
      .addCase(getTeamThunk.fulfilled, (state, action) => {
        setDeep(state, ["data", action.meta.arg, "isLoading"], false);
        setDeep(state, ["data", action.meta.arg, "data"], action.payload);
      })
      .addCase(getTeamThunk.rejected, (state, action) => {
        setDeep(state, ["data", action.meta.arg, "isLoading"], false);
        setDeep(state, ["data", action.meta.arg, "error"], action.error);
      });
  }
});

export const teamReducer = slice.reducer;
