import React from "react";

import { Routes, Route } from "react-router-dom";

import { selectFeatureCreateOrganizationEnabled } from "Reducers/featureFlags/featureFlags.selectors";
import BillingRedirect from "src/billing/BillingRedirect";
import SupportRedirect from "src/tickets/common/components/supportRedirect";
import { useAppSelector } from "Store/hooks";

import AddPluginPage from "./pluginApps/addPlugin/Page";
import PluginRoutes from "./pluginApps/Routes";
import { useTrackPreviousRoutes } from "./useTrackPreviousRoutes";

const InvitationWaitingPage = React.lazy(
  () =>
    import(
      "../../organization/pages/organizationProjects/containers/InvitationWaiting"
    )
);
const LogoutPage = React.lazy(() => import("./logout/Page"));
const PostLogoutPage = React.lazy(() => import("./postLogout/Page"));
const OrganizationsRoutes = React.lazy(
  () => import("../../organization/pages/Routes")
);
const ProjectsRoutes = React.lazy(
  () => import("../../organization/pages/projects/Routes")
);
const NewOrganizationRoute = React.lazy(
  () => import("../../organization/pages/createOrganization/Routes")
);
const Page = React.lazy(() => import("./Page"));
const UsersRoutes = React.lazy(() => import("./users/Routes"));

const RootRoutes = () => {
  const isCreateOrganizationEnabled = useAppSelector(
    selectFeatureCreateOrganizationEnabled
  );
  useTrackPreviousRoutes();

  return (
    <Page>
      <Routes>
        {isCreateOrganizationEnabled && (
          <Route path="organization/*" element={<NewOrganizationRoute />} />
        )}
        <Route path="support" element={<SupportRedirect />} />
        <Route path="-/users/*" element={<UsersRoutes />} />
        <Route path="-/app/:appId" element={<PluginRoutes />} />
        <Route path="-/add-plugin" element={<AddPluginPage />} />
        <Route path="-/logout" element={<LogoutPage />} />
        <Route path="-/post-logout" element={<PostLogoutPage />} />
        <Route path="-/billing" element={<BillingRedirect />} />
        <Route
          path="-/organizations/:id/wait"
          element={<InvitationWaitingPage />}
        />
        <Route path="billing" element={<BillingRedirect />} />
        <Route path=":organizationId/*" element={<OrganizationsRoutes />} />
        <Route path="" element={<ProjectsRoutes />} />
      </Routes>
    </Page>
  );
};

export default RootRoutes;
