import { createAsyncThunk } from "@reduxjs/toolkit";

import { adminAgentRequest } from "Api/adminAgent/adminAgent.request";
import logger from "Libs/logger";

export type GetServiceProcessesThunkArguments = {
  adminAgentUrl: string;
};

export const getServiceProcessesThunk = createAsyncThunk(
  "service/processes",
  (
    { adminAgentUrl }: GetServiceProcessesThunkArguments,
    { rejectWithValue }
  ) => {
    try {
      return adminAgentRequest({
        adminAgentUrl: `${adminAgentUrl}/procs`
      });
    } catch (error) {
      logger(error, { action: "get_service_processes" });

      return rejectWithValue(error);
    }
  }
);
