import { createSlice } from "@reduxjs/toolkit";

import { setDeep } from "Libs/objectAccess";

import {
  ProjectSettingsState,
  loadProjectSetting,
  updateBuildResources,
  updateBackupSchedule
} from ".";

const initialState: ProjectSettingsState = {
  data: {},
  loading: false
};

const projectSettingsReducer = createSlice({
  name: "app/project_settings/index",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // LOAD SETTINGS
      .addCase(loadProjectSetting.pending, state => {
        state.loading = true;
        delete state.error;
      })
      .addCase(loadProjectSetting.fulfilled, (state, { meta, payload }) => {
        const { projectId } = meta.arg;
        state.loading = false;
        setDeep(state, ["data", projectId], payload);
      })
      .addCase(loadProjectSetting.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // UPDATE BUILD RESOURCES
      .addCase(updateBuildResources.pending, state => {
        state.loading = true;
        delete state.error;
      })
      .addCase(updateBuildResources.fulfilled, (state, { meta, payload }) => {
        const { projectId } = meta.arg;
        state.loading = false;
        setDeep(state, ["data", projectId], payload);
      })
      .addCase(updateBuildResources.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // UPDATE CUSTOM BACKUP SCHEDULE
      .addCase(updateBackupSchedule.pending, state => {
        state.loading = true;
        delete state.error;
      })
      .addCase(updateBackupSchedule.fulfilled, (state, { meta, payload }) => {
        const { projectId } = meta.arg;
        state.loading = false;
        setDeep(state, ["data", projectId], payload);
      })
      .addCase(updateBackupSchedule.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default projectSettingsReducer.reducer;
