import { createSelector, createSlice } from "@reduxjs/toolkit";

import { getOrganizationId } from "Libs/utils";
import { RootState } from "Store/configureStore";
import { createAppAsyncThunk } from "Store/createAppAsyncThunk";

import type Client from "platformsh-client";

export const loadDunning = createAppAsyncThunk(
  "dunning/load",
  async (
    { organizationId }: { organizationId: string },
    { rejectWithValue }
  ) => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;

    try {
      const data = await client.getDunningActions(organizationId);

      return data.items.sort((a, b) => a.days_until - b.days_until);
    } catch (error) {
      rejectWithValue(error as { error: string });
    }
  }
);

type DunningState = {
  data: Record<
    string,
    Awaited<ReturnType<Client["getDunningActions"]>>["items"] | undefined
  >;
};

const initialState: DunningState = {
  data: {}
};

const dunning = createSlice({
  name: "dunning",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadDunning.fulfilled, (state, { payload, meta }) => {
      state.data[meta.arg.organizationId] = payload;
    });
  }
});

export default dunning.reducer;

export const dunningSelector = (state: RootState) => state.organizationDunning;

export const organizationDunningEventsSelector = createSelector(
  dunningSelector,
  (
    state: RootState,
    arg: { organizationId?: string } | { organizationName: string }
  ) =>
    "organizationName" in arg
      ? getOrganizationId(state, arg.organizationName)
      : arg.organizationId,
  (dunning, organizationId) =>
    organizationId ? dunning.data[organizationId] : undefined
);

export const currentDunningEventSelector = createSelector(
  organizationDunningEventsSelector,
  (
    _: RootState,
    { status }: { status?: "active" | "restricted" | "suspended" | "deleted" }
  ) => status,
  (events, status) => {
    if (status === "active") {
      return (
        events?.find(event => event.type === "suspend") ??
        events?.find(event => event.type === "revoke")
      );
    }

    return events?.find(event => {
      const type =
        status === "restricted"
          ? "suspend"
          : status === "suspended"
            ? "revoke"
            : undefined;
      return event.type === type;
    });
  }
);

export const organizationHasDunningEventsSelector = createSelector(
  organizationDunningEventsSelector,
  data => (data ?? []).length > 0
);
