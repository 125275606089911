import styled from "styled-components";

import Button, { ButtonWrapper } from "ds/Button";
import Info from "ds/Icons/Info";
import Tag from "ds/Tag";

export const FeatureFlags = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const TitleActiveTag = styled(Tag).attrs({ variant: "yellow" })`
  margin-left: 20px;
  vertical-align: text-bottom;
`;
export const MenuActiveTag = styled(Tag).attrs({ variant: "yellow" })`
  margin: 4px 16px 4px 8px;
`;

export const AccordionWrapper = styled.div`
  margin: 8px 0 16px;
`;

export const FormWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: flex-start;
  margin-bottom: 8px;
`;

export const CheckboxWrapper = styled.div`
  flex: 0 0 264px;
  &:not(:last-child) {
    margin-right: 16px;
  }
`;

export const SelectWrapper = styled.div`
  flex: 0 0 264px;
  &:not(:last-child) {
    margin-right: 16px;
  }

  .react-datepicker__day--today:not(.react-datepicker__day--in-range):not(
      .react-datepicker__day--in-selecting-range
    ) {
    border-radius: 2px;
    background-color: transparent;
    color: var(--section-ebony-light-color, var(--ebony-light));
  }
`;

export const Divider = styled.hr`
  background-color: var(--divider-stone-background-color, var(--stone));
  margin: 16px 0 8px;
  border: none;
`;

export const InfoIcon = styled(Info).attrs({ width: "20px", height: "20px" })`
  margin-right: 10px;
  vertical-align: text-bottom;
  color: var(--icon-granite-color, var(--granite));
`;

export const TestingButton = styled(Button)``;

export const ClearButton = styled(Button).attrs({ variant: "link" })`
  margin-right: 16px;
`;

export const TestModalButtonWrapper = styled(ButtonWrapper)`
  margin-top: 40px;
  ${TestingButton}:first-child {
    margin-right: auto;
  }
`;
