import React, { ReactNode } from "react";

import * as S from "./styles";

type MenuDropdownWrapperProps = {
  isOpen?: boolean;
  width?: string;
  onKeyUp?: (e: React.KeyboardEvent) => void;
  children?: ReactNode;
  className?: string;
};
const MenuDropdownWrapper = ({
  isOpen,
  width,
  onKeyUp,
  children,
  className
}: MenuDropdownWrapperProps) => (
  <S.Layout
    isOpen={isOpen}
    className={className}
    width={width}
    onKeyUp={onKeyUp}
  >
    {children}
  </S.Layout>
);

export default MenuDropdownWrapper;
