import styled from "styled-components";

import { AddButton } from "ds/Button";

export const Container = styled.div`
  display: flex;
  align-items: center;
  color: var(--customTab-tab-color);
  width: 40px;

  cursor: pointer;
`;

export const Separator = styled.hr`
  background: var(--section-periwinkle-grey-background, var(--periwinkle-grey));
  overflow: hidden;
  width: 100%;
  margin-bottom: 20px;
  margin-right: 16px;
`;

export const SelectOrganizationText = styled.p`
  color: var(--mode-text-neutral-default);
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
`;

export const LinksUl = styled.ul`
  padding-left: 0cqb;
`;

export const OrganizationContainer = styled.div`
  display: flex;
  height: 323px;
  margin-top: 20px;
  border-radius: 8px;
`;

export const HelpContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 24px;
  padding-right: 8px;
  width: 100%;
`;

export const UserSettingsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 8px;
`;

export const CreateProjectContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
`;

export const CustomAddButton = styled(AddButton)`
  width: 100%;
`;

export const SelectOrganizationsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  padding-right: 8px;
  width: 100%;
`;
