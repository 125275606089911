import React, { useContext } from "react";

import { PopoverContentProps } from "@radix-ui/react-popover";

import * as S from "./Content.styles";
import { DialogContext } from "./dialog.context";

const Content = ({
  align = "start",
  side = "bottom",
  ...props
}: PopoverContentProps & { align?: "start" | "center" | "end" } & {
  side?: PopoverContentProps["side"];
}) => {
  const dialogContext = useContext(DialogContext);

  return (
    <S.Content
      onMouseEnter={() => dialogContext.setIsOpen(true)}
      onMouseLeave={() => dialogContext.setIsOpen(false)}
      align={align}
      side={side}
      {...props}
    />
  );
};

export default Content;
