import { createSlice } from "@reduxjs/toolkit";

import { isJson } from "Libs/utils";

import { addInvitationThunk } from "./thunks/addInvitation.thunk";
import { deleteInvitationThunk } from "./thunks/deleteInvitation.thunk";
import { fetchInvitationsThunk } from "./thunks/fetchInvitations.thunk";
import { resendInvitationThunk } from "./thunks/resendInvitation.thunk";

import type { Invitation } from "platformsh-client";

export type InvitationsState = {
  data: Array<Invitation>;
  loading: boolean;
  errors?: unknown;
  status:
    | "ilde"
    | "rejected"
    | "resent"
    | "added"
    | "pending"
    | "delete.pending"
    | "deleted";
};

const initialState: InvitationsState = {
  data: [],
  loading: false,
  errors: undefined,
  status: "ilde"
};

const invitations = createSlice({
  name: "invitations",
  initialState,
  reducers: {
    clearInvitationErrors: state => {
      delete state.errors;
    }
  },
  extraReducers: builder => {
    // GET LIST
    builder
      .addCase(fetchInvitationsThunk.pending, state => {
        state.loading = true;
        delete state.errors;
      })
      .addCase(fetchInvitationsThunk.fulfilled, (state, action) => {
        const invitationsWithoutDuplicates = action.payload.filter(
          invitation =>
            !state.data.find(
              storedInvitation => storedInvitation.id === invitation.id
            )
        );
        state.data = state.data.concat(invitationsWithoutDuplicates);
        state.loading = false;
        state.status = "ilde";
      })
      .addCase(fetchInvitationsThunk.rejected, (state, action) => {
        let message = action.error.message;
        if (action.error.message && isJson(action.error.message)) {
          const errors = JSON.parse(action.error.message);
          if (errors?.detail?.errors?.length) message = errors.detail.errors[0];
        }

        state.errors = message;
        state.loading = false;
      });

    // ADD
    builder
      .addCase(addInvitationThunk.pending, state => {
        state.status = "pending";
        delete state.errors;
      })
      .addCase(addInvitationThunk.fulfilled, (state, action) => {
        state.data.push(action.payload);
        state.status = "resent";
      })
      .addCase(addInvitationThunk.rejected, (state, action) => {
        // manage case when invitation already exists
        state.status =
          !action.payload?.errors && action.payload?.resendInvitation
            ? "resent"
            : "rejected";
      });

    builder
      .addCase(resendInvitationThunk.pending, state => {
        state.status = "pending";
        state.errors = undefined;
      })
      .addCase(resendInvitationThunk.fulfilled, state => {
        state.status = "resent";
      })
      .addCase(resendInvitationThunk.rejected, (state, action) => {
        state.errors = action.payload;
      });

    // Delete
    builder
      .addCase(deleteInvitationThunk.pending, state => {
        state.status = "delete.pending";
        state.errors = undefined;
      })
      .addCase(deleteInvitationThunk.fulfilled, (state, action) => {
        state.data = state.data.filter(
          invitation => invitation.id !== action.payload.id
        );
      })
      .addCase(deleteInvitationThunk.rejected, (state, action) => {
        state.errors = JSON.parse(action.error.message || "").message;
        state.status = "rejected";
      });
  }
});

export const { clearInvitationErrors } = invitations.actions;
export default invitations.reducer;
