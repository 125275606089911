import styled from "styled-components";

export const ContainerLarge = styled.section`
  gap: 16px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  background: var(--mode-gradient-sellable-bg);
  padding: 40px 24px;
  .header {
    color: var(
      --sellable-banner-text-color,
      var(--text-stone-color, var(--stone))
    );
  }
  .description {
    color: var(
      --sellable-banner-text-color,
      var(--text-neutral-weak-color, var(--neutral-weak))
    );
  }
`;

export const ContainerMedium = styled(ContainerLarge)`
  padding: 40px 32px;
  @media screen and (max-width: 414px) {
    padding: 48px 24px 32px 24px;
  }
`;

export const ContainerSmall = styled(ContainerLarge)`
  padding: 22px;
  padding-right: 60px;
  @media screen and (max-width: 414px) {
    padding: 40px 24px 24px;
  }
`;

export const CloseWrapper = styled.div<{ small?: boolean }>`
  right: 16px;
  position: absolute;
  top: ${({ small }) => (small ? "50%" : "16px")};
  transform: ${({ small }) => (small ? "translateY(-50%)" : "none")};

  @media screen and (max-width: 414px) {
    position: absolute;
    top: ${({ small }) => (small ? "24px" : "16px")};
  }
`;

export const Content = styled.div<{ small?: boolean }>`
  display: flex;
  gap: 32px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: ${({ small }) => (small ? "row" : "column")};
`;
