import { NotificationId, Notification } from "./Notifications.types";

// In ms duration of the add/remove animation
export const ANIMATION_DURATION = 250;
// In ms max duration of the successful notifications. Adds the animation
// duration so we only count the amount of time is 100% visible.
export const SUCCESS_TIMEOUT = 7000 + ANIMATION_DURATION * 2;

export const isNotificationId = (payload: unknown): payload is NotificationId =>
  typeof payload === "string";

export const isNotification = (payload: unknown): payload is Notification =>
  !!payload && typeof payload === "object" && "id" in payload;

export const makeNotification = (
  notification: Partial<Notification>
): Notification => ({
  id: `notification-${Date.now()}`,
  flaggedForRemoval: false,
  priority: "information",
  ...notification
});
