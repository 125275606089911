import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "Store/configureStore";

const SLICE_NAME = "settingsMenu";

type SettingsMenuState = {
  projectPanelExpanded: boolean;
  environmentsPanelExpanded: boolean;
  environmentId: null | string;
};

const initialState: SettingsMenuState = {
  projectPanelExpanded: false,
  environmentsPanelExpanded: false,
  environmentId: null
};

export const settingsMenuSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    toggleProjectPanel: state => {
      state.projectPanelExpanded = !state.projectPanelExpanded;
    },
    toggleEnvironmentsPanel: state => {
      state.environmentsPanelExpanded = !state.environmentsPanelExpanded;
    },
    setEnvironmentsContext: (
      state,
      action: PayloadAction<SettingsMenuState["environmentId"]>
    ) => {
      state.environmentId = action.payload;
      state.environmentsPanelExpanded = !state.environmentsPanelExpanded;
      state.projectPanelExpanded = false;
    },
    setProjectContext: state => {
      state.environmentId = null;
      state.environmentsPanelExpanded = false;
      state.projectPanelExpanded = !state.projectPanelExpanded;
    }
  }
});

export const {
  toggleProjectPanel,
  toggleEnvironmentsPanel,
  setProjectContext,
  setEnvironmentsContext
} = settingsMenuSlice.actions;

export const selectProjectPanelExpanded = (state: RootState) =>
  state.settingsMenu.projectPanelExpanded;
export const selectEnvironmentsPanelExpanded = (state: RootState) =>
  state.settingsMenu.environmentsPanelExpanded;
export const selectEnvironmentId = (state: RootState) =>
  state.settingsMenu.environmentId;

export default settingsMenuSlice.reducer;
