import React, { ReactNode } from "react";

import * as S from "./styles";

type ListGroupProps = {
  children?: ReactNode;
  id?: string;
  className?: string;
};
const ListGroup = ({ children, id, className }: ListGroupProps) => (
  <S.Layout id={id} className={className}>
    {children}
  </S.Layout>
);

export default ListGroup;
