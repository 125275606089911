import styled from "styled-components";

export interface ListProps {
  $overflowDirection: "horizontal" | "vertical";
}

// Fix for overflow: hidden + box-shadows
const padding = 8;
const vHeight = 90 + padding * 2;
const hHeight = 24 + padding * 2;

export const List = styled.div<ListProps>`
  display: flex;
  width: 100%;
  gap: 8px;
  position: relative;
  flex-wrap: wrap;
  overflow: hidden;
  box-sizing: border-box;
  padding: ${padding}px;
  margin: -${padding}px;

  max-height: ${({ $overflowDirection }) =>
    $overflowDirection === "vertical" ? vHeight : hHeight}px;
`;

export default List;
