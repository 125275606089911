import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";

import { RootState } from "Store/configureStore";

type SearchState = {
  query: string;
};

const initialState: SearchState = {
  query: ""
};

const slice = createSlice({
  name: "search",
  initialState,
  reducers: {
    search: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
    }
  }
});

export const { search } = slice.actions;

export const searchQuerySelector = (state: RootState) => state.search;
export const selectSearchQuery = createSelector(
  searchQuerySelector,
  search => search.query || ""
);

export default slice.reducer;
