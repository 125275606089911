import { createAsyncThunk } from "@reduxjs/toolkit";

import { LoadOneProjectPayloadReturn } from "Reducers/project/project";

export const loadOneProjectHelper = createAsyncThunk(
  "app/project_reducer/load_one_project_helper",
  ({ project, projectMe, organizations }: LoadOneProjectPayloadReturn) => {
    return {
      project,
      projectMe,
      organizations
    };
  }
);
