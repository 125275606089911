import { Variants } from "ds/Button/Button.types";

export const createProjectInOrganizationTypes = {
  idle: "idle",
  notEnoughPermissions: "not-enough-permissions",
  createFirstOrganization: "create-first-organization",
  trialResourcesLimitReached: "trial-resources-limit-reached",
  billingDetails: "billing-details",
  restrictedOrg: "restricted-org",
  ticket: "ticket",
  phone: "phone",
  creditCard: "credit-card",
  generic: "generic",
  retry: "retry"
} as const;

export type CreateProjectInOrganizationTypes =
  (typeof createProjectInOrganizationTypes)[keyof typeof createProjectInOrganizationTypes];

export const createProjectTypes = {
  idle: "idle",
  veryHard: "very_hard",
  hard: "hard",
  soft: "soft"
} as const;

export type CreateProjectTypes =
  (typeof createProjectTypes)[keyof typeof createProjectTypes];

export type CreateProjectButtonProps = {
  isModalOpen?: boolean;
  organizationId?: string;
  setIsModalOpen?: (any: any) => any;
  openModal?: (any: any) => any;
  isLink?: boolean;
  linkStyle?: Record<string, string>;
  linkVariant?: Variants;
  message?: string;
  isOrgAdmin?: boolean;
  onOpenModal?: () => void;
  onCloseModal?: () => void;
  label?: string;
  onAuthorized?: () => void;
  disabled?: boolean;
};
