import React from "react";

import { useIntl } from "react-intl";

import { APP_NAME } from "Constants/documentationUrls";
import { Button, Link } from "ds/Button";
import { BodyText } from "ds/Typography";
import subscribeGraphic from "Vendor_resources/images/graphic_subscribe.svg";

import * as S from "./SubscribeCtaBox.styles";

type SubscribeCtaBoxProps = {
  bodyTextWidth?: string;
  organizationId: string;
  showIllustration?: boolean;
  disabledLearnMoreButton?: boolean;
  canSubscribe?: boolean;
};

export const SubscribeCtaBox = ({
  bodyTextWidth,
  organizationId,
  canSubscribe,
  showIllustration = false
}: SubscribeCtaBoxProps) => {
  const intl = useIntl();
  return (
    <S.SubscribeWrapper>
      {showIllustration && (
        <S.SubscribeImg
          src={subscribeGraphic}
          alt={intl.formatMessage(
            {
              id: "organization.billing.subscribe.title",
              defaultMessage: "Subscribe to app_name"
            },
            {
              app_name: APP_NAME
            }
          )}
        />
      )}
      <S.GradientTitle tag="h2">
        {intl.formatMessage(
          {
            id: "organization.billing.subscribe.title",
            defaultMessage: "Upgrade from trial"
          },
          {
            app_name: APP_NAME
          }
        )}
      </S.GradientTitle>
      <S.BodyTextDescription width={bodyTextWidth}>
        <S.DescriptionIconWrapper>
          <div>
            <BodyText size="small" weight="semiBold">
              {intl.formatMessage<React.ReactNode>(
                {
                  id: "organization.billing.subscribe.description",
                  defaultMessage:
                    "Save up to $19 every month on your first user license and first project fee with First Project Incentive.*"
                },
                {
                  Link: txt => (
                    <Link
                      href={intl.formatMessage({
                        id: "links.blog.first_project_incentive",
                        defaultMessage:
                          "https://upsun.com/blog/first-project-incentive/"
                      })}
                      analyticId="links.blog.first_project_incentive"
                    >
                      {txt}
                    </Link>
                  )
                }
              )}
            </BodyText>{" "}
            <S.TermsInfo weight="regular">
              {intl.formatMessage<React.ReactNode>(
                {
                  id: "organization.billing.subscribe.description.terms",
                  defaultMessage: "*Terms and conditions apply"
                },
                {
                  Link: txt => (
                    <Link
                      href={intl.formatMessage({
                        id: "links.faq.whats-the-upsun-first-project-incentive",
                        defaultMessage:
                          "https://upsun.com/faq/#whats-the-upsun-first-project-incentive"
                      })}
                      analyticId="links.faq.whats-the-upsun-first-project-incentive"
                    >
                      {txt}
                    </Link>
                  )
                }
              )}
            </S.TermsInfo>
          </div>
        </S.DescriptionIconWrapper>
      </S.BodyTextDescription>

      {canSubscribe ? (
        <S.SubscribeActions>
          <Button
            variant="primary"
            to={`/${organizationId}/-/subscribe`}
            analyticId="organization.billing.subscribe.btn.subscribe"
          >
            {intl.formatMessage({
              id: "organization.billing.subscribe.btn.upgrade",
              defaultMessage: "Upgrade"
            })}
          </Button>
        </S.SubscribeActions>
      ) : (
        <S.Disclaimer>
          {intl.formatMessage({
            id: "organization.billing.subscribe.btn.disclaimer",
            defaultMessage: "*Contact organization owner to subscribe"
          })}
        </S.Disclaimer>
      )}
    </S.SubscribeWrapper>
  );
};
