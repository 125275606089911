import { createAsyncThunk } from "@reduxjs/toolkit";

import { PROJECT_ID_FIELD } from "Constants/constants";
import logger from "Libs/logger";
import { AsyncThunkOptionType } from "Reducers/types";

import { AddCertificateReturnPayload, AddCertificateProps } from "../";

import type { Certificate } from "platformsh-client";

// To "mock" creating a certificate, use https://regery.com/en/security/ssl-tools/self-signed-certificate-generator to create a self-signed certificate

/**
 * Add one Certificate
 *
 * @param {string} organizationDescriptionId
 * @param {object} project
 * @param {object} certificate
 *
 */

export const addCertificate = createAsyncThunk<
  AddCertificateReturnPayload | undefined,
  AddCertificateProps,
  AsyncThunkOptionType
>(
  "app/projectCertificate/add_certificate",
  async (
    { organizationDescriptionId, project, certificate },
    { rejectWithValue }
  ) => {
    try {
      const result = await project.addCertificate(
        certificate.certificate,
        certificate.key,
        certificate.chain as never[]
      );

      if (Math.floor(result.data && result.data.code / 100) === 4) {
        return rejectWithValue({ error: result.detail });
      }

      const newCertificate: Certificate = await result.getEntity();

      return {
        certificate: newCertificate,
        projectDescriptionId: project[PROJECT_ID_FIELD]
      } as AddCertificateReturnPayload;
    } catch (err: unknown) {
      logger(err, {
        action: "addCertificate",
        organizationDescriptionId,
        project,
        certificate
      });

      return rejectWithValue(err);
    }
  },
  {
    condition: ({ project }: AddCertificateProps): boolean => {
      // If there is no project, stop "addCertificate"
      if (!project) return false;

      return true;
    }
  }
);
