import { useMemo } from "react";

let counter = 0;

/**
 * Generates an unique identifier on mount
 *
 * @returns {number} unique identifier
 */
const useUniqueId = () => useMemo(() => ++counter, []);

export default useUniqueId;
