import client from "Libs/platform";
import { loadUserProfile } from "Reducers/profile";

export const initialState = {
  setup: {
    data: null,
    error: null,
    isLoading: false
  },
  recoveryCodes: {
    data: null,
    error: null,
    isLoading: false
  }
};

export const getErrorMessage = error => {
  let message;
  if (typeof error === "string") {
    message = JSON.parse(error).error;
  } else if (typeof error === "object") {
    message = error.error || error.message || error;
  }
  return message;
};

const SETUP_START = "organization/settings/security/setup_start";
const SETUP_SUCCESS = "organization/settings/security/setup_success";
const SETUP_FAILURE = "organization/settings/security/setup_failure";

export const setupStart = () => ({ type: SETUP_START });
export const setupSucess = tfaInfo => ({
  type: SETUP_SUCCESS,
  payload: tfaInfo
});
export const setupFailure = error => ({ type: SETUP_FAILURE, payload: error });

export const setup = userId => async dispatch => {
  dispatch(setupStart());
  try {
    const tfaInfo = await client.getTFA(userId);
    dispatch(setupSucess(tfaInfo));
  } catch (error) {
    dispatch(setupFailure(getErrorMessage(error)));
  }
};

const ENROLL_START = "organization/settings/security/enroll_start";
const ENROLL_SUCESS = "organization/settings/security/enroll_success";
const ENROLL_FAILURE = "organization/settings/security/enroll_failure";

export const enrollStart = () => ({ type: ENROLL_START });
export const enrollSucess = recoveryCodes => ({
  type: ENROLL_SUCESS,
  payload: recoveryCodes
});
export const enrollfailure = error => ({
  type: ENROLL_FAILURE,
  payload: error
});

export const enroll =
  (userId: string, secret: string, passcode: string) => async dispatch => {
    dispatch(enrollStart());
    try {
      const { recovery_codes } = await client.enrollTFA(
        userId,
        secret,
        passcode
      );
      dispatch(enrollSucess(recovery_codes));
      return recovery_codes;
    } catch (error) {
      const message = enrollfailure(getErrorMessage(error));
      dispatch(message);
      return message;
    }
  };

const RESET_RECOVERY_CODES_START =
  "organization/settings/security/reset_recovery_codes_start";
const RESET_RECOVERY_CODES_SUCCESS =
  "organization/settings/security/reset_recovery_codes_success";
const RESET_RECOVERY_CODES_FAILURE =
  "organization/settings/security/reset_recovery_codes_failure";

export const resetRecoveryCodesStart = () => ({
  type: RESET_RECOVERY_CODES_START
});

export const resetRecoveryCodesSuccess = (recoveryCodes: string[]) => ({
  type: RESET_RECOVERY_CODES_SUCCESS,
  payload: recoveryCodes
});

export const resetRecoveryCodesFailure = error => ({
  type: RESET_RECOVERY_CODES_FAILURE,
  payload: error
});

export const resetRecoveryCodes = (userId: string) => async dispatch => {
  dispatch(resetRecoveryCodesStart());
  try {
    const { recovery_codes } = await client.resetRecoveryCodes(userId);
    dispatch(resetRecoveryCodesSuccess(recovery_codes));
  } catch (error: any) {
    dispatch(
      resetRecoveryCodesFailure(error?.error || error?.message || error)
    );
  }
};

const DISABLE_TFA_START = "organization/settings/security/disable_tfa_start";
const DISABLE_TFA_SUCCESS =
  "organization/settings/security/disable_tfa_success";
const DISABLE_TFA_FAILURE =
  "organization/settings/security/disable_tfa_failure";

export const disableTFAStart = () => ({ type: DISABLE_TFA_START });
export const disableTFASuccess = () => ({ type: DISABLE_TFA_SUCCESS });
export const disableTFAFailure = error => ({
  type: DISABLE_TFA_FAILURE,
  payload: error
});

export const disableTFA = (userId: string) => async dispatch => {
  dispatch(disableTFAStart());
  try {
    await client.disableTFA(userId);
    dispatch(disableTFASuccess());
    dispatch(loadUserProfile());
  } catch (error) {
    dispatch(disableTFAFailure(error));
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SETUP_START:
      return {
        ...state,
        setup: {
          ...state.setup,
          isLoading: true,
          error: null,
          data: action.payload
        }
      };
    case SETUP_SUCCESS:
      return {
        ...state,
        setup: {
          ...state.setup,
          data: action.payload,
          isLoading: false
        }
      };
    case SETUP_FAILURE:
      return {
        ...state,
        setup: {
          ...state.setup,
          error: action.payload,
          isLoading: false
        }
      };
    case ENROLL_FAILURE:
    case RESET_RECOVERY_CODES_FAILURE:
      return {
        ...state,
        recoveryCodes: {
          ...state.recoveryCodes,
          error: action.payload,
          isLoading: false
        }
      };
    case ENROLL_START:
    case RESET_RECOVERY_CODES_START:
      return {
        ...state,
        recoveryCodes: {
          ...state.recoveryCodes,
          isLoading: true,
          error: null
        }
      };
    case RESET_RECOVERY_CODES_SUCCESS:
    case ENROLL_SUCESS:
      return {
        ...state,
        recoveryCodes: {
          ...state.recoveryCodes,
          data: action.payload,
          isLoading: false
        }
      };
    case DISABLE_TFA_SUCCESS:
      return {
        ...state,
        recoveryCodes: {
          ...state.recoveryCodes,
          data: null
        },
        setup: {
          ...state.setup,
          data: null
        }
      };
    default:
      return state;
  }
};

export function selectRecoveryCodes(state) {
  return state.security.recoveryCodes.data;
}

export function selectIsLoadingRecoveryCodes(state) {
  return state.security.recoveryCodes.isLoading;
}

export function selectRecoveryCodesError(state) {
  return state.security.recoveryCodes.error;
}

export function selectSetupData(state) {
  return state.security.setup.data;
}

export function selectIsLoadingSetup(state) {
  return state.security.setup.isLoading;
}

export function selectSetupError(state) {
  return state.security.setup.error;
}

export default reducer;
