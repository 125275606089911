import { FeatureFlagName, FEATURE_FLAGS_LOCAL_STORAGE_KEY } from "./types";

export function persistFeatureFlagOverrideToLocalStorage(
  featureName: FeatureFlagName,
  value: boolean
) {
  const flags = getAllFeatureFlagOverridesFromLocalStorage();
  flags[featureName] = value;
  localStorage.setItem(FEATURE_FLAGS_LOCAL_STORAGE_KEY, JSON.stringify(flags));
}

function getAllFeatureFlagOverridesFromLocalStorage(): Record<string, boolean> {
  return JSON.parse(
    localStorage.getItem(FEATURE_FLAGS_LOCAL_STORAGE_KEY) || "{}"
  );
}

export function getFeatureFlagFromLocalStorage(
  featureName: FeatureFlagName
): boolean | undefined {
  const flags = JSON.parse(
    localStorage.getItem(FEATURE_FLAGS_LOCAL_STORAGE_KEY) || "{}"
  );
  return flags[featureName];
}

export function resetFeatureFlagOverridesOnLocalStorage() {
  localStorage.setItem(FEATURE_FLAGS_LOCAL_STORAGE_KEY, "{}");
}
