import styled from "styled-components";

import PrimitiveCheckbox from "ds/Checkbox";

export const Icon = styled.span`
  margin-left: 8px;
`;

export const PermissionDialog = styled.div`
  display: flex;
`;

export const Checkbox = styled(PrimitiveCheckbox)`
  margin-bottom: 0;
`;

export const PermissionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TooltipWrapper = styled.div`
  display: flex;
`;
