import React, { ReactNode } from "react";

import * as S from "ds/Modal";

export enum Size {
  custom = "custom",
  large = "large",
  medium = "medium",
  small = "small"
}

export interface ModalProps {
  children: ReactNode;
  size?: keyof typeof Size;
  width?: string | number;
  height?: string;
  hasOverlay?: boolean;
  isOpen: boolean;
  onOpenChange?: (open: boolean | Event) => void;
  handleClose?: () => void;
  id?: string;
  className?: string;
  autoFocus?: boolean;
}

const setSize = (size: string | undefined, width: string | number) => {
  switch (size) {
    case Size.custom:
      return width;
    case Size.large:
      return 900;
    case Size.medium:
      return 600;
    case Size.small:
    default:
      return 480;
  }
};

const Modal = ({
  children,
  isOpen,
  onOpenChange,
  hasOverlay = true,
  size,
  width = "95%",
  height,
  id,
  className,
  handleClose,
  autoFocus = false,
  ...props
}: ModalProps) => (
  <S.Root {...props} open={isOpen}>
    <S.Portal>
      {hasOverlay && <S.Overlay />}
      <S.Content
        width={setSize(size, width)}
        height={height}
        onOpenAutoFocus={e => (autoFocus ? true : e.preventDefault())}
        onPointerDownOutside={open => onOpenChange?.(open)}
        className={className}
        onKeyDown={e => {
          if (e.key === "Escape") {
            onOpenChange?.(false);
            handleClose?.();
          }
        }}
        id={id}
      >
        <S.Close asChild>
          <S.CloseButton
            onClick={open => {
              onOpenChange?.(open);
              handleClose?.();
            }}
          />
        </S.Close>
        {children}
      </S.Content>
    </S.Portal>
  </S.Root>
);

export default Modal;
