import { createAsyncThunk } from "@reduxjs/toolkit";

import { ErrorWithCode } from "Api/types/ErrorWithCode";
import logger from "Libs/logger";
import client from "Libs/platform";
import { getOrganizationId } from "Libs/utils";
import { AsyncThunkOptionType } from "Reducers/types";

import type { GetOrganizationOrdersPropType } from "../types";
import type { OrganizationOrder } from "platformsh-client";

export const getOrganizationRecurringOrderThunk = createAsyncThunk<
  OrganizationOrder | undefined,
  GetOrganizationOrdersPropType,
  AsyncThunkOptionType<{ error: ErrorWithCode }>
>(
  "organization/recurringOrder",
  async ({ organizationId }, { getState, rejectWithValue }) => {
    try {
      const orgId = getOrganizationId(getState, organizationId);
      if (!orgId) {
        return;
      }
      const recurringOrders = await client.getOrganizationOrders(orgId, {
        status: "recurring_open"
      });

      return recurringOrders[0];
    } catch (_error: unknown) {
      const error = _error as ErrorWithCode;

      if (![404, 403].includes(error.code)) {
        logger(error, {
          action: "organization_load_orders",
          orgId: organizationId
        });
      }

      return rejectWithValue({ error });
    }
  }
);
