import { createSlice } from "@reduxjs/toolkit";

import { setDeep } from "Libs/objectAccess";

import { BranchActionState, branch } from "./";

const initialState: BranchActionState = {
  loading: false,
  data: {},
  errors: undefined
};

const environmentBranchSlice = createSlice({
  name: "branch",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(branch.pending, state => {
        state.loading = true;
        state.errors = undefined;
      })
      .addCase(branch.fulfilled, (state, { meta, payload }) => {
        const { organizationId, projectId, environmentId } = meta.arg;

        state.loading = false;
        state.errors = undefined;
        setDeep(
          state,
          ["data", organizationId, projectId, environmentId],
          payload
        );
      })
      .addCase(branch.rejected, (state, { payload }) => {
        state.loading = false;
        state.errors = payload;
      });
  }
});

export default environmentBranchSlice.reducer;
