import { apiProxyRequest as request } from "Api/apiProxyRequest";
import {
  ListTeamMemberOptions,
  listTeamMembers
} from "Api/teams/members/listTeamMembers.request";
import { paginatedEndpointSliceFactory } from "Reducers/sliceFactory";

import type { RefUser } from "Api/ref/User.types";
import type { TeamMemberWithUserData } from "Api/teams/teams.types";

type Options = {
  teamId: string;
  options?: ListTeamMemberOptions;
};

const { slice, hooks, selectors } = paginatedEndpointSliceFactory(
  "teamMembers",
  (options: Options) => listTeamMembers(options.teamId, options.options),
  options => options.teamId,
  async response => {
    if (!response._links["ref:users:0"])
      return { items: [], _links: response._links };

    const users = await request<Record<string, RefUser>>(
      response._links["ref:users:0"].href
    );
    const mergedItems: Array<TeamMemberWithUserData> = response.items.map(
      member => {
        const user = users[member.user_id];
        return { ...member, ...user };
      }
    );

    return { items: mergedItems, _links: response._links };
  }
);

export const teamMembersReducer = slice.reducer;

export const {
  useDataByKey: useTeamMembers,
  useAllData: useAllTeamMembers,
  useInvalidateKey: useInvalidateTeamMembers
} = hooks;

export const teamMembersCountSelector = selectors.selectByKey(
  state => state?.data?.items.length || 0
);
