import { apiProxyRequest as request } from "Api/apiProxyRequest";
import { resolveIfHasLink } from "Api/utils";

import type {
  OrganizationMembers,
  OrganizationMembersSortOptions
} from "Api/organizations/member/Member.types";
import type { RefUser } from "Api/ref/User.types";

export interface GetMembersQueryParameters {
  sort?: OrganizationMembersSortOptions;
  page?: {
    size?: number;
    before?: string;
    after?: string;
  };
}

export const getMembers = (
  organizationId: string,
  queryParameters: GetMembersQueryParameters
) =>
  request<OrganizationMembers>(
    `/organizations/${organizationId}/members`,
    "GET",
    queryParameters
  );

export const resolveMembersLinks = (
  organizationMembers: OrganizationMembers
) => ({
  users: resolveIfHasLink(organizationMembers, "ref:users:0", () =>
    request<{ [userId: string]: RefUser }>(
      organizationMembers._links["ref:users:0"]!.href
    )
  ),
  next: resolveIfHasLink(organizationMembers, "next", () =>
    request(organizationMembers._links.next!.href)
  ),
  previous: resolveIfHasLink(organizationMembers, "previous", () =>
    request(organizationMembers._links.previous!.href)
  )
});
