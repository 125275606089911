import { isJson } from "Libs/utils";

const defaultParseError = (error: unknown) => {
  if (typeof error === "string" && isJson(error)) {
    const jsonError = JSON.parse(error);

    return "message" in jsonError ? jsonError.message : jsonError;
  }

  return error;
};

const identity = v => v;

const bulkOperation = async <PromiseReturn, FulfilledType, ErrorType = unknown>(
  promises: Array<Promise<PromiseReturn>>,
  parseFulfilled: (
    response: PromiseReturn,
    index: number
  ) => FulfilledType = identity,
  parseError: (error: unknown, index: number) => ErrorType = defaultParseError
) => {
  const settledPromises = await Promise.allSettled(promises);
  const fulfilled: Array<FulfilledType> = [];
  const rejected: Array<ErrorType> = [];

  for (let index = 0; index < settledPromises.length; index++) {
    const settledPromise = settledPromises[index];
    if (settledPromise.status === "fulfilled") {
      fulfilled.push(parseFulfilled(settledPromise.value, index));
    }

    if (
      settledPromise.status === "rejected" &&
      typeof settledPromise.status === "string"
    ) {
      rejected.push(parseError(settledPromise.reason, index));
    }
  }

  return { fulfilled, rejected };
};

export default bulkOperation;
