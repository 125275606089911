import styled from "styled-components";

import * as InputDs from "ds/Input";

export const InputRoot = styled(InputDs.Root)`
  margin-bottom: 24px;
`;

export const Field = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;
