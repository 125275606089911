import { createAsyncThunk } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import { environmentSelector } from "Reducers/environment";
import { AsyncThunkOptionType } from "Reducers/types";

import { PauseEnvironmentActionProp } from "../";

import type { Activity } from "platformsh-client";

export const pause = createAsyncThunk<
  Activity,
  PauseEnvironmentActionProp,
  AsyncThunkOptionType
>(
  "app/environment/actions/pause",
  async (
    { organizationId, projectId, environmentId },
    { getState, rejectWithValue }
  ) => {
    try {
      const environment = environmentSelector(getState(), {
        organizationId: organizationId,
        projectId: projectId,
        environmentId: environmentId
      });

      if (!environment) return rejectWithValue("Environment not found");

      const pause = await environment.pause();
      return pause;
    } catch (error) {
      if (![404, 403].includes((error as { code: number })?.code)) {
        logger(error, {
          action: "pause",
          meta: {
            organizationId,
            environmentId,
            projectId
          }
        });
      }
      return rejectWithValue(error);
    }
  }
);
