import { createSlice, SerializedError, PayloadAction } from "@reduxjs/toolkit";

import { ProcessSignalNumber } from "Api/adminAgent/types/ProcessSignal.enum";
import { ServiceProcess } from "Api/adminAgent/types/ServiceProcess.type";
import { Status } from "Reducers/types";

import { getServiceProcessesThunk } from "./thunks/getServiceProcesses.thunk";
import { sendServiceProcessesSignalThunk } from "./thunks/sendServiceProcessesSignal.thunk";

export type ServiceProcessesModalState = {
  isOpen: boolean;
  signal: ProcessSignalNumber;
  process?: ServiceProcess;
  status: Status;
  error?: SerializedError;
};

export type ServiceProcessesState = {
  data: ServiceProcess[];
  status: Status;
  error?: SerializedError;
  modal: ServiceProcessesModalState;
};

export type OpenProcessActionModalArgs = {
  signal: ProcessSignalNumber;
  process: ServiceProcess;
};

export const initialServiceProcessesState: ServiceProcessesState = {
  data: [],
  status: Status.Idle,
  error: undefined,
  modal: {
    signal: ProcessSignalNumber.SIGNULL,
    isOpen: false,
    status: Status.Idle,
    error: undefined
  }
};

const serviceProcessesSlice = createSlice({
  name: "serviceProcesses",
  initialState: initialServiceProcessesState,
  reducers: {
    openProcessActionModal: (
      state,
      action: PayloadAction<OpenProcessActionModalArgs>
    ) => ({
      ...state,
      modal: {
        isOpen: true,
        status: Status.Idle,
        ...action.payload
      }
    }),
    closeProcessActionModal: state => ({
      ...state,
      modal: { ...initialServiceProcessesState.modal }
    })
  },
  extraReducers: builder => {
    builder
      .addCase(getServiceProcessesThunk.pending, state => ({
        ...state,
        data: [],
        error: undefined,
        status: Status.Loading
      }))
      .addCase(getServiceProcessesThunk.fulfilled, (state, action) => ({
        ...state,
        status: Status.Fulfilled,
        data: action.payload
      }))
      .addCase(getServiceProcessesThunk.rejected, (state, action) => ({
        ...state,
        error: action.error,
        status: Status.Rejected
      }))
      .addCase(sendServiceProcessesSignalThunk.pending, state => ({
        ...state,
        modal: {
          ...state.modal,
          status: Status.Loading
        }
      }))
      .addCase(sendServiceProcessesSignalThunk.fulfilled, state => ({
        ...state,
        modal: {
          ...state.modal,
          status: Status.Fulfilled
        }
      }))
      .addCase(sendServiceProcessesSignalThunk.rejected, (state, action) => ({
        ...state,
        modal: {
          ...state.modal,
          status: Status.Rejected,
          error: action.error
        }
      }));
  }
});

export const serviceProcessesReducer = serviceProcessesSlice.reducer;

export const { openProcessActionModal, closeProcessActionModal } =
  serviceProcessesSlice.actions;
