import React from "react";

import * as S from "./Accordion.styles";

export type SectionHeaderProps = {
  children: React.ReactNode;
  onClear?: () => void;
  disabled?: boolean;
};

const SectionHeader = ({ children, onClear, disabled }: SectionHeaderProps) => {
  return (
    <S.Header>
      <S.Title>{children}</S.Title>
      <S.Divider />
      {onClear && (
        <S.ClearButton analyticId="clear" onClick={() => onClear()}>
          Clear
        </S.ClearButton>
      )}
      <S.Trigger>{!disabled && <S.Chevron />}</S.Trigger>
    </S.Header>
  );
};

export default SectionHeader;
