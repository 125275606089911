import { createAsyncThunk } from "@reduxjs/toolkit";

import { adminAgentRequest } from "Api/adminAgent/adminAgent.request";
import { ProcessSignalNumber } from "Api/adminAgent/types/ProcessSignal.enum";
import { ServiceProcess } from "Api/adminAgent/types/ServiceProcess.type";
import logger from "Libs/logger";

export type SendServiceProcessesSignalThunkArguments = {
  adminAgentUrl: string;
  process: ServiceProcess;
  signal: ProcessSignalNumber;
};

export const sendServiceProcessesSignalThunk = createAsyncThunk(
  "service/processes/signal",
  (
    {
      adminAgentUrl,
      process,
      signal
    }: SendServiceProcessesSignalThunkArguments,
    { rejectWithValue }
  ) => {
    try {
      return adminAgentRequest({
        method: "post",
        adminAgentUrl: `${adminAgentUrl}/procs/${process.pid}/signal`,
        body: {
          signo: signal
        }
      });
    } catch (error) {
      logger(error, { action: "send_service_processes_signal" });

      return rejectWithValue(error);
    }
  }
);
