import React from "react";

import UserIcon from "ds/Icons/User";

import * as S from "../styles";

type Props = {
  displayName: string;
  picture: string;
  email: string;
};

const UserInfo = ({ displayName, picture, email }: Props) => {
  const showDefaultImage = picture?.endsWith("user-default.png") || !picture;
  return (
    <S.UserInfoContainer>
      <S.UserInfoImageContainer>
        {showDefaultImage ? (
          <UserIcon color="var(--mode-vector-neutral-weakest)" />
        ) : (
          <img src={picture} alt={displayName} referrerPolicy="no-referrer" />
        )}
      </S.UserInfoImageContainer>

      <S.UserInfoTextContainer>
        <S.NameText>{displayName}</S.NameText>
        <S.EmailText>{email}</S.EmailText>
      </S.UserInfoTextContainer>
    </S.UserInfoContainer>
  );
};

export default UserInfo;
