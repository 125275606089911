import React from "react";

import { useIntl } from "react-intl";

import config from "console_config";
import { AddButton, Link } from "ds/Button";
import { Variants } from "ds/Button/Button.types";
import {
  selectFeatureCreateProjectEnabled,
  selectFeatureOrganizationsEnabled
} from "Reducers/featureFlags/featureFlags.selectors";
import { useAppSelector } from "Store/hooks";

import CreateProjectButton from "./Components/CreateProjectButton";
import CreateProjectInOrganizationButton from "./Components/CreateProjectInOrganizationButton";
import * as S from "./styles";

const NewProjectButton = ({
  organizationId,
  isLink = false,
  linkStyle = {},
  linkVariant = "primary",
  onCloseModal,
  onOpenModal,
  customLabel,
  onAuthorized,
  disabled = false
}: {
  organizationId?: string;
  isLink?: boolean;
  linkStyle?: Record<string, string>;
  linkVariant?: Variants;
  onCloseModal?: () => void;
  onOpenModal?: () => void;
  customLabel?: string;
  onAuthorized?: () => void;
  disabled?: boolean;
}) => {
  const intl = useIntl();
  const createProjectEnabled = useAppSelector(
    selectFeatureCreateProjectEnabled
  );
  const organizationEnabled = useAppSelector(selectFeatureOrganizationsEnabled);
  const label = customLabel || intl.formatMessage({ id: "create_project" });

  if (config.CUSTOM_URL_PROJECT_CREATION) {
    return (
      <div>
        {isLink ? (
          <Link
            id="create_project"
            variant={linkVariant}
            style={linkStyle}
            href={config.CUSTOM_URL_PROJECT_CREATION}
            target="_blank"
            analyticId="create_project"
            disabled={disabled}
          >
            {label}
          </Link>
        ) : (
          <AddButton
            analyticId="create_project"
            id="create_project"
            href={config.CUSTOM_URL_PROJECT_CREATION}
            target="_blank"
            disabled={disabled}
          >
            {label}
          </AddButton>
        )}
      </div>
    );
  } else if (!createProjectEnabled) {
    return <div></div>;
  } else if (organizationEnabled) {
    return (
      <S.Wrapper>
        <CreateProjectInOrganizationButton
          isLink={isLink}
          disabled={disabled}
          linkVariant={linkVariant}
          linkStyle={linkStyle}
          organizationId={organizationId}
          onOpenModal={onOpenModal}
          onCloseModal={onCloseModal}
          label={label}
          onAuthorized={onAuthorized}
        />
      </S.Wrapper>
    );
  } else {
    return (
      <div>
        <CreateProjectButton
          isLink={isLink}
          disabled={disabled}
          linkVariant={linkVariant}
          linkStyle={linkStyle}
        />
      </div>
    );
  }
};

export default NewProjectButton;
