import styled, { keyframes, css } from "styled-components";

import * as Toast from "ds/Toast";

import { ANIMATION_DURATION } from "./Notifications.utils";

const slideIn = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
`;

export const Root = styled(Toast.Root)<{ $animateOut: boolean }>`
  animation: ${slideIn} ${ANIMATION_DURATION}ms linear 1;

  ${({ $animateOut }) =>
    $animateOut &&
    css`
      animation: ${slideOut} ${ANIMATION_DURATION}ms ease-out 1;
    `}
`;
