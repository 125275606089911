import moment from "moment";

import type { Environment } from "platformsh-client";

export type EnvironmentTreeData = Pick<
  Environment,
  | "created_at"
  | "deployment_state"
  | "deployment_target"
  | "head_commit"
  | "status"
  | "title"
  | "type"
  | "updated_at"
> & {
  children: EnvironmentTreeData[];
  deployed_at: string;
  depth: number;
  environmentId: string;
  isRoot: boolean;
  projectId: string;
};

export const getAllEnvironments = (
  tree: EnvironmentTreeData[] | undefined
): EnvironmentTreeData[] => {
  if (!tree) return [];

  return tree.reduce((acc, current) => {
    if (!current) return acc;
    return acc.concat(current, getAllEnvironments(current.children));
  }, [] as EnvironmentTreeData[]);
};

export const getTreeData = (
  nodes: Environment[],
  environments: Environment[],
  depth = 0
): EnvironmentTreeData[] => {
  const environmentKeys = environments.map(env => env.id);

  return nodes.map(node => {
    const orphanedEnvs = environments.filter(
      env => env.parent && !environmentKeys.includes(env.parent)
    );

    orphanedEnvs.forEach(env => {
      env.parent = node.id;
    });

    const newNodes = environments.filter(
      environment => environment.parent === node.id
    );

    const children =
      newNodes.length > 0 ? getTreeData(newNodes, environments, depth + 1) : [];

    return {
      environmentId: node.id,
      projectId: node.project,
      created_at: node.created_at,
      updated_at: node.updated_at,
      title: node.title,
      type: node.type,
      status: node.status,
      head_commit: node.head_commit,
      deployment_state: node.deployment_state,
      deployed_at:
        node.deployment_state?.last_deployment_at || moment().format(),
      deployment_target: node.deployment_target,
      isRoot: !node.parent,
      depth,
      children
    };
  });
};
