import { createSlice } from "@reduxjs/toolkit";

import ObservabilityPipelineHttpClient from "Api/observability/pipeline/client";

import { loadObservabilityPipelineConfiguration } from "./thunks";
import { ObservabilityPipelineState } from "./types";

const initialState: ObservabilityPipelineState = {
  clients: {}
};

const observabilityPipelineReducer = createSlice({
  name: "observabilityPipeline",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      loadObservabilityPipelineConfiguration.fulfilled,
      (state, action) => {
        if (!action.payload) {
          return;
        }

        const client = new ObservabilityPipelineHttpClient(
          action.meta.arg.projectId,
          action.meta.arg.machineName,
          action.payload
        );
        state.clients[
          `${action.meta.arg.projectId}/${action.meta.arg.machineName}`
        ] = client;
      }
    );
  }
});

export default observabilityPipelineReducer.reducer;
