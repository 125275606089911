import React from "react";

import { FormattedMessage } from "react-intl";

import { capitalize } from "Libs/utils";
import PlusIcon from "UI/icons/Plus";

import Button, { PolymorphicProps } from "./Button";

const AddButton = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  PolymorphicProps
>(({ children, ...props }, ref) => (
  // Typecasing ref as `any` allows us to avoid repeating the type
  // discrimination currently located on the Button component. This does not
  // affect the external types since PolymorphicProps is being used on the
  // forwardRef typing already
  <Button {...props} ref={ref as any} content="mixed">
    <PlusIcon />
    {children ? (
      typeof children === "string" ? (
        capitalize(children)
      ) : (
        children
      )
    ) : (
      <FormattedMessage id="add" />
    )}
  </Button>
));

AddButton.displayName = "AddButton";

export default AddButton;
