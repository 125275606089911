import { createAsyncThunk } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import { environmentSelector } from "Reducers/environment";
import { AsyncThunkOptionType } from "Reducers/types";

import type { Activity } from "platformsh-client";

export const redeploy = createAsyncThunk<
  Activity,
  {
    organizationId: string;
    projectId: string;
    environmentId: string;
  },
  AsyncThunkOptionType
>(
  "app/environment/actions/redeploy",
  async (
    { organizationId, projectId, environmentId },
    { getState, rejectWithValue }
  ) => {
    try {
      const environment = environmentSelector(getState(), {
        organizationId,
        projectId,
        environmentId
      });

      if (!environment) {
        return rejectWithValue({ message: "Environment not found" });
      }

      const redeploy = await environment.redeploy();
      return redeploy;
    } catch (error: unknown) {
      if (![404, 403].includes((error as { code: number })?.code)) {
        logger(error, {
          action: "redeploy",
          meta: {
            organizationId,
            environmentId,
            projectId
          }
        });
      }
      return rejectWithValue({
        message: (error as { message: string })?.message
      });
    }
  }
);
