import styled from "styled-components";

import { semiBoldAlias } from "Libs/theme";

export const Wrapper = styled.div``;

export const Live = styled.span`
  display: inline-block;
  margin: 4px 0 4px auto;
  background-color: var(
    --section-color-primary-100-background-color,
    var(--color-primary-100)
  );
  border-radius: 2px;
  color: var(--section-color-primary-600-color, var(--color-primary-600));
  font-size: 12px;
  line-height: 24px;
  height: 24px;
  padding: 0 8px;
  ${semiBoldAlias()};
`;
