import { createAsyncThunk } from "@reduxjs/toolkit";

import client from "Libs/platform";

interface GetInvitationsCallback {
  projectId: string;
  organizationId: string;
}

// Fetches invitations for a given project
export const fetchInvitationsThunk = createAsyncThunk(
  "app/invitations",
  async ({ projectId }: GetInvitationsCallback) => {
    const invitations = await client.getInvitations(projectId);
    return invitations;
  }
);
