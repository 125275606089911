import { createSelector, createSlice } from "@reduxjs/toolkit";

import { RootState } from "Store/configureStore";

export type TestModeStateTrial = {
  startDate?: Date | string;
  expirationDate?: Date | string;
  status: string;
  days_remaining?: number;
};

export type TestModeState = {
  isActive?: boolean;
  theme: string;
  trial: TestModeStateTrial;
  systemStatus: string;
};

export const testModeSelector = (state: RootState) => state.testMode;

export const isActiveSelector = createSelector(
  testModeSelector,
  ({ theme, trial, systemStatus }) =>
    theme !== "" ||
    trial.startDate !== "" ||
    trial.expirationDate !== "" ||
    trial.status !== "" ||
    systemStatus !== ""
);

export const currentTestTrialSelector = createSelector(
  testModeSelector,
  ({ trial: { startDate, expirationDate, status, days_remaining } }) =>
    startDate !== "" ||
    expirationDate !== "" ||
    status !== "" ||
    days_remaining !== undefined
      ? {
          active: status === "active",
          created: status === "created",
          expiration: expirationDate,
          spend: { amount: 0 },
          current: { amount: 99, currency_code: "USD" },
          status,
          days_remaining
        }
      : null
);

const initialState: TestModeState = {
  theme: "",
  trial: {
    startDate: "",
    expirationDate: "",
    status: "",
    days_remaining: undefined
  },
  systemStatus: ""
};

const testMode = createSlice({
  name: "testMode",
  initialState,
  reducers: {
    setTestTheme(state, { payload: theme }) {
      state.theme = theme;
    },
    setTestTrialModes(state, { payload: trialModes }) {
      state.trial = trialModes;
    },
    setTestSystemStatus(state, { payload: systemStatus }) {
      state.systemStatus = systemStatus;
    }
  }
});

export const { setTestTheme, setTestSystemStatus, setTestTrialModes } =
  testMode.actions;

export default testMode.reducer;
