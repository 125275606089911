import React from "react";

export const Critical = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM10 11C9.44772 11 9 10.5523 9 10V5C9 4.44772 9.44772 4 10 4C10.5523 4 11 4.44772 11 5V10C11 10.5523 10.5523 11 10 11ZM10 15C10.5523 15 11 14.5523 11 14C11 13.4477 10.5523 13 10 13C9.44771 13 9 13.4477 9 14C9 14.5523 9.44771 15 10 15Z"
        fill="#FB3728"
      />
    </svg>
  );
};

export default Critical;
