import React from "react";

import * as S from "./styles";

interface NodeProps {
  children: React.ReactNode;
  id?: string | null;
  isActive: boolean;
  to: string;
  separator?: string;
}

const Node = ({ children, id, isActive, to, ...props }: NodeProps) => (
  <S.Wrapper {...props}>
    <S.Link
      aria-current={isActive ? "page" : undefined}
      id={id}
      isActive={isActive}
      to={to}
    >
      {children}
    </S.Link>
  </S.Wrapper>
);

export default Node;
