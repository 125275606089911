import styled from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const Field = styled.input`
  display: flex;
  align-items: center;
  text-align: center;
  width: 44px;
  height: 48px;
  box-sizing: border-box;
  border-radius: var(
    --box-wrapper-section-border-radius,
    var(--section-8px-border-radius, var(--8px))
  );
  border: 1px solid var(--mode-border-neutral-default);
  background-color: var(--mode-bg-neutral-weaker);
  font-size: 20px;
  line-height: 32px;
  color: var(--mode-text-neutral-default);
  font-family: "Open Sans";
  font-weight: 700;
  outline: none;
  box-shadow: 0px 0px 2px 1px var(--mode-bs-primary-focus);

  &:focus {
    border-color: var(--mode-border-primary-default);
    box-shadow: 0px 0px 2px 1px var(--theme-primary-800);
  }

  &[aria-invalid="true"] {
    border-color: var(--mode-border-critical-default);
    background-color: var(--mode-bg-critical-weakest);

    &:focus {
      border-color: var(--mode-border-critical-default);
      box-shadow: none;
    }
  }
`;
