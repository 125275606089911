import React from "react";

import Icon from "ds/Icon";
import { WrappingIconProps } from "ds/Icon/Icon";

const Branch = (props: WrappingIconProps) => {
  return (
    <Icon {...props}>
      <path d="M9.59998 18.1L7.99998 16.5L10.4 14.1C10.8 13.7 10.8 13.1 10.4 12.7L10.3 12.6C9.89998 12.2 9.29997 12.2 8.89997 12.6L6.49998 15L4.89997 13.4C4.59997 13.1 3.99998 13.3 3.99998 13.8V18.5C3.99998 18.8 4.19998 19 4.49998 19H9.19998C9.69998 19 9.89998 18.5 9.59998 18.1ZM15.3 19H19.6C19.9 19 20.1 18.8 20.1 18.5V14.2C20.1 13.8 19.6 13.5 19.2 13.8L17.8 15.2L13.7 11.1C13.3 10.7 13.1 10.2 13.1 9.7V4C13.1 3.4 12.7 3 12.1 3C11.5 3 11.1 3.4 11.1 4V10.6C11.1 11.1 11.3 11.6 11.7 12L16.4 16.7L15 18.1C14.6 18.5 14.9 19 15.3 19Z" />
    </Icon>
  );
};

export default Branch;
