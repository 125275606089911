import styled, { css as styledCSS } from "styled-components";

import MenuLink from "Containers/NavBar/UserMenu/MenuLink";
import Button from "ds/Button";
import { breakpoints } from "Libs/theme";

export const NavBarRightLayout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  min-width: 184px;
  height: 32px;
  flex-shrink: 2;
  overflow: visible;
  a {
    padding: 0 8px;
    line-height: 32px;
    height: 32px;
    &:hover,
    &:focus {
      background-color: rgba(255, 255, 255, 0.12);
      border-radius: 4px;
      outline: none;
    }
  }
  .navbar-link {
    color: #fff;
    text-decoration: none;
    padding: 0 8px;
    margin-right: 20px;
  }
`;

export const NavBarLayout = styled.header`
  width: 100%;
  max-width: 100%;
  min-height: 32px;
  box-sizing: border-box;
  padding: 0;
  font-weight: 300;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome and Opera */
  &.user-menu-fix {
    z-index: auto;
  }
  &.settings {
    background-color: var(
      --settings-navbar-background-color,
      var(--navbar-dark-shade-background-color, var(--dark-shade))
    );
  }
  .navigation-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 18px;
  }
  .float-box-wrapper {
    padding: 0 24px;
    border-radius: 20px;
  }
  .float-box {
    color: var(--input-snow-color, var(--snow));
    background-color: var(
      --navbar-layout-section-background-color,
      var(--section-ebony-background-color, var(--ebony))
    );
    border-radius: var(
      --navbar-layout-section-border-radius,
      var(--section-0px-border-radius, var(--0px))
    );

    box-sizing: border-box;
    margin-top: -24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    box-shadow: var(--box-shadow)
      var(--input-ebony-shadow-box-shadow-color, var(--ebony-shadow));
    margin: 0 -24px;
    .navigation-menu {
      border-radius: 20px;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      width: 100%;
      > nav,
      > .info-button {
        width: 50%;
      }
    }
    > .search-wrapper {
      flex: 1;
      font-weight: 400;
      margin-left: 24px;
      width: 574px;
      max-width: 100%;
      margin-right: 72px;
      @media (max-width: 1024px) {
        margin-right: 24px;
      }
      @media (max-width: 768px) {
        border-radius: 20px;

        margin-right: 16px;
      }
      .search-image {
        position: absolute;
        left: 10px;
        top: 16px;
      }
      input.search {
        color: #fff;
        font-size: 14px;
        font-weight: 400 !important;
        padding-left: 42px;
        &::placeholder {
          color: rgba(255, 255, 255, 0.5);
          font-weight: 400 !important;
        }
      }
      &.search-active input.search:focus {
        border: none;
        box-shadow: none;
      }
      svg {
        opacity: 0.5;
        path,
        rect {
          fill: #fff;
        }
      }
      &:hover {
        .search-image {
          opacity: 1;
        }
        svg {
          opacity: 1;
          rect {
            fill: #fff;
          }
        }
        input.search::placeholder {
          color: #fff;
        }
      }
      &:focus-within {
        svg {
          opacity: 1;
        }
        &:after {
          display: none;
        }
      }
      &:after {
        display: none;
      }
    }
    @media only screen and (max-width: ${breakpoints[2]}) {
      margin: 0;
      padding: 12px 16px;
    }
  }
  span.add-project {
    min-width: 144px;
    text-align: right;
    margin-right: 24px;
    white-space: nowrap;
  }
  .action-buttons {
    padding: 0 25px;
    position: absolute;
    bottom: -110px;
  }
  @media (min-width: 768px) {
    position: relative;

    .float-box-wrapper {
      padding: 0 40px;
    }
    .float-box {
      height: 56px;
      margin: -34px 0 0;
      padding: 0 12px;
      .navigation-menu {
        width: auto;
        margin-left: -12px;
        > nav,
        > .info-button {
          width: 232px;
        }
      }
      > .search-wrapper {
        margin-left: 0;
      }
      @media only screen and (max-width: ${breakpoints[2]}) {
        padding: 12px;
      }
    }
    .action-buttons {
      padding: 0;
      position: relative;
      bottom: auto;
    }
    span.add-project {
      margin-right: 0;
    }
  }
`;

export const NavBarLeftLayout = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: row;
  line-height: 32px;
  padding-bottom: 20px;
  text-overflow: ellipsis;

  #test-logo {
    height: 100%;
    overflow: hidden;
    text-align: center;
  }

  #breadcrumb a {
    color: var(--navbar-snow-color, var(--snow));
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    height: 32px;
  }
  a.header-arrow,
  a.arrow-toggle {
    z-index: 3;
    font-size: 12px;
    font-weight: 500;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }
  svg {
    > g > g {
      fill: #fff;
    }
  }
  .header-arrow-open span {
    svg {
      > g > g {
        fill: #fff;
      }
    }
  }
  @media (min-width: 768px) {
    flex-direction: row;
    padding-bottom: 0;
    width: 0;
    flex: 1;
    align-items: start;
  }
`;

export const NavBarLogo = styled.div`
  max-height: 36px;
  min-width: 28px;
  display: inline-block;
  a {
    position: relative;
    display: flex;
    align-items: center;
    line-height: 32px;
    height: 32px;
    &.logo {
      height: 36px;
      width: auto;
      display: inline-flex;
      justify-content: center;
      margin: -4px;
      padding: 4px;
      > div {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        svg {
          max-height: 100%;
          max-width: 100%;
        }
      }
      &:hover {
        background: rgba(255, 255, 255, 0.16);
        border-radius: 2px;
      }
      &:focus {
        margin: -5px;
        outline: none;
        border: 1px solid
          var(--button-color-primary-800-border-color, var(--color-primary-800));
      }
      box-shadow: 0 0 2px
        var(
          --button-color-primary-800-box-shadow-color,
          var(--color-primary-800)
        );
    }
  }
`;

export const PageHeader = styled.div<{
  reduceHeight?: boolean;
}>`
  background-color: var(
    --mode-overrides-header-bg,
    var(--navbar-dark-shade-background-color, var(--dark-shade))
  );
  color: var(--navbar-snow-color, var(--snow));
  padding: 0 24px;
  @media (min-width: 768px) {
    min-height: ${props => (props.reduceHeight ? "64px" : "92px")};
    padding: 0 40px;
  }
  @media (min-width: 1920px) {
    padding: 0 60px;
  }
`;

export const OrganizationDivider = styled.span<{
  hasDivider?: boolean;
}>`
  @media (max-width: 768px) {
    display: none;
  }
  color: var(
    --divider-section-color,
    var(--section-ebony-light-color, var(--ebony-light))
  );

  ${props =>
    props.hasDivider &&
    styledCSS`
       &:after {
         content: "";
         display: block;
         position: relative;
         top: -1px;
         transform: translateY(50%);
         width: 1px;
         height: 18px;
         background-color: var(--header-ebony-light-background-color,var(--ebony-light));
       }
     `}
`;

export const BannerButton = styled(Button)`
  margin-left: 20px;
`;

export const FloatingContainer = styled.div`
  li a,
  #test-logo {
    padding: 0;
  }
  position: fixed;

  overflow: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  background-color: var(
    --copy-button-button-background-color,
    var(--button-snow-background-color, var(--snow))
  );

  display: flex;
  flex-direction: column;

  ul,
  ol {
    li {
      list-style: none;
    }
  }
`;

export const Layout = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
`;

export const UserMenu = styled.ul`
  padding-left: 24px;
  width: 100%;
  list-style: none;
  margin-top: 0;
  margin-left: 0;
  margin-bottom: 32px;
  margin-right: 0;
  padding: 0 16px;
  font-family: "Open sans", sans-serif;

  li:not(:last-child) {
    padding-bottom: 16px;
  }

  a {
    box-sizing: border-box;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    text-decoration: none;
    font-weight: 400;

    &:hover {
      background-color: var(
        --link-color-primary-100-background-color,
        var(--color-primary-100)
      );
      border-radius: 4px;
    }
    &:focus {
      outline: none;
      border: 1px solid var(--color-primary-600);
      border-radius: 4px;
      box-shadow: 0 0 2px var(--color-primary-600);
      margin: -1px;
    }
    &.active,
    &:active {
      background: transparent;
      border: none;
      box-shadow: none;
      margin: 0;
      span {
        color: var(--link-color-primary-600-color, var(--color-primary-600));
      }
    }
  }

  a.add-link {
    font-size: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    height: auto;

    :active,
    :focus,
    :hover {
      height: auto;
      border-radius: 2px;
      background-color: var(
        --link-color-primary-800-background-color,
        var(--color-primary-800)
      );
    }
  }
`;

export const ThemeText = styled.p`
  color: var(--section-granite-color, var(--granite));
  font-family: "Open sans", sans-serif;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  white-space: nowrap;
`;

export const CustomMenuLink = styled(MenuLink)`
  color: var(--mode-text-neutral-default);
`;

export const ButtonTheme = styled.div<{
  selectedTheme?: string;
}>`
  margin-left: 4px;
  display: flex;
  height: 24px;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: ${props =>
    props.theme.name === props.selectedTheme
      ? "var(--mode-bg-primary-weakest)"
      : "transparent"};
  cursor: pointer;
`;

export const ThemeUIText = styled.p<{
  selectedTheme?: string;
}>`
  color: ${props =>
    props.theme.name === props.selectedTheme
      ? "var(--mode-text-primary-default)"
      : "var(--granite)"};
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
`;
