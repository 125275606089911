import { blackfireApiRequest } from "Api/blackfireApiRequest";
import { ObservabilityPipelineProjectUrls } from "Reducers/observability/pipeline";

export const fetchObservabilityPipelineConfiguration = (
  projectId: string,
  machineName: string
) => {
  return blackfireApiRequest<ObservabilityPipelineProjectUrls | undefined>(
    `/platformsh-api/${projectId}/${machineName}/configuration`
  );
};
