import styled from "styled-components";

import MenuLink from "Containers/NavBar/UserMenu/MenuLink";

export const Layout = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
`;

export const UserMenu = styled.ul`
  padding-left: 34px;
  width: 100%;
  list-style: none;
  margin-top: 0;
  margin-left: 0;
  margin-bottom: 32px;
  margin-right: 0;
  padding: 0 34px;
  font-family: "Open sans", sans-serif;

  li:not(:last-child) {
    padding-bottom: 16px;
  }

  a {
    display: inline-block;
    box-sizing: border-box;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    text-decoration: none;
    font-weight: 400;
    &:hover {
      background-color: var(
        --link-color-primary-100-background-color,
        var(--color-primary-100)
      );
      border-radius: 4px;
    }
    &:focus {
      outline: none;
      border: 1px solid var(--color-primary-600);
      border-radius: 4px;
      box-shadow: 0 0 2px var(--color-primary-600);
      margin: -1px;
    }
    &.active,
    &:active {
      background: transparent;
      border: none;
      box-shadow: none;
      margin: 0;
      span {
        color: var(--link-color-primary-600-color, var(--color-primary-600));
      }
    }
  }

  a.add-link {
    font-size: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    height: auto;

    :active,
    :focus,
    :hover {
      height: auto;
      border-radius: 2px;
      background-color: var(
        --link-color-primary-800-background-color,
        var(--color-primary-800)
      );
    }
  }
`;

export const CustomMenuLink = styled(MenuLink)`
  color: var(--mode-text-neutral-default);
`;
