import { createSelector } from "@reduxjs/toolkit";

import { SUPPORT_ROLES } from "src/constants/constants";
import { RootState } from "Store/configureStore";

export const userStateSelector = (state: RootState) => state.user;

export const usersSelector = createSelector(
  [userStateSelector, (_: RootState, userId: string) => userId],
  (userState, userId) => userState.data?.[userId]
);

export const isUserSupport = createSelector([usersSelector], user =>
  user?.roles?.some(role => SUPPORT_ROLES.includes(role))
);
