import { createSlice } from "@reduxjs/toolkit";

import { loadUser, UserState } from "./";

const initialState: UserState = {
  data: {},
  loading: false
};

const user = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: builder => {
    // LOAD USER
    builder
      .addCase(loadUser.pending, state => {
        state.loading = true;
      })
      .addCase(loadUser.fulfilled, (state, { payload }) => {
        state.data[payload.id] = payload;
        state.loading = false;
      })
      .addCase(loadUser.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      });
  }
});

export default user.reducer;
