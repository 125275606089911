import { createSelector } from "@reduxjs/toolkit";

import { BackupEnvironmentPropType } from ".";

import type { RootState } from "Store/configureStore";

export const backupStateSelector = (state: RootState) => state.backup;

export const environmentBackupSelector = createSelector(
  backupStateSelector,
  (
    _: RootState,
    params: Pick<
      BackupEnvironmentPropType,
      "organizationId" | "projectId" | "environmentId"
    >
  ) => params,
  (backupState, { organizationId, projectId, environmentId }) =>
    backupState.data?.[organizationId]?.[projectId]?.[environmentId]
);
