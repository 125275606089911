import { createSlice } from "@reduxjs/toolkit";

import { setDeep } from "Libs/objectAccess";
import { CommonErrorType } from "Reducers/types";

import getGitBlobContent from "./thunk/getGitBlobContent.thunk";
import getGitCommit from "./thunk/getGitCommit.thunk";
import getGitTree from "./thunk/getGitTree.thunk";

import type { Tree, Commit } from "platformsh-client";

type GitStateType<D> = {
  data?: D;
  error?: CommonErrorType;
  isLoading?: boolean;
  subscriberCount: number;
};

type InitGitStateType = {
  tree: {
    [id: string]: GitStateType<Tree>;
  };
  blob: {
    [id: string]: GitStateType<string>;
  };
  commit: { [id: string]: GitStateType<Commit> };
};

const initialState: InitGitStateType = {
  tree: {},
  blob: {},
  commit: {}
};

// Create a slice
const gitSlice = createSlice({
  name: "git",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // Reducer for getGitCommit
      .addCase(getGitCommit.pending, (state, action) => {
        const { arg } = action.meta;
        if (!arg.envHeadCommit) return;
        setDeep(state, ["commit", arg.envHeadCommit, "error"], undefined);
        setDeep(state, ["commit", arg.envHeadCommit, "isLoading"], true);
      })
      .addCase(getGitCommit.fulfilled, (state, action) => {
        const { arg } = action.meta;
        if (!arg.envHeadCommit) return;
        setDeep(state, ["commit", arg.envHeadCommit, "isLoading"], false);
        setDeep(state, ["commit", arg.envHeadCommit, "data"], action.payload);
      })
      .addCase(getGitCommit.rejected, (state, action) => {
        const { arg } = action.meta;
        if (!arg.envHeadCommit) return;
        setDeep(
          state,
          ["commit", arg.envHeadCommit, "error"],
          action.payload.error
        );
        setDeep(state, ["commit", arg.envHeadCommit, "isLoading"], false);
      })
      // Reducer for getGitTree
      .addCase(getGitTree.pending, (state, action) => {
        const { arg } = action.meta;
        if (!arg.commitId) return;
        setDeep(state, ["tree", arg.commitId, "error"], undefined);
        setDeep(state, ["tree", arg.commitId, "isLoading"], true);
      })
      .addCase(getGitTree.fulfilled, (state, action) => {
        const { arg } = action.meta;
        if (!arg.commitId) return;
        setDeep(state, ["tree", arg.commitId, "isLoading"], false);
        setDeep(state, ["tree", arg.commitId, "data"], action.payload);
      })
      .addCase(getGitTree.rejected, (state, action) => {
        const { arg } = action.meta;
        if (!arg.commitId) return;
        setDeep(state, ["tree", arg.commitId, "error"], action.payload.error);
        setDeep(state, ["tree", arg.commitId, "isLoading"], false);
      })

      // Reducer for getGitBlobContent
      .addCase(getGitBlobContent.pending, (state, action) => {
        const { arg } = action.meta;
        const sha = arg?.sha;
        if (!sha) return;
        setDeep(state, ["blob", sha, "error"], undefined);
        setDeep(state, ["blob", sha, "isLoading"], true);
      })
      .addCase(getGitBlobContent.fulfilled, (state, action) => {
        const { arg } = action.meta;
        const sha = arg?.sha;
        if (!sha) return;
        setDeep(state, ["blob", sha, "isLoading"], false);
        setDeep(state, ["blob", sha, "data"], action.payload);
      })
      .addCase(getGitBlobContent.rejected, (state, action) => {
        const { arg } = action.meta;
        const sha = arg?.sha;
        if (!sha) return;
        setDeep(state, ["blob", sha, "error"], action.payload.error);
        setDeep(state, ["blob", sha, "isLoading"], false);
      });
  }
});

export default gitSlice;
