import { createSelector } from "@reduxjs/toolkit";

import type { RootState } from "Store/configureStore";

export const memberData = (state: RootState) => state.memberData;

export const organizationProjectsSelector = createSelector(
  memberData,
  state => state.organizationProjects.organizationProjects
);

export const organizationProjectsLoadingSelector = createSelector(
  memberData,
  state => state.organizationProjects.isLoading
);
