import config from "console_config";

export const redirectIfAuthenticated = () => {
  if (document.cookie && document.cookie.includes("skip_login_form")) {
    window.location.href = config.URL_REDIRECT_AUTH_USER;
    return true;
  }

  return false;
};

let tracking = new URLSearchParams();

export const setTracking = t => {
  tracking = t;
};

export const getTracking = () => {
  return tracking;
};
