import styled from "styled-components";

import { CloseButton as BaseCloseButton } from "ds/Button";
import { BodyText } from "ds/Typography";

export const VariableWrapper = styled.div``;

export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const VariableInput = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 2fr 3fr max-content;
`;

export const CloseButton = styled(BaseCloseButton)`
  margin-top: 26px;
`;

export const AddVariableHeader = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const VariableInfo = styled(BodyText)`
  margin-bottom: 32px;
`;
