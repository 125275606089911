import React from "react";

import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const MergeIcon = ({ color = "#fff" }) => {
  const uid = useUniqueId();

  return (
    <svg
      className="icon-merge"
      height="24px"
      width="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`merge-icon-${uid}`}
    >
      <title id={`merge-icon-${uid}`}>Merge icon</title>
      <g>
        <g transform="translate(-89.000000, -1127.000000)">
          <g transform="translate(50.000000, 920.000000)">
            <g transform="translate(34.000000, 204.000000)">
              <path
                fill={color}
                className="fill-target"
                d="M22.7,22.7L22.7,22.7c0.4-0.4,0.4-1,0-1.4l-2-2c-0.4-0.4-1-0.4-1.4,0l0,0c-0.4,0.4-0.4,1,0,1.4l2,2
					C21.7,23.1,22.3,23.1,22.7,22.7z M13.7,11H16v4.8c0,0.5-0.2,1-0.6,1.4l-4.1,4.1c-0.4,0.4-0.4,1,0,1.4h0c0.4,0.4,1,0.4,1.4,0
					l4.7-4.7c0.4-0.4,0.6-0.9,0.6-1.4V11h2.3c0.4,0,0.7-0.5,0.4-0.9l-3.3-3.3c-0.2-0.2-0.5-0.2-0.7,0l-3.3,3.3
					C13,10.5,13.3,11,13.7,11z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

MergeIcon.propTypes = {
  color: PropTypes.string
};

export default MergeIcon;
