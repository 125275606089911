import { combineReducers } from "redux";

import commentsReducer from "Reducers/tickets/comments";
import ticketReducer from "Reducers/tickets/ticket";

const reducer = combineReducers({
  comments: commentsReducer,
  ticket: ticketReducer
});

export default reducer;
