import React, { useEffect } from "react";

import { sendHeapEventAccordion } from "Store/analytics/heapFunctions";

import * as S from "./Accordion.styles";

const Root = ({ type, ...props }) => {
  useEffect(() => {
    sendHeapEventAccordion(props.value);
  }, [props.value]);

  return <S.Root type={type} {...props} />;
};

export default Root;
