import React from "react";

import Icon from "ds/Icon";
import { WrappingIconProps } from "ds/Icon/Icon";

const CloseSm = (props: WrappingIconProps) => {
  return (
    <Icon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7539 7.24614C16.4257 6.91795 15.8936 6.91795 15.5654 7.24614L12 10.8115L8.4346 7.24614C8.10642 6.91795 7.57432 6.91795 7.24614 7.24614C6.91795 7.57432 6.91795 8.10642 7.24614 8.4346L10.8115 12L7.24614 15.5654C6.91795 15.8936 6.91795 16.4257 7.24614 16.7539C7.57433 17.082 8.10642 17.082 8.4346 16.7539L12 13.1885L15.5654 16.7539C15.8936 17.082 16.4257 17.082 16.7539 16.7539C17.082 16.4257 17.082 15.8936 16.7539 15.5654L13.1885 12L16.7539 8.4346C17.082 8.10642 17.082 7.57432 16.7539 7.24614Z"
      />
    </Icon>
  );
};

export default CloseSm;
