import { createAsyncThunk } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import { environmentSelector } from "Reducers/environment";
import { AsyncThunkOptionType } from "Reducers/types";

import { BackupEnvironmentPropType } from "../";

import type { Activity } from "platformsh-client";

export const backup = createAsyncThunk<
  Activity,
  BackupEnvironmentPropType,
  AsyncThunkOptionType
>(
  "environment/backup",
  async (
    { organizationId, projectId, environmentId, safe },
    { getState, rejectWithValue }
  ) => {
    try {
      const environment = environmentSelector(getState(), {
        organizationId,
        projectId,
        environmentId
      });

      if (!environment) {
        return rejectWithValue({ message: "Environment not found" });
      }

      const backup = await environment.backup(safe);
      return backup;
    } catch (err) {
      logger(err as Error, {
        action: "backup",
        meta: {
          organizationId,
          projectId,
          environmentId
        },
        payload: backup
      });
      return rejectWithValue(err);
    }
  }
);
