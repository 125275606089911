import React from "react";

import Icon from "ds/Icon";
import { WrappingIconProps } from "ds/Icon/Icon";

const Discount = (props: WrappingIconProps) => {
  return (
    <Icon {...props} viewBox="0 0 26 26" width={24} height={24}>
      <path
        id="Vector"
        d="M3.65166 6.64691C3.65166 5.87419 3.9607 5.13312 4.51081 4.58673C5.06092 4.04034 5.80702 3.73337 6.58499 3.73337H7.91832C8.69285 3.73293 9.43578 3.42825 9.98499 2.8858L10.9183 1.95876C11.1909 1.68648 11.515 1.4704 11.872 1.32296C12.2289 1.17551 12.6117 1.09961 12.9983 1.09961C13.3849 1.09961 13.7677 1.17551 14.1247 1.32296C14.4816 1.4704 14.8057 1.68648 15.0783 1.95876L16.0117 2.8858C16.5609 3.42825 17.3038 3.73293 18.0783 3.73337H19.4117C20.1896 3.73337 20.9357 4.04034 21.4858 4.58673C22.0359 5.13312 22.345 5.87419 22.345 6.64691V7.97125C22.3454 8.74056 22.6522 9.47847 23.1983 10.024L24.1317 10.951C24.4058 11.2218 24.6233 11.5437 24.7718 11.8982C24.9202 12.2528 24.9966 12.633 24.9966 13.017C24.9966 13.401 24.9202 13.7812 24.7718 14.1357C24.6233 14.4903 24.4058 14.8122 24.1317 15.0829L23.1983 16.01C22.6522 16.5555 22.3454 17.2934 22.345 18.0627V19.387C22.345 20.1598 22.0359 20.9008 21.4858 21.4472C20.9357 21.9936 20.1896 22.3006 19.4117 22.3006H18.0783C17.3038 22.301 16.5609 22.6057 16.0117 23.1481L15.0783 24.0752C14.8057 24.3475 14.4816 24.5635 14.1247 24.711C13.7677 24.8584 13.3849 24.9343 12.9983 24.9343C12.6117 24.9343 12.2289 24.8584 11.872 24.711C11.515 24.5635 11.1909 24.3475 10.9183 24.0752L9.98499 23.1481C9.43578 22.6057 8.69285 22.301 7.91832 22.3006H6.58499C5.80702 22.3006 5.06092 21.9936 4.51081 21.4472C3.9607 20.9008 3.65166 20.1598 3.65166 19.387V18.0627C3.65121 17.2934 3.34446 16.5555 2.79832 16.01L1.86499 15.0829C1.59086 14.8122 1.37331 14.4903 1.22487 14.1357C1.07642 13.7812 1 13.401 1 13.017C1 12.633 1.07642 12.2528 1.22487 11.8982C1.37331 11.5437 1.59086 11.2218 1.86499 10.951L2.79832 10.024C3.34446 9.47847 3.65121 8.74056 3.65166 7.97125V6.64691Z"
        fill="#DDF933"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M8.98499 16.9773L16.985 9.03125"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M9.65165 10.3556C10.0198 10.3556 10.3183 10.0591 10.3183 9.69342C10.3183 9.32771 10.0198 9.03125 9.65165 9.03125C9.28346 9.03125 8.98499 9.32771 8.98499 9.69342C8.98499 10.0591 9.28346 10.3556 9.65165 10.3556Z"
        fill="black"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_4"
        d="M16.3183 16.9767C16.6865 16.9767 16.985 16.6802 16.985 16.3145C16.985 15.9488 16.6865 15.6523 16.3183 15.6523C15.9501 15.6523 15.6517 15.9488 15.6517 16.3145C15.6517 16.6802 15.9501 16.9767 16.3183 16.9767Z"
        fill="black"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default Discount;
