import React, { useEffect, useMemo, useState } from "react";

import { useIntl } from "react-intl";
import { formatPhoneNumberIntl } from "react-phone-number-input";

import ErrorComponent from "Components/Error";
import consoleConfig from "console_config";
import {
  StepsList,
  StepPropType
} from "Containers/PhoneNumberVerificationModal/types";
import { Button, ButtonWrapper } from "ds/Button";
import CodeField from "ds/CodeField";
import Loading from "ds/PageLoading";
import { BodyText } from "ds/Typography";
import useDecodedParams from "Hooks/useDecodedParams";
import { request } from "Libs/platform";
import { meSelector } from "Reducers/app/selectors";
import { getOrganizationProfile } from "Reducers/organization/profile";
import { useAppDispatch, useAppSelector } from "Store/hooks";

import * as C from "../Steps.styles";

const CODE_LENGTH = 6;

const Code = ({
  config,
  handleChangeConfig,
  goToStep,
  onCancel
}: StepPropType) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { organizationId } = useDecodedParams<{
    organizationId: string;
  }>();

  const [code, setCode] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [sessionId, setSessionId] = useState<string | undefined>();

  const me = useAppSelector(meSelector);

  useEffect(() => {
    if (!me?.id) return;

    const handleSendCode = async () => {
      setLoading(true);
      try {
        const data = await request(
          `${consoleConfig.URL_AUTH}/users/${me.id}/phonenumber`,
          "POST",
          {
            channel: config.methods,
            phone_number: config.phone
          }
        );
        setLoading(false);
        setSessionId(data.sid);
      } catch (err) {
        handleChangeConfig("showErrorMessage", true);
        goToStep(StepsList.phone);
        setLoading(false);
        console.error("err", err); // eslint-disable-line no-console
      }
    };

    handleSendCode();
  }, [config.methods, config.phone, goToStep, handleChangeConfig, me?.id]);

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!me) return;
    e.preventDefault();
    setLoading(true);

    try {
      await request(
        `${consoleConfig.URL_AUTH}/users/${me.id}/phonenumber/${sessionId}`,
        "POST",
        { code }
      ).then(response => {
        if (response.status >= 400 && response.status <= 499) {
          setError(response.error || response.message);
        }
      });
      onCancel();
      if (organizationId) dispatch(getOrganizationProfile({ organizationId }));
    } catch (err) {
      setLoading(false);
      console.error(err); // eslint-disable-line no-console
    }
  };

  const canSubmit = useMemo(
    () => !loading && code?.length === CODE_LENGTH,
    [code, loading]
  );

  if (loading) {
    return (
      <C.Wrapper>
        <Loading />
      </C.Wrapper>
    );
  }

  return (
    <C.Wrapper>
      <BodyText>
        {intl.formatMessage(
          { id: `kyc_verification.code.intro.${config.methods}` },
          {
            phone: formatPhoneNumberIntl(config.phone || "")
          }
        )}
      </BodyText>

      {error && <ErrorComponent>{error}</ErrorComponent>}

      <CodeField codeLength={CODE_LENGTH} onChange={str => setCode(str)} />

      <C.Help>
        {intl.formatMessage<React.ReactNode>(
          { id: "kyc_verification.code.help" },
          {
            a: chunk => (
              <Button
                analyticId="kyc_verification.code.help"
                variant="link"
                onClick={() => goToStep(StepsList.methods)}
              >
                {chunk}
              </Button>
            )
          }
        )}
      </C.Help>

      <ButtonWrapper justifyContent="end" spacing="modal">
        <Button
          analyticId="cancel"
          variant="secondary"
          type="button"
          aria-label={intl.formatMessage({ id: "cancel" })}
          onClick={onCancel}
        >
          {intl.formatMessage({ id: "cancel" })}
        </Button>
        <Button
          analyticId="kyc_verification.code.submit"
          type="submit"
          aria-label={intl.formatMessage({
            id: "kyc_verification.code.submit"
          })}
          disabled={!canSubmit}
          onClick={handleSubmit}
        >
          {intl.formatMessage({ id: "kyc_verification.code.submit" })}
        </Button>
      </ButtonWrapper>
    </C.Wrapper>
  );
};

export default Code;
